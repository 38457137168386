// export default {
//   title: `<span class="text">第2回EBC100万ドル取引大会</span>`,
//   title2: ``,
//   巴萨传奇再启: "新たなチャレンジが始まる",
//   成就交易之王: "",
//   大赛时间:
//     "大会期間：2025年3月1日（土）08:00:00～2025年5月31日（土）05:59:59（日本時間）",
//   距开赛: ``,
//   天: "d",
//   时: "h",
//   分: "m",
//   秒: "s",
//   一键跟单: "コピートレード",
//   免费复制冠军收益: "参加者の取引シグナルをコピー可能",
//   公开透明: "公正・透明",
//   观摩冠军交易技巧: "参加者の取引履歴を閲覧可能",
//   推荐有礼: "紹介キャンペーン",
//   推荐有奖上不封顶: "大会参加者を紹介して報酬を無制限に獲得",
//   立即参赛: "今すぐ参加",
//   现金奖池: "賞金総額",
//   巴萨梦之队: "100万ドルチャレンジコース",
//   拉玛西亚组: "3万ドルチャレンジコース",
//   冠军: "1位",
//   亚军: "2位",
//   季军: "3位",
//   第四至五名: "4～5位",
//   第六至十名: "6～10位",
//   "$1,000,000交易账号或$200,000现金":
//     "20万ドルの賞金または100万ドル分の入金済取引口座 ",
//   "1,000,000交易账号或200,000现金":
//     "20万ドルの賞金または100万ドル分の入金済取引口座 ",
//   大赛推荐官: "取引大会紹介キャンペーン",
//   推荐奖励: "大会参加者を紹介して報酬を獲得、上限なし",
//   巴萨梦之队HTML: `100万ドルチャレンジコース`,
//   拉玛西亚组HTML: `3万ドルチャレンジコース`,
//   "/人": "/一人",
//   活动时间:
//     "紹介期間：2025年2月22日（土）08:00:00～2025年5月23日（土）05:59:59（日本時間） ",
//   推荐流程: "紹介の流れ",
//   推荐好友: "参加者を紹介する",
//   好友参赛: "参加者が取引大会に登録",
//   赢得奖金: "報酬を獲得",
//   实时赛况: "最新ランキング",
//   排名: "ランキング",
//   昵称: "ニックネーム",
//   服务器: "サーバー",
//   账号: "取引口座番号",
//   收益额: "利益額",
//   收益率: "利益率",
//   奖金: "賞金",
//   跟单: "コピトレ",
//   该页面仅展示收益表现排名前10的交易账号:
//     "*このページでは、実績によってランク付けされたトップ10の取引口座のみを表示します。米ドルで表示され、1時間毎に自動更新されます。",
//   赛事进程: "大会スケジュール",
//   比赛开始: "大会及び紹介開始",
//   推荐结束: "紹介期間終了",
//   比赛结束: "大会終了",
//   颁奖典礼: "授賞式",
//   赛组规则: "大会ルール",
//   类别: "コース",
//   账号要求: "参加資格",
//   "新交易账号，且账号余额大于$500": "口座残高が500ドル以上の新規取引口座",
//   "新交易账号，且$10,000≤账号余额≤$200,000":
//     "口座残高が1万ドル以上20万ドル以下の新規取引口座",
//   排名规则: "順位決定方法",
//   以收益率排名为评判标准: "利益率でランキング付けられます。",
//   "收益率=期末净值/账号累积入金":
//     "利益率（%）=（最終残高額 / 累積入金額）x 100",
//   以净盈利额排名为评判标准: "純利益額でランキング付けられます。",
//   持仓限制: "ポジションの制限",
//   每个参赛账号最多持有100手仓位:
//     "各アカウントの最大ポジションは100 lotsとなります。",
//   无持仓限制: "ポジション無制限",
//   参赛条款: "大会規約",
//   参赛须知: "大会概要",
//   section6List: [
//     {
//       t: "1.開催期間  ",
//       c: [
//         "大会期間：2025年3月1日（土）08:00:00～2025年5月31日（土）05:59:59（日本時間）  ",
//       ],
//     },
//     {
//       t: "2.参加資格  ",
//       c: [
//         "大会参加に使用する取引口座（大会用口座）はEBCのコピートレードコミュニティーでシグナル提供者として登録する必要があります。ただし、大会用口座のシグナル公開設定（公開または未公開）に指定はありません。",
//         "参加者は複数の大会用口座で参加できますが、最も成績の良い口座のみがランキングに表示されます。",
//         "大会用口座にはSTD口座およびPRO口座のみが利用対象になります。PAMM口座とスワップフリー口座では参加できません。",
//       ],
//     },
//     {
//       t: "3.賞金について",
//       c: [
//         "1位入賞者は20万ドルの賞金または100万ドル分の入金済取引口座のいずれかを選ぶことができます。",
//         "100万ドルの取引口座を選択した場合、当該口座における最大損失限度額は20万ドルとなります。また、当該口座から出金される場合は、20万ドルと実現損益金額の合計となります。 ",
//         "その他の賞金は順位に応じて授与されます。 ",
//       ],
//     },
//     {
//       t: "4.禁止事項 ",
//       c: [
//         "弊社が不正と判断する取引行為は禁止とします。",
//         "サーバー故障や価格配信エラーなどのような不可抗力による注文は無効とします。",
//       ],
//     },
//     {
//       t: "5.本イベントの最終解釈権は当社に帰属します。",
//       c: [],
//     },
//   ],
//   参赛流程: "参加方法",
//   注册开户: "新規登録",
//   注册并开设新交易账号: "新規取引口座の開設",
//   报名参赛: "参加登録",
//   在用户中心报名参赛: "マイページから参加登録する",
//   参赛成功: "参加登録完了",
//   参赛成功查看实时赛况: "ランキングを確認可能",
//   往届回顾: "過去の大会の様子",
//   第一届交易大赛颁奖典礼: "第1回取引大会表彰式",
//   第一届交易大赛高光时刻: "第1回取引大会のハイライト",
//   第一届交易大赛巅峰对决: "第1回取引大会の最終決戦",
//   查看更多: "もっと見る",
//   常见问题: "よくある質問",
//   qaList: [
//     {
//       t: "1. 「EBC口座」と「取引口座」について",
//       c: [
//         "EBC口座：EBCのマイページにログインするアカウントを指します。お客様は本人確認認証後、1つのEBC口座（UID）しか持つことができません。",
//         "取引口座：MT4/MT5の取引口座を指します。各EBC口座の下に複数の取引口座を作成することができます。",
//       ],
//     },
//     {
//       t: "2. 大会への登録状況はどのように確認すればよいですか？",
//       c: [
//         "登録が完了すると、マイページの大会ページでお客様ご自身のランキングを確認することができます。",
//       ],
//     },
//     {
//       t: "3. 複数の取引口座で参加できますか？",
//       c: [
//         "各チャレンジコースに複数の取引口座で参加することはできますが、ランキングには最も成績の良い取引口座のみが表示されます。また、同一の取引口座は一つのコースのみ参加が可能です。",
//       ],
//     },
//     {
//       t: "4. 二つのコースから賞金を同時に獲得することはできますか？",
//       c: [
//         "できます。大会ルールに沿って参加した場合、二つのコースから賞金を獲得することができます。",
//       ],
//     },
//     {
//       t: "5. すでにMT4口座を持っていますが、大会に参加できますか?",
//       c: [
//         "取引口座は、2025年2月22日08:00:00（日本時間）以降に作成された新規取引口座で、取引履歴がないことが必要です。",
//       ],
//     },
//     {
//       t: "6. 3万ドルチャレンジコースに参加しましたが、あとからその取引口座に10,000ドル以上入金した場合、コースは変更されますか？",
//       c: [
//         "いいえ、コースは登録時に設定されるため、その後の残高変動がコースに影響することはありません。",
//       ],
//     },
//     {
//       t: "7. 大会ランキングはどのように確認できますか？",
//       c: [
//         "ランキングは1時間ごとに更新され（毎正時ではありません）、大会ページで確認できます。",
//       ],
//     },
//     {
//       t: "8. 大会中に入出金はできますか？",
//       c: [
//         "入出金は可能ですが、収益率の計算に影響を与えます。",
//         "利益率（%）=（最終残高額 / 累積入金額）x 100",
//       ],
//     },
//     {
//       t: "9. 紹介報酬の受け取り方法は？",
//       c: [
//         "報酬は大会終了後7営業日以内に紹介者へ入金いたします。紹介者へはカスタマーサポートより別途ご連絡させて頂きます。",
//       ],
//     },
//     {
//       t: "10. 被紹介者は、大会期間中に紹介者を変更することができますか？",
//       c: [
//         "公平性を確保するため、大会期間中の変更はできません。大会終了後、お客様が紹介者以外への紐づけを希望される場合は、カスタマーサポートまでお問い合わせください。",
//       ],
//     },
//   ],
// };
export default {
  title: `<span class="text">EBC's Million Dollar Trading Challenge II</span>`,
  title2: ``,
  巴萨传奇再启: "Trade Like a Champion: Inspired by Barça, Built for Winners",
  成就交易之王: "",
  大赛时间:"Event Duration: 1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replicate Top Trades in Just One-Click",
  公开透明: "Transparent",
  观摩冠军交易技巧: "Master the Trading Techniques of Champions",
  推荐有礼: "Refer for Rewards",
  推荐有奖上不封顶: "Unlimited Rewards for Every Referral",
  立即参赛: "Join Now",
  现金奖池: "Cash Prize Pool",
  巴萨梦之队: "Barça Dream Squad",
  拉玛西亚组: "La Masia Prodigies",
  冠军: "Champion",
  亚军: "Runner-up",
  季军: "Third place",
  第四至五名: "4th to 5th Place",
  第六至十名: "6th to 10th Place",
  "$1,000,000交易账号或$200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  "1,000,000交易账号或200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  大赛推荐官: "Referral Programme",
  推荐奖励: "Unlimited Referral Rewards",
  巴萨梦之队HTML: `Barça Dream Squad`,
  拉玛西亚组HTML: `La Masia Prodigies`,
  "/人": "/person",
  活动时间:
    "Event Duration: 22 February 2025, 01:00:00 (MT4/MT5 time) - 23 May 2025, 23:59:59 (MT4/MT5 time)",
  推荐流程: "Referral Process",
  推荐好友: "Refer Friends",
  好友参赛: "Friend Trades",
  赢得奖金: "Win Rewards",
  实时赛况: "Real-time Leaderboard",
  排名: "Rank",
  昵称: "Nickname",
  服务器: "Server",
  账号: "Account Number",
  收益额: "Profit",
  收益率: "ROI",
  奖金: "Prizes",
  跟单: "Copy Trading",
  该页面仅展示收益表现排名前10的交易账号:
    "*This page only displays the top ten trading accounts ranked by profit performance, with the leaderboard in USD and updated every hour",
  赛事进程: "Event Timeline",
  比赛开始: "Challenge Begins",
  推荐结束: "Referral Period Ends",
  比赛结束: "Challenge Ends",
  颁奖典礼: "Awards Ceremony",
  赛组规则: "Event Rules",
  类别: "Categories",
  账号要求: "Account Requirements",
  "新交易账号，且账号余额大于$500":
    "A new trading account with a starting balance ≥ $500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "A new trading account with a starting balance of $10,000 ≤ $200,000 ",

  排名规则: "Leaderboard Rules",
  以收益率排名为评判标准:
    "Rankings are determined based on the rate of return",
  "收益率=期末净值/账号累积入金":
    "Rate of Return = Net Account Value / Cumulative Deposit",
  以净盈利额排名为评判标准: "Rankings are determined based on total account value",
  持仓限制: "Position Limits",
  每个参赛账号最多持有100手仓位: "Each participating account may hold a maximum position of 100 lots",
  无持仓限制: "No position limits",
  参赛条款: "Event Terms",
  参赛须知: "Event Guidelines",
  section6List: [
    {
      t: "1. Event Duration",
      c: ["1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)"],
    },
    {
      t: "2. Account Requirements",
      c: [
        "The account must be listed as a signal account in the trading community and allow others to copy trades for free.",
        "Participants may use multiple accounts to compete, but only the account with the best performance will be considered for ranking.",
        "Only STP and PRO accounts are eligible to participate. PAMM and Islamic accounts are not eligible.",
      ],
    },
    {
      t: "3. Reward Rules",
      c: [
        "Prizes: If the champion chooses the 1,000,000 USD trading account reward, the maximum loss limit is 200,000 USD.",
        "Other prizes will be awarded based on rankings.",
      ],
    },
    {
      t: "4. Prohibited Actions",
      c: [
        "Arbitrage trading between accounts to boost profits is strictly prohibited. Any violations, including cross-account positions, hedging trades, or abuse of quotes, will lead to disqualification.",
        "Orders caused by uncontrollable factors, such as server hardware failure or pricing errors, will be considered invalid.",
      ],
    },
    {
      t: "5. Disclaimer",
      c: ["EBC Financial Group reserves the right to make the final decision of this event."],
    },
    
  ],
  参赛流程: "Event Process",
  注册开户: "Account Registration",
  注册并开设新交易账号: "Register and Open A New Trading Account",
  报名参赛: "Event Registration",
  在用户中心报名参赛: "Register for the Challenge in the User Portal",
  参赛成功: "Successful Registration",
  参赛成功查看实时赛况: "Once Registered, View Your Real-time Performance",
  往届回顾: "Previous Edition",
  第一届交易大赛颁奖典礼: "The First Trading Competition Awards Ceremony",
  第一届交易大赛高光时刻: "Highlights of the First Trading Competition",
  第一届交易大赛巅峰对决: "The Ultimate Showdown of the First Trading Competition",
  查看更多: "View More",
  常见问题: "Frequently Asked Questions",
  qaList: [
    {
      t: '1. "Account" vs "Account Number"',
      c: [
        "Account: Refers to the EBC User Portal Account (UID). Each user can only have one account after completing their real-name authentication.",
        "Account Number: Refers to the MT trading account. Multiple trading accounts can be created under the same EBC account for trading purposes.",
      ],
    },
    {
      t: "2. How Can I Confirm My Registration Status for the Event?",
      c: ["After successful registration, your competition account and current ranking will appear on the event page in the user portal."],
    },
    {
      t: "3. Can I Join the Event with Multiple Accounts?",
      c: [
        "You can join the event with multiple accounts, but each of these accounts can only be registered for one category.",
      ],
    },
    {
      t: "4. Can I Claim Prizes from Both Categories?",
      c: ["Yes, you can claim rewards from both categories as long as you comply with the rules and complete both challenges successfully."],
    },
    {
      t: "5. Can I Join the Challenge If I Already Have an MT4 Account?",
      c: ["Yes, provided the MT4 account was created on or after 22 February 2025, at 01:00:00 (MT4/MT5 time) and has no previous trading history."],
    },
    {
      t: "6. If I deposit more than 10,000 USD after joining La Masia Prodigies, will my category be changed?",
      c: ["No, your category is determined at the time of registration. Subsequent fund changes will not affect the category."],
    },
    {
      t: "7. How Do I Check My Ranking?",
      c: ["Rankings are updated hourly (not on the hour) and can be viewed on the event activity page in the user portal."],
    },
    {
      t: "8. Can I Deposit or Withdraw Funds During the Event?",
      c: [
        "Yes, you can freely deposit or withdraw funds. However, deposits will affect the rate of return calculation: Rate of Return = Net Account Value / Cumulative Deposits.",
      ],
    },
    {
      t: "9. How Do I Claim My Referral Reward?",
      c: [
        'The referral reward will be credited to your bonus balance 30 days after the invited user completes their first trade. You may then request a withdrawal, and the reward will be processed upon approval.',
      ],
    },
    {
      t: `10. Why Can't Invited Users Transfer Their Agents During the Event?`,
      c: [
        `To ensure fairness, invited users can only join through the referral link and are not assigned a specific agent. This avoids duplicate commissions or rewards. After the event ends, users can contact customer service to connect with an agent.`,
      ],
    },
  ],
};
