export default {  
  tradingView: {  
    tvlang: 'th', 
    tvBodyClass: 'en', 

    // 通用文本  
    disclaimer: 'EBC ขอสงวนสิทธิ์ในการอธิบายกิจกรรมทั้งหมด',  
    registerNow: 'เปิดบัญชีเทรดกับ EBC',  
    paidBenefits: 'สมาชิก',  
    getItNow: 'รับสิทธิ์ทันที',  
    getItFree: 'รับฟรี',  
    depositToGet: 'รับฟรีสมาชิก',  

    icon15: require('@/assets/image/TradingView/TradingView-icon15_th.svg'),  

    // 顶部权益   
    benefits: {  
      analysis: 'วิเคราะห์ตลาดแบบมืออาชีพ',  
      charts: 'ฟีเจอร์กราฟทรงพลัง',  
      orderFlow: 'รับเพิ่ม รายงานจาก Order Flow'  
    },  

    // 第二部分 - 领取步骤  
    steps: {  
      title: 'ขั้นตอนรับสิทธิพิเศษ',  
      list: [  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
          title: 'ลงทะเบียน',  
          desc: 'ลงทะเบียนที่เว็บไซต์ EBC<br>เริ่มต้นเส้นทางการเทรดของคุณ'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),   
          title: 'ฝากเงิน',  
          desc: 'ฝากเงิน 1,000$ หรือ 10,000$ ภายใน 1 เดือน<br>รับสิทธิ์สมาชิกฟรี ในระยะเวลาที่กำหนด'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
          title: 'เริ่มต้นใช้งาน',  
          desc: 'ติดต่อฝ่ายบริการลูกค้า EBC<br>ขอรับสิทธิ์สมาชิก TradingView'  
        }  
      ]  
    },  

    // 第3部分 - 市场动态  
    section3: {  
      title: 'เทรดกับ EBC ใช้ Trading View ดูการเคลื่อนไหวตลาดทั่วโลก',  
      desc: 'เชื่อมต่อแหล่งข้อมูลจากทั่วโลกและเข้าถึงเครื่องมือกว่า 2,019,615 รายการจากสถาบันชั้นนำ พร้อมข้อมูลเชิงลึกที่นักลงทุนอาชีพเลือกใช้',  
      slides: [  
        {  
          image: require('@/assets/image/TradingView/TradingView-img3.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
          title: 'ข้อมูลตลาดเรียลไทม์และกราฟประสิทธิภาพสูง',  
          desc: 'เท่าทันทุกความเคลื่อนไหว ช่วยให้ไม่พลาดโอกาสทำกำไร'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img4.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
          title: 'ตัวชี้วัดและกลยุทธ์ทางเทคนิคเชิงลึก',  
          desc: 'ปลดล็อกตัวชี้วัดหลายชนิด สร้างกลยุทธ์การเทรดในแบบคุณ'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img5.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
          title: 'เทรดได้ทุกเวลา ด้วยอุปกรณ์ที่หลากหลาย',  
          desc: 'ทั้งบนคอมพิวเตอร์และมือถือ ไม่ว่าจะตอนไหนก็เทรดได้'  
        }  
      ]  
    },  

    // 第4部分 - 会员计划  
    section4: {  
      title: 'ฝากเงินรับฟรีสมาชิก TradingView',  
      desc: 'คุณกำลังพลาดโอกาสทำกำไรอยู่หรือไม่? หยิบโทรศัพท์เปิดบัญชี EBC ตอนนี้ รับฟรีสมาชิกระดับ Premium ของ TradingView ก้าวแรกสู่กำไรที่มั่นคง',  
      plans: [  
        {  
          isPremium: false,  
          title: 'สมาชิก Essential รายเดือน',  
          requirement: 'ฝากเงินครบ 1,000$ ภายใน 1 เดือน รับฟรีสมาชิก',  
          features: [  
            { text: '2 ชาร์ตต่อแท็บ', enabled: true },  
            { text: '5 อินดิเคเตอร์ต่อชาร์ต', enabled: true },  
            { text: '10K แท่งเทียนย้อนหลัง', enabled: true },  
            { text: '20 การแจ้งเตือนราคา', enabled: true },  
            { text: '20 การแจ้งเตือนทางเทคนิค', enabled: true },  
            { text: '10 การเชื่อมต่อชาร์ตพร้อมกัน', enabled: true },  
            { text: 'ไม่มีโฆษณา', enabled: true },  
            { text: 'Volume Profile', enabled: true },  
            { text: 'ไทม์เฟรมที่กำหนดเอง', enabled: true },  
            { text: 'เรนจ์บาร์ที่กำหนดได้เอง', enabled: true },  
            { text: 'รายการเฝ้าติดตามที่ยกระดับขึ้นแบบหลายอัน', enabled: true },  
            { text: 'โหมดแสดงแท่งเทียนแบบเล่นซ้ำ', enabled: true },  
            { text: 'อินดิเคเตอร์บนอินดิเคเตอร์', enabled: true },  
            { text: 'เอ็กซ์พอร์ตข้อมูลชาร์ต', enabled: false },  
            { text: 'ชาร์ต Renko, Kagi, Line Break, Point & Figure charts ระหว่างวัน', enabled: false },  
            { text: 'ชาร์ตที่ขึ้นกับสูตรแบบกำหนดเอง', enabled: false },  
            { text: 'เวลา ราคา โอกาส', enabled: false },  
            { text: 'Volume Footprint', enabled: false },  
            { text: 'รูปแบบชาร์ต แบบอัตโนมัติ', enabled: false },  
            { text: 'การแจ้งเตือนระดับวินาที', enabled: false },  
            { text: 'การแจ้งเตือนที่ไม่หมดอายุ', enabled: false },  
            { text: 'เผยแพร่สคริปต์เฉพาะสำหรับผู้ได้รับเชิญเท่านั้น', enabled: false },  
            { text: 'ช่วงเวลาแบบวินาที', enabled: false }  
          ]  
        },  
        {  
          isPremium: true,  
          title: 'สมาชิก Premium รายเดือน',  
          requirement: 'ฝากเงินครบ 10,000$ ภายใน 1 เดือน รับฟรีสมาชิก',  
          features: [  
            { text: '8 ชาร์ตต่อแท็บ', enabled: true },  
            { text: '25 อินดิเคเตอร์ต่อชาร์ต', enabled: true },  
            { text: '20K แท่งเทียนย้อนหลัง', enabled: true },  
            { text: '400 การแจ้งเตือนราคา', enabled: true },  
            { text: '400 การแจ้งเตือนทางเทคนิค', enabled: true },  
            { text: '50 การเชื่อมต่อชาร์ตพร้อมกัน', enabled: true },  
            { text: 'ไม่มีโฆษณา', enabled: true },  
            { text: 'Volume Profile', enabled: true },  
            { text: 'ไทม์เฟรมที่กำหนดเอง', enabled: true },  
            { text: 'เรนจ์บาร์ที่กำหนดได้เอง', enabled: true },  
            { text: 'รายการเฝ้าติดตามที่ยกระดับขึ้นแบบหลายอัน', enabled: true },  
            { text: 'โหมดแสดงแท่งเทียนแบบเล่นซ้ำ', enabled: true },  
            { text: 'อินดิเคเตอร์บนอินดิเคเตอร์', enabled: true },  
            { text: 'เอ็กซ์พอร์ตข้อมูลชาร์ต', enabled: true },  
            { text: 'ชาร์ต Renko, Kagi, Line Break, Point & Figure charts ระหว่างวัน', enabled: true },  
            { text: 'ชาร์ตที่ขึ้นกับสูตรแบบกำหนดเอง', enabled: true },  
            { text: 'เวลา ราคา โอกาส', enabled: true },  
            { text: 'Volume Footprint', enabled: true },  
            { text: 'รูปแบบชาร์ต แบบอัตโนมัติ', enabled: true },  
            { text: 'การแจ้งเตือนระดับวินาที', enabled: true },  
            { text: 'การแจ้งเตือนที่ไม่หมดอายุ', enabled: true },  
            { text: 'เผยแพร่สคริปต์เฉพาะสำหรับผู้ได้รับเชิญเท่านั้น', enabled: true },  
            { text: 'ช่วงเวลาแบบวินาที', enabled: true }  
          ]  
        }  
      ]  
    },  

    // 第5部分 - 新客福利  
    section5: {  
      title: 'สิทธิพิเศษ สำหรับลูกค้าใหม่<br>เพิ่มโอกาสทำกำไรอย่างมีประสิทธิภาพ',  
      desc: 'อัพเดตข้อมูลเชิงลึกรายวันจาก CME : ติดตามการเคลื่อนไหวของนักลงทุนรายใหญ่ เพิ่มโอกาสทำกำไร',  
      desc2: 'ข้อมูลจากนักวิเคราะห์มืออาชีพ Trading Central : เจาะลึกข้อมูล เพิ่มโอกาสทำกำไร',  
      desc3: 'ข้อมูลเศรษฐกิจแบบเรียลไทม์จาก 38 ประเทศ/ภูมิภาค : ช่วยให้ติดตามแนวโน้ม คาดการณ์ล่วงหน้าและรับมือกับความผันผวน',  
      images: [  
        require('@/assets/image/TradingView/TradingView-img7.png'),  
        require('@/assets/image/TradingView/TradingView-img8.png'),  
        require('@/assets/image/TradingView/TradingView-img9.png')  
      ]  
    },  

    // 第6部分 - 荣誉展示  
    section6: {  
      title: 'เริ่มฝากเงิน ร่วมเส้นทางทำกำไรกับ EBC',  
      desc: 'EBC Financial Group พันธมิตรระดับโลกที่คุณไว้วางใจ'  
    }  
  }  
}