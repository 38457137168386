export default {  
  tradingView: {  
    tvlang: 'cn',  
    tvBodyClass: 'cn',

    // 通用文本  
    disclaimer: '最終活動解釋權歸EBC所有',  
    registerNow: '立即在EBC註冊交易',  
    paidBenefits: '付費權益',  
    getItNow: '立即領取',  
    getItFree: '免費領取',  
    depositToGet: '入金免費領取',  

    icon15: require('@/assets/image/TradingView/TradingView-icon15_zh.svg'),  

    // 顶部权益  
    benefits: {  
      analysis: '專業市場分析',  
      charts: '強大圖示功能',  
      orderFlow: '額外附加訂單流報告'  
    },  

    // 第二部分 - 领取步骤  
    steps: {  
      title: '領取步驟',  
      list: [  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
          title: '立即註冊',  
          desc: '在EBC官網完成註冊<br>開啟您的交易之旅'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),  
          title: '入金立享',  
          desc: '單月淨入金$1000或單月淨入金$10000<br>免費領取限時會員權益'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
          title: '開始使用',  
          desc: '聯絡EBC客服取得Trading View<br>付費高級會員權益'  
        }  
      ]  
    },  

    // 第3部分 - 市场动态  
    section3: {  
      title: '在EBC交易, 使用Trading View掌握全球市場動態',  
      desc: '連接到數百個資料來源，直接存取全球各地的 2,019,615種工具，從機構級資料合作夥伴那裡，獲取專業投資者使用的前瞻性資訊。',  
      slides: [  
        {  
          image: require('@/assets/image/TradingView/TradingView-img3.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
          title: '即時市場數據與進階圖表',  
          desc: '掌握全球市場動態，協助捕捉每一次獲利機會'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img4.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
          title: '深度技術指標與策略',  
          desc: '解鎖多種技術指標，打造個人化交易策略。'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img5.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
          title: '多設備隨時交易',  
          desc: '電腦、手機同步交易，隨時隨地掌握市場。'  
        }  
      ]  
    },  

    // 第4部分 - 会员计划  
    section4: {  
      title: '入金立享Trading View高級會員',  
      desc: '還在錯過行情，看不準機遇？ 在EBC一鍵開戶，TradingView高級會員免費送，即刻開啟盈勝第一步。',  
      plans: [  
        {  
          isPremium: false,  
          title: 'Essential月訂閱會員',  
          requirement: '單月淨入金$1000免費領取',  
          features: [  
            { text: '每個頁籤2個圖表', enabled: true },  
            { text: '每個圖表5個指標', enabled: true },  
            { text: '10K個歷史K線', enabled: true },  
            { text: '20個價格快訊', enabled: true },  
            { text: '20個技術快訊', enabled: true },  
            { text: '10個並行圖表連接', enabled: true },  
            { text: '無廣告', enabled: true },  
            { text: '成交量分佈', enabled: true },  
            { text: '自訂時間週期', enabled: true },  
            { text: '自訂範圍K線', enabled: true },  
            { text: '多個觀察清單', enabled: true },  
            { text: 'K線重播', enabled: true },  
            { text: '指標上的指標', enabled: true },  
            { text: '匯出圖表數據', enabled: false },  
            { text: '日內的磚型圖、卡吉圖、新價線圖、點數圖', enabled: false },  
            { text: '基於自訂公式的圖表', enabled: false },  
            { text: '時間 價格 機會', enabled: false },  
            { text: '成交量軌跡', enabled: false },  
            { text: '自動圖表模式', enabled: false },  
            { text: '秒級快訊', enabled: false },  
            { text: '不會過期的快訊', enabled: false },  
            { text: '發佈僅限邀請的腳本', enabled: false },  
            { text: '秒級時間週期', enabled: false }  
          ]  
        },  
        {  
          isPremium: true,  
          title: 'Premium月訂閱會員',  
          requirement: '單月淨入金$10000免費領取',  
          features: [  
            { text: '每個頁籤8個圖表', enabled: true },  
            { text: '每個圖表25個指標', enabled: true },  
            { text: '20K個歷史K線', enabled: true },  
            { text: '400個價格快訊', enabled: true },  
            { text: '400個技術快訊', enabled: true },  
            { text: '50個並行圖表連接', enabled: true },  
            { text: '無廣告', enabled: true },  
            { text: '成交量分佈', enabled: true },  
            { text: '自訂時間週期', enabled: true },  
            { text: '自訂範圍K線', enabled: true },  
            { text: '多個觀察清單', enabled: true },  
            { text: 'K線重播', enabled: true },  
            { text: '指標上的指標', enabled: true },  
            { text: '匯出圖表數據', enabled: true },  
            { text: '日內的磚型圖、卡吉圖、新價線圖、點數圖', enabled: true },  
            { text: '基於自訂公式的圖表', enabled: true },  
            { text: '時間 價格 機會', enabled: true },  
            { text: '成交量軌跡', enabled: true },  
            { text: '自動圖表模式', enabled: true },  
            { text: '秒級快訊', enabled: true },  
            { text: '不會過期的快訊', enabled: true },  
            { text: '發佈僅限邀請的腳本', enabled: true },  
            { text: '秒級時間週期', enabled: true }  
          ]  
        }  
      ]  
    },  

    // 第5部分 - 新客福利  
    section5: {  
      title: '新客專屬福利<br>助你提升勝率',  
      desc: 'CME交易所每日深度訂單流報告:追蹤主力，提高勝率',  
      desc2: 'Trading Central分析師市場洞察：精準捕捉市場機會',  
      desc3: '提供38個國家的即時宏觀經濟數據，幫助交易者監控、預測和應對潛在的市場波動。',  
      images: [  
        require('@/assets/image/TradingView/TradingView-img7.png'),  
        require('@/assets/image/TradingView/TradingView-img8.png'),  
        require('@/assets/image/TradingView/TradingView-img9.png')  
      ]  
    },  

    // 第6部分 - 荣誉展示  
    section6: {  
      title: '立即入金, 與EBC盈在起點',  
      desc: 'EBC金融集團 您最值得信賴的全球合作夥伴'  
    }  
  }  
}