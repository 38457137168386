export default {  
    tradingView: {  
      tvlang:'cn',
      tvBodyClass: 'cn',

      // 通用文本  
      disclaimer: '最终活动解释权归EBC所有',  
      registerNow: '即刻在EBC注册交易',  
      paidBenefits: '付费权益',  
      getItNow: '立即领取',  
      getItFree: '免费领取',  
      depositToGet: '入金免费领取',
      
      icon15: require('@/assets/image/TradingView/TradingView-icon15.svg'),

      // 顶部权益  
      benefits: {  
        analysis: '专业市场分析',  
        charts: '强大图标功能',  
        orderFlow: '额外附送订单流报告'  
      },  

      // 第二部分 - 领取步骤  
      steps: {  
        title: '领取步骤',  
        list: [  
          {  
            icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
            title: '立即注册',  
            desc: '在EBC官网完成注册<br>开启您的交易之旅'  
          },  
          {  
            icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),  
            title: '入金立享',  
            desc: '单月净入金$1000或单月净入金$10000<br>免费领取限时会员权益'  
          },  
          {  
            icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
            title: '开始使用',  
            desc: '联系EBC客服获取Trading View<br>付费高级会员权益'  
          }  
        ]  
      },  

      // 第3部分 - 市场动态  
      section3: {  
        title: '在EBC交易, 使用Trading View掌握全球市场动态',  
        desc: '连接到数百个数据源，直接访问全球各地的 2,019,615种工具，从机构级数据合作伙伴那里，获取专业投资者使用的前瞻性信息。',  
        slides: [  
          {  
            image: require('@/assets/image/TradingView/TradingView-img3.png'),  
            icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
            title: '实时市场数据与高级图表',  
            desc: '掌握全球市场动态，助力捕捉每一次盈利机会'  
          },  
          {  
            image: require('@/assets/image/TradingView/TradingView-img4.png'),  
            icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
            title: '深度技术指标与策略',  
            desc: '解锁多种技术指标，打造个性化交易策略'  
          },  
          {  
            image: require('@/assets/image/TradingView/TradingView-img5.png'),  
            icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
            title: '多设备随时交易',  
            desc: '电脑、手机同步交易，随时随地掌握市场'  
          }  
        ]  
      },  

      // 第4部分 - 会员计划  
      section4: {  
        title: '入金立享Trading View高级会员',  
        desc: '还在错过行情，看不准机遇？ 在EBC一键开户，TradingView高级会员免费送，即刻开启盈胜第一步。',  
        plans: [  
          {  
            isPremium: false,  
            title: 'Essential月度会员',  
            requirement: '单月净入金$1000免费领取',  
            features: [  
              { text: '每个标签页2个图表', enabled: true },  
              { text: '每个图表5个指标', enabled: true },  
              { text: '10K根历史K线', enabled: true },  
              { text: '20个价格警报', enabled: true },  
              { text: '20个技术警报', enabled: true },  
              { text: '10个并行图表连接', enabled: true },  
              { text: '无广告', enabled: true },  
              { text: '成交量分布图(VP)', enabled: true },  
              { text: '自定义时间周期', enabled: true },  
              { text: '自定义范围K线', enabled: true },  
              { text: '多个自选表', enabled: true },  
              { text: 'k线回放', enabled: true },  
              { text: '指标上的指标', enabled: true },  
              { text: '导出图表数据', enabled: false },  
              { text: '日内砖型图、卡吉图、新价线图、点数图', enabled: false },  
              { text: '基于自定义公式的图表', enabled: false },  
              { text: '时间 价格 机会', enabled: false },  
              { text: '成交量轨迹', enabled: false },  
              { text: '自动图表形态', enabled: false },  
              { text: '秒级警报', enabled: false },  
              { text: '不会过期的警报', enabled: false },  
              { text: '发布仅限邀请的脚本', enabled: false },  
              { text: '秒级时间周期', enabled: false }
            ]  
          },  
          {  
            isPremium: true,  
            title: 'Premium月度会员',  
            requirement: '单月净入金$10000免费领取',  
            features: [  
              { text: '每个标签页8个图表', enabled: true },  
              { text: '每个图表25个指标', enabled: true },  
              { text: '20K根历史K线', enabled: true },  
              { text: '400个价格警报', enabled: true },  
              { text: '400个技术警报', enabled: true },  
              { text: '50个并行图表连接', enabled: true },  
              { text: '无广告', enabled: true },  
              { text: '成交量分布图(VP)', enabled: true },  
              { text: '自定义时间周期', enabled: true },  
              { text: '自定义范围K线', enabled: true },  
              { text: '多个自选表', enabled: true },  
              { text: 'k线回放', enabled: true },  
              { text: '指标上的指标', enabled: true },  
              { text: '导出图表数据', enabled: true },  
              { text: '日内砖型图、卡吉图、新价线图、点数图', enabled: true },  
              { text: '基于自定义公式的图表', enabled: true },  
              { text: '时间 价格 机会', enabled: true },  
              { text: '成交量轨迹', enabled: true },  
              { text: '自动图表形态', enabled: true },  
              { text: '秒级警报', enabled: true },  
              { text: '不会过期的警报', enabled: true },  
              { text: '发布仅限邀请的脚本', enabled: true },  
              { text: '秒级时间周期', enabled: true }
            ]  
          }  
        ]  
      },  

      // 第5部分 - 新客福利  
      section5: {  
        title: '新客专属福利<br>助你提升胜率',  
        desc: 'CME交易所每日深度订单流报告:追踪主力，提高胜率', 
        desc2: 'Trading Central分析师市场洞察:精准捕捉市场机会',
        desc3: '提供38个国家/地区的实时宏观经济数据，帮助交易者监控、预测和应对潜在的市场波动。', 
        images: [  
          require('@/assets/image/TradingView/TradingView-img7.png'),  
          require('@/assets/image/TradingView/TradingView-img8.png'),  
          require('@/assets/image/TradingView/TradingView-img9.png')  
        ]  
      },  

      // 第6部分 - 荣誉展示  
      section6: {  
        title: '立即入金, 与EBC盈在起点',  
        desc: 'EBC金融集团 您最值得信赖的全球合作伙伴', 
      }  
    }  
  }  