export default {
	"screen0": {
		"enTitle": "Your Trust, Our Protection",
		"title": "在线交易的安全和防范欺诈，是我们的首要优先考虑事项",
		"text": "在EBC，我们提供了一系列安全、即时和易于操作的出入金方式。您可以随时提取您的获利或初始投资。<br /><br />为确保您的账户得到充分的保护，我们对在线支付实施了行业高级别的加密，并采取了额外的验证措施，全方位保障您在EBC上的所有资金均安全无忧。",
		"rightText": "有机会驾驭的人生，<span>即刻启程。</span>"
	},
	"screen1": {
		"smallEnTitle": "Multiple Deposit Methods",
		"smallTitle": "多种入金方式",
		"title": "我们支持借记卡，电汇，PayPal 等多种入金方式",
		"desc": "为帮助确保您拥有更多的剩余资金用于交易， EBC对于您从交易账户注入资金或从该账户提取资金不收取任何费用",
		"list": ["入金方式", "最低入金", "入金到账时间", "最低出金", "支付货币", "手续费", "出金处理时间"],
		"summary": "说明：",
		"btn": "开始入金",
		"summaryDesc": "<p>1. 具体到账时间取决于各银行、中间机构的办理情况</p><p>2. 具体限额可能因客户银行、发卡行规定而各有不同 </p><p>3. 请注意，如果您在提交出金申请时，您的账户持有仓位，请留意您提取的金额大小，以免影响您的持仓。</p>",
		"imgUrl": "zh"
	},
	"screen2": {
		"title": "成为全球百万交易者之一",
		"desc": "仅需轻松3步，享受极速安全的开户体验。",
		"list": [{
			"title": "注册账户",
			"desc": "提交基本信息，安全简单的网上申请页面"
		}, {
			"title": "提交个人信息",
			"desc": "只需1分钟轻松完成开户填写"
		}, {
			"title": "入金激活",
			"desc": "最低入金200美金实时激活"
		}],
		"btn1": "开设模拟账户",
		"btn2": "开设真实账户",
		"yuyan": "zh"
	},
	"screen3": {
		"title": "出入金常见问题",
		"faqList": [{
			"title": "我如何向我的账户入金？",
			"desc": "在您建立账户后，可以通过我们的网络客户端进入您的支付首选项。<br>具体操作：<br> 1. 系统将首先提示您选择要入金的帐户。<br>2. 然后使用付款方式表右侧的按钮选择付款方式。<br>3. 输入您想向您的账户里入金的金额。<br>请仔细阅读所有说明，条款和条件，如果您同意，<br>请确保选中“我已阅读所有说明并同意付款操作的条款和条件”。<br>【点击提交】"
		}, {
			"title": "为何我入金不成功？",
			"desc": "如果您的借记卡入金失败，请尝试再次入金，并检查：<br>1. 您是否正确输入了您的卡片信息。<br>2. 您是否使用了有效（未过期）卡。<br>3. 您的卡中是否有足够的资金。<br>如果不存在上述问题，但您仍然没有成功入金，那么可能是您的发卡行并未授权您的卡进行入金。如果是这样，请使用其他卡，或您的交易账户中的其他付款方式。"
		}, {
			"title": "我如何从我的账户中出金？",
			"desc": "您可以在任意时间提出出金申请。我们的账户部门每天都会处理请求。出金将转回到您的指定账户中。如果该选项不可用，资金将自动退回至客户的后台钱包中。另请注意，<br>银行电汇出金，请以各银行实际处理的时间为准。 各家银行到账时间不同，详细请咨询银行。<br>若在持仓时出金，请确保交易账户中的预付比例在扣除出金金额后依然高于200%，否则平台将无法处理您的取款申请。"
		}, {
			"title": "EBC收取手续费吗？",
			"desc": "EBC对于采用任何支付方式的入金或出金，都不收取任何费用。但请注意，您向某些国际银行机构的付款或从这些机构办理的付款可能会产生费用。还请注意，您的银行可能会将支付给EBC的款项视为预付现金，并根据您的预付现金规则向您开具账单。"
		}, {
			"title": "我是否可以用他人的银行卡入金/出金？",
			"desc": "否，EBC不接受来自第三方的付款。请确保所有进入您交易账户的入金均来自您名下的账户。如交易账户的持有者是联合账户的其中一方，将会接受联合账户的付款。<br><br> 如果我们怀疑是由第三方提供的资金，我们保留将资金退回给汇款人并冻结您账户余额的权利，等待验证身份证明和资金来源后再行处理。在未经验证的情况下，我们保留冻结您帐户余额的权利，您将不得提取您的帐户余额。<br><br>EBC不办理对第三方的出金。如有特殊情况，请联系我们。"
		}]
	},
	"funding1":{
		"text1": "出入金",
		"text2": "在线交易的安全和防范欺诈，是我们的首要优先考虑事项",
		"btn": "即刻入金",
	},
	"funding2":{
		"text1": "全球多币种资金解决方案",
		"text2": "提供全球主要货币的即时入金与出金服务，简化国际和跨货币交易流程，实现全天候、便捷的资金流动",
	},
	"funding3":{
		"text1": "资金隔离，独立托管",
		"text2": "巴克莱银行最高级别Corporate Banking Account",
		"text3": "EBC拥有巴克莱银行企业账户，该账户要求企业营业额和存款达到650万英镑以上且须要通过巴克莱一系列严格的财务审计和背景调查。EBC英国严格执行CASS规定，通过特定信托函独立托管投资者资金，保障资金安全与独立。",
		"btn": "了解更多",
	},
	"funding4":{
		"text1": "专业金融责任险",
		"text2": "多重国际专业金融责任险，全方位保障客户资金安全，有效避免资金损失",
	},
	"funding5":{
		"text1": "出入金方式",
		"text2": "支持本地银行转账，国际电汇，电子钱包 ，加密货币等多种出入金方式",
		"text3": "出入金方式",
		"text4": "支持货币",
		"text5": "入金单笔限额",
		"text6": "入金到账时间",
		"text7": "出金处理时间",
		"text8": "本地银行",
		"text9": "1-15分钟",
		"text10": "1个工作日",
		"text11": "国际电汇",
		"text12": "1000-100000",
		"text13": "2-5个工作日",
		"text14": "电子钱包",
		"text15": "数字货币",
		
	},
	入金到账说明:`*入金到账说明：工作日处理时间约为1至15分钟，超时未到账请及时联系客服或将您的订单编号与付款截图邮件至cs@ebc.com查询。<br/>
	出金处理说明：工作日16:00(GMT+8)前提交的申请，最快当天到账；16:00(GMT+8)后提交的申请，延迟至第二个工作日到账。`
}