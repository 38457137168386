export default {
	"official-class-name": "official-en-box",
	"lang": "th",
	"official-page1": {
		"text1": "การตรวจสอบช่องทางอย่างเป็นทางการ",
		"text2": "เพื่อป้องกันไม่ให้มิจฉาชีพนำชื่อของ EBC ไปใช้แสวงหาผลกำไรกับผู้บริสุทธิ์ และเพื่อไม่ให้คุณตกเป็นเหยื่อของมิจฉาชีพ คุณสามารถใช้หน้าเว็บไซต์นี้ในการตรวจสอบความถูกต้องของช่องทางนั้นได้",
		"text3": "กรุณากรอกลิงค์เว็บไซต์ให้ครบถ้วน",
		"text4": "ตรวจสอบการยืนยัน",
	},
	"official-page2": {
		"text1": "แถลงการณ์อย่างเป็นทางการของ EBC:",
		"text2": "1. ปัจจุบัน EBC ให้บริการซอฟต์แวร์การเทรดเฉพาะ MT4 และ MT5 เท่านั้น โปรดดาวน์โหลดเครื่องมือจากเว็บไซต์ทางการของเรา",
		"text3": "2. ปัจจุบัน EBC ไม่เคยเกี่ยวข้องกับการซื้อขายสกุลเงินดิจิทัล และไม่มีการทำธุรกรรม CFD ที่เกี่ยวข้องกับสกุลเงินดิจิทัลใดๆ",
		"text4": "3. EBC ไม่ได้ให้คำแนะนำด้านการลงทุนในรูปแบบใดๆ และไม่มีข้อผูกพันหรือรับประกันผลตอบแทนการซื้อขายใดๆทั้งสิ้น",
		"text5": "4. สำหรับการแอบอ้างชื่อของ EBC เราจะดำเนินการทางกฎหมายทันที และปราบปรามมิจฉาชีพอย่างถึงที่สุด",
		"text6": "5. EBC มุ่งมั่นที่จะปกป้องสิทธิ์และผลประโยชน์ของลูกค้า หากคุณพบกิจกรรมที่น่าสงสัย โปรดส่งหลักฐานให้กับทีมบริการลูกค้าของเราบนช่องทางไลฟ์แชทบนเว็บไซต์หรือติดต่อทางอีเมลที่ <a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a> เตรียมพร้อมและจะตอบกลับอย่างรวดเร็ว เพื่อให้การสนับสนุนทางกฎหมายและความช่วยเหลือที่จำเป็นแก่ผู้ที่ถูกฉ้อโกงอย่างเต็มที่",
	},
	"official-page3": {
		"text1": "ข้อมูลที่คุณกำลังสอบถามได้รับการยืนยันเรียบร้อยแล้ว",
		"text2": "ค้นหาอีกครั้ง",
		"text3": "ข้อมูลที่สอบถามยังไม่ได้รับการยืนยันอย่างเป็นทางการจากทีมงาน โปรดรรอสักครู่ กรุณาอย่าเคลื่อนไหวในช่องแชทที่ยังไม่ได้รับการยืนยันอย่างเป็นทางการ",
		"text4": "รายงานโพสต์นี้",
		"text5": "หากคุณเชื่อว่าข้อมูลนี้เป็นเท็จหรือถูกยักยอก โปรดส่งรายงานให้เราทราบ",
		"text6": "รายงานข้อมูล",
		"text7": "ที่อยู่เว็บไซต์",
		"text8": "*หากคุณต้องการความช่วยเหลือและคำแนะนำทางกฎหมายเพิ่มเติม ",
		"text8-1": "โปรดคลิกที่นี่และฝากข้อความไว้",
		"text8-2": "",
		"text9": "ปิดช่องแสดงความคิดเห็น",
		"text10": "ชื่อ-นามสกุล",
		"text11": "กรุณากรอกนามสกุล/ชื่อเล่นของคุณ",
		"text12": "อีเมล",
		"text13": "กรุณากรอกอีเมลของคุณ",
		"text14": "เบอร์โทรศัพท์",
		"text15": "กรุณากรอกเบอร์โทรศัพท์ของคุณ",
		"text16": "ข้อเสนอแนะ",
		"text17": "กรุณากรอกข้อมูลข้อเสนอแนะ",
		"text18": "รหัสยืนยัน",
		"text19": "กรุณากรอกรหัสยืนยัน",
		"text20": "ยกเลิก",
		"text21": "ส่งรายงาน",
		"text22": "ส่งคำร้องเรียบร้อย ขอบคุณสำหรับข้อเสนอแนะของคุณ",
	},
}