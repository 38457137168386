import { render, staticRenderFns } from "./lab.vue?vue&type=template&id=36d062db&scoped=true&"
import script from "./lab.vue?vue&type=script&lang=js&"
export * from "./lab.vue?vue&type=script&lang=js&"
import style0 from "./lab.vue?vue&type=style&index=0&id=36d062db&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d062db",
  null
  
)

export default component.exports