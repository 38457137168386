<template>
	<div class="zero">
		<div class="zero-head">
			<div class="head-box flex">
				<div class="head-box__logo">
					<a href="https://www.ebcfin.com"><img src="@/assets/image/No1/Group-19.png"
							title=" EBC官网地址 https://www.ebcfin.com" class="img"></a>
				</div>
				<div class="head-box__tit flex-1">
					<a href="https://www.ebcfin.com" class="btns">www.ebcfin.com</a>
				</div>
				<div class="head-box__btn flex">
					<a href="https://ebcfinsns.co" target="_blank" class="left-btn">
						登录社区
					</a>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="zero-block1 flex flex-column flex-ajcenter">
				<h1 class="zero-block1__h1">From Zero To Hero</h1>
				<div class="flex flex-ajcenter zero-block1__tips">
					每个交易员心中都有一个<p>英雄梦</p>
				</div>
				<div class="zero-block1__title">
					EBC 智能跟单社区
				</div>
				<div class="zero-block1__img">
					<img src="@/assets/image/img/shot-0.png" class="img">
				</div>
			</div>
			<div class="zero-line flex flex-ajcenter">
				<div class="zero-line__box flex-1">

				</div>
				<img src="@/assets/image/img/icon-xx.svg" class="img">
				<div class="zero-line__box flex-1">

				</div>
			</div>
			<div class="zero-info flex flex-ajcenter flex-column">
				<div class="zero-info__title flex">
					优势一：基于MT底层通讯协议，实现<p>“毫秒级”</p>跟单
				</div>
				<div class="zero-info__tips flex flex-ajcenter">
					<div class="item flex flex-ajcenter">
						<img src="@/assets/image/img/icon-xx.svg" class="img">非EA插件跟单
					</div>
					<div class="item flex flex-ajcenter">
						<img src="@/assets/image/img/icon-xx.svg" class="img">跟单无需打开MT
					</div>
					<div class="item flex flex-ajcenter">
						<img src="@/assets/image/img/icon-xx.svg" class="img">免费
					</div>
				</div>
				<div class="zero-info__img">
					<img src="@/assets/image/img/shot-1.png" class="img">
				</div>
			</div>
			<div class="zero-line flex flex-ajcenter">
				<div class="zero-line__box flex-1">

				</div>
				<img src="@/assets/image/img/icon-xx.svg" class="img">
				<div class="zero-line__box flex-1">

				</div>
			</div>
			<div class="zero-info flex flex-ajcenter flex-column">
				<div class="zero-info__title flex">
					优势二：<p>“更专业” </p>的信号选择
				</div>
				<div class="zero-info__tips flex flex-ajcenter">
					首次引入基金评级系统。标准差、夏普比、净值余额比...专业评级指标将辅助你选择一个真正优质的信号。
				</div>
				<div class="zero-info__img">
					<img src="@/assets/image/img/shot-2.png" class="img">
				</div>
			</div>
			<div class="zero-line flex flex-ajcenter">
				<div class="zero-line__box flex-1">

				</div>
				<img src="@/assets/image/img/icon-xx.svg" class="img">
				<div class="zero-line__box flex-1">

				</div>
			</div>
			<div class="zero-info flex flex-ajcenter flex-column">
				<div class="zero-info__title flex">
					优势三：保护您的<p>“交易隐私” </p>
				</div>
				<div class="zero-info__tips flex flex-ajcenter">
					公私域信号分离，为交易隐私保驾护航；公域信号引流，私域信号变现，帮助交易者解决市场转化问题。
				</div>
				<div class="zero-info__img">
					<img src="@/assets/image/img/shot-3.png" class="img">
				</div>
			</div>
			<div class="zero-line flex flex-ajcenter">
				<div class="zero-line__box flex-1">

				</div>
				<img src="@/assets/image/img/icon-xx.svg" class="img">
				<div class="zero-line__box flex-1">

				</div>
			</div>
			<div class="zero-info flex flex-ajcenter flex-column">
				<div class="zero-info__title flex">
					优势四：<p>“AI选手”</p>一键筛选信号
				</div>
				<div class="zero-info__tips flex flex-ajcenter">
					根据跟单者风险/收益偏好，一键筛选目标信号，轻松复制全球顶级交易策略。
				</div>
				<div class="zero-info__img">
					<img src="@/assets/image/img/shot-4.png" class="img">
				</div>
			</div>
			<div class="zero-line flex flex-ajcenter">
				<div class="zero-line__box flex-1">

				</div>
				<img src="@/assets/image/img/icon-xx.svg" class="img">
				<div class="zero-line__box flex-1">

				</div>
			</div>
			<div class="zero-info flex flex-ajcenter flex-column">
				<div class="zero-info__title flex">
					优势五：去伪存真的<p>“交易社区”</p>
				</div>
				<div class="zero-info__tips flex flex-ajcenter">
					集成化交易研究交流社区 ：EBC研究院、订单流学习基地、对冲基金研究策略等，<br>助您在交易之路上保持进步，学习成长。
				</div>
				<div class="zero-info__img">
					<img src="@/assets/image/img/shot-5.png" class="img">
				</div>
			</div>
			<view class="zero-bot flex flex-ajcenter flex-column">
				<div class="zero-bot__desc flex flex-ajcenter">
					<p>数百万</p>投资者的智慧结晶触手可及
				</div>
				<div class="zero-bot__desc flex flex-ajcenter">
					让您的投资之路离专业更近,让您的交易结果离目标更近。
				</div>
			</view>
			<div class="zero-footer">
				<div class="zero-footer__info flex flex-ajcenter">
					<img src="@/assets/image/img/icon-xx.svg" class="img">
					<div class="info-line flex-1">

					</div>
					<div class="info-desc">
						每个认真交易的人都值得被认真对待
					</div>
					<div class="info-line flex-1">

					</div>
					<img src="@/assets/image/img/icon-xx.svg" class="img">
				</div>
				<div class="zero-footer__logo flex flex-ajcenter">
					<img src="@/assets/image/img/icon-6.svg" class="img">
				</div>
			</div>
			<a href="https://ebcfinsns.co" target="_blank" class="zero-btn">
				登录社区
			</a>
		</div>
	</div>
</template>

<script>
	if (process.browser) {
		var {
			WOW
		} = require('wowjs')
	}
	export default {
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		data() {
			return {
				seoInfo: ""
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$t("common.requestLang"),//this.$store.state.Lan,
				path: "/from0tohero"
			}).then(res=>{
				this.seoInfo = res
			})
		},
		methods: {

		}
	}
</script>

<style lang="scss">
	.zero {
		background: #fff;
		padding-top: 146px;

		.content {
			max-width: 1400px;
			margin: auto;
		}

		p {
			display: inline-block;
		}

		&-info {
			&__title {
				font-size: 36px;
				font-weight: 700;
				letter-spacing: 8px;
				color: rgba(38, 7, 6, 1);
				align-items: flex-end;
				margin-bottom: 50px;

				p {
					font-size: 50px;
					color: rgba(187, 140, 75, 1);
				}
			}

			&__tips {
				font-size: 28px;
				font-weight: 400;
				letter-spacing: 3px;
				color: rgba(38, 7, 6, 1);
				text-align: center;
				line-height: 1.5;

				.img {
					margin: 0 6px 0 30px;
					width: 29px;
					height: 29px;
				}
			}

			&__img {
				margin-bottom: 30px;
			}
		}

		&-bot {
			margin-top: 20px;

			&__desc {
				font-size: 36px;
				font-weight: 700;
				letter-spacing: 6px;
				color: rgba(38, 7, 6, 1);
				margin-top: 20px;

				p {
					font-size: 50px;
					font-weight: 700;
					letter-spacing: 8px;
					color: rgba(187, 140, 75, 1);
				}
			}
		}

		&-btn {
			background: url("../assets/image/img/btn.png")no-repeat;
			background-size: 100%;
			width: 180px;
			height: 53px;
			line-height: 45px;
			color: rgba(69, 5, 5, 1);
			font-size: 18px;
			font-weight: 600;
			letter-spacing: 6px;
			margin-top: 40px;
			display: block;
			text-align: center;
			position: fixed;
			right: 0;
			bottom: 100px;

			&:hover {
				color: #73201a;
			}
		}

		&-footer {
			margin: 100px 0 0px;
			padding-bottom: 50px;

			&__info {
				.info-desc {
					color: rgba(192, 151, 108, 1);
					font-size: 36px;
					font-weight: 400;
					letter-spacing: 6px;
				}

				.info-line {
					border-bottom: 1.45px solid rgba(163, 123, 80, 1.00);
					margin: 0 20px;
				}
			}

			&__logo {
				margin-top: 20px;
			}
		}

		&-line {
			padding: 40px 0;

			&__box {
				background-color: rgba(187, 140, 75, 1);
				height: 1px;
			}

			.img {
				margin: 0 30px;
			}
		}

		&-block1 {
			padding-top: 50px;

			&__h1 {
				color: rgba(84, 0, 0, 1);
				font-size: 72px;
				font-weight: bold;
				letter-spacing: 8px;
				font-family: fangsong;
			}

			&__tips {
				font-size: 36px;
				font-weight: 400;
				letter-spacing: 17px;
				padding: 20px 0 60px;
				color: rgba(84, 0, 0, 1);
				align-items: center;

				p {
					font-weight: bold;
				}
			}

			&__title {
				font-size: 50px;
				font-weight: 700;
				letter-spacing: 8px;
				color: rgba(187, 140, 75, 1);
				font-family: fangsong;
				margin-bottom: 40px;
			}
		}

		&-head {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			background-color: rgba(84, 0, 0, 1);
			padding: 15px 0;
			z-index: 1000;

			.head-box {
				max-width: 1400px;
				margin: auto;
				align-items: center;

				&__logo {}

				&__tit {
					text-align: center;

					.btns {
						color: #a97c50;
						font-family: Times New Roman, Georgia, serif;
						font-weight: 700;
						font-size: 22px;
						line-height: 70px;
					}
				}

				&__btn {
					align-items: center;

					.left-btn {
						display: block;
						color: #c49a6c;
						border-radius: 5px;
						font-size: 20px;
						font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif;
						background-color: #760006;
						padding: 0.3em 1em;
						line-height: 1.7em;
					}

					.right-btn {
						margin-left: 20px;
						font-size: 20px;
						font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif !important;
						color: #e3e3e3;
						padding: 0.3em 1em;
						text-decoration: underline
					}
				}
			}
		}
	}

	@media (max-width: 500px) {
		.zero {
			padding-top: 110px;

			.content {
				padding: 0 10px;
			}

			&-head {
				.head-box {
					padding: 0 10px;

					&__logo {
						flex: 1;

						img {
							width: 90%;
						}
					}

					&__tit {
						display: none;
					}

					&__btn {
						flex: 1;
						justify-content: flex-end;

						.right-btn {
							margin-left: 0;
							font-size: 14px;
						}

						.left-btn {
							padding: 0.3em 0.6em;
							font-size: 14px;
						}
					}
				}
			}

			&-bot {
				margin-top: 20px;

				&__desc {
					font-size: 18px;
					font-weight: 700;
					letter-spacing: 2px;
					color: rgba(38, 7, 6, 1);
					margin-top: 10px;
					line-height: 1.75;
					display: block;

					&:last-child {
						font-size: 14px;
						letter-spacing: 0px;
						white-space: nowrap;
					}

					p {
						font-size: 24px;
						font-weight: 700;
						letter-spacing: 8px;
						color: rgba(187, 140, 75, 1);
					}
				}
			}

			&-info {
				&__title {
					font-size: 18px;
					font-weight: 700;
					letter-spacing: 2px;
					color: rgba(38, 7, 6, 1);
					margin-bottom: 20px;
					display: block;
					line-height: 1.5;
					text-align: center;

					p {
						font-size: 24px;
						color: rgba(187, 140, 75, 1);
						white-space: nowrap;
					}
				}

				&__tips {
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0px;
					color: rgba(38, 7, 6, 1);
					text-align: center;
					line-height: 1.75;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;
					margin-bottom: 20px;

					.img {
						margin: 0 6px 0 10px;
						width: 15px;
						height: 15px;
					}
				}

				&__img {
					margin-bottom: 10px;
				}
			}

			&-line {
				padding: 30px 0;

				.img {
					margin: 0 10px;
				}
			}

			&-footer {
				margin: 20px 0 0px;
				padding-bottom: 50px;

				&__info {
					.info-desc {
						color: rgba(192, 151, 108, 1);
						font-size: 14px;
						letter-spacing: 0px;
					}

					.info-line {
						border-bottom: 1.45px solid rgba(163, 123, 80, 1.00);
						margin: 0 4px;
					}

					.img {
						width: 14px;
						height: 14px;
					}
				}

				&__logo {
					margin-top: 20px;

					img {
						max-width: 30%;
					}
				}
			}

			&-block1 {
				padding-top: 20px;

				&__h1 {
					color: rgba(84, 0, 0, 1);
					font-size: 28px;
					font-weight: bold;
					letter-spacing: 2px;
				}

				&__tips {
					font-size: 18px;
					font-weight: 400;
					letter-spacing: 2px;
					padding: 20px 0 20px;
					color: rgba(84, 0, 0, 1);
					align-items: center;

					p {
						font-weight: bold;
					}
				}

				&__title {
					font-size: 28px;
					font-weight: 700;
					letter-spacing: 8px;
					color: rgba(187, 140, 75, 1);
					font-family: fangsong;
					margin-bottom: 40px;
				}
			}

		}
	}
</style>