<template>
    <div id="page-navigate" class="navigate-wrapper"
        :class="[$t('common.classLang'), $t('common.fontFamily')]">
        <div class="navigate-box">
            <div class="navigate-logo">
                <a href="/">
                    <img class="navigate-logo-img" src="../../assets/image/navigate/logo.svg" alt="" />
                </a>
                <a id="menu" href="javascript:;"><span class="iconfont icon-navigate-menu"></span></a>
            </div>
            <div id="linkBox" class="navigate-links navigate-link-box-close">
                <div id="linkList" class="navigate-menu-list">
                    <div class="navigate-menu-item" v-for="(nav, index) in $t('PageNavigate.headlist')" :key="index">
                        <div class="navigate-menu-item-top">
                            <a class="navigate-menu-item-top-link" :href="nav.url ? nav.url : 'javascript:;'">{{
                                nav.text }}
                                <span class="iconfont icon-navigate-down" v-if="nav.menu"></span></a>
                        </div>
                        <div class="navigate-menu-item-content" v-if="nav.menu && nav.menu.length">
                            <div class="navigate-menu-item-content-box">
                                <div class="navigate-menu-item-content-value"
                                    :class="{ 'navigate-menu-item-content-value-row': !list.tit }"
                                    v-for="(list, i) in nav.menu">
                                    <div class="navigate-menu-item-content-value-label" v-if="list.tit">
                                        {{ list.tit }}
                                    </div>
                                    <template v-if="list.tit">
                                        <a :href="link.url" v-for="(link, key) in list.list"> {{ link.text }} </a>
                                    </template>
                                    <template v-else>
                                        <div class="navigate-menu-item-content-value" v-for="(link, key) in list.list">
                                            <a :href="link.url">{{ link.text }}</a>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navigate-entry">
                    <div class="navigate-entry-link">
                        <a :href="FCAUrl" class="nav-link">{{ $t('PageNavigate.FCA开户') }}</a>
                        <a :href="signupUrl">{{ $t('PageNavigate.立即开户') }}</a>
                        <a :href="loginUrl">{{ $t('PageNavigate.登录') }}</a>
                    </div>
                    <div class="navigate-help">
                        <div class="navigate-help-item">
                            <a href="/official-verification"><span class="iconfont icon-navigate-channel"></span></a>
                            <div class="navigate-help-item-content">
                                <div class="navigate-help-item-content-arrow"></div>
                                <a href="/official-verification" class="navigate-help-item-content-text">
                                    {{ $t('PageNavigate.官方验证') }}</a>
                            </div>
                        </div>
                        <div class="navigate-help-item">
                            <a :href="$t('PageNavigate.帮助中心Url')" target="_blank"><span
                                    class="iconfont icon-navigate-help"></span></a>
                            <div class="navigate-help-item-content">
                                <div class="navigate-help-item-content-arrow"></div>
                                <a :href="$t('PageNavigate.帮助中心Url')" class="navigate-help-item-content-text"
                                    target="_blank">{{
                                    $t('PageNavigate.帮助中心') }}</a>
                            </div>
                        </div>
                        <div ref="languageBox" class="navigate-menu-item">
                            <div class="navigate-menu-item-top">
                                <a class="navigate-menu-item-top-link" href="javascript:;"><span
                                        class="iconfont icon-navigate-language"></span></a>
                            </div>
                            <div class="navigate-menu-item-content mobile-lang-selector">
                                <div id="langSelector" class="navigate-menu-item-content-box">
                                    <div class="navigate-menu-item-content-value l-w-14">
                                        <a v-if="hasLan('en')" :class="{ 'current-lang': language == 'en' }" @click="changeLange('en')"
                                            href="javascript:;">English</a>
                                        <a v-if="hasLan('ja')" :class="{ 'current-lang': language == 'ja' }" @click="changeLange('ja')"
                                            href="javascript:;">Japanese · 日本語</a>
                                        <a v-if="hasLan('ko')" :class="{ 'current-lang': language == 'ko' }" @click="changeLange('ko')"
                                            href="javascript:;">Korean · 한국어</a>
                                    </div>
                                    <div class="navigate-menu-item-content-value l-w-23">
                                        <a v-if="hasLan('zh')" :class="{ 'current-lang': language == 'zh' }" @click="changeLange('zh')"
                                            href="javascript:;">Simplified Chinese · 简体中文</a>
                                        <a v-if="hasLan('ct')" :class="{ 'current-lang': language == 'ct' }" @click="changeLange('ct')"
                                            href="javascript:;">Traditional Chinese · 繁體中文</a>
                                    </div>
                                    <div class="navigate-menu-item-content-value l-w-18">
                                        <a v-if="hasLan('th')" :class="{ 'current-lang': language == 'th' }" @click="changeLange('th')"
                                            href="javascript:;">Thai
                                            · ภาษาไทย</a>
                                        <a v-if="hasLan('vn')" :class="{ 'current-lang': language == 'vn' }" @click="changeLange('vn')"
                                            href="javascript:;">Vietnamese · Tiếng Việt</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navigate-mask mobile"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    inject: ['reload'], //注入App里的reload方法
    props: {
        langList: {
            type: Array,
            default: () => {
                return ['zh', 'ct', 'en', 'ja', 'ko', 'th', 'vn',]
            }
        }
    },
    data() {
        return {

        }
    },
    computed: {

        ...mapGetters([
            'language',
            'FCAUrl',
            'signupUrl',
            'loginUrl',
        ])
    },
    created() {

    },

    mounted() {

        // 匹配当前路由并高亮
        let hrefReg = new RegExp("^" + window.location.href + "$");
        // console.log("hrefReg", window.location, hrefReg);
        let linkList = document.getElementById("linkList");
        let navigateMenuItemList =
            linkList.getElementsByClassName("navigate-menu-item");
        for (let i = 0; i < navigateMenuItemList.length; i++) {
            let linkItems = navigateMenuItemList[i].getElementsByTagName("a");
            for (let j = 0; j < linkItems.length; j++) {
                // console.log("linkItems[j].href", linkItems[j].href)
                if (hrefReg.test(linkItems[j].href)) {
                    navigateMenuItemList[i].classList.add("current-group");
                    linkItems[j].classList.add("current-link");
                }
            }
        }

        // 添加移入移出事件
        let navigateMenuItemTopList = document.getElementsByClassName(
            "navigate-menu-item-top"
        );
        let pageNavigate = document.getElementById("page-navigate");
        navigateMenuItemList =
            pageNavigate.getElementsByClassName("navigate-menu-item");
        for (let i = 0; i < navigateMenuItemTopList.length; i++) {
            navigateMenuItemTopList[i].addEventListener("mouseenter", function (e) {
                for (let j = 0; j < navigateMenuItemList.length; j++) {
                    if (e.target.parentNode == navigateMenuItemList[j]) {
                        e.target.parentNode.classList.add(
                            "navigate-menu-item-content-selected"
                        );
                    } else {
                        navigateMenuItemList[j].classList.remove(
                            "navigate-menu-item-content-selected"
                        );
                    }
                }
            });
        }
        // 清除选中展开样式
        function clearNavigateMenuItemList() {
            for (let i = 0; i < navigateMenuItemList.length; i++) {
                navigateMenuItemList[i].classList.remove(
                    "navigate-menu-item-content-selected"
                );
            }
        }
        document
            .getElementsByClassName("navigate-entry-link")[0]
            .addEventListener("mouseenter", function () {
                clearNavigateMenuItemList();
            });

        pageNavigate.addEventListener("mouseleave", function () {
            clearNavigateMenuItemList();
        });

        // 手机端操作
        // 切换菜单栏
        let menuBtn = document.getElementById("menu");
        menuBtn.addEventListener("click", function (e) {
            linkBox.classList.toggle("navigate-link-box-close");
        });
        // 点击空白处关闭菜单栏
        let navigateMask = document.getElementsByClassName("navigate-mask")[0];
        navigateMask.addEventListener("click", function (e) {
            linkBox.classList.toggle("navigate-link-box-close");
        });
        //
        let navigateMenuItemTopLinkList = document.getElementsByClassName(
            "navigate-menu-item-top-link"
        );

        for (let i = 0; i < navigateMenuItemTopLinkList.length; i++) {
            navigateMenuItemTopLinkList[i].addEventListener("click", function (e) {
                if (e.target.nodeName === "SPAN") {
                    e.target.parentNode.parentNode.parentNode.classList.toggle(
                        "navigate-link-selected"
                    );
                } else {
                    e.target.parentNode.parentNode.classList.toggle("navigate-link-selected");
                }
            });
        }
    },
    methods: {
        changeLange(language) {
            this.$store.commit("setLanguage", language);
            this.$refs.languageBox.classList.remove('navigate-menu-item-content-selected');
            this.reload();
        },
        hasLan(language){
            return this.langList.includes(language)
        }
    }
}
</script>
<style lang="scss" scoped>
@import url("../../assets/css/page-navigate.css");
</style>