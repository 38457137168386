export default {
  type: "cn",
  lang: "en",
  lang2: "en",
  "Trading Central": "Trading Central",
  市场分析: "市場分析",
  EBC与TradingCentral携手合作:
    "EBC與Trading Central攜手合作，為所有活躍客戶提供專業的市場分析工具，幫助客戶深入了解市場、發現交易機會並優化交易策略。",
  分析师观点: "分析師觀點",
  财经日历解析: "財經日曆解析",
  "Alpha-MT4/MT5技术指标": "Alpha-MT4/MT5技術指標",
  "Martin Pring教学视频": "Martin Pring教學視頻",
  解锁完整版: "解鎖完整版",
  "*本页面的信息不构成EBC":
    "*本頁面的資訊不構成EBC、Trading Central或其內容供應商對金融工具投資的建議或推薦。 EBC、Trading Central及其內容供應商不對因使用此資訊而導致的任何損失或損害承擔責任。",
  收起: "收起",
  衍生品: "Forex",
  指数: "Indices ",
  商品: "Commodities ",
  星号数代表支撑位和阻力位的强度: "Star Ratings indicate the strength of the support and resistance level",
  分析师观点: "分析師觀點",
  通过清晰的趋势线展示简明易行的交易计划:
    "透過清晰的趨勢線展示簡潔易行的交易計劃，並在整個交易時段持續發布，幫助您發現新的交易機會。",
  财经日历解析: "財經日曆解析",
  提供38个国家:
    "提供38個國家的即時宏觀經濟數據，幫助交易者監控、預測和應對潛在的市場波動。",
  "Alpha-MT4/MT5 技术指标": "Alpha-MT4/MT5技術指標",
  通过三个简明的创新指标展示市场情绪:
    "透過三個簡潔的創新指標展現市場情緒，幫助交易者做出決策，找到潛在的進場/出場點。",
  技术观点: "技術觀點：",
  通过技术分析结果: "透過技術分析結果，為每張圖表提供首選方向和目標點位。",
  自适应K线图: "自適應K線圖：",
  支持16种与决策相关的K线图形模式: "支援16種與決策相關的K線圖形模式。",
  "自适应趋异指标(ADC)": "自適應趨異指標(ADC)：",
  "长短进/出信号": "長短進/出訊號、快慢價格指標、初始/平滑訊號線和快慢震盪器。",
  下载指标: "下載指標",
  "Martin Pring 教学视频": "Martin Pring教學影片",
  "通过Martin Pring的视频课程":
    "透過Martin Pring的影片課程，學習技術分析基礎，掌握趨勢線和移動平均線等技巧，提升交易水準。",
  灵活的学习模式: "靈活的學習模式：",
  可以自由安排学习进度:
    "可以自由安排學習進度，透過豐富的內容和工具，輕鬆掌握投資技巧。",
  广泛的内容覆盖: "廣泛的內容覆蓋：",
  从初级到高级:
    "從初級到高級，全面課程內容滿足不同程度使用者的需求，系統提升投資技能。",
  互动学习体验: "互動學習體驗：",
  包含术语表和自我评估等互动工具:
    "包含術語表和自我評估等互動工具，檢測學習進展，增強理解和記憶效果。",
  了解更多: "了解更多",
};
