export default {
  type: "cn",
  lang: "zh-cn",
  lang2: "cs",
  "Trading Central": "Trading Central",
  市场分析: "市场分析",
  EBC与TradingCentral携手合作:
    "EBC与TradingCentral携手合作，为所有活跃客户提供专业的市场分析工具，帮助客户深入了解市场、发现交易机会并优化交易策略。",
  分析师观点: "分析师观点",
  财经日历解析: "财经日历解析",
  "Alpha-MT4/MT5技术指标": "Alpha-MT4/MT5技术指标",
  "Martin Pring教学视频": "Martin Pring教学视频",
  解锁完整版: "解锁完整版",
  "*本页面的信息不构成EBC":
    "*本页面的信息不构成EBC、TradingCentral或其内容供应商对金融工具投资的建议或推荐。EBC、TradingCentral及其内容供应商不对因使用此信息导致的任何损失或损害承担责任。",
  收起: "收起",
  衍生品: "衍生品",
  指数: "指数",
  商品: "商品",
  星号数代表支撑位和阻力位的强度: "星号数代表支撑位和阻力位的强度。",
  分析师观点: "分析师观点",
  通过清晰的趋势线展示简明易行的交易计划:
    "通过清晰的趋势线展示简明易行的交易计划，并在整个交易时段持续发布，帮助您发现新的交易机会。",
  财经日历解析: "财经日历解析",
  提供38个国家:
    "提供38个国家/地区的实时宏观经济数据，帮助交易者监控、预测和应对潜在的市场波动。",
  "Alpha-MT4/MT5 技术指标": "Alpha-MT4/MT5 技术指标",
  通过三个简明的创新指标展示市场情绪:
    "通过三个简明的创新指标展示市场情绪，帮助交易者做出决策，找到潜在的进入/退出点。",
  技术观点: "技术观点：",
  通过技术分析结果: "通过技术分析结果，为每张图表提供首选方向和目标点位。",
  自适应K线图: "自适应K线图：",
  支持16种与决策相关的K线图形模式: "支持16种与决策相关的K线图形模式。",
  "自适应趋异指标(ADC)": "自适应趋异指标(ADC)：",
  "长短进/出信号": "长短进/出信号、快慢价格指标、初始/平滑信号线和快慢震荡器。",
  下载指标: "下载指标",
  "Martin Pring 教学视频": "Martin Pring 教学视频",
  "通过Martin Pring的视频课程":
    "通过Martin Pring的视频课程，学习技术分析基础，掌握趋势线和移动平均线等技巧，提升交易水平。",
  灵活的学习模式: "灵活的学习模式：",
  可以自由安排学习进度:
    "可以自由安排学习进度，通过丰富的内容和工具，轻松掌握投资技巧。",
  广泛的内容覆盖: "广泛的内容覆盖：",
  从初级到高级:
    "从初级到高级，全面课程内容满足不同水平用户的需求，系统提升投资技能。",
  互动学习体验: "互动学习体验：",
  包含术语表和自我评估等互动工具:
    "包含术语表和自我评估等互动工具，检测学习进展，增强理解和记忆效果。",
  了解更多: "了解更多",
};
