<template>
  <div>
    <page-navigate></page-navigate>
    <div :class="$t('common.lang') + ' ' + $t('Oxford.box-class-name')">
      <div class="oxford-wrapper" :class="$t('common.fontFamily')">
        <div class="oxford-session1">
          <div class="oxford-session1-info">
            <div class="oxford-session1-logos">
              <img src="../assets/image/oxford/session1_logo1.png" class="oxford-session1-logo1" alt="" />
              <div class="pc">
                <img src="../assets/image/oxford/session1_logo2.png" class="oxford-session1-logo2" alt="" />
                <img src="../assets/image/oxford/session1_logo3.png" class="oxford-session1-logo3" alt="" />
              </div>
            </div>
            <div class="oxford-session1-info-wrapper">
              <div class="left">
                <div class="left-main">
                  WHAT<br />
                  ECONOMISTS<br />
                  REALLY <br />
                  DO
                </div>
                <div class="left-text">{{ $t('Oxford.经济学家都干了什么') }}</div>
                <div class="mobile logo-m">
                  <img src="../assets/image/oxford/session1_logo2.png" class="oxford-session1-logo2" alt="" />
                  <img src="../assets/image/oxford/session1_logo3.png" class="oxford-session1-logo3" alt="" />
                </div>
              </div>
              <div class="right">
                <div class="right-main " :class="$t('common.fontFamilyTitle')">
                  {{ $t('Oxford.如何利用气候经济学') }}<br />
                  {{ $t('Oxford.挖掘投资机遇') }}
                </div>
                <div class="line"></div>
                <div class="right-text">
                  {{ $t('Oxford.2024年11月14日') }}
                  <br />{{ $t('Oxford.牛津大学Michael Dummett讲座厅') }}
                </div>
                <a href="https://www.economics.ox.ac.uk/event/what-economists-really-do" target="_blank"
                  class="btn-go">{{
                    $t('Oxford.预约报名') }}</a>
              </div>
            </div>
          </div>
          <div class="oxford-session1-bottom"></div>
        </div>
        <div class="oxford-session2">
          <div class="oxford-session2-inner">
            <div class="oxford-session2-title " :class="$t('common.fontFamilyTitle')">
              {{ $t('Oxford.EBC邀您游览牛津大学') }}
            </div>
            <div class="oxford-session2-text">
              {{ $t('Oxford.EBC金融集团再次携手牛津大学') }}

            </div>
            <div class="oxford-session2-active-title"> {{ $t('Oxford.活动介绍') }} </div>
            <div class="oxford-session2-active-text">
              {{ $t('Oxford.本次线下研讨会将于11月14日14:30') }}
              <br /><br />
              {{ $t('Oxford.EBC金融专家还将与您一同漫步在新学院的回廊') }}
            </div>
            <a href="https://www.economics.ox.ac.uk/event/what-economists-really-do" target="_blank" class="btn-go">{{
              $t('Oxford.预约报名')
            }}</a>
          </div>
        </div>
        <div class="oxford-session3">
          <div class="oxford-session3-title " :class="$t('common.fontFamilyTitle')">{{ $t('Oxford.与会嘉宾') }}</div>
          <div class="speakers">
            <div class="speaker-item">
              <img src="../assets/image/oxford/session3_1.png" alt="" />
              <div class="box-right">
                <p class="p1">Nicola Ranger</p>
                <p class="p2">{{ $t('Oxford.牛津大学环境复原力及马丁系统韧性负责人') }}</p>
                <p class="p3">
                  {{ $t('Oxford.Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人') }}
                </p>
                <a class="btn-more" href="https://www.ox.ac.uk/news-and-events/find-an-expert/dr-nicola-ranger"
                  rel="nofollow" target="_blank">{{ $t('Oxford.查看更多') }}</a>
              </div>
            </div>
            <div class="speaker-item">
              <img src="../assets/image/oxford/session3_2.png" alt="" />
              <div class="box-right">
                <p class="p1">Andrea Chiavari</p>
                <p class="p2">{{ $t('Oxford.牛津大学经济学系教授') }}</p>
                <p class="p3">
                  {{ $t('Oxford.Andrea Chiavari 是牛津大学经济学系教授') }}

                </p>
                <a class="btn-more" href="https://www.economics.ox.ac.uk/people/andrea-chiavari" rel="nofollow"
                  target="_blank">{{ $t('Oxford.查看更多') }}</a>
              </div>
            </div>
            <div class="speaker-item">
              <img src="../assets/image/oxford/session3_3.png" alt="" />
              <div class="box-right">
                <p class="p1">Banu Demir Pakel</p>
                <p class="p2">{{ $t('Oxford.牛津大学经济学系教授2') }}</p>
                <p class="p3">
                  {{ $t('Oxford.Banu Demir Pakel 是牛津大学经济学系教授') }}

                </p>
                <a class="btn-more" href="https://economics.web.ox.ac.uk/people/banu-demir-pakel" rel="nofollow"
                  target="_blank">{{ $t('Oxford.查看更多') }}</a>
              </div>
            </div>
            <div class="speaker-item">
              <img src="../assets/image/oxford/session3_4.png" alt="" />
              <div class="box-right">
                <p class="p1">David Barrett</p>
                <p class="p2">{{ $t('Oxford.EBC金融集团（英国）首席执行官') }} </p>
                <p class="p3">
                  {{ $t('Oxford.David Barrett是EBC 金融集团 (英国) CEO') }}

                </p>
                 <a class="btn-more" href="JavaScript:void(0)" @click.prevent="jumPage('LEARN-72-34C')" rel="nofollow"
                  target="_blank">{{ $t('Oxford.查看更多') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="oxford-session4">
          <div class="oxford-session4-title " :class="$t('common.fontFamilyTitle')">{{ $t('Oxford.往期回顾') }}</div>
          <img class="banner" src="../assets/image/oxford/session4_bg.png" alt="" />
          <div class="introduce-title">
            {{ $t('Oxford.EBC金融集团联合牛津大学经济学系第一期') }}

          </div>
          <div class="introduce-info" v-html="$t('Oxford.2024年3月6日EBC与牛津大学联合举办主题为')">
          </div>
          <a class="btn-more" :href="$t('Oxford.lang') + '/oxford1'" rel="nofollow" target="_blank">{{ $t('Oxford.查看更多')
            }}</a>
        </div>
        <div class="oxford-session5">
          <div class="oxford-session5-title " :class="$t('common.fontFamilyTitle')">{{ $t('Oxford.关于EBC金融集团') }} </div>
          <div class="oxford-session5-info" v-html="$t('Oxford.EBC Financial Group 成立于英国伦敦')">
          </div>
          <a class="btn-more" :href="$t('Oxford.lang') + '/about-us/'" rel="nofollow" target="_blank">{{
            $t('Oxford.查看更多')
          }}</a>
        </div>
        <div class="oxford-session6">
          <div class="oxford-session6-title " :class="$t('common.fontFamilyTitle')">
            {{ $t('Oxford.关于牛津大学经济学系') }}

          </div>
          <div class="oxford-session6-info">
            {{ $t('Oxford.牛津大学是享誉全球的顶尖高等学府') }}

            <br /><br />
            {{ $t('Oxford.牛津大学经济学系以其专业权威的学术研究') }}

          </div>
          <div class="logos">
            <img class="logos-img" src="../assets/image/oxford/session6_logo1.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <page-footer></page-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seoInfo: "",
    }
  },
  metaInfo() {
    return {
      title: this.seoInfo.title || 'EBC联合牛津大学研讨会 | EBC Group',
      meta: [ // set meta
        {
          name: 'keyWords',
          content: this.seoInfo.keyWords || ''
        },
        {
          name: 'description',
          content: this.seoInfo.description || ''
        }
      ]
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    jumPage(id) {
      // this.$router.push({
      //   name: 'LearningCenterInfo',
      //   query: {
      //     id: id
      //   },
      //   target: "blank"
      // })

      let routeData = this.$router.resolve({
        name: 'LearningCenterInfo',
        query: {
          id: id
        },
      });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<style lang="scss" scoped>
@charset "UTF-8";
@import "../assets/font/lang-font-family.css";



@import url('https://fonts.googleapis.com/css2?family=Gloock&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

@font-face {
  font-family: 'Norwester';
  src: url('../assets/font/norwester.otf');
  font-style: normal;
  font-display: swap;
}

body {
  background: #f3f3f3;
}

.oxford-wrapper {
  background: url(../assets/image/oxford/bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;

}


.oxford-session1 {
  // margin-top: 30px;
  position: relative;
  padding: 140px 140px 210px;
  min-height: 675px;
  background-image: url(../assets/image/oxford/session1_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.oxford-session1-info {
  margin: 0 auto;
  max-width: 1160px;
}

.oxford-session1-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oxford-session1-logo1 {
  width: 580px;
  height: auto;
}

.oxford-session1-logo2,
.oxford-session1-logo3 {
  width: 92px;
  height: auto;
}

.oxford-session1-logo3 {
  margin-left: 12px;
}

.oxford-session1-info-wrapper {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  gap: 190px;
}

.oxford-session1 .left-main {
  position: relative;
  color: #fac104;
  font-family: Norwester;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 117.9%;
  /* 83.12px */

}

.oxford-session1 .left-text {
  margin-top: 10px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.cn .oxford-session1 .right-main {
  font-family: "Noto Serif SC", serif;
  color: #fff;
  font-size: 54px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  /* 81px */
}

.oxford-session1 .right-main {
  font-family: "Oswald", sans-serif;
  letter-spacing: normal;
  color: #fff;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.oxford-session1 .line {
  margin: 29px 0 0 0;
  width: 145px;
  height: 1px;
  background-color: #fff;
}

.cn .oxford-session1 .right-text {
  font-family: "Noto Serif SC", serif;
}

.oxford-session1 .right-text {
  font-family: "Oswald", sans-serif;
  margin-top: 29px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.oxford-session1 .btn-go,
.oxford-session2 .btn-go,
.oxford-session3 .btn-more,
.oxford-session4 .btn-more,
.oxford-session5 .btn-more {
  margin-top: 14px;
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: rgba(251, 181, 11, 1);
  color: #0d1d41;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.oxford-session1 .btn-go {
  margin-top: 29px;
}

.oxford-session2 .btn-go {
  margin-top: 35px;
}

.oxford-session1 .btn-go:hover,
.oxford-session2 .btn-go:hover {
  border-radius: 5px;
  background: rgba(251, 181, 11, 0.8);
}

.oxford-session1-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 33px;
  width: 100%;
  background-image: url(../assets/image/oxford/session1_bg_b.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.oxford-session2 {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -124px;
}

.oxford-session2-inner {
  max-width: 1180px;
  padding: 40px 70px 60px 70px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.06);
}

.cn .oxford-session2-title {
  font-size: 48px;
  font-weight: 900;
}

.oxford-session2-title {
  color: #1e284c;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.oxford-session2-text {
  margin-top: 8px;
  color: #143785;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 25.5px */
  letter-spacing: 0.51px;

}

.oxford-session2-active-title {
  margin-top: 34px;
  color: #1e284c;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.oxford-session2-active-text {
  margin-top: 10px;
  color: #525c60;
  text-align: justify;
  font-family: "Noto Sans SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.oxford-session3 {
  margin: 144px auto 0;
  max-width: 1180px;
}

.oxford-session4,
.oxford-session5 {
  margin: 100px auto 0;
  max-width: 1180px;
}

.oxford-session6 {
  margin: 54px auto 0;
  max-width: 1180px;
  padding-bottom: 180px;
}

.cn .oxford-session3 .oxford-session3-title,
.cn .oxford-session4 .oxford-session4-title,
.cn .oxford-session5 .oxford-session5-title,
.cn .oxford-session6 .oxford-session6-title {
  font-weight: 900;
}

.oxford-session3 .oxford-session3-title,
.oxford-session4 .oxford-session4-title,
.oxford-session5 .oxford-session5-title,
.oxford-session6 .oxford-session6-title {
  color: #1e284c;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.speaker-item {
  display: flex;
  margin-top: 54px;
  gap: 24px;
}

.speaker-item>img {
  width: 280px;
  height: 302px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #c4c4c4;
}

.speaker-item .box-right .p1 {
  color: #1e284c;
  font-family: Gloock;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.speaker-item .box-right .p2 {
  margin-top: 12px;
  color: #525c60;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.speaker-item .box-right .p3 {
  margin-top: 18px;
  color: #525c60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  min-height: 153px;
}

.oxford-session4 .btn-more {
  margin-top: 20px;
}

.oxford-session5 .btn-more {
  margin-top: 24px;
}

.oxford-session4 .banner {
  margin-top: 20px;
  width: 100%;
  height: auto;
}

.oxford-session4 .introduce-title {
  margin-top: 20px;
  color: #525c60;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 32px */
}

.oxford-session4 .introduce-info {
  margin-top: 24px;
  color: #525c60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.oxford-session5 .oxford-session5-info,
.oxford-session6 .oxford-session6-info {
  margin-top: 24px;
  color: #525c60;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.oxford-session6 .logos {
  margin-top: 100px;
}

.logos-img {
  max-width: 887px;
  width: 95%;
  height: auto;
}

@media (max-width: 1400px) {
  .oxford-session1-info-wrapper {
    gap: 50px;
  }

  .oxford-session1 .right-text {
    font-size: 22px;
  }
}

@media (max-width: 1300px) {
  .cn .oxford-session1 .right-main {
    font-size: 46px;
  }

  .oxford-session1 .right-main {
    font-size: 32px;
  }
}

@media (max-width: 1200px) {
  .oxford-session1 .left-main {
    font-size: 60px;
  }

  .cn .oxford-session1 .right-main {
    font-size: 42px;
  }

  .oxford-session1 .right-main {
    font-size: 28px;
  }
}

@media (max-width: 1100px) {
  .oxford-session1 .left-main {
    font-size: 56px;
  }

  .cn .oxford-session1 .right-main {
    font-size: 38px;
  }

  .oxford-session1 .right-main {
    font-size: 26px;
  }

  .oxford-session1-logo1 {
    width: 500px;
  }
}

@media (max-width: 995px) {
  .oxford-session1 {
    padding: 67px 24px 87px;
  }

  .oxford-session1 .left-main {
    font-size: 42px;
  }

  .oxford-session1 .left-text {
    margin-top: 6px;
    font-size: 15px;
    max-width: calc(100% - 140px);
    white-space: normal;
  }

  .oxford-session1-info-wrapper {
    margin-top: 14px;
    flex-direction: column;
    gap: 0;
  }

  .cn .oxford-session1 .right-main {
    margin-top: 32px;
    font-size: 36px;
  }

  .oxford-session1 .right-main {
    margin-top: 32px;
    font-size: 22px;
  }

  .oxford-session1 .line {
    margin-top: 19px;
    width: 97px;
  }

  .oxford-session1 .right-text {
    margin-top: 19px;
    font-size: 16px;
  }

  .oxford-session1 .left {
    position: relative;
  }

  .oxford-session1 .logo-m {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .oxford-session1-logo2,
  .oxford-session1-logo3 {
    width: 60px;
    height: auto;
  }

  .oxford-session1 .btn-go,
  .oxford-session2 .btn-go,
  .oxford-session3 .btn-more,
  .oxford-session4 .btn-more,
  .oxford-session5 .btn-more {
    display: flex;
  }

  .oxford-session2 {
    margin: 0;
  }

  .oxford-session2-inner {
    max-width: none;
    padding: 70px 27px;
    border-radius: 0;
    box-shadow: none;
  }

  .cn .oxford-session2-title {
    font-size: 42px;
  }

  .oxford-session2-title {
    font-size: 32px;
  }

  .oxford-session2-text {
    margin-top: 20px;
    font-size: 16px;
  }

  .oxford-session2-active-title {
    margin-top: 40px;
  }

  .oxford-session2 .btn-go {
    margin-top: 40px;
  }

  .oxford-session3 .oxford-session3-title,
  .oxford-session4 .oxford-session4-title,
  .oxford-session5 .oxford-session5-title,
  .oxford-session6 .oxford-session6-title {
    text-align: center;
  }

  .oxford-session3,
  .oxford-session4,
  .oxford-session5,
  .oxford-session6 {
    margin: 0;
    padding: 60px 27px;
  }

  .oxford-session6 {
    padding-top: 0;
  }

  .speaker-item {
    margin-top: 60px;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .box-right>p {
    text-align: center;
  }

  .oxford-session4 {
    background-color: #fff;
    padding-bottom: 117px;
  }

  .oxford-session4 .introduce-title {
    font-size: 18px;
    text-align: center;
  }

  .oxford-session4 .introduce-info {
    text-align: center;
  }

  .oxford-session5 .oxford-session5-info,
  .oxford-session6 .oxford-session6-info {
    text-align: center;
  }

  .oxford-session6 .logos {
    margin-top: 54px;
  }

  .logos-img {

    width: 100%;
  }
}
</style>