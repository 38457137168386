// export default {
//   title: `<span class="text">Cuộc Thi Giao Dịch Triệu Đô EBC II</span>`,
//   title2: ``,
//   巴萨传奇再启: "Huyền Thoại Barca Quay Lại: Chinh Phục Ngôi Vương Giao Dịch",
//   成就交易之王: "",
//   大赛时间:
//     "Thời gian giải đấu: 01/03/2025 - 30/05/2025",
//   距开赛: ``,
//   天: "d",
//   时: "h",
//   分: "m",
//   秒: "s",
//   一键跟单: "Click để Copy Trade",
//   免费复制冠军收益: "Sao chép miễn phí lợi nhuận của nhà vô địch",
//   公开透明: "Công Khai Và Minh Bạch",
//   观摩冠军交易技巧: "Quan sát kỹ thuật giao dịch của nhà vô địch",
//   推荐有礼: "Giới Thiệu Nhận Thưởng",
//   推荐有奖上不封顶: "Nhận thưởng không giới hạn",
//   立即参赛: "THAM GIA NGAY",
//   现金奖池: "Giải thưởng tiền mặt",
//   巴萨梦之队: "Bảng Tinh Anh",
//   拉玛西亚组: "Bảng Tân Binh",
//   冠军: "Quán Quân",
//   亚军: "Á Quân",
//   季军: "Hạng Ba",
//   第四至五名: "Hạng Tư - Hạng Năm",
//   第六至十名: "Hạng Sáu - Hạng Mười",
//   "$1,000,000交易账号或$200,000现金":
//     "1.000.000 USD Tài khoản giao dịch hoặc 200.000 USD Tiền mặt",
//   "1,000,000交易账号或200,000现金":
//     "1.000.000 USD Tài khoản giao dịch hoặc 200.000 USD Tiền mặt",
//   大赛推荐官: "Đại sứ giới thiệu Cuộc thi",
//   推荐奖励: "Thưởng giới thiệu không giới hạn",
//   巴萨梦之队HTML: `Bảng Tinh Anh`,
//   拉玛西亚组HTML: `Bảng Tân Binh`,
//   "/人": "/ người",
//   活动时间:
//     "Thời gian hoạt động:Từ 04:00:00 ngày 01/03/2025 đến 03:59:59 ngày 01/05/2025 (GMT+7)",
//   推荐流程: "Quy Trình Giới Thiệu",
//   推荐好友: "Giới thiệu bạn bè",
//   好友参赛: "Bạn bè tham gia",
//   赢得奖金: "Nhận thưởng",
//   实时赛况: "Xếp hạng hiện tại",
//   排名: "Xếp Hạng",
//   昵称: "Biệt Danh",
//   服务器: "Máy Chủ",
//   账号: "Tài Khoản",
//   收益额: "Lợi Nhuận",
//   收益率: "Tỷ Lệ Lợi Nhuận",
//   奖金: "Thưởng",
//   跟单: "Copy Trading",
//   该页面仅展示收益表现排名前10的交易账号:
//     "Trang này chỉ hiển thị 10 tài khoản giao dịch có hiệu suất lợi nhuận cao nhất, xếp hạng được tính bằng đơn vị tiền tệ USD và được cập nhật mỗi giờ.",
//   赛事进程: "Tiến Trình Cuộc Thi",
//   比赛开始: "Khởi Động Cuộc Thi",
//   推荐结束: "Kết Thúc Thời Gian Giới Thiệu",
//   比赛结束: "Kết Thúc Cuộc Thi",
//   颁奖典礼: "Lễ Trao Giải",
//   赛组规则: "Quy Tắc Tham Gia Cuộc Thi",
//   类别: "Danh mục",
//   账号要求: "Yêu Cầu Tài Khoản",
//   "新交易账号，且账号余额大于$500":
//     "Tài khoản giao dịch mới, với số dư tài khoản lớn hơn $500.",
//   "新交易账号，且$10,000≤账号余额≤$200,000":
//     "Tài khoản giao dịch mới, với số dư tài khoản trong khoảng từ 10.000 USD đến 200.000 USD.",
//   排名规则: "Quy Tắc Xếp Hạng",
//   以收益率排名为评判标准: "Xếp hạng theo tỷ suất lợi nhuận:",
//   "收益率=期末净值/账号累积入金":
//     "Tỷ lệ lợi nhuận = Vốn cuối kỳ / Tổng số tiền nạp vào tài khoản.",
//   以净盈利额排名为评判标准: "Xếp hạng theo lợi nhuận ròng",
//   持仓限制: "Giới Hạn Vị Thế",
//   每个参赛账号最多持有100手仓位:
//     "Mỗi tài khoản tham gia chỉ có thể nắm giữ tối đa 100 lots (vị thế).",
//   无持仓限制: "Không hạn chế số lượng vị thế",
//   参赛条款: "Điều Khoản Cuộc Thi",
//   参赛须知: "Nguyên tắc cuộc thi:",
//   section6List: [
//     {
//       t: "1. Thời gian sự kiện",
//       c: [
//         "Thời gian cuộc thi: Từ 00:00:00 ngày 01/03/2025 đến 23:59:59 ngày 30/05/2025 (GMT+3)",
//       ],
//     },
//     {
//       t: "2. Yêu cầu tài khoản",
//       c: [
//         "Tài khoản tham gia phải được đăng làm tài khoản Master trên cộng đồng giao dịch, nhưng không bắt buộc mở cho phép theo dõi và không được sao chép tín hiệu từ tài khoản khác.",
//         "Người chơi có thể sử dụng nhiều tài khoản để tham gia, nhưng chỉ tài khoản có kết quả tốt nhất được tính vào xếp hạng.",
//         "Chỉ áp dụng cho tài khoản STD và PRO; tài khoản PAMM và tài khoản Hồi giáo không đủ điều kiện tham gia.",
//       ],
//     },
//     {
//       t: "3. Quy tắc nhận thưởng",
//       c: [
//         "Nhà vô địch: Nếu chọn tài khoản giao dịch trị giá 1.000.000 USD, mức lỗ tối đa được phép là 200.000 USD.",
//         "Các giải thưởng khác sẽ được phân theo bảng xếp hạng.",
//       ],
//     },
//     {
//       t: "4. Hành vi nghiêm cấm",
//       c: [
//         "Nghiêm cấm sử dụng nhiều tài khoản để thực hiện giao dịch hedging nhằm tạo ra kết quả gian lận.",
//         "Các lệnh giao dịch bị ảnh hưởng bởi lỗi phần cứng máy chủ, báo giá sai hoặc yếu tố ngoài tầm kiểm soát sẽ không được tính.",
        
//       ],
//     },
//     {
//       t: "5. Quyền giải thích cuối cùng",
//       c: [
//         "EBC Financial Group sở hữu quyền giải thích cuối cùng đối với các quy định và kết quả của sự kiện.",
//       ],
//     },
//   ],
//   参赛流程: "Quy Trình Tham Gia",
//   注册开户: "Đăng ký tài khoản",
//   注册并开设新交易账号: "Đăng ký và mở tài khoản giao dịch mới",
//   报名参赛: "Đăng ký tham gia cuộc thi",
//   在用户中心报名参赛:
//     "Đăng ký tham gia cuộc thi qua trang người dùng (User Center)",
//   参赛成功: "Tham gia thành công",
//   参赛成功查看实时赛况:
//     "Sau khi tham gia thành công, bạn có thể theo dõi kết quả thi đấu trong thời gian thực.",
//   往届回顾: "Nhìn Lại Mùa Trước",
//   第一届交易大赛颁奖典礼: "Lễ trao giải cuộc thi giao dịch mùa I",
//   第一届交易大赛高光时刻: "Khoảnh khắc nổi bật trong cuộc thi giao dịch mùa I",
//   第一届交易大赛巅峰对决: "Cuộc đua đỉnh cao trong cuộc thi giao dịch mùa I",
//   查看更多: "Xem thêm",
//   常见问题: "Câu Hỏi Thường Gặp",
//   qaList: [
//     {
//       t: `1. "Tài khoản" và "Số tài khoản" có gì khác nhau?`,
//       c: [
//         "Tài khoản: Là tài khoản trung tâm người dùng EBC (UID). Mỗi người dùng chỉ sở hữu một tài khoản sau khi xác minh danh tính.",
//         "Số tài khoản: Là số tài khoản giao dịch MT. Một tài khoản EBC có thể tạo nhiều số tài khoản giao dịch để thực hiện nạp tiền và giao dịch.",
//       ],
//     },
//     {
//       t: "2. Làm thế nào để xác nhận đã tham gia thi đấu thành công?",
//       c: [
//         "Sau khi đăng ký thành công, trang sự kiện thi đấu trong trung tâm người dùng sẽ hiển thị tài khoản thi đấu cùng thứ hạng hiện tại.",
//       ],
//     },
//     {
//       t: "3. Có thể tham gia thi đấu bằng nhiều tài khoản không?",
//       c: [
//         "Người dùng được phép tham gia thi đấu ở hai bảng khác nhau bằng nhiều tài khoản, tuy nhiên mỗi tài khoản chỉ được tham gia một bảng đấu duy nhất.",
//       ],
//     },
//     {
//       t: "4. Có thể nhận thưởng ở cả hai bảng đấu nếu giành giải không?",
//       c: [
//         "Có, người tham gia có thể nhận thưởng ở cả hai bảng đấu, miễn là cả hai bảng đấu đều tuân thủ quy định, hoàn thành cuộc thi và đạt giải.",
//       ],
//     },
//     {
//       t: "5. Tài khoản MT4 hiện có thể tham gia thi đấu không?",
//       c: [
//         "Tài khoản tham gia thi đấu phải được tạo sau ngày 01/02/2025 và không có lịch sử giao dịch trước đó.",
//       ],
//     },
//     {
//       t: "6. Sau khi tham gia bảng đấu Tân Binh, nếu nạp thêm hơn 10.000 USD, bảng đấu có bị thay đổi không?",
//       c: [
//         "Không, bảng đấu sẽ được xác định theo thời điểm đăng ký tham gia. Các thay đổi về số dư sau đó không ảnh hưởng đến bảng đấu.",
//       ],
//     },
//     {
//       t: "7. Làm thế nào để xem xếp hạng trong cuộc thi?",
//       c: [
//         "Xếp hạng được cập nhật mỗi giờ (không cập nhật vào giờ chẵn), người dùng có thể xem xếp hạng của mình trên trang sự kiện thi đấu trong trung tâm người dùng.",
//       ],
//     },
//     {
//       t: "8. Trong suốt cuộc thi có thể nạp và rút tiền không?",
//       c: [
//         "Có thể tự do nạp và rút tiền, nhưng việc nạp tiền sẽ ảnh hưởng đến cách tính tỷ lệ lợi nhuận (Tỷ lệ lợi nhuận = Vốn cuối kỳ / Tổng số tiền nạp vào tài khoản).",
//       ],
//     },
//     {
//       t: "9. Làm thế nào để nhận thưởng giới thiệu?",
//       c: [
//         "Trong vòng 7 ngày làm việc sau khi cuộc thi kết thúc, trạng thái hoàn thành của người được mời sẽ được cập nhật và thưởng sẽ được phát. Bộ phận chăm sóc khách hàng sẽ liên hệ với người chiến thắng để thông báo.",
//       ],
//     },
//     {
//       t: "10. Tại sao trong cuộc thi, người “được mời” không thể đổi IB?",
//       c: [
//         `Để đảm bảo tính công bằng, người "được mời" phải tham gia qua liên kết giới thiệu và không thể chỉ định IB, nhằm tránh việc nhận thưởng và hoa hồng nhiều lần. Sau khi kết thúc sự kiện, người tham gia có thể liên hệ với bộ phận chăm sóc khách hàng để gắn tên mình dưới link IB bất kỳ.`,
//       ],
//     },
//   ],
// };
export default {
  title: `<span class="text">EBC's Million Dollar Trading Challenge II</span>`,
  title2: ``,
  巴萨传奇再启: "Trade Like a Champion: Inspired by Barça, Built for Winners",
  成就交易之王: "",
  大赛时间:"Event Duration: 1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replicate Top Trades in Just One-Click",
  公开透明: "Transparent",
  观摩冠军交易技巧: "Master the Trading Techniques of Champions",
  推荐有礼: "Refer for Rewards",
  推荐有奖上不封顶: "Unlimited Rewards for Every Referral",
  立即参赛: "Join Now",
  现金奖池: "Cash Prize Pool",
  巴萨梦之队: "Barça Dream Squad",
  拉玛西亚组: "La Masia Prodigies",
  冠军: "Champion",
  亚军: "Runner-up",
  季军: "Third place",
  第四至五名: "4th to 5th Place",
  第六至十名: "6th to 10th Place",
  "$1,000,000交易账号或$200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  "1,000,000交易账号或200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  大赛推荐官: "Referral Programme",
  推荐奖励: "Unlimited Referral Rewards",
  巴萨梦之队HTML: `Barça Dream Squad`,
  拉玛西亚组HTML: `La Masia Prodigies`,
  "/人": "/person",
  活动时间:
    "Event Duration:  22 February 2025, 01:00:00 (MT4/MT5 time) - 23 May 2025, 23:59:59 (MT4/MT5 time)",
  推荐流程: "Referral Process",
  推荐好友: "Refer Friends",
  好友参赛: "Friend Trades",
  赢得奖金: "Win Rewards",
  实时赛况: "Real-time Leaderboard",
  排名: "Rank",
  昵称: "Nickname",
  服务器: "Server",
  账号: "Account Number",
  收益额: "Profit",
  收益率: "ROI",
  奖金: "Prizes",
  跟单: "Copy Trading",
  该页面仅展示收益表现排名前10的交易账号:
    "*This page only displays the top ten trading accounts ranked by profit performance, with the leaderboard in USD and updated every hour",
  赛事进程: "Event Timeline",
  比赛开始: "Challenge Begins",
  推荐结束: "Referral Period Ends",
  比赛结束: "Challenge Ends",
  颁奖典礼: "Awards Ceremony",
  赛组规则: "Event Rules",
  类别: "Categories",
  账号要求: "Account Requirements",
  "新交易账号，且账号余额大于$500":
    "A new trading account with a starting balance ≥ $500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "A new trading account with a starting balance of $10,000 ≤ $200,000 ",

  排名规则: "Leaderboard Rules",
  以收益率排名为评判标准:
    "Rankings are determined based on the rate of return",
  "收益率=期末净值/账号累积入金":
    "Rate of Return = Net Account Value / Cumulative Deposit",
  以净盈利额排名为评判标准: "Rankings are determined based on total account value",
  持仓限制: "Position Limits",
  每个参赛账号最多持有100手仓位: "Each participating account may hold a maximum position of 100 lots",
  无持仓限制: "No position limits",
  参赛条款: "Event Terms",
  参赛须知: "Event Guidelines",
  section6List: [
    {
      t: "1. Event Duration",
      c: ["1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)"],
    },
    {
      t: "2. Account Requirements",
      c: [
        "The account must be listed as a signal account in the trading community and allow others to copy trades for free.",
        "Participants may use multiple accounts to compete, but only the account with the best performance will be considered for ranking.",
        "Only STP and PRO accounts are eligible to participate. PAMM and Islamic accounts are not eligible.",
      ],
    },
    {
      t: "3. Reward Rules",
      c: [
        "Prizes: If the champion chooses the 1,000,000 USD trading account reward, the maximum loss limit is 200,000 USD.",
        "Other prizes will be awarded based on rankings.",
      ],
    },
    {
      t: "4. Prohibited Actions",
      c: [
        "Arbitrage trading between accounts to boost profits is strictly prohibited. Any violations, including cross-account positions, hedging trades, or abuse of quotes, will lead to disqualification.",
        "Orders caused by uncontrollable factors, such as server hardware failure or pricing errors, will be considered invalid.",
      ],
    },
    {
      t: "5. Disclaimer",
      c: ["EBC Financial Group reserves the right to make the final decision of this event."],
    },
    
  ],
  参赛流程: "Event Process",
  注册开户: "Account Registration",
  注册并开设新交易账号: "Register and Open A New Trading Account",
  报名参赛: "Event Registration",
  在用户中心报名参赛: "Register for the Challenge in the User Portal",
  参赛成功: "Successful Registration",
  参赛成功查看实时赛况: "Once Registered, View Your Real-time Performance",
  往届回顾: "Previous Edition",
  第一届交易大赛颁奖典礼: "The First Trading Competition Awards Ceremony",
  第一届交易大赛高光时刻: "Highlights of the First Trading Competition",
  第一届交易大赛巅峰对决: "The Ultimate Showdown of the First Trading Competition",
  查看更多: "View More",
  常见问题: "Frequently Asked Questions",
  qaList: [
    {
      t: '1. "Account" vs "Account Number"',
      c: [
        "Account: Refers to the EBC User Portal Account (UID). Each user can only have one account after completing their real-name authentication.",
        "Account Number: Refers to the MT trading account. Multiple trading accounts can be created under the same EBC account for trading purposes.",
      ],
    },
    {
      t: "2. How Can I Confirm My Registration Status for the Event?",
      c: ["After successful registration, your competition account and current ranking will appear on the event page in the user portal."],
    },
    {
      t: "3. Can I Join the Event with Multiple Accounts?",
      c: [
        "You can join the event with multiple accounts, but each of these accounts can only be registered for one category.",
      ],
    },
    {
      t: "4. Can I Claim Prizes from Both Categories?",
      c: ["Yes, you can claim rewards from both categories as long as you comply with the rules and complete both challenges successfully."],
    },
    {
      t: "5. Can I Join the Challenge If I Already Have an MT4 Account?",
      c: ["Yes, provided the MT4 account was created on or after 22 February 2025, at 01:00:00 (MT4/MT5 time) and has no previous trading history."],
    },
    {
      t: "6. If I deposit more than 10,000 USD after joining La Masia Prodigies, will my category be changed?",
      c: ["No, your category is determined at the time of registration. Subsequent fund changes will not affect the category."],
    },
    {
      t: "7. How Do I Check My Ranking?",
      c: ["Rankings are updated hourly (not on the hour) and can be viewed on the event activity page in the user portal."],
    },
    {
      t: "8. Can I Deposit or Withdraw Funds During the Event?",
      c: [
        "Yes, you can freely deposit or withdraw funds. However, deposits will affect the rate of return calculation: Rate of Return = Net Account Value / Cumulative Deposits.",
      ],
    },
    {
      t: "9. How Do I Claim My Referral Reward?",
      c: [
        'The referral reward will be credited to your bonus balance 30 days after the invited user completes their first trade. You may then request a withdrawal, and the reward will be processed upon approval.',
      ],
    },
    {
      t: `10. Why Can't Invited Users Transfer Their Agents During the Event?`,
      c: [
        `To ensure fairness, invited users can only join through the referral link and are not assigned a specific agent. This avoids duplicate commissions or rewards. After the event ends, users can contact customer service to connect with an agent.`,
      ],
    },
  ],
};
