export default {
  多元活动连接全球市场: "กิจกรรมหลากหลาย เชื่อมต่อตลาดโลก",
  共享全球顶级资源与学术支持:
    "เข้าถึงทรัพยากรที่ดีที่สุดจากทั่วโลกและการสนับสนุนความรู้ทางการเงิน สามารถช่วยให้คุณเริ่มต้นเส้นทางเทรดได้อย่างมั่นใจ",
  了解更多: "เรียนรู้เพิ่มเติม",
  敬请期待: "โปรดรอติดตาม",
  每个认真交易的人: "เทรดเดอร์ที่จริงจัง ต้องได้รับบริการที่จริงใจจากเรา",
  activityListMetaData: [
    {
      label: "กิจกรรมทางตลาด",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_en.png"),
          title: "แข่งเทรด EBC Million Dollar Challenge II",
          subtitle: "คัดลอกผลกำไรแบบแชมป์ฟรี",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7_th.png"),
          title: "EBC ร่วมมือกับ TradingView เชื่อมต่อตลาดการเงินทั่วโลก",
          subtitle: "รับสิทธิ์สมาชิก TradingView ฟรีภายในระยะเวลาจำกัด ควบคุมข้อมูลตลาดทั่วโลกแบบเรียลไทม์",
          url: "/TradingView",
          time: "",
        },
        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_th.png"),
          title: "โปรแกรมครีเอทีฟ",
          subtitle: "สร้างคอนเทนต์การเงินทรงคุณค่า พร้อมรับเงินรางวัล",
          url: "/cfa",
          time: "",
        },
      ],
    },
    {
      label: "ข่าวสารและกิจกรรม EBC",
      list: [
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_th.png"),
          title: "EBC เข้าร่วมงาน Money Expo Mexico 2025",
          subtitle:
            "EBC เตรียมเข้าร่วมเป็นผู้สนับสนุนในงาน Money Expo Mexico 2025! ที่ประเทศเม็กซิโก มาพบกับเราที่บูธ 48 เพื่อสำรวจแนวโน้มใหม่ในวงการฟินเทคและค้นพบโอกาสใหม่ๆในการเทรด!",
          url: "",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_th.png"),
          title: "วิธีใช้เศรษฐศาสตร์กับสภาพภูมิอากาศ เพื่อหาโอกาสในการเทรด",
          subtitle: `"นักเศรษฐศาสตร์จริงๆ แล้วทำอะไร" ค้นหาคำตอบเกี่ยวกับบทบาทของเศรษฐศาสตร์ในด้านการขับเคลื่อนนโยบายและการเติบโตทางเศรษฐกิจ`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_th.png"),
          title: "EBC ร่วมมือกับมหาวิทยาลัย Oxford จัดซีรีส์สัมมนา",
          subtitle: `งานสัมมนาจะจัดขึ้นที่ Christ Church College มหาวิทยาลัย Oxford ในวันที่ 14 พฤศจิกายน เวลา 14:30 น. (ตามเวลา GMT) และจะมีการถ่ายทอดสดให้กับนักลงทุนจากทั่วโลกได้รับชม`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
