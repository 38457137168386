export default {
	"official-class-name": "official-en-box",
	"lang": "ko",
	"official-page1": {
		"text1": "공식 채널 인증",
		"text2": "EBC 명의를 도용하여 이익을 취하는 불법 행위를 방지하기 위해, 이 페이지를 통해 채널의 안정성을 확인할 수 있습니다.",
		"text3": "올바른 웹사이트 주소를 입력해주세요",
		"text4": "인증하기",
	},
	"official-page2": {
		"text1": "EBC 공식 성명：",
		"text2": "1. EBC는 현재 공식 MT4 및 MT5 거래 소프트웨어만 출시하고 있습니다. 공식 웹사이트에서 다운로드하세요.",
		"text3": "2. 현재 EBC는 어떠한 암호화폐 및 관련 CFD 거래도 진행하지 않고 있습니다.",
		"text4": "3. EBC는 어떠한 형태의 투자 조언도 제공하지 않으며, 거래 수익에 대한 어떠한 약속이나 보장도 하지 않습니다.",
		"text5": "4. EBC 명의를 도용하는 모든 행위에 대해서는 즉시 법적 조치를 취하고, 도용자에게 엄격한 처벌을 가합니다.",
		"text6": "5. EBC는 고객의 권익을 유지하기 위해 최선을 다하고 있으며, 만약 어떠한 의심스러운 활동을 발견하신 경우 증거를 당사의 고객 서비스 팀에 제출하시거나 이메일(<a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a>)로 문의하여 주시기 바랍니다. EBC는 신속히 대응하여 피해자에게 필요한 법적 지원과 지원을 제공할 것입니다.",
	},
	"official-page3": {
		"text1": "귀하가 조회한 정보는 공식 인증을 통과하였습니다",
		"text2": "다시 조회하기",
		"text3": "귀하가 조회한 정보는 공식 홈페이지의 인증을 받지 않았습니다. 인증되지 않은 채널과 연락하지 않는 것을 권고드립니다.",
		"text4": "신고하기",
		"text5": "이 정보가 허위이거나 부당하다고 생각되면 신고서를 제출해 주세요.",
		"text6": "신고정보",
		"text7": "사이트 주소",
		"text8": "**더 많은 법률 지원과 피드백이 필요하신 경우, ",
		"text8-1": "여기를 클릭하여 메시지를 남겨주세요",
		"text8-2": "",
		"text9": "메세지창 닫기",
		"text10": "성함/닉네임",
		"text11": "들어 오세요 성함/닉네임",
		"text12": "이메일",
		"text13": "이메일 주소를 입력해주세요",
		"text14": "휴대폰 번호",
		"text15": "휴대폰 번호를 입력해주세요",
		"text16": "건의사항",
		"text17": "정보 피드백을 작성해주세요",
		"text18": "인증코드",
		"text19": "입력해주세요인증코드",
		"text20": "취소하기",
		"text21": "신고 제출하기",
		"text22": "접수가 완료되었습니다. 감사합니다.",
	},
}