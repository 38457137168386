export default {
  多元活动连接全球市场: "多元活動連結全球市場",
  共享全球顶级资源与学术支持: "共享全球頂尖資源與學術支持，助您盈在投資起點",
  了解更多: "了解更多",
  敬请期待: "敬請期待",
  每个认真交易的人: "每個認真交易的人，都值得被認真對待",
  activityListMetaData:  [
    {
      label: "積分商城",
      list: [
        {
          id: "1-1",
          img:require("@/assets/image/activityList/banner1_1.png"),
          title: "EBC積分商城 認真對待每個期待",
          subtitle: "積分暢兌夢想豪華車 、巴薩週邊、熱門單品",
          url: "https://ebcpoints.com/html/ebc/index.html",
          time: "",
          target:"_blank"
        },
      ],
    },
    {
      label: "市集活動",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_zh.png"),
          title: "EBC全球百萬美金交易大賽Ⅱ",
          subtitle: "巴塞傳奇再啟，成就交易之王",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7_zh.png"),
          title: "EBC 攜手 TradingView 連接全球資源",
          subtitle: "限時領取 TradingView 會員權益 掌控全球市場即時動態",
          url: "/TradingView",
          time: "",
        },
       
        
        {
          id: "2-3",
          img:require("@/assets/image/activityList/banner2_3_en.png"),
          title: "創作者激勵計劃",
          subtitle: "創作原創金融觀點，專屬額外獎勵",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-4",
          img:require("@/assets/image/activityList/banner2_4_zh.png"),
          title: "交易領薪計劃",
          subtitle:
            "加入交易社區，享受豐厚獎勵和專屬福利，助力交易者成就交易傳奇。",
          url: "/pay",
          time: "",
        },
        {
          id: "2-1",
          img:require("@/assets/image/activityList/banner2_1_en.png"),
          title: "百萬美金交易大賽",
          subtitle: "懸賞百萬美金 尋找交易之王",
          url: "/win",
          time: "",
        },
      ],
    },
    {
      label: "品牌動態",
      list: [
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_zh.png"),
          title: "EBC即將亮相墨西哥Money Expo 2025",
          subtitle:
            "EBC將作為铂金贊助商，亮相墨西哥Money Expo 2025！在這場墨西哥頂級的交易盛會中，我們將在48號展位與您相約，共同探索金融科技新趨勢，發現交易新機會！",
          url: "",
          time: "2025年2月26-27日",
        },
        {
          id: "3-1",
          img:require("@/assets/image/activityList/banner3_1_zh.png"),
          title: "如何利用氣候經濟學，挖掘投資機會",
          subtitle: `經濟學家究竟在做什麼 結合時下熱議話題，探討如何利用經濟學推動政客的改進，促進經濟繁榮。`,
          url: "/oxford",
          time: "2024年11月14日",
        },
        {
          id: "3-2",
          img:require("@/assets/image/activityList/banner3_2_zh.png"),
          title: "EBC聯合牛津大學研討會",
          subtitle: `本次線下研討會將於11月14日14:30 (GMT)在牛津大學基督教會學院展開，線上同步直播，並向全球投資者開放。`,
          url: "/oxford1",
          time: "2024年3月6日",
        },
      ],
    },
  ],
};
