<template>
<div :class="$t('ESG.esg-class-name')">
	<page-navigate></page-navigate>

	<div class="esg-banner">
        <div class="swiper esg-banner-list">
            <div class="swiper-wrapper">
                <div class="swiper-slide" id="esg-banner1">
                    <img src="../assets/image/ESG/ubm-logo.png" alt="">
                    <p v-html="$t('ESG.esg-banner.text1')"></p>
                    <router-link :to="'/malaria'">{{$t('ESG.esg-banner.btn')}}</router-link>
                </div>
                <div class="swiper-slide" id="esg-banner2">
                    <p v-html="$t('ESG.esg-banner.text2')"></p>
                    <router-link :to="'/LearningCenterInfo?id=LEARN-944-382'">{{$t('ESG.esg-banner.btn')}}</router-link>
                </div>
                <div class="swiper-slide" id="esg-banner3">
                    <img class="oxford-logo1" src="../assets/image/ESG/oxford-logo.png" alt="">
                    
                    <p class="p1" v-html="$t('ESG.esg-banner.text3')"></p>
                    <p class="p2" v-if="$t('ESG.esg-banner.text3-2')">{{$t('ESG.esg-banner.text3-2')}}</p>
                    <p class="p3">Sarah Clifford & David Barrett</p>

                    <img class="oxford-logo2" src="../assets/image/ESG/oxford-logo2.png" alt="">
                    <router-link :to="'/oxford'">{{$t('ESG.esg-banner.btn')}}</router-link>
                </div>
                <div class="swiper-slide" id="esg-banner4">
                    <img class="esg-banner4-logo" src="../assets/image/ESG/esg-banner4-logo.png" alt="">
                    <p v-html="$t('ESG.esg-banner.text4')"></p>
                    <router-link :to="'/LearningCenterInfo?id=LEARN-814-37E'">{{$t('ESG.esg-banner.btn')}}</router-link>
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div class="esg-banner-bottom">
            <p class="p1">{{$t('ESG.esg-banner.text5')}}</p>
            <p class="p2">{{$t('ESG.esg-banner.text6')}}</p>
        </div>
    </div>

    <div class="esg-nav" id="esg-nav" :class="navLock">
        <a href="javascript:;" id="transmit1" @click="onClick(1)" :class="{'esg-active':pagIndex == 1}">{{$t('ESG.esg-nav.text1')}}</a>
        <a href="javascript:;" id="transmit2" @click="onClick(2)" :class="{'esg-active':pagIndex == 2}">{{$t('ESG.esg-nav.text2')}}</a>
        <a href="javascript:;" id="transmit3" @click="onClick(3)" :class="{'esg-active':pagIndex == 3}">{{$t('ESG.esg-nav.text3')}}</a>
        <a href="javascript:;" id="transmit4" @click="onClick(4)" :class="{'esg-active':pagIndex == 4}">{{$t('ESG.esg-nav.text4')}}</a>
    </div>
    
    <div class="esg-1" id="esg1">
        <p class="esg-title">{{$t('ESG.esg-page1.text1')}}</p>
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="box">
                        <img src="../assets/image/ESG/esg-1-icon-1.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page1.text2')"></p>
                        <p class="p2">{{$t('ESG.esg-page1.text3')}}</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <img src="../assets/image/ESG/esg-1-icon-2.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page1.text4')"></p>
                        <p class="p2">{{$t('ESG.esg-page1.text5')}}</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <img src="../assets/image/ESG/esg-1-icon-3.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page1.text6')"></p>
                        <p class="p2">{{$t('ESG.esg-page1.text7')}}</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <img src="../assets/image/ESG/esg-1-icon-4.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page1.text8')"></p>
                        <p class="p2">{{$t('ESG.esg-page1.text9')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <div class="esg-2" id="esg2">
        <p class="esg-title">{{$t('ESG.esg-page2.text1')}}</p>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="esg-2-box-left">
                        <img src="../assets/image/ESG/esg-E.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page2.text2')"></p>
                        <p class="p2">Environmental</p>
                    </div>
                    <div class="esg-2-box-right">
                        <p v-html="$t('ESG.esg-page2.text3')"></p>
                        <p v-html="$t('ESG.esg-page2.text4')"></p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="esg-2-box-left">
                        <img src="../assets/image/ESG/esg-S.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page2.text5')"></p>
                        <p class="p2">Social</p>
                    </div>
                    <div class="esg-2-box-right">
                        <p v-html="$t('ESG.esg-page2.text6')"></p>
                        <p v-html="$t('ESG.esg-page2.text7')"></p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="esg-2-box-left">
                        <img src="../assets/image/ESG/esg-G.svg" alt="">
                        <p class="p1" v-html="$t('ESG.esg-page2.text8')"></p>
                        <p class="p2">Governance</p>
                    </div>
                    <div class="esg-2-box-right">
                        <p v-html="$t('ESG.esg-page2.text9')"></p>
                        <p v-html="$t('ESG.esg-page2.text10')"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="esg-3" id="esg3">
        <p class="esg-title">{{$t('ESG.esg-page3.text1')}}</p>
        <div class="container">
            <div class="row">
                <div class="col-md-6 esg3-box-1">
                    <img src="../assets/image/ESG/esg-3-1.png" alt="">
                    <p class="p1">{{$t('ESG.esg-page3.text2')}}</p>
                    <div class="esg3-mask">
                        <p class="mask-p1">{{$t('ESG.esg-page3.text2')}}</p>
                        <p class="mask-title">{{$t('ESG.esg-page3.text3')}}</p>
                        <p class="mask-line"></p>
                        <p class="mask-desc">{{$t('ESG.esg-page3.text4')}}</p>
                    </div>
                </div>
                <div class="col-md-6 esg3-box-2">
                    <img src="../assets/image/ESG/esg-3-2.png" alt="">
                    <p class="p1">{{$t('ESG.esg-page3.text5')}}</p>
                    <div class="esg3-mask">
                        <p class="mask-p1">{{$t('ESG.esg-page3.text5')}}</p>
                        <p class="mask-title">{{$t('ESG.esg-page3.text6')}}</p>
                        <p class="mask-line"></p>
                        <p class="mask-desc">{{$t('ESG.esg-page3.text7')}}</p>
                    </div>
                </div>
                <div class="col-md-6 esg3-box-3">
                    <img src="../assets/image/ESG/esg-3-3.png" alt="">
                    <p class="p1">{{$t('ESG.esg-page3.text8')}}</p>
                    <div class="esg3-mask">
                        <p class="mask-p1">{{$t('ESG.esg-page3.text8')}}</p>
                        <p class="mask-title">{{$t('ESG.esg-page3.text9')}}</p>
                        <p class="mask-line"></p>
                        <p class="mask-desc">{{$t('ESG.esg-page3.text10')}}</p>
                    </div>
                </div>
                <div class="col-md-6 esg3-box-4">
                    <img src="../assets/image/ESG/esg-3-4.png" alt="">
                    <p class="p1">{{$t('ESG.esg-page3.text11')}}</p>
                    <div class="esg3-mask">
                        <p class="mask-p1">{{$t('ESG.esg-page3.text11')}}</p>
                        <p class="mask-title">{{$t('ESG.esg-page3.text12')}}</p>
                        <p class="mask-line"></p>
                        <p class="mask-desc">{{$t('ESG.esg-page3.text13')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="esg-4" id="esg4">
          <div class="container">
              <p class="p1">{{$t('ESG.esg-page4.text1')}}</p>
              <div class="row">
                  <div class="col-md-6" v-for="(item, index) in newData" :key="index" v-if="item.tips != '' && index < 6"
						@click="jumPage(item)">
                    <a @click="jumPage(item)">
                      <img :src="item.logo" :alt="item.tit">
                    </a>
                    <p class="time">{{item.time}}</p>
                    <a @click="jumPage(item)" class="title">{{item.tit}}</a>
                  </div>
              </div>
          </div>
      </div>
      <page-footer></page-footer>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
    import Swiper from 'swiper'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				infoData: "",
				newData: "",
        pagIndex: 0,
				topNum: 360,
        navLock: ""
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
			this.getNewData()
            $('html,body').animate({
				scrollTop: 0
			}, 0);
		},
        mounted(){
          if (!this.isMobile()) {
            window.addEventListener('scroll', this.scroll)
          }
          this.swiper = new Swiper ('.esg-banner-list', {
              loop: true,
              autoplay: true,
              pagination: {
                el: '.swiper-pagination',
                clickable :true,
              },
              navigation: {
                nextEl: '.swiper-banner-next',
                prevEl: '.swiper-banner-prev',
              },
          });
        },
		methods: {
			getNewData() {
				let parms;
				if (this.lang === 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http("/ebc/api/getLearnCategory/run", {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data[0].info
				})
			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id,
						lang: this.$t("common.requestLang")//this.$store.state.Lan
					}
				})
			},
        isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
      async scroll() {
				const elOffsetTop = document.getElementById('esg-nav').offsetTop
				const docScrollTop = document.documentElement.scrollTop

        if(docScrollTop >= $(".esg-1").offset().top-150){
					this.navLock = "esg-nav-lock"
          $('.esg-1').css("margin-top", "71px");
				} else {
          this.navLock = ""
          $('.esg-1').css("margin-top", "0px");
				}

				// if(docScrollTop >= 281 && docScrollTop < $(".trade3").offset().top-170){
				// 	this.pagIndex = 1
				// }

				if(docScrollTop >= $(".esg-1").offset().top-150 && docScrollTop < $(".esg-2").offset().top-150){
					this.pagIndex = 1
				}

				if(docScrollTop >= $(".esg-2").offset().top-150 && docScrollTop < $(".esg-3").offset().top-150){
					this.pagIndex = 2
				}

				if(docScrollTop >= $(".esg-3").offset().top-150 && docScrollTop < $(".esg-4").offset().top-150){
					this.pagIndex = 3
				}

        if(docScrollTop >= $(".esg-4").offset().top - 150){
          this.pagIndex = 4
        }

			},
			changTab(idx) {
				this.tabIndex = idx
			},
      onClick(index) {
				$('html,body').animate({
					scrollTop: $("#esg" + index).offset().top - 140
				}, 0);
			},
		}
	}

</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
@font-face{
    font-family: 'Baskerville Old Face';
    src : url('../assets/font/BaskervilleOldFace.ttf');
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: 'Gilroy Bold';
    src : url('../assets/font/Gilroy-ExtraBold.otf');
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: 'Gilroy Light';
    src : url('../assets/font/Gilroy-Light.otf');
    font-style: normal;
    font-display: swap;
}

.esg-banner{
  height: 100%;
  overflow: hidden;
}
.esg-banner .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}
/* .esg-banner .swiper-wrapper{
  height: 77vh;
  padding-top: 60px;
} */
.esg-banner .swiper-pagination-bullet{
    background: #fff;
    opacity: 0.5;
    margin: 20px 6px 30px!important;
}
.esg-banner .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 0.8;
}

#esg-banner1{
    background: url(../assets/image/ESG/esg-banner1.jpg?t=1699008869) 100%/auto 100% no-repeat;
    background-position: center center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
}
#esg-banner2{
    background: url(../assets/image/ESG/esg-banner2.jpg?t=1698834569) 100%/auto 100% no-repeat;
    background-position: center center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
}
#esg-banner3{
    background: url(../assets/image/ESG/esg-banner3.jpg?t=1699008869) 100%/auto 100% no-repeat;
    background-position: center center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
}
#esg-banner4{
    background: url(../assets/image/ESG/esg-banner4.jpg?t=1709794589) 100%/auto 100% no-repeat;
    background-position: center center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
}
.esg-banner-list{
  position: relative;
}
.esg-banner-list p{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 72px */
  letter-spacing: 0.96px;
}
.esg-banner-list a{
  border-radius: 40px;
  background: #003B99;
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.8px;
  display: inline-flex;
  padding: 18px 42px;
  width: max-content;
  margin: 20px auto 0;
}

#esg-banner3 .p3{
  color: #FBB50B;
  font-family: "League Spartan";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.42px;
  text-transform: uppercase;
}
#esg-banner3 p{
  text-align: left;
  margin-left: 20%;
  margin-top: 8px;
}
#esg-banner3 .p2{
  font-size: 26px;
  margin-left: 19%;
}
#esg-banner3 .oxford-logo1{
  width: 28%;
  margin-left: 20%;
  margin-bottom: 30px;
}
#esg-banner3 .oxford-logo2{
  width: 14%;
  position: absolute;
  right: 20px;
  top: 30px;
}
#esg-banner3 a{
  margin-left: 20%;
  margin-top: 30px;
}

.esg-banner-bottom{
  background: #003B99;
  width: 100%;
  height: auto;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.esg-banner-bottom .p1{
  color: #FFF;
  text-align: center;
  font-family: Gilroy Bold;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 114.5%;
}
.esg-banner-bottom .p2{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%; 
  margin-top: 20px;
  padding: 0 18%;
}

.esg-nav{
    background: rgba(255, 255, 255, 1);
    text-align: center;
    height: auto;
}
.esg-nav a{
  color: #999;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.28px;
  margin: 0px 40px;
  padding: 28px 0 26px;
  display: inline-block;
}
/* .esg-nav a:hover{
  color: #003B99;
  border-bottom: 2px solid #003B99;
} */
.esg-nav a.esg-active{
  color: #003B99;
  border-bottom: 2px solid #003B99;
}


.esg-1{
  background: url(../assets/image/ESG/esg-1-bg.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: auto;
  padding: 130px 0 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.esg-title{
  color: #1C1C1C;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}
.esg-1 .row .col-md-3{

}
.esg-1 .row .box{
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.05);
  padding: 60px 30px 30px;
  margin-top: 40px;
  height: 100%;
}
.esg-1 .row .box .p1{
  color: #545454;
  font-family: "Source Han Sans CN";
  font-size: 22px;
  line-height: 150%;
  font-weight: 700;
  margin-top: 20px;
}
.esg-1 .row .box .p2{
  color: #545454;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 14px;
  font-weight: 350;
  line-height: 22px;
  margin-top: 20px;
}


.esg-2{
  background: url(../assets/image/ESG/esg-2-bg.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 130px 0 120px;
}

.esg-2 .container{
  width: 1100px;
}
.esg-2 .col-md-12{
  margin-bottom: 30px;
}
.esg-2 .row{
  margin-top: 40px;
}
.esg-2-box-left{
  border-radius: 0px 81px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.05);
  width: 28%;
  padding: 50px 50px;
  position: relative;
  float: left;
  margin-left: 8%;
}

.esg-2-box-left img{
  position: absolute;
  z-index: 0;
  left: 16%;
  top: 50%;
  transform: translateY(-50%);
}

.esg-2-box-left .p1{
  color: #003B99;
  font-family: "Source Han Sans CN";
  font-size: 32px;
  font-weight: 700;
  line-height: 130%;
  text-align: right;
  position: relative;
  z-index: 1;
}

.esg-2-box-left .p2{
  color: #D6D6D6;
  text-align: right;
  font-family: "Source Han Sans CN";
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

.esg-2-box-right{
  width: 60%;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.esg-2-box-right p{
  color: #545454;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  text-align: left;
  margin-top: 30px;
}
.esg-2-box-right p span{
  font-weight: 700;
}

.esg-3{
  background: url(../assets/image/ESG/esg-3-bg.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 130px 0 120px;
}
.esg-3 .container{
  width: 1200px;
}
.esg-3 .row{
  margin-top: 40px;
}
.esg-3 .col-md-6{
  position: relative;
  margin-bottom: 22px;
}
.esg-3 .p1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 700;
  line-height: 114.5%;
  color: #fff;
}
.esg-3 .esg3-mask{
  display: none;
}
.esg-3 .col-md-6:hover .esg3-mask{
  display: flex;
}
.esg-3 .col-md-6:hover .p1{
  display: none;
}
.esg-3 .col-md-6:hover img{
}
.esg3-mask{
  width: 96%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8%;
}
.esg3-mask .mask-p1{
  display: none;
}
.esg3-mask .mask-title{
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 142%;
  text-align: left;
}
.esg3-mask .mask-line{
  border: 1px solid #fff;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.esg3-mask .mask-desc{
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  text-align: left;
}


.esg-4{
  background: url(../assets/image/ESG/ubm-pc5.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.esg-4 .p1{
  color: #1C1C1C;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 47px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 80px;
}
.esg-4 .row div{
  margin-bottom: 24px;
}
.esg-4 a{
  cursor: pointer;
}
.esg-4 a img{
  width: 40%;
  height: auto;
  border-radius: 5px;
  float: left;
}
.esg-4 .time{
  color: #1C1C1C;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 5px;
  border: 1px solid #CECECE;
  width: max-content;
  float: left;
  padding: 5px 14px;
  margin-top: 23px;
  margin-left: 20px;
}
.esg-4 .title{
  float: left;
  width: 52%;
  color: #1C1C1C;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
  margin-left: 20px;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.esg-4 .title:hover{
  color: #003B99;
}

.esg-nav-lock{
  position: fixed;
  right: 0;
  left: 0;
  top: 78px;
  z-index: 9;
}

#esg-banner1 img{
  display: none;
}

.esg-banner4-logo{
  display: none;
}

.en-esg .esg-banner-list p{
  font-size: 32px;
  letter-spacing: 0px;
  padding: 0 15%;
}
.en-esg #esg-banner3 p,.en-esg #esg-banner3 .p2,.en-esg #esg-banner3 .p3{
  padding: 0 20% 0 0;
}
.en-esg #esg-banner3 .p2{
  margin-left: 20%;
  font-size: 26px;
}
.en-esg .esg-1 .row .box .p1{
  font-size: 18px;
  line-height: 26px;
}
.en-esg .esg-1 .row .box{
  padding: 50px 30px 20px;
}
.en-esg .esg-2-box-left .p1{
  font-size: 20px;
}
.en-esg .esg-2 .col-md-12{
  margin-bottom: 50px;
}
.en-esg .esg-2-box-right p:first-child{
  margin-top: 8px;
}
.en-esg .esg-title{
  font-size: 42px;
}
.en-esg .esg-3 .p1{
  font-family: auto;
  font-size: 28px;
}
.en-esg .esg3-mask .mask-title{
  font-size: 18px;
}
.en-esg .esg3-mask .mask-desc{
  font-size: 14px;
}



@media(max-width:1600px) {
  .esg-nav-lock{
      top: 74px;
  }
  /* .esg-banner .swiper-wrapper{
      height: 100vh;
      padding-top: 60px;
  } */
  .esg-banner-list p{
    font-size: 38px;
  }
  .esg-title{
    font-size: 40px;
  }
  .esg-3 .container{
    width: 1100px;
  }
  .esg-3 .p1{
    font-size: 28px;
  }
  .esg-4 .p1{
    font-size: 40px;
  }
  .esg-banner-bottom .p1{
    font-size: 38px;
  }
  .esg-banner-bottom .p2{
    margin-top: 14px;
  }
}

@media(max-width:1400px) {
  .en-esg .esg-banner-list p{
    font-size: 26px;
  }
  .esg3-mask .mask-line{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media(max-width:995px) {
  .esg-nav{
    display: none;
  }
  .esg-4{
    padding-top: 60px;
    padding-bottom: 40px;
    height: auto;
  }
  .esg-4 .p1{
    font-size: 26px;
    margin-bottom: 40px;
  }
  .esg-4 a img{
    width: 34%;
  }
  .esg-4 .time{
    font-size: 12px;
    letter-spacing: 0px;
    margin-top: 3px;
  }
  .esg-4 .title{
    font-size: 14px;
    width: 60%;
    margin-top: 6px;
  }

  .esg-2 .container{
    width: 100%;
  }
  .esg-3 .container{
    width: 100%;
  }
  .esg-banner-bottom{
    display: none;
  }

    #esg-banner1{
      background: url(../assets/image/ESG/esg-banner1-m.jpg?t=1699008869) 100%/auto 100% no-repeat;
      background-position: center center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0px;
  }
  #esg-banner2{
      background: url(../assets/image/ESG/esg-banner2-m.jpg?t=1698834569) 100%/auto 100% no-repeat;
      background-position: center center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0px;
  }
  #esg-banner3{
      background: url(../assets/image/ESG/esg-banner3-m.jpg?t=1699008869) 100%/auto 100% no-repeat;
      background-position: center center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0px;
  }
  #esg-banner4{
      background: url(../assets/image/ESG/esg-banner4-m.jpg?t=1709794589) 100%/auto 100% no-repeat;
      background-position: center center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0px;
  }

  #esg-banner1 img{
    display: block;
    position: absolute;
    top: 100px;
  }
  .esg-banner-list p{
    font-size: 26px;
    padding: 0 6%;
    line-height: 140%;
  }
  .esg-banner-list a{
    position: absolute;
    bottom: 16%;
    left: 50%;
    transform: translateX(-50%);
    padding: 14px 36px;
    font-size: 14px;
  }
  /* .esg-banner .swiper-wrapper{
      height: 85vh;
      padding-top: 60px;
  } */
  #esg-banner3 .oxford-logo2{
    width: 38%;
    position: unset;
    margin-left: 6%;
  }
  #esg-banner3 .oxford-logo1{
    margin-left: 0;
    left: 6%;
    width: 84%;
    position: absolute;
    top: 50px;
  }
  #esg-banner3 p{
    margin-left: 0;
  }
  #esg-banner3 .p2{
    margin-left: 0;
    font-size: 20px;
    padding-left: 4%;
  }
  #esg-banner3 .p3{
    font-size: 14px;
    margin-bottom: 30px;
  }
  #esg-banner3 a{
    left: 6%;
    transform: unset;
    margin-left: 0;
  }

  .esg-banner4-logo{
    margin-left: 0;
    width: 84%;
    position: absolute;
    top: 50px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
  }

  .esg-1 .row .box .p1{
    display: block;
  }
  .esg-1 .row .box .p2{
    display: block;
  }

  .esg-1,.esg-2,.esg-3{
    height: auto;
    padding: 80px 0;
  }
  .esg-title{
    font-size: 30px;
  }

  .esg-1 .row .box{
    height: auto;
    padding: 40px 30px 50px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .esg-1 .esg-title{
    margin-bottom: 20px;
  }

  .esg-2-box-left{
    float: unset;
    margin: 0 auto;
    width: 74%;
  }
  .esg-2-box-right{
    float: unset;
    width: 100%;
    padding: 0 6%;
  }
  .esg-2 .row{
    margin-top: 20px;
  }
  .esg-2 .col-md-12{
    margin-bottom: 50px;
  }
  .esg-2-box-left .p1{
    font-size: 26px;
  }
  .esg-2-box-left .p2{
    font-size: 18px;
  }
  .esg-2{
    padding-bottom: 30px;
  }
  .esg-2-box-left{
    padding: 40px;
  }
  .esg-2-box-left img{
    width: 75px;
  }

  .esg-3 .p1{
    display: none;
  }
  .esg-3 .esg3-mask{
    display: flex;
    width: 94%;
    padding: 0 4%;
  }
  .esg-3 .row{
    margin-top: 12px;
  }
  .esg3-mask .mask-p1{
    display: block;
    font-size: 20px;
    color: #ffffff;
    text-align: left;
    margin-bottom: 16px;
  }
  .esg3-mask .mask-title{
    font-size: 16px;
  }
  .esg3-mask .mask-line{
    margin-top: 8px;
    margin-bottom: 8px;
    width: 65%;
    opacity: 0.7;
  }
  .esg3-mask .mask-desc{
    font-size: 13px;
  }

  .esg-3{
    padding-bottom: 30px;
  }

  .en-esg .esg-banner-list p{
    font-size: 22px;
    padding: 0 5%;
  }

  .en-esg #esg-banner3 p, .en-esg #esg-banner3 .p2, .en-esg #esg-banner3 .p3{
    padding: 0 5%;
  }

  .en-esg .esg-title{
    font-size: 30px;
  }
  .en-esg .esg-1 .row .box{
    padding: 40px 30px 50px;
  }
  .en-esg .esg-2-box-right p:first-child{
    margin-top: 30px;
  }

  .esg3-mask .mask-p1{
    font-family: "Source Han Sans CN";
  }

  .en-esg .esg3-mask .mask-title{
    font-size: 16px;
  }

  .en-esg .esg3-mask .mask-p1{
    margin-bottom: 0;
  }
  .en-esg .esg3-mask .mask-desc{
    font-size: 12px;
  }
  .en-esg .esg3-mask .mask-title{
    display: none;
  }
}

</style>

