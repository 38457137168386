<template>
<div :class="$t('DiNapoli.box-name')">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap" rel="stylesheet">
	<page-navigate></page-navigate>
  <div class="DiNapoli-page-cn">
        <div class="DiNapoli-section1">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <img class="bg-m" src="../assets/image/DiNapoli/DiNapoli-bg-m.png" alt="">
                        <h1 class="p1" v-html="$t('DiNapoli.section1.text1')"></h1>
                        <ul v-html="$t('DiNapoli.section1.text2')">
                        </ul>
                        <p class="p2">{{$t('DiNapoli.section1.text3')}}</p>
                    </div>
                    <div class="col-md-6 bg-pc">
                        <img src="../assets/image/DiNapoli/DiNapoli-bg.png" alt="">
                    </div>
                </div>
            </div>
        </div>



        <div class="DiNapoli-section2">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <img src="../assets/image/DiNapoli/DiNapoli-1.png" alt="">
                    </div>
                    <div class="col-md-7 info">
                        <p class="p1">{{$t('DiNapoli.section1.text4')}}</p>
                        <p class="p2">{{$t('DiNapoli.section1.text5')}}</p>
                        <ul v-html="$t('DiNapoli.section1.text6')">
                        </ul>
                        <a rel="nofollow" href="https://client.ebccrm.com/signup/F1601658-a84" target="_blank">{{$t('DiNapoli.section1.text7')}}</a>
                    </div>
                </div>
            </div>
        </div>


        <div class="DiNapoli-section3">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 info">
                        <img class="img-m" src="../assets/image/DiNapoli/DiNapoli-2.png" alt="">
                        <p class="p1">{{$t('DiNapoli.section1.text8')}}</p>
                        <p class="p2">{{$t('DiNapoli.section1.text9')}}</p>
                        <ul>
                            <li>
                                <p class="pp1">{{$t('DiNapoli.section1.text10')}}</p>
                                <p class="pp2">{{$t('DiNapoli.section1.text11')}}</p>
                            </li>
                            <li>
                                <p class="pp1">{{$t('DiNapoli.section1.text12')}}</p>
                                <p class="pp2">{{$t('DiNapoli.section1.text13')}}</p>
                            </li>
                            <li>
                                <p class="pp1">{{$t('DiNapoli.section1.text14')}}</p>
                                <p class="pp2">{{$t('DiNapoli.section1.text15')}}</p>
                            </li>
                        </ul>
                        <a rel="nofollow" href="https://client.ebccrm.com/signup/F1601658-a84" target="_blank">{{$t('DiNapoli.section1.text7')}}</a>
                    </div>
                    <div class="col-md-5">
                        <img class="img-pc" src="../assets/image/DiNapoli/DiNapoli-2.png" alt="">
                    </div>
                </div>
            </div>
        </div>


        <div class="DiNapoli-section4">
            <div class="container">
                <p class="section4-title">{{$t('DiNapoli.section1.text16')}}</p>
                <div class="row">
                    <div class="col-md-4">
                        <div class="left">
                            <img class="img1" src="../assets/image/DiNapoli/DiNapoli-icon1.svg" alt="">
                            <p class="p1">{{$t('DiNapoli.section1.text17')}}
                                <img class="img2" src="../assets/image/DiNapoli/DiNapoli-icon2.svg" alt="">
                            </p>
                            
                            <p class="p2">George Lane</p>
                            <p class="p3">{{$t('DiNapoli.section1.text18')}}</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="center">
                            <img class="img1" src="../assets/image/DiNapoli/DiNapoli-icon1.svg" alt="">
                            <p class="p1">{{$t('DiNapoli.section1.text19')}}<img class="img2" src="../assets/image/DiNapoli/DiNapoli-icon2.svg" alt="">
                            </p>
                            <p class="p2">Walter Bressert</p>
                            <p class="p3">{{$t('DiNapoli.section1.text20')}}</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="right">
                            <img class="img1" src="../assets/image/DiNapoli/DiNapoli-icon1.svg" alt="">
                            <p class="p1">{{$t('DiNapoli.section1.text21')}} <img class="img2" src="../assets/image/DiNapoli/DiNapoli-icon2.svg" alt=""></p>
                            <p class="p2">Linda Raschke</p>
                            <p class="p3">{{$t('DiNapoli.section1.text22')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="DiNapoli-section5">
            <div class="container">
                <p class="section5-title1">{{$t('DiNapoli.section1.text23')}}</p>
                <p class="section5-title2">{{$t('DiNapoli.section1.text24')}}</p>
                <a class="btn" href="https://www.fibnodes.com/index.asp" rel="nofollow" target="_blank">{{$t('DiNapoli.section1.text25')}}</a>
                <div class="swiper DiNapoli-slide" style="overflow: hidden; position: relative;">
                        <div class="swiper-wrapper slide-box">
                            <div class="swiper-slide"><img src="../assets/image/DiNapoli/DiNapoli-3.jpg" alt=""></div>
                            <div class="swiper-slide"><img src="../assets/image/DiNapoli/DiNapoli-4.jpg" alt=""></div>
                            <div class="swiper-slide"><img src="../assets/image/DiNapoli/DiNapoli-5.jpg" alt=""></div>
                            <div class="swiper-slide"><img src="../assets/image/DiNapoli/DiNapoli-6.jpg" alt=""></div>
                        </div>
                        <div class="swiper-button-prev DiNapoli-prev"></div>
                        <div class="swiper-button-next DiNapoli-next"></div>
                    </div>
                </div>
            </div>
        </div>
      <page-footer></page-footer>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
    import Swiper from 'swiper'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
		},
		created() {
		},
        mounted(){
          
          this.swiper = new Swiper('.DiNapoli-slide', {
            navigation: { // 如果需要前进后退按钮
              nextEl: '.DiNapoli-next',
              prevEl: '.DiNapoli-prev'
            }, // 启动自动切换，等同于以下设置
            loop: true // 循环模式选项
          })
           

            
        },
		methods: {
		}
	}

</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
  .DiNapoli-section1{
    background: linear-gradient(0deg, #0D0101 0%, #350101 64%, #3D0101 100%);
    height: auto;
    margin-top: 0px;
    padding-top: 140px;
    padding-bottom: 100px;
}
.DiNapoli-section1 .p1{
    color: #FFF;
    font-family: "Roboto", sans-serif;;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 90px;
}
.DiNapoli-section1 ul{
    margin-top: 40px;
}
.DiNapoli-section1 ul li{
    width: 100%;
    color: #fff;
    font-family: "Roboto", sans-serif;;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: block;
    float: left;
    margin-bottom: 22px;
    padding-left: 22px;
    position: relative;
}
.DiNapoli-section1 ul li::before {  
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon11.svg');  
  background-size: contain;
  background-repeat: no-repeat;
}

.DiNapoli-section1 .p2{
    width: 100%;
    color: #fff;
    text-align: justify;
    font-family: "Roboto", sans-serif;;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    display: inline-block;
    margin-top: 12px;
    opacity: 0.6;
}

.DiNapoli-public-title{
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}




.DiNapoli-section2{
    margin-top: 120px;
    margin-bottom: 160px;
}
.DiNapoli-section2 .p1{
    color: #000;
    font-family: "Roboto", sans-serif;;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 20px;
}
.DiNapoli-section2 .row{
    margin-top: 80px;
}

.DiNapoli-section2 .info{
    padding-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.DiNapoli-section2 img{
    width: 100%;
}

.DiNapoli-section2 .info ul li{
    width: 100%;
    color: #666;
    font-family: "Roboto", sans-serif;;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: block;
    float: left;
    margin-bottom: 22px;
    padding-left: 22px;
    position: relative;
}
.DiNapoli-section2 .info ul li::before {  
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon11.svg');  
  background-size: contain;
  background-repeat: no-repeat;
}

.DiNapoli-section2 .p2{
    width: 100%;
    color: #666;
    text-align: justify;
    font-family: "Roboto", sans-serif;;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    margin-bottom: 40px;
}

.DiNapoli-section2 .info a{
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #3D0101;
    background: #3D0101;
    color: #FFF;
    font-family: "Roboto", sans-serif;;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 20px; 
    display: block;
    width: max-content;
}   


.DiNapoli-section3{
    margin-top: 150px;
    margin-bottom: 160px;
}
.DiNapoli-section3 .p1{
    color: #000;
    font-family: "Roboto", sans-serif;;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 20px;
}
.DiNapoli-section3 .row{
    margin-top: 80px;
}

.DiNapoli-section3 .info{
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.DiNapoli-section3 img{
    width: 100%;
}

.DiNapoli-section3 ul li .pp1{
    width: 100%;
    color: #666;
    font-family: "Roboto", sans-serif;;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: block;
    margin-bottom: 3px;
    padding-left: 22px;
    position: relative;
}
.DiNapoli-section3 ul li .pp2{
    width: 100%;
    color: #666;
    font-family: "Roboto", sans-serif;;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 22px;
    padding-left: 22px;
}
.DiNapoli-section3 ul li .pp1::before {  
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon11.svg');  
  background-size: contain;
  background-repeat: no-repeat;
}

.DiNapoli-section3 .p2{
    width: 100%;
    color: #666;
    text-align: justify;
    font-family: "Roboto", sans-serif;;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    margin-bottom: 40px;
}

.DiNapoli-section3 .info a{
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #3D0101;
    background: #3D0101;
    color: #FFF;
    font-family: "Roboto", sans-serif;;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 20px; 
    display: block;
    width: max-content;
}  


.DiNapoli-section4 .section4-title{
    color: #000;
    font-family: Roboto;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.DiNapoli-section4 .row {  
  display: flex;  
  flex-wrap: wrap;  
}  

.DiNapoli-section4 .col-md-4 {  
  display: flex;  
  flex-direction: column;  
}  

.DiNapoli-section4 .left, .DiNapoli-section4 .center, .DiNapoli-section4 .right {  
  flex: 1;  
  display: flex;  
  flex-direction: column;  
}  

.DiNapoli-section4 .col-md-4 div{
    border: 1px solid #999;
    padding: 40px 32px;
    margin-top: 50px;
    flex-direction: column;
    display: flex;
    min-height: 300px;
    position: relative;
}
.DiNapoli-section4 .col-md-4 div .p1{
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 16px;
}
.DiNapoli-section4 .col-md-4 div .p2{
    color: #666;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 30px;
}
.DiNapoli-section4 .col-md-4 div .p3{
    color: #666;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.DiNapoli-section4 .col-md-4 div img{
    width: 20px;
}
.DiNapoli-section4 .col-md-4 div .img2{
    float: right;
    margin-top: 30px;
}
.DiNapoli-section5{
    margin-top: 140px;
}
.DiNapoli-section5 .section5-title1{
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}
.DiNapoli-section5 .section5-title2{
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 30px;
}
.DiNapoli-section5 .btn{
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #3D0101;
    background: #3D0101;
    color: #FFF;
    font-family: "Roboto", sans-serif;;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
    width: max-content;
    margin: 20px auto;
}
.DiNapoli-slide{
    margin-top: 60px;
    margin-bottom: 80px;
}
.DiNapoli-slide .swiper-button-next:after,.swiper-button-prev:after{
    content: "";
}
.DiNapoli-slide .swiper-button-prev{
    left: 22px;
    background-image: url(../assets/image/DiNapoli/DiNapoli-prev.svg);
    width: 50px;
    height: 50px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 50%;
}
.DiNapoli-slide .swiper-button-next{
    right: 22px;
    background-image: url(../assets/image/DiNapoli/DiNapoli-next.svg);
    width: 50px;
    height: 50px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 50%;
}
.DiNapoli-section1 .bg-m{
    display: none;
}
.DiNapoli-section1 .p1 br{
    display: none;
}
.DiNapoli-section3 .img-m{
    display: none;
}
.DiNapoli-page-ar .DiNapoli-section1 ul li,.DiNapoli-page-ar .DiNapoli-section2 .info ul li,.DiNapoli-page-ar .DiNapoli-section3 ul li .pp1{
    padding-left: 0px;
    padding-right: 22px;
}
.DiNapoli-page-ar .DiNapoli-section1 ul li::before,.DiNapoli-page-ar .DiNapoli-section2 .info ul li::before,.DiNapoli-page-ar .DiNapoli-section3 ul li .pp1::before{
    left: unset;
    right: 0;
}
.DiNapoli-page-ar .DiNapoli-section4 .col-md-4 div .img2{
    float: left;
}
.DiNapoli-page-ar .DiNapoli-section3 .info{
    padding-left: 4%;
    padding-right: 0%;
}
.DiNapoli-page-ar .DiNapoli-section2 .info{
    padding-left: 0%;
    padding-right: 4%;
}
.DiNapoli-page-ar .swiper-rtl .swiper-button-prev:after{
    content: '';
}
.DiNapoli-slide .swiper-button-prev{
    left: 20px;
    right: unset;
}
.DiNapoli-slide .swiper-button-next{
    right: 20px;
    left: unset;
}



@media(max-width:1400px) {
    .DiNapoli-section1{
        margin-top: 30px;
        padding-bottom: 100px;
    }
    .DiNapoli-section1 .p1{
        margin-top: 30px;
    }
}

@media(max-width:1300px) {
    .DiNapoli-section1{
        padding-top: 100px;
        padding-bottom: 80px;
    }
    .DiNapoli-section1 .p1{
        font-size: 42px;
    }
    .DiNapoli-section1 .p2{
        font-size: 16px;
    }
    .DiNapoli-section2{
        margin-top: 110px;
    }
    .DiNapoli-section2 .p1{
        font-size: 28px;
    }
    .DiNapoli-section2 .info ul li{
        margin-bottom: 14px;
        width: 100%;
    }
}


@media(max-width:996px) {
    .DiNapoli-section1{
        background: linear-gradient(0deg, #0D0101 0%, #350101 64%, #3D0101 100%);
        height: auto;
        margin-top: 0px;
        padding-top: 120px;
        padding-bottom: 60px;
        padding-left: 3%;
        padding-right: 3%;
    }
    .DiNapoli-section1 .bg-m{
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        left: 0;
    }
    .DiNapoli-section1 .bg-pc{
        display: none !important;
    }
    .DiNapoli-section1 .p1{
        position: relative;
        z-index: 9;
        margin-top: 220px;
        font-size: 46px;
    }
    .DiNapoli-section1 .p1 br{
        display: block;
    }
    .DiNapoli-section1 ul{
        margin-top: 30px;
    }
    .DiNapoli-section1 ul li{
        margin-bottom: 12px;
    }
    .DiNapoli-section1 .p2 {
        font-size: 16px;
    }
    .DiNapoli-section3 .img-m{
        display: block;
    }
    .DiNapoli-section3 .img-pc{
        display: none;
    }
    .DiNapoli-section2 .row{
        margin-top: 30px;
    }
    .DiNapoli-section2,.DiNapoli-section3,.DiNapoli-section4,.DiNapoli-section5{
        margin-bottom: 20px;
        margin-top: 90px;
        padding-left: 1%;
        padding-right: 1%;
    }
    .DiNapoli-section2 .p1,.DiNapoli-section3 .p1{
        margin-top: 30px;
        font-size: 34px;
    }
    .DiNapoli-section2 .info ul li{
        color: #666;
    }
    .DiNapoli-section2 .p2{
        margin-bottom: 30px;
    }
    .DiNapoli-section2 .info a,.DiNapoli-section3 .info a{
        width: 100%;
        text-align: center;
    }
    .DiNapoli-section3 .row{
        margin-top: 0;
    }
    .DiNapoli-section3{
        margin-top: 80px;
    }
    .DiNapoli-section4 .section4-title{
        font-size: 34px;
        margin-top: 60px;
    }
    .DiNapoli-section4 .col-md-4 div{
        margin-top: 30px;
    }
    .DiNapoli-section5 .section5-title1{
        font-size: 34px;
    }
    .DiNapoli-slide .swiper-button-next,.DiNapoli-slide .swiper-button-prev{
        width: 30px;
        height: 30px;
    }
    .DiNapoli-slide .swiper-button-next{
        right: 10px;
    }
    .DiNapoli-slide .swiper-button-prev{
        left: 10px;
    }
    .DiNapoli-slide{
        margin-bottom: 60px;
    }
    .DiNapoli-page-ar .DiNapoli-section3 .info{
        padding-left: 4%;
        padding-right: 4%;
    }
    .DiNapoli-page-ar .DiNapoli-section2 .info{
        padding-left: 4%;
        padding-right: 4%;
    }
}
</style>

