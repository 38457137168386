<template>
	<div class="home-header">
		<nav class="navbar fixed-top navbar-expand-lg" :class="{ 'home-navbar': mode == 'Home' }"
			:style="{ 'backgroundColor': bg }">
			<div class="container-fluid home-header-bd">
				<router-link to="/" aria-current="page"
					class="navbar-brand nuxt-link-exact-active nuxt-link-active en-box">
					<img src="../../assets/image/ss-header/logo.png" alt="" width="145" height="60"
						v-if="mode == 'Home'">
					<img src="../../assets/logo_1.png" alt="" width="145" height="60" v-else>
				</router-link>
				<button class="navbar-toggler" @click="showHeadMenu()">
					<img src="../../assets/image/index/menu.png" alt="" v-if="mode == 'Home'">
					<img src="../../assets/image/Home/head-icon.png" alt="" v-else>
				</button>
				<div class="collapse navbar-collapse justify-content-around" :class="{ 'headState': headMenu }">
					<ul class="navbar-nav dropdown-roll">
						<template v-for="(item, index) in $t('Header.headlist')">
							<li class="nav-item dropdown" v-if="item.menu" @click="toggleDropdown(index)"
								@mouseenter="!isMobile() && dropdownshow(index)"
								@mouseleave="!isMobile() && dropdownhied()">
								<a href="javascript:;" id="navbarDropdown1" role="button" data-bs-toggle="dropdown"
									aria-expanded="false" class="nav-link dropdown-toggle" v-html="item.text"></a>
								<div class="dropdown-menu"
									:class="{ 'show': menuIndex === index && (isMobile() || !isMobile()) }">
									<ul class="container">
										<li class="dropdown-center">
											<div class="nav-down" v-for="(itx, idx) in item.menu" :key="idx">
												<p style="margin-top: 6px;" v-if="itx.titUrl && itx.tit"><router-link
														style="margin-top: 0px;margin-bottom: 6px;font-size: 19px;color: #AF8147;"
														:to="itx.titUrl">{{ itx.tit }}</router-link></p>
												<p style="margin-top: 6px;" v-if="itx.tit && !itx.titUrl">{{ itx.tit }}
												</p>
												<router-link
													v-if="me.url != 'PointsMall' && !me.url.includes('support.ebchelp.com')"
													v-for="(me, id) in itx.list" :key="id"
													:to="!$store.state.suffix ? me.url : ('/' + $store.state.suffix + me.url)">{{
														me.text }}
												</router-link>
												<a v-else-if="me.url.includes('support.ebchelp.com')" :href="me.url"
													target="_blank">{{ me.text }}</a>
												<a v-else :href="getPointsMallUrl(me.url)" target="_blank">{{ me.text
													}}</a>
											</div>
										</li>
									</ul>
								</div>
							</li>
							<div class="nav-item" v-else>
								<a href="https://ebcfinsns.co/" class="nav-link"
									v-if="item.url == 'https://ebcfinsns.co/'">{{ item.text }}</a>
								<router-link class="nav-link nuxt-link-exact-active nuxt-link-active"
									:to="!$store.state.suffix ? item.url : ('/' + $store.state.suffix + item.url)"
									v-html="item.text" v-else>

								</router-link>
							</div>
						</template>
						<li class="nav-item d-md-none">
							<a :href="$t('Header.faq_url')" target="_blank" class="nav-link"><span>{{
								$t('FAQ.faq-title') }}</span>
							</a>
						</li>
						<li class="nav-item d-md-none">
							<router-link to="/official-verification" class="nav-link"><span>{{
								$t('OfficialVerification.official-page1.text1')
							}}</span>
							</router-link>
						</li>
						<li class="nav-item d-md-none">
							<router-link :to="!$store.state.suffix ? '/FCA' : ('/' + $store.state.suffix + '/FCA')"
								class="nav-link"><span>{{
									$t('Header.FCA') }}</span>
							</router-link>
						</li>
						<li class="nav-item d-md-none">
							<router-link :to="$t('Header.from0tohero_url')" class="nav-link"><span>{{
								$t('Header.from0tohero_text') }}</span>
							</router-link>
						</li>
					</ul>
					<ul class="navbar-nav login-pc">
						<li class="nav-item nav-item-c49a6c">
							<router-link :to="!$store.state.suffix ? '/FCA' : ('/' + $store.state.suffix + '/FCA')"
								style="color: #AF8147;">{{ $t('Header.FCA') }}
							</router-link>
							<em>|</em>&nbsp;
							<a :href="$store.state.khUrl">{{ $t('Header.zhuce') }}
							</a>
							<em>|</em>&nbsp;
							<a :href="$store.state.loginUrl">
								{{ $t('Header.login') }}
							</a>
						</li>
					</ul>

					<div class="nav-icon-div">
						<router-link to="/official-verification"
							:title="$t('OfficialVerification.official-page1.text1')"><img class="nav-icon"
								src="../../assets/image/ss-header/nav-icon1.svg" alt=""></router-link>
						<a :href="$t('Header.faq_url')" :title="$t('FAQ.faq-title')" target="_blank"><img
								class="nav-icon" src="../../assets/image/ss-header/nav-icon2.svg" alt=""></a>


						<div class="head-lang" v-show="languageShow">
							<a href="javascript:void(0);" title="" class="head-lang__title"><img
									src="../../assets/image/ss-header/nav-icon3.svg" alt=""><span>{{
										$t('CFooters.language') }}</span>
							</a>
							<ul class="head-lang__menu" style="background-color: #AF8147;">
								<li v-for="(item, index) in languageList" :key="index"
									@click="handleChangeLamguage(index)" v-if="item.text != $t('CFooters.language')">
									<span @mouseleave="xxXuan" @mouseenter="xxXuan" class="head-lang__menu__item">{{
										item.text }}</span>
								</li>
							</ul>
						</div>
					</div>
					<ul class="navbar-nav login-mobile">
						<li class="nav-item nav-item-c49a6c">
							<a :href="$store.state.khUrl">{{ $t('Header.zhuce') }}
							</a>
							<em>|</em>&nbsp;
							<a :href="$store.state.loginUrl">
								{{ $t('Header.login') }}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
	inject: ['reload'], //注入App里的reload方法
	props: {
		mode: {
			type: String,
			default: 'Inner'
		},
		bg: {
			type: String,
			default: '#fff'
		},
		languageShow: {
			type: Boolean,
			default: true
		},
		langList: {
			type: Array,
			default: () => {
				return ['ct', 'en', 'ja', 'ko', 'th', 'vn', 'zh']
			}
		}
	},
	data() {
		return {
			headMenu: false,
			menuIndex: -1,
			threeIndex: -1,
			languageList: [],
			xxStaus: false
		}
	},
	created() {
		console.log(this.mode);
		let result = {};
		for (let el in this.$config.languageData) {
			let f = false;
			this.langList.map(lg => {
				if (el == lg) {
					f = true
				}
			})
			if (f) {
				result[el] = this.$config.languageData[el]
			}
		}
		console.log("---this.$config.languageData---", this.$config.languageData)
		console.log("---result---", result)
		// this.languageList = this.$config.languageData
		this.languageList =result

	},
	methods: {
		xxXuan() {
			this.xxStaus = !this.xxStaus
		},
		dropdownshow(idx) {
			if (this.menuIndex === -1) {
				this.menuIndex = idx
			} else {
				this.menuIndex = -1
			}
		},
		dropdownhied() {
			this.menuIndex = -1
		},
		toggleDropdown(index) {
			if (this.isMobile()) {
				if (this.menuIndex === index) {
					this.menuIndex = -1;
				} else {
					this.menuIndex = index;
				}
			}
		},
		handleChangeLamguage(language) {
			console.log("handleChangeLamguage language",language)
			let pathname = window.location.pathname
			if (pathname.indexOf('/cn') != -1 || pathname.indexOf('/en') != -1 || pathname.indexOf('/ko') != -1 ||
				pathname.indexOf('/ja') != -1 || pathname.indexOf('/th') != -1 || pathname.indexOf('/ug') != -1 ||
				pathname.indexOf('/tw') != -1) {
				if (language == 'vi') {
					this.$store.commit('setSuffix', 'ug')
				} else if (language == 'ct') {
					this.$store.commit('setSuffix', 'tw')
				} else {
					this.$store.commit('setSuffix', language)
				}
			} else {
				if (language == 'zh') {
					this.$store.commit('setPosition', 'zh_CN')
				} else if (language == 'en') {
					this.$store.commit('setPosition', 'en_US')
				} else if (language == 'ko') {
					this.$store.commit('setPosition', 'ko_KR')
				} else if (language == 'ja') {
					this.$store.commit('setPosition', 'ja_JP')
				} else if (language == 'th') {
					this.$store.commit('setPosition', 'th_TH')
				} else if (language == 'vi') {
					this.$store.commit('setPosition', 'weiwuer')
				} else if (language == 'ct') {
					this.$store.commit('setPosition', 'zh_TW')
				} else if (language == 'vn') {
					this.$store.commit('setPosition', 'vn')
				}
			}
			this.$i18n.locale = language
			localStorage.setItem('language', language)
			this.reload();
		},
		showHeadMenu() {
			this.headMenu = !this.headMenu
		},
		getPointsMallUrl(url) {
			if (this.isMobile()) {
				return "http://ebcpoints.com/html/m2/index.html";
			} else {
				return "https://ebcpoints.com/html/ebc/index.html";
			}
		},
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag || window.innerWidth <= 768;
		}
	}
}
</script>

<style lang="scss" scoped>
.home-header {
	position: fixed;
	top: 0px;
	z-index: 99;

	.navbar {
		background-color: #fff;

		.home-header-bd {
			.nav-link {
				color: #000;
			}
		}
	}

	.head-lang__title {
		color: #333;
	}

	.home-navbar {

		.home-header-bd {
			.head-lang__title {
				color: #e0e0e0;

				&::after {
					display: none;
				}
			}

			.nav-link {
				color: #e0e0e0;
			}

			.nav-item {
				&.nav-item-c49a6c {
					color: #e0e0e0;
				}

				.dropdown-menu {
					background-color: rgba(38, 5, 5, 1);

					.dropdown-left {
						.nav-title {
							color: #e0e0e0;
						}

						.nav-desc {
							color: #e0e0e0;
						}
					}

					.dropdown-center {
						.nav-down {
							a {
								color: #e0e0e0;

								&:hover {
									color: #c49a6c;
								}
							}
						}
					}
				}
			}
		}
	}

	&-bd {
		.navbar-brand {
			padding-left: 55px;
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.nav-item {
			font-size: 16px;

			.dropdown-menu {
				background-color: #fff;
				min-width: 140px;

				li {
					position: relative
				}

				.dropdown-menu {
					position: absolute;
					left: 100%;
					top: 0
				}
			}
		}

		.navbar-toggler img {
			width: 30px;
		}

		.nav-item-c49a6c {
			color: #333;
			line-height: 1.45;

			a {
				padding: 0 10px;
				color: inherit;
				font-size: 16px;
			}
		}

		.dropdown-roll {
			.nav-item {
				position: initial;

				.dropdown-menu {
					position: absolute;
					width: 100%;
					left: 0;
					top: 74px;
					border-top: none;
					padding-top: 26px;

					.dropdown-item {
						color: #fff;

						&:hover {
							background-color: hsla(0, 0%, 93.3%, .1);
							color: #c49a6c
						}
					}
				}
			}
		}

		.nav-link {
			padding-top: 26px !important;
			padding-bottom: 26px !important;
			font-size: 16px;
			line-height: 1.75;
			letter-spacing: .5px;
			color: #e0e0e0;
			height: 100%;
			/* font-family: auto; */
		}

		.color-h {
			color: #212529
		}

		.three-link:after {
			transform: rotate(-90deg)
		}
	}

	.dropdown-menu li {
		width: 33%;
		float: left;
	}

	.dropdown-left {
		border-right: 1px #482e2e solid;
		height: 180px;
		margin-right: 70px;
		width: 24% !important;
		margin-top: 0px;
		margin-bottom: 60px;
		padding-top: 36px;

		.nav-title {
			font-size: 26px;
			margin-bottom: 20px;
			color: #333;
		}

		.nav-desc {
			color: #333;
			font-size: 16px;
			line-height: 24px;
			width: 92%;
		}
	}

	.dropdown-center {

		.nav-down {
			float: left;
			text-align: left;

			a {
				font-size: 16px;
				display: block;
				color: #333;
				margin: 30px 0;

				&:nth-child(1) {
					margin-top: 8px;
				}

				&:hover {
					color: #c49a6c;
				}
			}
		}
	}

	.dropdown-right {
		width: 10% !important;

		img {
			width: 116px;
			opacity: 0.7;
			margin: 12px 50px;
		}
	}

	.head-lang {
		.lang-star-hover {
			display: none;
		}

		&:hover {
			.head-lang__menu {
				display: block;
			}

			.lang-star {
				display: none;
			}

			.lang-star-hover {
				display: inline-block;
			}
		}

		&__title {
			img {
				width: 18px;
				height: 18px;
				margin-left: 6px;

				&.rotate {
					transition: all 0.2s ease-in-out;
					transform: rotate(-90deg);
				}
			}
		}

		&__menu {
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			border-top: 4px solid #2b1010;
			min-width: 100px;
			display: none;

			&.showClass {
				position: relative
			}

			&__item {
				text-align: center;
				display: inline-block;
				padding: 15px;
				cursor: pointer;
				width: 100%;
				font-weight: 400;
				font-size: 14px;
				color: #3D0101;
				font-family: auto;

				&:hover {
					background-color: #e0e0e0;
					color: #6c100f
				}
			}
		}
	}

	.login-mobile {
		display: none;
	}

	.head-lang__title {
		span {
			display: none;
		}

		padding: 20px 13px !important;

		img {
			width: auto;
			height: auto;
			margin-left: 0;
		}
	}

	.nav-icon-div {
		.nav-icon {
			padding: 0 13px;
		}
	}

	.head-lang {
		display: inline-block;
	}

	.head-lang__menu {
		top: 80%;
		left: 50%;
		transform: translate(-50%, 0%);

		&__item {
			font-size: 14px;
		}
	}

	.home-header-bd {
		.nav-item {
			em {
				display: inline-block;
				margin-bottom: 2px;
			}
		}
	}
}

.home-header-bd .dropdown-roll .nav-item .dropdown-menu {
	width: auto;
	left: unset;
}

.dropdown-center {
	width: 100% !important;
	padding: 0px 0px;
}

.dropdown-center .nav-down p {
	color: #949699;
	font-weight: normal;
	font-size: 16px;
}

.dropdown-center .nav-down {
	width: auto !important;
	margin: 0 0px;
}

.dropdown-center .nav-down p {
	margin: 0px 30px 30px 30px !important;
}

.dropdown-center .nav-down a {
	margin: 0px 30px 36px 30px !important;
}

.dropdown-menu {
	padding-bottom: 6px;
}

.home-header-bd .nav-link {
	padding: 26px 21px !important;
}

@media(max-width:1600px) {

	.head-lang__menu {
		left: -10px !important;
		transform: unset;
	}

	.home-header-bd .nav-link {
		padding: 26px 18px !important;
	}
}

@media(max-width:1580px) {
	.home-header-bd .nav-link {
		font-size: 14px;
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	.home-header-bd .nav-item-c49a6c a {
		font-size: 14px;
	}
}

@media(max-width:1500px) {
	.home-header-bd .nav-link {
		padding: 26px 12px !important;
		font-size: 14px;
	}

	.home-header-bd .nav-item-c49a6c a {
		font-size: 14px;
	}

	.home-header .head-lang__title {
		font-size: 14px;
	}

	.home-header .head-lang__menu__item {
		padding: 11px;
		font-size: 13px;
	}

	.home-header .head-lang__menu {
		left: -100% !important;
		transform: unset;
	}

	.home-header .ar-head-lang .head-lang__menu {
		left: 30px;
		transform: unset;
	}
}

@media(max-width:1400px) {
	.home-header-bd .nav-link {
		padding: 26px 16px !important;
		font-size: 14px;
	}

	.home-header-bd .nav-item-c49a6c a {
		font-size: 14px;
	}

	.home-header .head-lang__title {
		font-size: 14px;
	}

	.home-header-bd .dropdown-roll .nav-item .dropdown-menu {
		top: 70px;
	}

	.head-lang__menu {
		left: -110% !important;
		transform: unset;
	}

	.home-header-bd .nav-link {
		font-size: 13px;
		padding-left: 12px !important;
		padding-right: 12px !important;
	}

	.home-header-bd .nav-item-c49a6c a {
		font-size: 13px;
	}

	.bottom-fixed .span4 {
		margin-right: 40px;
	}

	.bottom-fixed .social-media {
		margin-right: 23px;
		position: relative;
		top: 6px;
		display: inline-block;
	}

	.bottom-fixed .social-media img {
		opacity: 0.8;
	}

	.bottom-fixed .media4 {
		top: 9px;
	}

	.home-header .head-lang__menu {
		left: -110% !important;
		transform: unset;
	}

	.home-header .ar-head-lang .head-lang__menu {
		left: 60px;
		transform: unset;
	}
}

@media(max-width:1300px) {
	.home-header-bd .nav-link {
		padding: 26px 15px !important;
		font-size: 13px;
	}

	.home-header-bd .nav-item-c49a6c a {
		font-size: 13px;
		padding: 0 5px;
	}

	.home-header .head-lang__title {
		font-size: 13px;
	}

	.fth2-lock {
		top: 74.5px;
	}

	.home-header .head-lang__title {
		padding: 6px 2px;
	}

	.home-header .head-lang__menu {
		left: -130% !important;
		transform: unset;
	}

	.home-header .ar-head-lang .head-lang__menu {
		left: 60px !important;
		transform: unset;
	}

	.home-header-bd .nav-link {
		padding: 26px 6px !important;
		font-size: 12px;
	}
}

@media(max-width:1200px) {
	.dropdown-right {
		display: none;
	}

	.nav-icon-div>a:nth-child(1),
	.nav-icon-div>a:nth-child(2) {
		display: none;
	}

	.bottom-fixed .social-media {
		margin-right: 16px;
	}
}

@media(max-width:1270px) {}

@media screen and (max-width:1024px)and (min-width:769px) {
	.home-header-bd .nav-item .nav-link {
		padding: 6px 8px !important;
		white-space: nowrap
	}

	.home-header-bd .nav-item .nav-link.en-box,
	.home-header-bd .nav-item .nav-link.ja-box,
	.home-header-bd .nav-item .nav-link.th-box,
	.home-header-bd .nav-item .nav-link.vi-box {
		padding: 6px 0 !important
	}

	.home-header-bd .navbar-brand {
		padding-left: 15px !important;
		margin-right: 10px
	}

	.home-header-bd .navbar-brand.en-box,
	.home-header-bd .navbar-brand.th-box,
	.home-header-bd .navbar-brand.vi-box {
		padding-left: 6px !important
	}

	.home-header-bd .navbar-brand.ja-box {
		margin-right: 0
	}
}

@media(max-width:1200px) {
	.home-header-bd .nav-link {
		padding: 26px 7px !important;
		font-size: 12px;
	}
}

@media(max-width:1100px) {
	.home-header-bd .nav-link {
		padding: 26px 6px !important;
	}
}

@media screen and (max-width:1024px)and (min-width:769px) {
	.section1 {
		margin-top: 30px;
	}

	.section1 .wns {
		width: 165px;
	}

	.section1 .r2 img {
		width: 22%;
	}

	.section1 .r2 .p3 {
		width: 50%;
	}
}

@media(max-width:995px) {
	.home-header-bd .nav-item:nth-child(6) {
		display: none;
	}

	.dropdown-center {
		width: 92% !important;
	}

	.login-mobile {
		display: block !important;

		.nav-item {
			text-align: center;
		}
	}

	.dropdown-center .nav-down {
		width: 100% !important;
	}

	.dropdown-center .nav-down a:first-child {
		margin-top: 14px !important;
	}


	.dropdown-left {
		display: none;
	}

	.dropdown-right {
		display: none;
	}

	.home-header .head-lang__title img {
		position: relative;
		top: 4px;
	}

	.home-header-bd .dropdown-roll .nav-item .dropdown-menu {
		top: 0;
		padding-top: 6px;
		border: none;
	}

	.dropdown-center {
		padding-left: 4%;
	}

	.dropdown-center a {
		margin: 28px 0 !important;
	}

	.dropdown-center .nav-down p {
		margin-bottom: 0px;
		margin-top: 8px !important;
	}

	.dropdown-center .nav-down a {
		font-size: 16px;
	}

	.login-pc {
		display: none;
	}

	.login-mobile {
		display: block;
	}

	.home-header .head-lang {
		text-align: center;
	}

	.home-header .head-lang__title {
		display: block;
	}

	.home-header .justify-content-around {
		padding: 8px 0px 20px;
		max-height: 600px;
	}

	.home-header .navbar-nav .nav-item:last-child {
		display: block;
	}

	.home-header-bd .nav-link {
		padding: 10px 30px !important;
		font-size: 16px;
		/* text-align: center; */
	}

	.home-header-bd .nav-item-c49a6c a {
		font-size: 16px;
		color: #fff;
		background: #9d7345;
		padding: 8px 30px;
		border-radius: 4px;
		margin: 10px 10px;
		display: inline-block;
	}

	.home-header-bd .nav-item-c49a6c em {
		display: none;
	}

	.home-header .head-lang__title {
		font-size: 16px;
	}

	.home-header .nav-icon-div .nav-icon {
		display: none;
	}

	.home-header .head-lang {
		display: block;
	}

	.home-header .head-lang__title img {
		width: 18px;
	}

	.home-header .head-lang__menu {
		width: 100%;
		position: absolute;
		top: 80%;
		left: 50% !important;
		transform: translate(-50%, 0%);
	}

	.home-header .justify-content-around {
		padding-bottom: 40px;
	}

	.home-header-bd .nav-item em {
		display: none !important;
	}

	.home-header .head-lang__title span {
		display: inline-block;
	}
}

@media(max-width:767px) {
	.dropdown-menu {
		background-color: unset !important;
	}

	.home-header-clearfix {
		height: 66px
	}

	.home-header-bd {
		border-bottom: none;
	}

	.home-header-bd .nav-item-c49a6c {
		padding-left: 8px
	}

	.home-header .justify-content-around {
		max-height: 540px;
		overflow-y: auto
	}

	.home-header .dropdown-menu {
		position: relative !important;
		left: 0 !important
	}

	.home-header .navbar-brand {
		padding-left: 20px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.home-header .navbar-toggler:focus {
		box-shadow: none
	}

	.home-header .navbar-toggler .icon {
		width: 30px;
		height: 30px;
		background-color: #000
	}

	.home-header .color-h {
		color: #212529
	}

	.home-header .fixed-menu {
		position: fixed;
		bottom: 150px;
		right: 0;
		transform: scale(.75);
		transform-origin: 100% 50%;
		z-index: 100
	}
}
</style>