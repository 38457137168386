export default {
  linkList: [
    {
      title: "บัญชีและเงื่อนไข",
      list: [
        {
          label: "บัญชีธุรกรรม",
          link: "/TradingAccount",
        },
        {
          label: "ซื้อขายสินค้า",
          link: "/tradingproduct",
        },
        {
          label: "เลเวอเรจมาร์จิ้น",
          link: "/leverage-margin",
        },
        {
          label: "ฝากและถอนเงิน",
          link: "/funding",
        },
        {
          label: "เงินปันผล",
          link: "/dividend",
        },
      ],
    },
    {
      title: "เครื่องมือการซื้อขาย",
      list: [
        {
          label: "ดาวน์โหลด MT4",
          link: "/MT4",
        },
        {
          label: "ดาวน์โหลด MT5",
          link: "/MT5",
        },
        {
          label: "เครื่องมือการซื้อขาย MT4",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "การวิเคราะห์ตลาด",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "EBCสถาบัน",
      list: [
        {
          label: "ศูนย์การเรียนรู้",
          link: "/LearningCenter",
        },
        {
          label: "ศูนย์ข้อมูล",
          link: "/lab",
        },
      ],
    },
    {
      title: "เหตุการณ์ล่าสุด",
      list: [
        {
          label: "การแข่งขันการซื้อขาย",
          link: "/win",
        },
        // {
        // 	"label": "ท้าทายกับสภาพแวดล้อมการเทรดที่ดีที่สุด",
        // 	"link": "/no1"
        // },
        {
          label: "Creative Program",
          link: "/cfa",
        },
      ],
    },
    {
      title: "เกี่ยวกับ EBC",
      list: [
        {
          label: "WHY EBC",
          link: "/about-us",
        },
        {
          label: "ความมั่นคงทางการเงิน",
          link: "/LegalDocumen",
        },
        {
          label: "Sustainability & Impact",
          link: "/ESG",
        },
        {
          label: "FAQ",
          link: "https://support.ebchelp.com/hc/en-001",
        },
        {
          label: "การตรวจสอบช่องทางอย่างเป็นทางการ",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "โหมดความร่วมมือ",
      list: [
        {
          label: "อิทธิพลที่มีต่อพันธมิตร",
          link: "/affiliate-programmes",
        },
        {
          label: "บริการสถาบัน",
          link: "/service",
        },
        {
          label: "เทคโนโลยีพื้นฐาน",
          link: "/technology",
        },
      ],
    },
    
  ],
  context: `
      <p>
        <span class="page-footer-intro-blod">
          EBC Financial Group มีกลุ่มองค์กรเครือข่ายต่างๆ ได้แก่:<br />
        </span>
        EBC Financial Group (SVG) LLC ได้รับอนุญาตจาก St.Vincent และ The
        Grenadines Financial Services Authority (SVGFSA) หมายเลขจดทะเบียนบริษัท
        353 LLC 2020 ,ที่อยู่สำนักงานที่จดทะเบียน Euro House, Richmond Hill
        Road, Kingstown, VC0100, St. Vincent and the Grenadines.
        <br />
        EBC Financial Group (Comoros) Limited
        ได้รับอนุญาตจากหน่วยงานการเงินนอกชายฝั่งของเกาะ Anjouan ,Union of
        Comoros. หมายเลขควบคุม L 15637/EFGC ,ที่อยู่สำนักงานที่จดทะเบียน
        Hamchako, Mutsamudu, Autonomous Island of Anjouan, Union of Comoros
      </p>
      <p>
        <span class="page-footer-intro-blod">หน่วยงานที่เกี่ยวข้อง:<br /></span>
        EBC FINANCIAL GROUP (UK) LTD ได้รับอนุญาตและควบคุมโดย Financial Conduct
        Authority (FCA) หมายเลขควบคุม: 927552 ,เว็บไซต์:
        <a target="_blank" href="https://www.ebcfin.co.uk" rel="nofollow"
          >www.ebcfin.co.uk</a
        >
      <br />
        EBC FINANCIAL GROUP (CAYMAN) LTD ได้รับอนุญาตและควบคุมโดย Cayman Islands
        Monetary Authority (CIMA) หมายเลขควบคุม: 2038223 ,เว็บไซต์:
        <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow"
          >www.ebcgroup.ky</a
        >
        <br />
        EBC Group (Cyprus) Ltd
        ให้บริการการชำระเงินแก่หน่วยงานที่ได้รับใบอนุญาตและอยู่ภายใต้การควบคุมภายในองค์กรของ
        EBC Financial Group โดยจดทะเบียนภายใต้กฎหมายบริษัทของสาธารณรัฐไซปรัส
        หมายเลข: HE 449205 ,ที่อยู่สำนักงานที่จดทะเบียน 101 Gladstonos,
        Agathangelou Business Centre, 3032 Limassol, Cyprus
        <br />
        EBC Financial Group (Australia) Pty Ltd (ACN: 619 073 237)
        ได้รับอนุญาตและควบคุมโดย Australian Securities and Investments
        Commission (ASIC) หมายเลข: 500991 โดย EBC Financial Group (Australia)
        Pty Ltd เป็นองค์กรที่เกี่ยวข้องกับ EBC Financial Group (SVG) LLC
        ทั้งสององค์กรมีการบริหารจัดการแยกต่างหาก
        ผลิตภัณฑ์และบริการทางการเงินที่นำเสนอในเว็บไซต์นี้ไม่ได้ให้บริการโดยองค์กรในออสเตรเลียและไม่สามารถเรียกร้องความรับผิดชอบจากองค์กรในออสเตรเลียได้
        
      </p>
      <p>
        <span class="page-footer-intro-blod"> ที่อยู่บริษัท:</span>
        The Leadenhall Building, 122 Leadenhall Street, London, United Kingdom
        EC3V 4AB ,อีเมล: cs@ebc.com ,หมายเลขโทรศัพท์: +44 20 3376 9662
      </p>
      <p>
        <span class="page-footer-intro-blod">คำชี้แจง:</span>EBC Financial Group
        ยืนยันว่าไม่ได้มีส่วนร่วมให้บริการสกุลเงินดิจิทัล
        หรือให้บริการสัญญาซื้อขายส่วนต่าง (CFD) ที่เกี่ยวกับคริปโต
        โดยบริการทางการเงินของเราเกี่ยวข้องกับตราสารแบบดั้งเดิมเท่านั้น
        ผู้ใช้ควรทราบถึง การอ้างการให้บริการซื้อขายคริปโตในนาม EBC
        นั้นไม่ถูกต้องและไม่ได้รับอนุญาต
        การอ้างอิงถึงผลการดำเนินงานในอดีตไม่ได้บ่งชี้ถึงผลการดำเนินงานในอนาคต
        ข้อมูลในเว็บไซต์นี้จัดทำขึ้นเพื่อการอ้างอิงเท่านั้น
        และไม่ถือเป็นคำแนะนำการลงทุนแต่อย่างใด
      </p>
      <p>
        <span class="page-footer-intro-blod">ข้อจำกัดภูมิภาค:</span>EBC
        ไม่ได้ให้บริการแก่ผู้ใช้งานที่อยู่ในเขตอำนาจศาลบางแห่ง ได้แก่
        อัฟกานิสถาน, เบลารุส, เมียนมาร์, แคนาดา, สาธารณรัฐแอฟริกากลาง, คองโก,
        คิวบา, สาธารณรัฐประชาธิปไตยคองโก, เอริเทรีย, ไนจีเรีย, อิหร่าน, อิรัก,
        เลบานอน, ลิเบีย, มาเลเซีย, มาลี, เกาหลีเหนือ (สาธารณรัฐประชาชนเกาหลี),
        รัสเซีย, โซมาเลีย, ซูดาน, ซูดานใต้, ซีเรีย, ยูเครน
        (รวมถึงภูมิภาคไครเมีย, โดเนตสก์, และลูฮันสค์), สหรัฐอเมริกา, เวเนซุเอลา,
        และเยเมน ภาษาสเปนที่ใช้ในเว็บไซต์นี้มีไว้สำหรับ LATAM เท่านั้น
        และไม่ได้จัดทำสำหรับผู้ที่อยู่ในสหภาพยุโรปหรือสเปน สำหรับข้อมูลเพิ่มเติม
        โปรดดูที่คำถามที่พบบ่อย (FAQs)
      </p>
      <p>
        <span class="page-footer-intro-blod"
          >การเปิดเผยการปฏิบัติตามข้อกำหนด:</span
        >เว็บไซต์บริษัทสามารถเข้าถึงได้ทั่วโลกและไม่ได้เจาะจงไปที่องค์กรใดองค์กรหนึ่ง
        สิทธิ์และภาระผูกพันของคุณถูกกำหนดโดยองค์กรและเขตอำนาจศาลที่คุณเลือก
        กฎหมายและข้อบังคับท้องถิ่นอาจห้ามหรือจำกัดไม่ให้คุณเข้าไปดาวน์โหลด
        แจกจ่าย เผยแพร่ แบ่งปัน หรือใช้เอกสาร ข้อมูลใด ๆ ที่เผยแพร่ในเว็บไซต์นี้
      </p>
      <p>
        <span class="page-footer-intro-blod">คำเตือนความเสี่ยง:</span
        >การซื้อขายสัญญาส่วนต่าง (CFD) เป็นเครื่องมือทางการเงินที่ซับซ้อน
        และมีความเสี่ยงสูง เนื่องจากการใช้เลเวอเรจ
        การซื้อขายด้วยมาร์จิ้นมีความเสี่ยงสูงและอาจไม่เหมาะสำหรับนักลงทุนทุกท่าน
        ก่อนตัดสินใจทำการซื้อขายฟอเร็กซ์หรือ CFD
        คุณควรพิจารณาอย่างรอบคอบเกี่ยวกับวัตถุประสงค์ในการซื้อขาย
        ระดับประสบการณ์ และความสามารถในการรับความเสี่ยงของคุณ
        อาจมีความเป็นไปได้ที่คุณอาจสูญเสียเงินทุนบางส่วนหรือทั้งหมด
        เราขอแนะนำให้คุณขอคำแนะนำจากที่ปรึกษาอิสระและตรวจสอบให้แน่ใจว่าคุณเข้าใจความเสี่ยงที่เกี่ยวข้องอย่างครบถ้วนก่อนตัดสินใจลงทุน
        กรุณาอ่านคำชี้แจงเกี่ยวกับความเสี่ยงที่เกี่ยวข้องอย่างละเอียดก่อนทำการซื้อขาย
      </p>
  `,
};
