export default {
  linkList: [
    {
      title: "Tài khoản & Điều kiện",
      list: [
        {
          label: "Tài khoản giao dịch",
          link: "/TradingAccount",
        },
        {
          label: "Sản phẩm giao dịch",
          link: "/tradingproduct",
        },
        {
          label: "Quỹ đảm bảo/ đòn bẩy",
          link: "/leverage-margin",
        },
        {
          label: "Nạp/Rút tiền",
          link: "/funding",
        },
        {
          label: "Cổ tức",
          link: "/dividend",
        },
      ],
    },
    {
      title: "Công cụ giao dịch",
      list: [
        {
          label: "Tải xuống MT4",
          link: "/MT4",
        },
        {
          label: "Tải xuống MT5",
          link: "/MT5",
        },
        {
          label: "Công cụ giao dịch MT4",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "Phân tích thị trường",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "Viện nghiên cứu EBC",
      list: [
        {
          label: "Trung tâm học tập",
          link: "/LearningCenter",
        },
      ],
    },
    {
      title: "Hoạt động gần đây",
      list: [
        {
          label: "Cuộc thi giao dịch",
          link: "/win",
        },
        // {
        // 	"label": "Thách thức môi trường giao dịch mạnh nhất",
        // 	"link": "/no1"
        // }
      ],
    },
    {
      title: "Về EBC",
      list: [
        {
          label: "WHY EBC",
          link: "/about-us",
        },
        {
          label: "Tài liệu hợp pháp",
          link: "/LegalDocumen",
        },
        {
          label: "Trách nhiệm xã hội",
          link: "/ESG",
        },
        {
          label: "FAQ",
          link: "https://support.ebchelp.com/hc/en-001",
        },
        {
          label: "Xác minh kênh chính thức",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "Mẫu hợp tác",
      list: [
        {
          label: "Quan hệ đối tác",
          link: "/affiliate-programmes",
        },
        {
          label: "Cơ chế phục vụ",
          link: "/service",
        },
        {
          label: "Công nghệ nền tảng",
          link: "/technology",
        },
      ],
    },
  ],
  context: `
      <p>
        <span class="page-footer-intro-blod">
          EBC Financial Group là một thương hiệu đồng sở hữu bởi nhóm các tổ
          chức bao gồm:<br />
        </span>
        EBC Financial Group (SVG) LLC được ủy quyền bởi Cơ quan Dịch vụ Tài
        chính St. Vincent và Grenadines (SVGFSA), với số đăng ký công ty là 353
        LLC 2020. Địa chỉ đăng ký tại Euro House, Richmond Hill Road, Kingstown,
        VC0100, St. Vincent và Grenadines.
        <br />
        EBC Financial Group (Comoros) Limited được ủy quyền bởi Cơ quan Tài
        chính Ngoài khơi của Đảo Tự trị Anjouan, Liên bang Comoros, với số giấy
        phép L 15637/EFGC. Địa chỉ văn phòng đăng ký tại Hamchako, Mutsamudu,
        Đảo Tự trị Anjouan, Liên bang Comoros.
      </p>
      <p>
        <span class="page-footer-intro-blod"
          >Các tổ chức liên quan khác:<br
        /></span>
        EBC Financial Group (UK) Limited được ủy quyền và quản lý bởi Cơ quan
        Quản lý Tài chính (FCA) của Vương quốc Anh. Số tham chiếu: 927552.
        Website:
        <a target="_blank" href="https://www.ebcfin.co.uk" rel="nofollow"
          >www.ebcfin.co.uk</a
        >
      <br />
        EBC Financial Group (Cayman) Limited được cấp phép và quản lý bởi Cơ
        quan Tiền tệ Quần đảo Cayman (Số: 2038223). Website:
        <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow"
          >www.ebcgroup.ky</a
        >
        <br />
        EBC Group (Cyprus) Ltd hỗ trợ dịch vụ thanh toán cho các tổ chức được
        cấp phép và quản lý trong hệ thống của EBC Financial Group, được đăng ký
        theo Luật Công ty của Cộng hòa Cyprus với số đăng ký HE 449205. Địa chỉ
        văn phòng đăng ký: 101 Gladstonos, Trung tâm Kinh doanh Agathangelou,
        3032 Limassol, Cyprus.
        <br />
        EBC Financial Group (Australia) Pty Ltd (ACN: 619 073 237) được ủy quyền
        và quản lý bởi Ủy ban Chứng khoán và Đầu tư Úc (ASIC) với số giấy phép
        500991. EBC Financial Group (Australia) Pty Ltd là một đơn vị liên quan
        của EBC Financial Group (SVG) LLC, tuy nhiên, hai đơn vị này được quản
        lý độc lập. Các sản phẩm và dịch vụ tài chính được cung cấp trên trang
        web này KHÔNG do đơn vị tại Úc cung cấp, và không có quyền truy đòi pháp
        lý đối với đơn vị này.
      </p>
      <p>
        <span class="page-footer-intro-blod"> Địa chỉ kinh doanh:</span>
        Tòa nhà Leadenhall, 122 Leadenhall Street, London, Vương quốc Anh, EC3V
        4AB. Địa chỉ Email :cs@ebc.com . Số điện thoại : +44 20 3376 9662
        
      </p>
      <p>
        <span class="page-footer-intro-blod"
          >Tuyên bố miễn trừ trách nhiệm:</span
        >EBC Financial Group hoàn toàn không tham gia vào bất kỳ hoạt động nào
        liên quan đến tiền điện tử hoặc cung cấp các Hợp đồng Chênh lệch (CFDs)
        về Tiền điện tử. Dịch vụ tài chính của chúng tôi chỉ liên quan đến các
        công cụ truyền thống. Khách hàng được khuyến cáo rằng bất kỳ tham chiếu
        nào liên quan đến dịch vụ tiền điện tử sử dụng tên EBC đều không chính
        xác và không được ủy quyền. Hiệu suất trong quá khứ không phản ánh kết
        quả trong tương lai. Các thông tin được cung cấp trên trang web này chỉ
        mang tính chất tham khảo và không cấu thành lời khuyên đầu tư.
      </p>
      <p>
        <span class="page-footer-intro-blod">Các khu vực hạn chế:</span>EBC
        không cung cấp bất kỳ dịch vụ nào cho công dân và cư dân của một số khu
        vực pháp lý bao gồm: Afghanistan, Belarus, Myanmar (Burma), Canada, Cộng
        hòa Trung Phi, Congo, Cuba, Cộng hòa Dân chủ Congo, Eritrea, Haiti,
        Iran, Iraq, Lebanon, Libya, Malaysia, Mali, Bắc Triều Tiên (Cộng hòa Dân
        chủ Nhân dân Triều Tiên), Nga, Somalia, Sudan, Nam Sudan, Syria, Ukraine
        (bao gồm các khu vực Crimea, Donetsk, và Luhansk), Hoa Kỳ, Venezuela và
        Yemen.
        <br />
        Bất kỳ nội dung tiếng Tây Ban Nha nào trên trang web này chỉ dành cho
        khu vực LATAM (Châu Mỹ Latinh) và không áp dụng cho bất kỳ ai ở Liên
        minh Châu Âu hoặc Tây Ban Nha. Để biết thêm thông tin, vui lòng xem mục
        Câu hỏi thường gặp (FAQs) của chúng tôi
      </p>
      <p>
        <span class="page-footer-intro-blod">Công bố tuân thủ:</span>Trang web
        này có thể được truy cập trên phạm vi toàn cầu và không dành riêng cho
        bất kỳ tổ chức nào. Quyền và nghĩa vụ thực tế của bạn sẽ được xác định
        dựa trên tổ chức và khu vực pháp lý mà bạn chọn để được quản lý. Có thể
        có các luật và quy định địa phương cấm hoặc hạn chế quyền truy cập, tải
        xuống, phân phối, phát tán, chia sẻ hoặc sử dụng bất kỳ hoặc tất cả các
        tài liệu và thông tin được công bố trên trang web này.
      </p>
      <p>
        <span class="page-footer-intro-blod">Cảnh báo rủi ro:</span>Giao dịch
        Hợp đồng Chênh lệch (CFDs) là các công cụ tài chính phức tạp và tiềm ẩn
        rủi ro cao về việc mất tiền nhanh chóng do đòn bẩy. Giao dịch ký quỹ
        mang mức độ rủi ro cao và có thể không phù hợp với tất cả nhà đầu tư.
        Trước khi quyết định giao dịch Forex và CFDs, bạn cần cân nhắc cẩn thận
        mục tiêu giao dịch, mức độ kinh nghiệm và khả năng chấp nhận rủi ro của
        mình. Có khả năng bạn sẽ chịu lỗ một phần hoặc toàn bộ số vốn giao dịch
        ban đầu. Chúng tôi khuyến nghị bạn tìm kiếm lời khuyên độc lập và đảm
        bảo rằng bạn hiểu đầy đủ các rủi ro liên quan trước khi đưa ra bất kỳ
        quyết định đầu tư nào. Vui lòng đọc kỹ các tuyên bố tiết lộ rủi ro có
        liên quan trước khi giao dịch.
      </p>
  `,
};
