export default {
  linkList: [
    {
      title: "取引関連",
      list: [
        {
          label: "取引口座",
          link: "/TradingAccount",
        },
        {
          label: "取引商品",
          link: "/tradingproduct",
        },
        {
          label: "レバレッジ・証拠金",
          link: "/leverage-margin",
        },
        {
          label: "入出金",
          link: "/funding",
        },
        {
          label: "CFD配当金",
          link: "/dividend",
        },
      ],
    },
    {
      title: "取引ツール",
      list: [
        {
          label: "MT4 ダウンロード",
          link: "/MT4",
        },
        {
          label: "MT5 ダウンロード",
          link: "/MT5",
        },
        {
          label: "MT4 取引ツール",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "市場分析",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "EBC研究所",
      list: [
        {
          label: "学習センター",
          link: "/LearningCenter",
        },
        {
          label: "データセンター",
          link: "/lab",
        },
      ],
    },
    {
      title: "キャンペーン",
      list: [
        // 	{
        // 	"label": "トレーディングコンテスト",
        // 	"link": "/win"
        // },
        // {
        // 	"label": "最高の取引環境キャンペーン",
        // 	"link": "/no1"
        // },
        {
          label: "ハッピーパートナープログラム",
          link: "/cfa",
        },
      ],
    },
    {
      title: "EBCについて",
      list: [
        {
          label: "会社情報",
          link: "/about-us",
        },
        {
          label: "法的文書",
          link: "/LegalDocumen",
        },
        {
          label: "サステナビリティ",
          link: "/ESG",
        },
        {
          label: "よくある質問",
          link: "https://support.ebchelp.com/hc/ja-jp",
        },
        {
          label: "公式チャンネルの認証",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "パートナー",
      list: [
        {
          label: "パートナーシップ",
          link: "/affiliate-programmes",
        },
        {
          label: "流動性サービス",
          link: "/service",
        },
        {
          label: "フィンテック",
          link: "/technology",
        },
      ],
    },
  ],
  context: `
      <p>
        <span class="page-footer-intro-blod">
          EBC Financial
          Groupは、以下を含むグループ会社によって所有される共同ブランドです。<br />
        </span>
        EBC Financial Group (SVG)
        LLCはセントビンセント・グレナディーン金融庁（SVGFSA）の認可を受けています。
        会社登録番号：353 LLC 2020 登録事務所住所：Euro House, Richmond Hill
        Road, Kingstown, VC0100, St.Vincent and the Grenadines
        <br />
        EBC Financial Group (Comoros) Limited
        はコモロ連邦アンジュアン島でアンジュアンオフショア金融局（AOFA）の認可を受けています。
        ライセンス番号：L 15637/EFGC 登録事務所住所：Hamchako, Mutsamudu,
        Autonomous Island of Anjouan, Union of Comoros
      </p>
      <p>
        <span class="page-footer-intro-blod">関連法人：<br /></span>
        EBC Financial Group (UK)
        Ltdは、英国金融規制当局（FCA）の認可を受け、規制を遵守しています。
        登録番号: 927552 ウェブサイト：<a
          target="_blank"
          href="https://www.ebcfin.co.uk"
          rel="nofollow"
          >https://www.ebcfin.co.uk</a
        >
      <br />
        EBC Financial Group (Cayman)
        Ltdはケイマン諸島金融庁（CIMA）の認可を受け、規制を遵守しています。
        登録番号：2038223 ウェブサイト：
        <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow"
          >www.ebcgroup.ky</a
        >
        <br />
        EBC Group (Cyprus) Ltd.,はEBC Financial
        Group内のグループ会社に対する決済サービスを提供しており、キプロス共和国の会社法に基づいて登録されています。
        登録番号：HE 449205 登録事務所住所：101 Gladstonos, Agathangelou
        Business Centre, 3032 Limassol, Cyprus
        <br />
        EBC Financial Group (Australia) Pty Ltd (ACN: 619 073 237)
        はオーストラリア証券投資委員会（ASIC）の認可と規制を受けています（登録番号:
        500991）。EBC Financial Group (Australia) Pty Ltd は EBC Financial Group
        (SVG) LLC
        の関連会社です。両社は別々に管理・運営されています。本ウェブサイトで提供される金融商品およびサービスは、オーストラリア法人によって提供されるものではなく、オーストラリア法人に対するいかなる請求権も利用できません。
        
      </p>
      <p>
        <span class="page-footer-intro-blod"> 会社住所：</span>
        The Leadenhall Building, 122 Leadenhall Street, London, United Kingdom,
        EC3V 4AB. メールアドレス：cs@ebc.com 電話番号 : +44 20 3376 9662
      </p>
      <p>
        <span class="page-footer-intro-blod">免責事項：</span>EBC Financial
        Groupは、いかなる暗号通貨取引も提供しておらず、また暗号通貨の差金決済取引（CFD）も提供しておりません。当社の金融サービスは、従来の伝統的な商品のみを取引対象としています。当社の名称を使用し、暗号通貨取引を謳うサービスは当社のものではありません。過去の実績に関する言及は、将来の実績を示すものではありません。本ウェブサイトに含まれる情報は参考としてのみ提供されており、いかなる投資助言と見なされるべきではありません。
      </p>
      <p>
        <span class="page-footer-intro-blod">地域制限：</span
        >EBCは、以下を含む特定の地域の市民および居住者に対しては、いかなるサービスも提供しません：アフガニスタン、ベラルーシ、ミャンマー（ビルマ）、カナダ、中央アフリカ共和国、コンゴ、キューバ、コンゴ民主共和国、エリトリア、ハイチ、イラン、イラク、レバノン、リビア、マレーシア、マリ、北朝鮮（朝鮮民主主義人民共和国）、ロシア、ソマリア、スーダン、南スーダン、シリア、ウクライナ（クリミア、ドネツク、ルハンスク地域を含む）、米国、ベネズエラ、およびイエメン。詳しくはよくある質問をご覧ください。
      </p>
      <p>
        <span class="page-footer-intro-blod">コンプライアンス情報</span
        >本ウェブサイトは全世界からアクセス可能であり、特定の事業体に限定されるものではありません。お客様の実際の権利および義務は、お客様が選択された事業体および管轄区域の規制に基づいて決定されます。本ウェブサイトに掲載されている文書および情報の一部またはすべてに、お客様がアクセス、ダウンロード、配信、共有、またはその他の方法で使用する権利を制限または禁止する現地の法律および規制がある可能性があります。
      </p>
      <p>
        <span class="page-footer-intro-blod">リスクに関する警告</span
        >差金決済取引（CFD）は複雑な金融商品であり、レバレッジにより急速に資金を失う高いリスクがあります。証拠金取引は高いリスクを伴うため、すべての投資家に適していません。取引を行う前に、ご自身の取引目的、経験レベル、リスク許容度を慎重に検討する必要があります。取引資金の一部または全部を失う可能性があります。リスク管理においては専門家の助言を求め、関連するリスクを十分に理解してください。取引開始前に、リスク開示に関する説明をよくご覧ください。
      </p>
  `,
};
