export default {
    "box-class-name": "en-box",
    "lang": "en",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTrader 5 (MT5) يېڭى بىر ئەۋلاد كۆپ خىل مۈلۈك سودا سۇپىسى بولۇپ ، دۇنياۋى سودىگەرلەرنىڭ كۆپ خىل ئېھتىياجىنى قاندۇرۇش ئۈچۈن تېخىمۇ ئىلغار سودا ئىقتىدارلىرى ۋە تەھلىل قوراللىرىنى بىرلەشتۈرگەن.",
    "提供市场深度信息": "بازار چوڭقۇرلۇقى ئۇچۇرلىرى بىلەن تەمىنلەيدۇ ، شۇڭا سودىگەرلەر بازارنىڭ ھەرىكەتچانلىقىنى تېخىمۇ ياخشى چۈشىنەلەيدۇ.",
    "支持6种挂单交易类型": "ساقلىنىۋاتقان 6 خىل زاكاز سودىسىنى قوللاپ ، ھەر خىل سودا ئېھتىياجىنى جانلىق قاندۇرىدۇ.",
    "内置经济日历": "ئىچىگە ئورۇنلاشتۇرۇلغان ئىقتىسادىي كالىندار ، يەرشارىدىكى مۇھىم ئىقتىسادىي ۋەقەلەرنىڭ دەل ۋاقتىدا يېڭىلىنىشى.",
    "立即注册": "ھازىر تىزىملىتىڭ",
    "MetaTrader 5下载": "MetaTrader 5 چۈشۈرۈش",
    "MT5移动版": "MT5 كۆچمە نۇسخىسى",
    "随时随地进行交易": "ھەر ۋاقىت ، ھەر جايدا سودا قىلىڭ ، بازار يۈزلىنىشىنىڭ ئۈستىدە تۇرۇڭ ، ھېساباتنى ئاسان باشقۇرۇڭ.",
    "下载Android版": "ئاندروئىد نەشرىنى چۈشۈرۈڭ",
    "下载IOS版": "IOS نەشرىنى چۈشۈرۈڭ",
    "下载iPad版": "IPad نەشرىنى چۈشۈرۈڭ",
    "MT5桌面版": "MT5 ئۈستەل يۈزى نۇسخىسى",
    "全面的交易工具和高级图表分析": "ئۇنىۋېرسال سودا قوراللىرى ۋە ئىلغار جەدۋەل ئانالىزى كەسپىي سودىگەرلەرنىڭ ئىلغار ئېھتىياجىنى قاندۇرىدۇ.",
    "下载Windows版": "Windows نەشرىنى چۈشۈرۈڭ",
    "下载MacOS版": "MacOS نەشرىنى چۈشۈرۈڭ",
    "MT5网页版": "MT5 تور نەشرى",
    "无需下载": "چۈشۈرۈشنىڭ ھاجىتى يوق ، دۇنيا بازىرىغا تېز كىرىش ۋە تور سودىسىنى ئەتراپلىق باشقۇرۇشنى ئەمەلگە ئاشۇرۇش ئاسان.",
    "打开网页版": "تور نەشرىنى ئېچىڭ",
    "MT4与MT5的区别": "MT4 بىلەن MT5 نىڭ پەرقى",
    "MT4和MT5均由MetaQuotes开发": "MT4 ۋە MT5 ھەر ئىككىسى MetaQuotes تەرىپىدىن ياسالغان ، ئەمما MT5 MT4 نىڭ ئاددىي يېڭىلانغان نۇسخىسى ئەمەس.",
    "项目": "تۈر",
    "发布时间": "قويۇپ بېرىش ۋاقتى",
    "2005年": "2005",
    "2010年": "2010",
    "交易市场": "سودا بازىرى",
    "主要是衍生品": "ئاساسلىقى تاشقى پېرېۋوت",
    "包括股票": "پاي چېكى ، قەرەللىك مال ، تاشقى پېرېۋوت قاتارلىقلارنى ئۆز ئىچىگە ئالىدۇ.",
    "挂单类型": "ساقلىنىۋاتقان زاكاز تىپى",
    "4种": "4 خىل",
    "6种": "6 خىل",
    "时间框架": "ۋاقىت چەكلىمىسى",
    "9种": "9 خىل",
    "21种": "21 خىل",
    "技术指标": "تېخنىكىلىق كۆرسەتكۈچ",
    "30个": "30",
    "38个": "38",
    "市场深度":"بازار چوڭقۇرلۇقى",
    "不支持":"قوللىمايدۇ",
    "支持":"قوللاش",
    "财经日历":"مالىيە كالىندارى",
    "内置":"ئىچىگە ئورۇنلاشتۇرۇلغان",
    "操作界面":"مەشغۇلات كۆرۈنمە يۈزى",
    "更直观简单":"تېخىمۇ بىۋاسىتە ۋە ئاددىي",
    "更加现代化灵活":"تېخىمۇ زامانىۋى ۋە جانلىق"
}