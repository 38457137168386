export default {  
  tradingView: {  
    tvlang: 'vi', 
    tvBodyClass: 'en', 

    // 通用文本  
    disclaimer: 'EBC giữ quyền giải thích cuối cùng cho chương trình này',  
    registerNow: 'Đăng ký giao dịch ngay tại EBC',  
    paidBenefits: 'Quyền lợi thành viên cao cấp',  
    getItNow: 'Đăng ký ngay',  
    getItFree: 'Nhận miễn phí',  
    depositToGet: 'Nạp tiền và nhận ưu đãi ngay',  

    icon15: require('@/assets/image/TradingView/TradingView-icon15_vi.svg'),  

    // 顶部权益  
    benefits: {  
      analysis: 'Phân tích thị trường chuyên nghiệp',  
      charts: 'Công cụ biểu đồ mạnh mẽ',  
      orderFlow: 'Tặng kèm báo cáo luồng lệnh chi tiết'  
    },  

    // 第二部分 - 领取步骤   
    steps: {  
      title: 'Hướng dẫn nhận ưu đãi',  
      list: [  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
          title: 'Đăng ký ngay',  
          desc: 'Hoàn tất đăng ký trên trang web chính thức của EBC<br>Và khởi đầu hành trình giao dịch thành công'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),  
          title: 'Ưu đãi nạp tiền',  
          desc: 'Net Deposit 1000 USD hoặc 10.000 USD trong một tháng<br>Nhận ngay gói thành viên miễn phí có giới hạn!'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
          title: 'Bắt đầu sử dụng',  
          desc: 'Liên hệ với đội ngũ hỗ trợ khách hàng của EBC để nhận quyền lợi thành viên cao cấp của TradingView'  
        }  
      ]  
    },  

    // 第3部分 - 市场动态  
    section3: {  
      title: 'Giao dịch tại EBC với TradingView – Làm chủ thị trường toàn cầu',  
      desc: 'Kết nối với hàng trăm nguồn dữ liệu và truy cập hơn 2.019.615 công cụ từ các đối tác dữ liệu chuyên nghiệp, cung cấp thông tin tiên tiến được các nhà đầu tư hàng đầu sử dụng.',  
      slides: [  
        {  
          image: require('@/assets/image/TradingView/TradingView-img3.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
          title: 'Dữ liệu thị trường theo thời gian thực và biểu đồ chuyên nghiệp',  
          desc: 'Nắm bắt thị trường toàn cầu, giúp bạn không bỏ lỡ bất kỳ cơ hội nào để tối ưu lợi nhuận'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img4.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
          title: 'Các chỉ báo kỹ thuật và chiến lược toàn diện',  
          desc: 'Mở khóa các chỉ báo kỹ thuật mạnh mẽ, xây dựng chiến lược giao dịch phù hợp với nhu cầu của bạn'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img5.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
          title: 'Giao dịch linh hoạt trên mọi thiết bị',  
          desc: 'Đồng bộ giao dịch trên máy tính và điện thoại, dễ dàng quản lý mọi lúc, mọi nơi'  
        }  
      ]  
    },  

    // 第4部分 - 会员计划  
    section4: {  
      title: 'Nạp tiền và nhận ngay thành viên cao cấp TradingView',  
      desc: 'Bạn còn chờ gì nữa? Đừng bỏ lỡ cơ hội giao dịch hiệu quả. Hãy mở tài khoản tại EBC ngay hôm nay và nhận ngay ưu đãi thành viên cao cấp TradingView để khởi đầu hành trình giao dịch thành công',  
      plans: [  
        {  
          isPremium: false,  
          title: 'Thành viên Essential',  
          requirement: 'Net Deposit 1.000 USD để nhận miễn phí',  
          features: [  
            { text: '2 biểu đồ trên mỗi tab', enabled: true },  
            { text: '5 chỉ báo trong mỗi biểu đồ', enabled: true },  
            { text: '10N thanh lịch sử', enabled: true },  
            { text: '20 cảnh báo giá', enabled: true },  
            { text: '20 cảnh báo kỹ thuật', enabled: true },  
            { text: '10 kết nối biểu đồ đồng thời', enabled: true },  
            { text: 'Không có quảng cáo', enabled: true },  
            { text: 'Hồ sơ Khối lượng giao dịch', enabled: true },  
            { text: 'Khung thời gian tùy chỉnh', enabled: true },  
            { text: 'Tùy chỉnh thanh phạm vi', enabled: true },  
            { text: 'Nhiều danh sách theo dõi', enabled: true },  
            { text: 'Thanh phát lại', enabled: true },  
            { text: 'Chỉ báo trên chỉ báo', enabled: true },  
            { text: 'Trích xuất dữ liệu biểu đồ', enabled: false },  
            { text: 'Biểu đồ Renko trong ngày, Kagi, Đường gãy, Điểm & Dữ liệu', enabled: false },  
            { text: 'Biểu đồ dựa trên công thức tùy biến', enabled: false },  
            { text: 'Cơ hội Giá theo Thời gian', enabled: false },  
            { text: 'Dấu chân khối lượng', enabled: false },  
            { text: 'Mô hình Biểu đồ Tự động', enabled: false },  
            { text: 'Cảnh báo theo giây', enabled: false },  
            { text: 'Cảnh báo không bị hết hạn', enabled: false },  
            { text: 'Xuất bản tập lệnh chỉ dành cho người nhận được lời mời', enabled: false },  
            { text: 'Khoảng thời gian tính theo giây', enabled: false }  
          ]  
        },  
        {  
          isPremium: true,  
          title: 'Thành viên Premium',  
          requirement: 'Net Deposit 10.000 USD để nhận miễn phí',  
          features: [  
            { text: '8 biểu đồ trên mỗi tab', enabled: true },  
            { text: '25 chỉ báo trong mỗi biểu đồ', enabled: true },  
            { text: '20N thanh lịch sử', enabled: true },  
            { text: '400 cảnh báo giá', enabled: true },  
            { text: '400 cảnh báo kỹ thuật', enabled: true },  
            { text: '50 kết nối biểu đồ đồng thời', enabled: true },  
            { text: 'Không có quảng cáo', enabled: true },  
            { text: 'Hồ sơ Khối lượng giao dịch', enabled: true },  
            { text: 'Khung thời gian tùy chỉnh', enabled: true },  
            { text: 'Tùy chỉnh thanh phạm vi', enabled: true },  
            { text: 'Nhiều danh sách theo dõi', enabled: true },  
            { text: 'Thanh phát lại', enabled: true },  
            { text: 'Chỉ báo trên chỉ báo', enabled: true },  
            { text: 'Trích xuất dữ liệu biểu đồ', enabled: true },  
            { text: 'Biểu đồ Renko trong ngày, Kagi, Đường gãy, Điểm & Dữ liệu', enabled: true },  
            { text: 'Biểu đồ dựa trên công thức tùy biến', enabled: true },  
            { text: 'Cơ hội Giá theo Thời gian', enabled: true },  
            { text: 'Dấu chân khối lượng', enabled: true },  
            { text: 'Mô hình Biểu đồ Tự động', enabled: true },  
            { text: 'Cảnh báo theo giây', enabled: true },  
            { text: 'Cảnh báo không bị hết hạn', enabled: true },  
            { text: 'Xuất bản tập lệnh chỉ dành cho người nhận được lời mời', enabled: true },  
            { text: 'Khoảng thời gian tính theo giây', enabled: true }  
          ]  
        }  
      ]  
    },  

    // 第5部分 - 新客福利  
    section5: {  
      title: 'Ưu đãi đặc biệt dành riêng cho khách hàng mới<br>Giúp bạn nâng cao cơ hội giao dịch thành công',  
      desc: 'Báo cáo dòng lệnh chi tiết hàng ngày từ CME: Theo dõi hành động của nhà đầu tư lớn, gia tăng lợi thế giao dịch.',  
      desc2: 'Phân tích thị trường từ chuyên gia Trading Central: Giúp bạn nắm bắt cơ hội trên thị trường một cách hiệu quả.',  
      desc3: 'Dữ liệu kinh tế vĩ mô thời gian thực từ 38 quốc gia và khu vực: Hỗ trợ bạn theo dõi, dự báo và ứng phó với các biến động thị trường bất ngờ.',  
      images: [  
        require('@/assets/image/TradingView/TradingView-img7.png'),  
        require('@/assets/image/TradingView/TradingView-img8.png'),  
        require('@/assets/image/TradingView/TradingView-img9.png')  
      ]  
    },  

    // 第6部分 - 荣誉展示  
    section6: {  
      title: 'Nạp tiền ngay để cùng EBC khởi đầu chiến thắng',  
      desc: 'EBC Financial Group – Đối tác toàn cầu đáng tin cậy của bạn'  
    }  
  }  
}