export default {
	"official-class-name": "official-en-box",
	"lang": "en",
	"official-page1": {
		"text1": "Official Channel Verification",
		"text2": "In order to prevent unauthorised parties from profiting by misusing the name of EBC, you may check the authenticity of the channel with this page.",
		"text3": "Please enter the complete URL of the page",
		"text4": "Verify",
	},
	"official-page2": {
		"text1": "EBC Official Statement:",
		"text2": "1. Currently, EBC only offers official MT4 and MT5 trading software, please download it from the official website.",
		"text3": "2. EBC does not engage in cryptocurrency trading business nor provide any CFD products related to cryptocurrency.",
		"text4": "3. EBC does not offer investment advice, make any promises or guarantees regarding trading profits.",
		"text5": "4. We will take immediate legal action to severely punish any organisation or individual found misusing the name of EBC.",
		"text6": "5. We are committed to safeguarding our clients' rights. If you notice any suspicious activities, please submit evidence to our client support team or contact us via email at<a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a>. We will respond promptly and provide the necessary legal support and assistance to the victims.",
	},
	"official-page3": {
		"text1": "The inquired information is officialy verified",
		"text2": "Back",
		"text3": "The inquired information is not officially verified, please do not interact with the unverified information channel",
		"text4": "Report",
		"text5": "If you believe that this information is false or misappropriated, please submit a report to us",
		"text6": "Report",
		"text7": "URL",
		"text8": "*If you need to acquire legal assistance, please ",
		"text8-1": "click here to leave your message.",
		"text8-2": "",
		"text9": "Close message board",
		"text10": "Full Name / Nick Name",
		"text11": "Please enter full name / nick name",
		"text12": "Email",
		"text13": "Please enter your email",
		"text14": "Phone",
		"text15": "Please enter your phone number",
		"text16": "Information feedback",
		"text17": "Please fill in the information feedback",
		"text18": "Verifiication code",
		"text19": "Please enter verifiication code",
		"text20": "Cancel",
		"text21": "Submit repot",
		"text22": "Your message is submitted. Thank you.",
	},
}