export default {
  fontFamily: "cn-font-family",
  classLang: "cn",
  headlist: [
    {
      text: "首頁",
      url: "/",
    },
    {
      text: "交易",
      menu: [
        {
          tit: "帳戶及條件",
          list: [
            {
              text: "交易賬戶",
              url: "/TradingAccount",
            },
            {
              text: "交易產品",
              url: "/tradingproduct",
            },
            {
              text: "槓桿保證金",
              url: "/leverage-margin",
            },
            {
              text: "出入金",
              url: "/funding",
            },
            {
              text: "股息",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "交易工具",
          list: [
            {
              text: "MT4下載",
              url: "/MT4",
            },
            {
              text: "MT5下載",
              url: "/MT5",
            },
            {
              text: "MT4交易工具",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "市場分析",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "EBC研究院",
      menu: [
        {
          tit: "",
          list: [
            {
              text: "學習中心",
              url: "/LearningCenter",
            },
            {
              text: "資料中心",
              url: "/lab",
            },
          ],
        },
      ],
    },
    {
      text: "最新活動",
      url: "/activity",
    },
    {
      text: "關於EBC",
      menu: [
        {
          tit: "關於我們",
          list: [
            {
              text: "關於EBC",
              url: "/about-us",
            },
            {
              text: "社會責任",
              url: "/ESG",
            },
            {
              text: "幫助中心",
              url: "https://support.ebchelp.com/hc/zh-tw",
            },
          ],
        },
        {
          tit: "合作夥伴",
          list: [
            {
              text: "影響力聯盟",
              url: "/affiliate-programmes",
            },
            {
              text: "機構服務",
              url: "/service",
            },
            {
              text: "底層科技",
              url: "/technology",
            },
          ],
        },
      ],
    },
    {
      text: "跟單社區",
      url: "/from0tohero",
    },
  ],
  FCA开户: "FCA開戶",
  立即开户: "立即開戶",
  登录: "登入",
  官方验证: "官方驗證",
  帮助中心: "幫助中心",
  帮助中心Url: "https://support.ebchelp.com/hc/zh-tw",
};
