export default {
  "screen0": {
    "enTitle": "Your Trust,Our Protection",
    "title": "安全な取引のためのEBCの万全のセキュリティ対策",
    "text": "EBCではすべてのお客様がいつでも安心、安全に入出金やお取引ができるよう、万全のセキュリティ対策を施しています。利益や初期投資資金はいつでも出金可能です。<br /><br /> 業界最高水準の暗号化技術と二段階認証によって、万が一、悪意の第三者から不正アクセスがあった場合でもセキュリティ認証が不正ログインをブロックし被害を未然に防ぎます。",
    "rightText": "人生をよりよくするチャンスは<span>目の前です<span>"
  },
  "screen1": {
    "smallEntTitle": "Multiple Deposit Methods",
    "smallTitle": "幅広い入金方法",
    "title": "UnionPay（銀聯）デビットカード、電信送金、PayPalなど幅広い入金方法が利用可能",
    "desc": "お客様が取引に必要な資金をより多く確保できるよう、EBCは取引口座の入出金にかかる手数料を一切頂いておりません",
    "list": ["入金方法", "最低入金", "入金時間", "最低出金", "支払通貨", "手数料", "出金処理時間"],
    "summary": "注意事項:",
    "btn": "入金を開始",
    "imgUrl": "ja",
    "summaryDesc": "<p>1.入金完了までの日数は銀行などによって異なります。</p><p>2.入金可能な限度額は、お客様の銀行およびカード発行会社の規則によって異なる場合があります。</p><p>3.出金申請時に、口座でポジションを保有されている場合は、ポジションに影響が出ないよう出金額にご注意ください。</p>"
  },
  "screen2": {
    "title": "世界に100万人いるトレーダーの仲間入りをしよう",
    "desc": "たったの3ステップで、安全に口座開設が完了します",
    "list": [{
      "title": "アカウント登録",
      "desc": "オンラインで安全に基本情報を入力します"
    }, {
      "title": "個人情報提出",
      "desc": "最短1分で口座が開設できます"
    }, {
      "title": "口座の有効化",
      "desc": "200米ドル以上の入金で口座が有効になります"
    }],
    "btn1": "デモ口座開設",
    "btn2": "ライブ口座開設",
    "yuyan": "ja"
  },
  "screen3": {
    "title": "入出金に関するよくある質問",
    "faqList": [{
        "title": "口座に入金する方法を教えてください。",
        "desc": "口座開設後、Webサイトから支払い設定にアクセスします。マイページにログインし、画面左にある緑色の「入金」ボタンをクリックすると入金できます<br/>操作手順：<br/>1.入金先口座を選択する画面が表示されます。<br/>2.支払い方法一覧の右にあるボタンで支払い方法を選択します。<br/>3.口座に入金する金額を入力します。<br/>ご利用条件をすべてよくお読みいただき、同意する場合は必ず<br/>「すべての説明を読み、支払い操作の条件に同意します」にチェックを入れてください。<br/>【クリックして提出する】"
      },
      {
        "title": "クレジットカードでの入金に失敗したのはなぜですか？",
        "desc": "クレジットカードでの入金に失敗した場合はカード情報が正しく入力されていることをご確認ください。<br/>有効期限が切れていないなど、有効なカードであることをご確認ください。<br/>十分な利用残高があることをご確認ください。<br/>上上記で問題がない場合は再度ご入金を試してください。それでも入金ができない場合、クレジットカードの使用が制限されている可能性があります。他のクレジットカード、または別の支払い方法をお試しください。"
      },
      {
        "title": "口座から資金を引き出すにはどうしたら良いですか？",
        "desc": "いつでも出金ができます。 毎日出金リクエストに対応しています。ご指定の銀行口座に出金されます。 このオプションが利用できない場合、資金は顧客のEBC口座に自動的に返されます。 ご了承ください。銀行振込による出金については、各銀行の実際の処理時間を参照してください。 各銀行の到着時間は異なりますので、詳しくは各銀行にお問い合わせください。ポジションを保有中に出金する場合は、出金金額を差し引いた後も取引口座の前払い比率が 200% より高いことを確認してください。そうでない場合、プラットフォームは出金リクエストを処理できません。"
      },
      {
        "title": "EBCでは手数料かかりますか？",
        "desc": "EBCでは入出金に一切手数料がかかりません。ただし、一部の仲介銀行との間で行われる支払いについては、仲介銀行に手数料が発生する場合がありますので改めてご了承ください。また、お客様の銀行がEBCへの支払いをキャッシングとして扱い、お客様のキャッシング規定に従って請求する場合もありますのでご注意ください。"
      },
      {
        "title": "他人の銀行カードで入出金できますか？",
        "desc": "できません。EBCは第三者からのお支払いを受け付けておりません。取引口座へのご入金は、必ずお客様ご本人名義の口座からお願いします。取引口座の名義人が共同名義人の場合は、共同名義人の口座からのお支払いが受け付けできます。第三者による資金提供が疑われる場合、EBCは資金を全額返却し、本人確認および資金確認が済むまで口座を凍結する権利を有しています。ご確認が取れない場合、口座残高を引き出すことができなくなります。EBCは原則として第三者からの出金には対応しません。その他、ここに記載している事柄以外のご質問がございましたら、以下までご連絡ください。"
      }
    ]
  },
  "funding1":{
		"text1": "入出金方法のご案内",
		"text2": "万全な資金セキュリティで、安心して取引いただきます。",
		"btn": "今すぐ入金",
	},
	"funding2":{
		"text1": "様々な通貨に対応",
		"text2": "主要通貨による入出金を24時間いつでもご利用いただけます。",
	},
	"funding3":{
		"text1": "資金の分別管理と独立保管",
		"text2": "バークレイズ銀行の法人口座",
		"text3": "EBCは、最低650万ポンドの売上高、厳格な財務監査および身元調査を必要とするバークレイズ法人口座を有しています。CASSに従い、EBCグループ（英国）は顧客資金を信託書簡を通じてカストディ口座に保管し、安全性を確保しています。",
		"btn": "詳細はこちら",
	},
	"funding4":{
		"text1": "専門職業賠償責任保険",
		"text2": "顧客資産を保護するための万全な体制を構築。専門職業賠償責任保険により顧客資産の損失を最小限に抑えています。",
	},
	"funding5":{
		"text1": "入出金方法",
		"text2": "現在、国内銀行送金、USDT（help2payでのお支払い）、STICPAY、VISA及びMastercardのクレジットカード/デビットカードをご利用頂けます。今後、新たな入出金方法を順次拡大予定です。",
		"text3": "入金方法",
		"text4": "ご利用可能通貨",
		"text5": "入金限度額",
		"text6": "入金反映時間",
		"text7": "出金処理時間",
		"text8": "現地銀行",
		"text9": "即時",
		"text10": "1 営業日",
		"text11": "国際電信送金",
		"text12": "1000-100000",
		"text13": "2 ～ 5 営業日",
		"text14": "電子ウォレット",
		"text15": "デジタル通貨",
	}
}
