<template>
	<section class="tp-new-section11">
          <div class="form-box contact-us">
            <p :class="`p1 ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{$t('CFooters.contact.title')}}</p>
                <div class="form-group">  
                    <input type="text" class="input-field" id="name" name="name" v-model="infoData.name" :placeholder="$t('CFooters.contact.name')">  
                    <div class="gender-selector">  
                        <button type="button" class="gender-btn select-label" v-for="(item, index) in $t('CFooters.contact.sexList')" :class="{'select-label--active': sexIndex === index}"
						@click="changeSix(index)">{{item.label}}</button> 
                    </div>  
                </div>  
                <div class="form-group">  
                    <input type="email" id="phone-email" name="email" v-model="infoData.call" class="input-field" :placeholder="$t('CFooters.contact.email')">  
                </div>
                <div class="form-group">  
                    <div class="captcha-container">  
                        <input id="verify" v-model="code" type="text" class="input-field" :placeholder="$t('CFooters.contactUs.code')">  
                        <div class="verify-code" @click="refreshCode"><ss-code :identifyCode="identifyCode"></ss-code></div>
                    </div>  
                </div>
                <button type="submit" class="contact-submit" id="contact-submit" @click="getContact">{{$t('CFooters.contact.btn')}}</button>  
        </div>
      </section>
</template>

<script>
	import axios from 'axios'
	import md5 from 'js-md5';
	export default {
		props:{
			mode: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				sexIndex: 0,
				infoData: {
					call: '',
					name: ''
				},
				code: null,
				identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
				identifyCode: "", //验证码图片内容
			}
		},
		mounted() {
			// 初始化验证码
			this.identifyCode = "";
			//参数：（1）随机串内容。（2）验证码显示位数
			this.makeCode(this.identifyCodes, 4);
		},
		methods: {
			// 重置验证码
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
			},
			//获取验证码的值
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					//通过循环获取字符串内随机几位
					this.identifyCode +=
						this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
				}
			},
			//随机数字：用于当角标拿字符串的值
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min);
			},
			getContact() {
				let infoData = {
					...this.infoData
				}
				if (this.identifyCode != this.code) {
					this.code = null
					return this.$toast(this.$t('CFooters.contact.tips4'));
				}
				if (!infoData.name) return this.$toast(this.$t('CFooters.contact.tips1'));
				if (!infoData.call) return this.$toast(this.$t('CFooters.contact.tips2'));
				let checksum = md5(infoData.call + "YISENCE")
				let parms = 'name=' + infoData.name + '&sex=' + this.$t('CFooters.contact.sexList')[this.sexIndex].label +
					'&mobile=' + infoData.call + '&checksum=' + checksum
				let time = new Date().getMilliseconds()
				let checkSum1 = md5(infoData.name + infoData.call + time + "YISENCE")
				this.$http('/ebc/api/setContact/run', {
					name: infoData.name,
					contact: infoData.call,
					date: time,
					checkSum: checkSum1
				}).then(res => {
					this.$toast(this.$t('CFooters.contact.tips3'))
					this.code = null
					this.infoData = {
						call: '',
						name: ''
					}
				})
				// axios.get('https://apinew.yisence.net/API/Yisenceebcmail.aspx?' + parms).then(res => {
				// 	this.$toast(this.$t('CFooters.contact.tips3'))
				// })
			},
			changeSix(index) {
				this.sexIndex = index
			},
		}
	}

</script>

<style lang="scss">
	.tp-new-section11{
  padding: 120rem 0;
  background-image: url('../../assets/image/trading-product/section11-1.jpg');  
  background-position: center;  
  background-size: cover;
  background-repeat: no-repeat; 
}

 
.tp-new-section11 .p1{
  color: #FFF;
  text-align: center;
  font-size: 52rem;
  font-weight: 700;
  line-height: 72rem;
  letter-spacing: 6.4rem;
  margin-bottom: 80rem;
}
.tp-new-section11 .form-box{
  width: 650rem;
  margin: 0 auto;
}
.tp-new-section11 .form-group {  
    margin-bottom: 25rem;  
    position: relative;
}  

.tp-new-section11 .input-field {  
    width: 100%;  
    padding: 10rem 0;  
    background: transparent;  
    border: none;  
    border-bottom: 1px solid rgba(255,255,255,0.6);  
    color: white;  
    font-size: 16rem;  
    outline: none;  
}  

.tp-new-section11 .gender-selector {  
    display: flex;  
    gap: 15rem;  
    margin-top: 5rem;  
    position: absolute;
    right: 0;
    top: -4rem;
}  

.tp-new-section11 .gender-btn {  
    padding: 5rem 15rem;  
    background: rgba(255,255,255,0.1);  
    border: 1px solid rgba(255,255,255,0.3);  
    border-radius: 5rem;  
    color: white;  
    cursor: pointer; 
    font-size: 16rem; 
}  

.tp-new-section11 .gender-btn.select-label--active {  
    background: #9F734C;  
    border-color: #9F734C; 
} 

.tp-new-section11 .captcha-container {  
    display: flex;  
    gap: 10trm;  
    align-items: center;  
}  

.tp-new-section11 .captcha-code {  
    padding: 5rem 10rem;  
    background: rgba(255,255,255,0.9);  
    color: #333;  
    border-radius: 4rem;  
}  

.tp-new-section11 .contact-submit {  
    width: 100%;  
    padding: 12rem;  
    background: rgba(168, 45, 45, 0.8);  
    border: none;  
    border-radius: 5rem;  
    color: white;   
    font-size: 16rem;  
    cursor: pointer;  
    transition: background 0.3s;
    margin-top: 46rem;  
}  

.tp-new-section11 .contact-submit:hover {  
    background: rgba(168, 45, 45, 1);  
}  

.tp-new-section11 ::placeholder {  
    color: rgba(255,255,255,0.6);  
} 

.en-trading-body .tp-new-section11 .form-box{
  width: 800rem;
}

.ar-trading-body .tp-new-section11 .gender-selector{
  left: 0;
  right: unset;
}

@media (max-width: 996px) {
	.tp-new-section11 .p1{
    font-size: 28px;
    line-height: 1.4;
  }
  .tp-new-section11 .input-field{
    font-size: 14px;
    padding: 10px 0;
  }
  .tp-new-section11 .gender-btn{
    font-size: 14px;
  }
  .tp-new-section11 .contact-submit{
    font-size: 16px;
    padding: 12px;
  }
  .tp-new-section11 .form-group{
    margin-bottom: 22px;
  }
  .tp-new-section11 .gender-selector{
    top: 4px;
  }
  .tp-new-section11 .form-box{
    width: 82%;
    padding-bottom: 0px;
  }
  .tp-new-section11{
    padding: 70px 0;
  }
  .en-trading-body .tp-new-section11 .form-box{
    width: 800rem;
  }
   .en-trading-body .tp-new-section9-item {
        padding: 60px 0;
    }
    .en-trading-body .tp-new-section11 .form-box {
        width: 82%;
    }
}

</style>

