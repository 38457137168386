export default {
  多元活动连接全球市场: "世界中で様々なイベントを実施",
  共享全球顶级资源与学术支持:
    "世界トップクラスのリソースとアカデミック・サポートを使い、自信を持って取引を始めましょう。",
  了解更多: "詳細はこちら",
  敬请期待: "乞うご期待！",
  每个认真交易的人: "すべてのトレーダーに、誠実な対応を。",
  activityListMetaData: [
    {
      label: "キャンペーン",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_en.png"),
          title: "第2回EBC100万ドル取引大会",
          subtitle: "参加者の取引シグナルをコピー可能",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        {
          id: "2-7",
          img:require("@/assets/image/activityList/banner2_7_jp.png"),
          title: "TradingView会員特典期間限定キャンペーン",
          subtitle: "グローバル市場の動向をリアルタイムで把握できるTradingViewのプレミアム会員機能を無料体験！",
          url: "/TradingView",
          time: "",
        },
        

        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_jp.png"),
          title: "ハッピーパートナープログラム",
          subtitle:
            "オリジナルな金融コンテンツを作ることで、様々な報酬が得られます。",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-1",
          img: require("@/assets/image/activityList/banner2_1_jp.png"),
          title: "EBC 100万ドル取引大会",
          subtitle: "勝者には賞金100万ドルを進呈します。",
          url: "/win",
          time: "",
        },
      ],
    },
    {
      label: "イベント",
      list: [
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_jp.png"),
          title: "メキシコで開催されるMoney Expo 2025に出展",
          subtitle:
            "EBCは、メキシコで開催されるMoney Expo 2025にプラチナスポンサーとして出展いたします。トップクラスの取引イベントであるMoney Expo 2025のブース48にて、皆様にお会いできるのを楽しみにしています。",
          url: "",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_jp.png"),
          title: "気候経済学に基づいて投資チャンスを発見する方法",
          subtitle: `「経済学者の研究公開」で政策改善と経済成長を推進するためのタイムリーな議論をご視聴いただけます。`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_jp.png"),
          title: "EBCとオックスフォード大学との共催セミナー",
          subtitle: `本セミナーは2024年11月14日23時30分（日本時間）にオックスフォード大学クライスト・チャーチ・カレッジにて開かれ、オンラインでも世界中の投資家に中継されました。`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
