<template>
	<div class="win">
		<div class="win-page1">
			<div class="page1-box">
				<div class="content">
					<router-link to="/"><img src="../assets/image/TC/logo1.png" alt="" class="logo"></router-link>
					<div class="page1-box__h1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						<img src="../assets/image/TC/tc-10.png" alt="">
					</div>
					<div class="page1-box__h1" v-else-if="lang === 'weiwuer'">
						<img src="../assets/image/TC/tc-15.png" alt="">
					</div>
					<div class="page1-box__h1" v-else>
						<img src="../assets/image/TC/tc-11.png" alt="">
					</div>

					<div class="page1-box__tips">
						<div class="tips-box" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							悬赏百万美金，寻找交易之王
						</div>
						<div class="tips-box" v-else-if="lang === 'weiwuer'">
							بىر مىليون ئامېرىكا دوللارمۇكاپات، سودا شاھىنى ئىزدەش
						</div>
						<div class="tips-box" v-else>
							EBC one millio dollar challenge,one million dollar for the king of trading
						</div>
					</div>
					<div class="page1-box__card flex">
						<div class="page1-box__card__pag flex flex-colum">
							<div class="name flex align-center">
								<div class="iconfont">
									&#xe653;
								</div>
								<div class="" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									一键跟单
								</div>
								<div v-else>
									One-click trading
								</div>
							</div>
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								百万奖金得主为你操盘<br />免费复制冠军收益！
							</div>
							<div class="desc" v-else>
								The million-dollar winner trades for your reference.
							</div>
						</div>
						<div class="page1-box__card__pag flex flex-colum">
							<div class="name flex align-center">
								<div class="iconfont">
									&#xe653;
								</div>
								<div class="" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									公开透明
								</div>
								<div class="" v-else>
									Fair and transparent
								</div>
							</div>
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								观摩参赛者每笔订单<br />学习冠军交易技巧！
							</div>
							<div class="desc" v-else>
								Follow competitor’s each order to learn trading techniques.
							</div>
						</div>
					</div>
					<div class="page1-box__desc flex flex-colum">
						<div class="page1-box__desc__pag flex align-center">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								一键跟单
							</div>
							<div v-else>
								One-click trading
							</div>
							<div class="ps" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								免费复刻冠军收益!
							</div>
							<div class="ps" v-else>
								Free copy of champion profits
							</div>
						</div>
						<div class="page1-box__desc__pag flex align-center">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								公开透明
							</div>
							<div class="" v-else>
								Fair and transparent
							</div>
							<div class="ps" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								观摩学习交易技巧
							</div>
							<div class="ps" v-else>
								Watch and learn trade skills
							</div>
						</div>
					</div>


					<div class="page1-box__btn">
						<a class="win-btn-box" href="https://client.ebccrm.com/signup" target="_blank"
							v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							立即报名 >
						</a>
						<a class="win-btn-box" href="https://client.ebccrm.com/signup" target="_blank"
							v-else-if="lang === 'weiwuer'">
							دەرھال تىزىملىتىڭ. >
						</a>
						<a class="win-btn-box" href="https://client.ebccrm.com/signup" target="_blank" v-else>
							Join Now >
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="win-title flex align-center">
				<div class="iconfont">
					&#xe653;
				</div>
				<div class="name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					奖项设置
				</div>
				<div class="name" v-else>
					Rewards
				</div>
				<div class="flex-1 win-line">

				</div>
			</div>
			<div class="win-page2 flex">
				<div class="win-page2__pag flex flex-colum flex-1">
					<div class="win-page2__pag__tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						冠军
					</div>
					<div class="win-page2__pag__tit" v-else>
						Champion  
					</div>
					<div class="win-page2__pag__desc flex flex-colum">
						<div class="desc-li flex" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							<div class="desc-li__sum">
								$1,000,000
							</div>
							交易账户
						</div>
						<div class="desc-li flex" v-else>
							<div class="desc-li__sum">
								$1,000,000
							</div>
							Account Balance
						</div>
						<div class="desc-li flex" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							<div class="desc-li__sum">
								$200,000
							</div>
							现金奖励
						</div>
						<div class="desc-li flex" v-else>
							<div class="desc-li__sum">
								$200,000
							</div>
							Cash Reward
						</div>
					</div>
				</div>
				<div class="win-page2__pag flex flex-colum flex-1">
					<div class="win-page2__pag__tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						亚军
					</div>
					<div class="win-page2__pag__tit" v-else>
						Runner-up 
					</div>
					<div class="win-page2__pag__desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						<div class="desc-li flex">
							<div class="desc-li__sum">
								$30,000
							</div>
						</div>
						<div class="desc-li flex">
							现金奖励
						</div>
					</div>
					<div class="win-page2__pag__desc" v-else>
						<div class="desc-li flex">
							<div class="desc-li__sum">
								$30,000
							</div>
						</div>
						<div class="desc-li flex">
							Cash Reward
						</div>
					</div>
				</div>
				<div class="win-page2__pag flex flex-colum flex-1">
					<div class="win-page2__pag__tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						第三至五名
					</div>
					<div class="win-page2__pag__tit" v-else>
						3rd – 5th 
					</div>
					<div class="win-page2__pag__desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						<div class="desc-li flex">
							<div class="desc-li__sum">
								$10,000
							</div>
						</div>
						<div class="desc-li flex">
							现金奖励
						</div>
					</div>
					<div class="win-page2__pag__desc" v-else>
						<div class="desc-li flex">
							<div class="desc-li__sum">
								$10,000
							</div>
						</div>
						<div class="desc-li flex">
							Cash Reward
						</div>
					</div>
				</div>
				<div class="win-page2__pag flex flex-colum flex-1">
					<div class="win-page2__pag__tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						第六至十名
					</div>
					<div class="win-page2__pag__tit" v-else>
						6th – 10th 
					</div>
					<div class="win-page2__pag__desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						<div class="desc-li flex">
							<div class="desc-li__sum">
								$5,000
							</div>
						</div>
						<div class="desc-li flex">
							现金奖励
						</div>
					</div>
					<div class="win-page2__pag__desc" v-else>
						<div class="desc-li flex">
							<div class="desc-li__sum">
								$5,000
							</div>
						</div>
						<div class="desc-li flex">
							Cash Reward
						</div>
					</div>
				</div>
			</div>
			<div class="win-nowBtn">
				<a class="win-nowBtn__box" href="https://client.ebccrm.com/signup" target="_blank"
					v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					立即报名 >
				</a>
				<a class="win-nowBtn__box" href="https://client.ebccrm.com/signup" target="_blank"
					v-else-if="lang === 'weiwuer'">
					دەرھال تىزىملىتىڭ. >
				</a>
				<a class="win-nowBtn__box" href="https://client.ebccrm.com/signup" target="_blank" v-else>
					Join Now >
				</a>
			</div>
			<div class="win-title flex align-center">
				<div class="iconfont">
					&#xe653;
				</div>
				<div class="name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					实时赛况
				</div>
				<div class="name" v-else>
					Current Ranking
				</div>
				<div class="flex-1 win-line">

				</div>
			</div>
			<div class="win-page3">
				<table class="list">
					<thead>
						<tr>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">排名</div>
								<div v-else-if="lang === 'weiwuer'">ئىسىمى</div>
								<div v-else>Competitor Name</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">昵称</div>
								<div v-else-if="lang === 'weiwuer'">ئىسىمى</div>
								<div v-else>Competitor Name</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">MT4</div>
								<div v-else-if="lang === 'weiwuer'">MT4</div>
								<div v-else>Account Number</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">净值</div>
								<div v-else-if="lang === 'weiwuer'">ساپ قىممەت</div>
								<div v-else>Net Value</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">总入金</div>
								<div v-else-if="lang === 'weiwuer'">ئومۇمىي كىرگۈزگەن مەبلەخ</div>
								<div v-else>Total Deposit</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">收益表现</div>
								<div v-else-if="lang === 'weiwuer'">پايدا ئەھۋالى</div>
								<div v-else>Yield</div>
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(itx,idx) in infoData" :key="idx" :class="{'active':(idx+1)%2 == 0}">
							<td v-if="idx < 3" :class="">
							</td>
							<td v-else>
								{{idx+1}}
							</td>
							<td v-if="lang == 'en_US'">{{itx.name}}</td>
							<td v-else>{{itx.nickName}}</td>
							<td>{{itx.mtAccount}}</td>
							<td>{{itx.equity}}</td>
							<td>{{itx.totalDeposit}}</td>
							<td>{{(itx.yields*100).toFixed(2)}}%</td>
							<td>
								<a href="https://ebcfinsns.co" target="_blank"
									v-if="lang == 'zh_CN' || lang == 'zh_TW'">查看</a>
								<a href="https://ebcfinsns.co" target="_blank" v-else-if="lang === 'weiwuer'">پايدا
									ئەھۋالى</a>
								<a href="https://ebcfinsns.co" target="_blank" v-else>Check</a>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="tips flex" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					*该页面仅展示收益表现排名前十的交易账号，每小时更新。
				</div>
				<div class="tips flex" v-else>
					*The page only displays 10 best-performing trading accoutns. Updated hourly.
				</div>
			</div>
			<div class="win-title flex align-center">
				<div class="iconfont">
					&#xe653;
				</div>
				<div class="name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					大赛新闻
				</div>
				<div class="name" v-else>
					News
				</div>
				<div class="flex-1 win-line">

				</div>
				<div class="win-nowBtn">
					<router-link class="win-nowBtn__box" v-if="lang == 'zh_CN' || lang == 'zh_TW'" to="/LearningCenter?tabId=5">
						了解更多 >
					</router-link>
					<router-link class="win-nowBtn__box" v-else-if="lang === 'weiwuer'" to="/LearningCenter?tabId=5">
						دەرھال تىزىملىتىڭ. >
					</router-link>
					<router-link class="win-nowBtn__box" v-else to="/LearningCenter?tabId=5">
						More >
					</router-link>
				</div>
			</div>
			<div v-for="(itx,idx) in newData" v-if="itx.id === 'CATE-00E'">
				<div class="win-page4 flex">
					<div class="win-page4__pag flex-1" v-for="(item,index) in itx.info" :key="index" v-if="index < 3"
						@click="jumPage(item)">
						<div class="win-page4__pag__img">
							<img :src="item.logo" alt="">
						</div>
						<div class="win-page4__pag__info">
							<div class="info-tit">
								{{item.tit}}
							</div>
							<div class="info-desc">
								{{item.tips}}
							</div>
							<div class="info-time">
								{{item.time}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="win-title flex align-center">
				<div class="iconfont">
					&#xe653;
				</div>
				<div class="name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					参赛须知
				</div>
				<div class="name" v-else>
					Rule
				</div>
				<div class="flex-1 win-line">

				</div>
			</div>
			<div class="win-page5">
				<div class="win-page5__desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					1.活动时间为 2023年1月30日00:00:00至2023年4月30日23:59:59（GMT+3）；<br />
					2.参赛选手需在活动期间新开交易账号并注册交易社区发布信号，且入金超过$500，不限参赛者账号数量；<br />
					3.为了公开公正参赛账号必须在交易社区注册成为信号方以便展示交易记录，但无须开放跟随；<br />
					4.根据活动期间参赛账号的收益排名，收益表现参考取值=净值/账号总入金；<br />
					5.账号类型仅限 STD 与 PRO， PAMM账户无法参赛；<br />
					6.每位参赛者可不限交易账号数量参赛，取最优成绩为排名依据；<br />
					7.冠军若选择100万美金交易账户奖励，可最大亏损幅度为20万美金；<br />
					8.为保证比赛公平，如遇服务器硬件故障，报价商发送错误报价等非可控因素产生的订单，均视为无效订单；<br />
					9.EBC有权取消违规账户的参赛资格；<br />
					10.EBC拥有规则最终解释权。
				</div>
				<div class="win-page5__desc" v-else>
					1.The campaign begins at 00:00:00 (GMT+3) on Jan 30 and ends at 23:59:59 (GMT+3) on April 30.<br />
					2.You must open a trading account and register on copy-trading community to send signals during the
					competition. The minimum deposit is $500. No limit for a competitor's number of accounts.<br />
					3.To ensure transparency and fairness, accounts must be registered as a signal source on
					https://www.ebcfinsns.co but are not required to open to followers. <br />
					4.Ranked by the return of contest account, which is calculated as net return divided by intial
					deposit. <br />
					5.This competition is only available to new STD and PRO accounts. PAMM accounts are
					ineligible.<br />
					6.Trading accounts under one competitor is unlimited, and the best performance among those will be
					considered for ranking. <br />
					7.The maximum loss is $200,000 for a champion who selects a trading account with $1,000,000 in it
					for reward.
					<br />
					8.In order to ensure the fairness of competition, orders would be invalidated involving a force
					majeure such as server breakdown and inaccurate quotes from providers. <br />
					9.EBC is entitled to the right to disqualify any competitor in breach of the rules. <br />
					10.All rights reserved by EBC.
				</div>
				<div class="win-nowBtn">
					<a class="win-nowBtn__box" href="https://client.ebccrm.com/signup" target="_blank"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						立即报名 >
					</a>
					<a class="win-nowBtn__box" href="https://client.ebccrm.com/signup" target="_blank"
						v-else-if="lang === 'weiwuer'">
						دەرھال تىزىملىتىڭ. >
					</a>
					<a class="win-nowBtn__box" href="https://client.ebccrm.com/signup" target="_blank" v-else>
						Join Now >
					</a>
				</div>
			</div>
		</div>
		<div class="win-foot">

		</div>
	</div>
</template>

<script>
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				infoData: "",
				newData: ""
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			}),
		},
		created() {
			this.getData()
			this.getNewData()
		},
		methods: {
			getNewData() {
				let parms;
				if (this.lang === 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http("/ebc/api/getLearnCategory/run", {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data
				})
			},
			getData() {
				$.ajax({
					type: "POST",
					url: "https://socialtradingwebapi.eappservice.com/api/profit_stats",
					contentType: "application/json",
					dataType: "json",
					data: "{}",
					success: (res) => {
						this.infoData = res.data
					}
				})
			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id,
						lang: this.$t("common.requestLang")//this.$store.state.Lan
					}
				})
			}
		}
	}

</script>

<style lang="scss" scoped>
	.win {
		background-color: #3D0101;

		.content {
			max-width: 1200px;
			margin: auto;
		}

		.flex-colum {
			flex-direction: column;
		}

		&-page1 {
			background: linear-gradient(144.29deg, #330101 0%, #3D0101 56.41%, #2E0101 100%);

			.page1-box {
				background: url("../assets/image/TC/tc1-2.png")no-repeat center right;
				background-size: 100%;

				.content {
					background: url("../assets/image/TC/tc1-1.png")no-repeat center right;
					background-size: 55%;
				}

				.logo {
					margin: 60px 0;
					width: 190px;
				}

				&__h1 {
					&.wap {
						display: none;
					}

					img {
						width: 35%;
					}
				}

				&__tips {
					font-size: 28px;
					color: rgba(248, 227, 159, 1);
					padding: 20px 0;
					letter-spacing: 4px;

					.tips-box {
						padding: 20px 0px;
						display: inline-block;
						border-radius: 5px;
						position: relative;
					}
				}

				&__desc {
					display: none;
				}

				&__card {
					&__pag {
						width: 210px;
						min-height: 110px;
						background: linear-gradient(180deg, #FBEAC1 0%, #DE974B 100%);
						margin-right: 20px;
						padding-left: 20px;
						flex-direction: column;

						.name {
							color: #3D0101;
							font-size: 24px;
							line-height: 2;
							font-weight: 700;

							.iconfont {
								font-size: 10px;
								margin-right: 5px;
							}
						}

						.desc {
							font-size: 16px;
							color: rgba(61, 1, 1, 1);
							line-height: 1.5;
						}
					}
				}

				&__btn {
					padding: 50px 0;

					.win-btn-box {
						width: 270px;
						height: 60px;
						opacity: 1;
						background: url(../assets/image/TC/tc1-3.png)no-repeat center left;
						background-size: 100%;
						display: block;
						line-height: 60px;
						text-indent: 20px;
						font-size: 24px;
						font-weight: 600;
						letter-spacing: 1px;
						color: rgba(225, 186, 130, 1);
					}
				}
			}
		}

		&-page2 {
			&__pag {
				background: url(../assets/image/TC/tc1-5.png)no-repeat top left;
				background-size: 100% 100%;
				min-height: 240px;
				align-items: center;

				&__tit {
					line-height: 3.4;
					color: #F2E3B1;
					font-size: 32px;
					font-weight: 700;
					text-align: center;
					letter-spacing: 4px;
				}

				&__desc {
					.desc-li {
						justify-content: center;
						font-size: 16px;
						color: rgba(225, 186, 130, 1);
						align-items: center;
						line-height: 1.5;

						&__sum {
							font-size: 28.8px;
							font-weight: 600;
							color: rgba(242, 227, 177, 1);
							padding-right: 4px;
						}
					}
				}
			}
		}

		&-page3 {
			padding: 30px 30px 0;
			background: url(../assets/image/TC/tc1-6.png)no-repeat bottom right;
			background-size: 100% 100%;

			.list {
				width: 100%;
				text-align: center;

				tbody {
					tr {
						&:nth-child(1) {
							td {
								&:nth-child(1) {
									background: url(../assets/image/TC/tc1-8.png)no-repeat #FFF7F0 center center;
								}
							}
						}

						&:nth-child(2) {
							td {
								&:nth-child(1) {
									background: url(../assets/image/TC/tc1-9.png)no-repeat rgba(236, 222, 216, 1) center center;
								}
							}
						}

						&:nth-child(3) {
							td {
								&:nth-child(1) {
									background: url(../assets/image/TC/tc1-10.png)no-repeat #FFF7F0 center center;
								}
							}
						}
					}
				}

				th {
					background: #C69C6D;
					color: #3D1500;
					line-height: 54px;
					font-size: 18px;
					font-weight: 700;
					border: none;
				}

				td {
					background: #FFF7F0;
					color: #333333;
					line-height: 54px;
					font-size: 14px;
					font-weight: 500;
					border: none;

					&:nth-child(6) {
						color: #7D1614;
					}

					&:nth-child(1) {
						min-width: 50px;
						color: rgba(61, 21, 0, 1);
					}

					a {
						color: #008AED;
					}
				}

				.active {
					td {
						background: rgba(236, 222, 216, 1);
					}
				}
			}

			.tips {
				color: #D9D9D9;
				line-height: 40px;
				font-size: 12px;

				.ps {
					color: #E7C48F;
				}
			}

		}

		&-page4 {
			padding: 30px 30px 20px;
			background: url(../assets/image/TC/tc1-6.png)no-repeat bottom right;
			background-size: 100% 100%;

			&__pag {
				background-color: #fff;
				border-radius: 4px;
				cursor: pointer;

				&:nth-child(2) {
					margin: 0 30px;
				}

				&__img {
					height: 180px;
					overflow: hidden;
					border-radius: 4px 4px 0 0;

					img {}
				}

				&__info {
					padding: 10px 20px;

					.info {
						&-tit {
							font-size: 17px;
							font-weight: 600;
							color: rgba(20, 21, 24, 1);
							line-height: 1.5;
						}

						&-desc {
							color: rgba(64, 65, 69, 1);
							font-size: 14px;
							line-height: 1.5;
							padding: 10px 0;
							height: 104px;
						}

						&-time {
							font-size: 14px;
							color: #949699;
						}
					}
				}
			}
		}

		&-page5 {
			padding: 20px 30px;
			background: url(../assets/image/TC/tc1-6.png)no-repeat bottom right;
			background-size: 100% 100%;
			position: relative;

			&__desc {
				color: #D9D9D9;
				font-size: 14px;
				line-height: 2.5;
			}

			.win-nowBtn {
				padding: 0;
				position: absolute;
				bottom: 30px;
				right: 30px;
			}
		}

		&-nowBtn {
			display: flex;
			justify-content: flex-end;
			padding-right: 12px;

			&__box {
				width: 270px;
				height: 60px;
				opacity: 1;
				background: url(../assets/image/TC/tc1-7.png)no-repeat center right;
				background-size: 100%;
				display: block;
				line-height: 60px;
				padding-right: 20px;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 1px;
				text-align: right;
				color: rgba(225, 186, 130, 1);
			}
		}

		&-foot {
			margin-top: 50px;
			background: #000000;
			height: 1px;
		}

		&-title {
			padding: 50px 0 30px;
			position: relative;

			.iconfont {
				color: #E1BA82;
				font-size: 20px;
			}

			.name {
				font-size: 38px;
				font-weight: 600;
				letter-spacing: 1px;
				color: #E1BA82;
				margin: 0 30px;
			}

			.win-line {
				height: 2px;
				background: url(../assets/image/TC/tc1-4.png)no-repeat center left;
				background-size: 100%;
			}

			.win-nowBtn {
				padding: 0;
				position: absolute;
				right: 0;
			}
		}

		@media (max-width: 500px) {
			.content {
				padding: 0 10px;
			}

			&-page1 {
				background: linear-gradient(144.29deg, #330101 0%, #3D0101 56.41%, #2E0101 100%);

				.page1-box {
					background: url(../assets/image/TC/tc1-2.png)no-repeat top right;
					background-size: 100% 100%;

					.content {
						background: url(../assets/image/TC/tc1-1.png)no-repeat bottom right;
						background-size: 55%;
					}

					.logo {
						margin: 10px 0;
					}

					&__h1 {
						img {
							width: 70%;
						}
					}

					&__tips {
						font-size: 18px;
						padding: 20px 0;
						letter-spacing: 1px;

						.tips-box {
							padding: 00px 0px;
							display: inline-block;
							border-radius: 5px;
							position: relative;
						}
					}

					&__desc {
						display: block;

						&__pag {
							color: #f8e39f;
							padding-top: 15px;

							.name {
								font-size: 18px;
								padding: 0 10px;
							}

							.ps {
								font-size: 14px;
							}
						}
					}

					&__card {
						display: none;

						&__pag {
							width: 210px;
							min-height: 110px;
							background: linear-gradient(180deg, #FBEAC1 0%, #DE974B 100%);
							margin-right: 20px;
							padding-left: 20px;
							flex-direction: column;

							.name {
								color: #3D0101;
								font-size: 24px;
								line-height: 2;
								font-weight: 700;

								.iconfont {
									font-size: 10px;
									margin-right: 5px;
								}
							}

							.desc {
								font-size: 16px;
								color: rgba(61, 1, 1, 1);
								line-height: 1.5;
							}
						}
					}

					&__btn {
						padding: 30px 0;

						.win-btn-box {
							width: 200px;
							height: 45px;
							opacity: 1;
							background: url(../assets/image/TC/tc1-3.png)no-repeat center left;
							background-size: 100%;
							display: block;
							line-height: 45px;
							text-indent: 20px;
							font-size: 18px;
							font-weight: 600;
							letter-spacing: 1px;
							color: rgba(225, 186, 130, 1);
						}
					}
				}
			}

			&-page2 {
				flex-wrap: wrap;

				&__pag {
					min-height: 180px;
					min-width: 150px;

					&__tit {
						line-height: 3;
						font-size: 24px;
					}

					&__desc {
						.desc-li {
							justify-content: center;
							font-size: 12px;
							line-height: 1.5;

							&__sum {
								font-size: 18px;
								font-weight: 600;
								color: rgba(242, 227, 177, 1);
								padding-right: 4px;
							}
						}
					}
				}
			}

			&-page3 {
				padding: 10px 0px 0;
				background: none;

				.list {

					th {
						background: #C69C6D;
						color: #3D1500;
						line-height: 46px;
						font-size: 12px;
						font-weight: 700;
						border: none;
					}

					td {
						background: #FFF7F0;
						color: #333333;
						line-height: 42px;
						font-size: 12px;
						font-weight: 500;
						border: none;
						text-align: center;

						&:nth-child(6) {
							color: #7D1614;
						}

						&:nth-child(1) {
							min-width: 40px;
							color: rgba(61, 21, 0, 1);
						}

						a {
							color: #008AED;
						}
					}

					.active {
						td {
							background: rgba(236, 222, 216, 1);
						}
					}
				}

				.tips {
					color: #D9D9D9;
					line-height: 2;
					font-size: 12px;
					display: block;

					.ps {
						color: #E7C48F;
					}
				}

			}

			&-page4 {
				padding: 20px 10px;

				&__pag {
					background-color: #fff;
					border-radius: 4px;

					&:nth-child(2) {
						margin: 0 0px 0 10px;
					}

					&:nth-child(3) {
						display: none;
					}

					&__img {
						height: 120px;
					}

					&__info {
						padding: 10px 5px;

						.info {
							&-tit {
								font-size: 14px;
								font-weight: 600;
								color: rgba(20, 21, 24, 1);
								line-height: 1.5;
							}

							&-desc {
								color: rgba(64, 65, 69, 1);
								font-size: 12px;
								line-height: 1.5;
								padding: 0px 0 5px;
								height: 120px;
							}

							&-time {
								font-size: 12px;
							}
						}
					}
				}
			}

			&-page5 {
				padding: 20px 10px;
				background: url(../assets/image/TC/tc1-6.png)no-repeat bottom right;
				background-size: 100% 100%;
				position: relative;

				&__desc {
					color: #D9D9D9;
					font-size: 14px;
					line-height: 1.75;
				}

				.win-nowBtn {
					padding: 0;
					position: relative;
					bottom: 0;
					right: 0;
				}
			}

			&-nowBtn {
				display: flex;
				justify-content: flex-end;
				padding-right: 0;

				&__box {
					width: 200px;
					height: 45px;
					opacity: 1;
					background: url(../assets/image/TC/tc1-7.png)no-repeat center right;
					background-size: 100%;
					display: block;
					line-height: 45px;
					padding-right: 10px;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 1px;
					text-align: right;
					color: rgba(225, 186, 130, 1);
				}
			}

			&-foot {
				margin-top: 25px;
				height: 60px;
			}

			&-title {
				padding: 20px 0;

				.iconfont {
					font-size: 10px;
				}

				.name {
					font-size: 18px;
					margin: 0 10px;
				}

				.win-line {
					height: 2px;
					background: url(../assets/image/TC/tc1-4.png)no-repeat center left;
					background-size: 100%;
				}

				.win-nowBtn {
					padding: 0;
					position: absolute;
					right: 0;
				}
			}
		}
	}

</style>

