export default {
	"screen0": {
		"enTitle": "Your Trust,Our Protection",
		"title": "線上交易的安全和防範欺詐,是我們的首要優先考慮事項",
		"text": "在EBC,我們提供了一系列安全、即時和易於操作的出入金管道。您可以隨時選取您的獲利或初始投資。為確保您的帳戶得到充分的保護,我們對線上支付實施了行業高級別的加密,並採取了額外的驗證措施,全方位保障您在EBC上的所有資金均安全無憂。",
		"rightText": "有機會駕馭的人生,<span>即刻啟程。</span>"
	},
	"screen1": {
		"smallEnTitle": "Multiple Deposit Methods",
		"smallTitle": "多種入金管道",
		"title": "我們支持銀聯借記卡,電匯,PayPal等多種入金管道",
		"desc": "為幫助確保您擁有更多的剩餘資金用於交易,EBC對於您從交易帳戶注入資金或從該帳戶選取資金不收取任何費用",
		"list": ["入金管道", "最低入金", "入金到賬時間", "最低出金", "支付貨幣", "手續費", "出金處理時間"],
		"summary": "說明：",
		"btn": "開始入金",
		"imgUrl": "ct",
		"summaryDesc": "<p>1.具體到賬時間取決於各銀行、中間機构的辦理情况</p><p>2.具體限額可能因客戶銀行、髮卡行規定而各有不同</p><p>3.請注意,如果您在提交出金申請時,您的帳戶持有倉位,請留意您選取的金額大小,以免影響您的持倉。</p>"
	},
	"screen2": {
		"title": "極速安全開戶",
		"desc": "僅需3個步驟,成為全球百萬交易者之一",
		"list": [{
			"title": "注册帳戶",
			"desc": "提交基本資訊,安全簡單的網上申請頁面"
		}, {
			"title": "提交個人資訊",
			"desc": "只需1分鐘輕鬆完成開戶填寫"
		}, {
			"title": "入金啟動",
			"desc": "最低入金200美金實时啟動"
		}],
		"btn1": "開設類比帳戶",
		"btn2": "開設真實帳戶",
		"yuyan": "zh"
	},
	"screen3": {
		"title": "出入金常見問題",
		"faqList": [{
			"title": "我如何向我的帳戶入金？",
			"desc": "在您建立帳戶後,可以通過我們的網絡用戶端進入您的支付首選項。<br>具體操作：<br>1.系統將首先提示您選擇要入金的帳戶。<br>2.然後使用付款方式錶右側的按鈕選擇付款方式。<br>3.輸入您想向您的帳戶裏入金的金額。<br>請仔細閱讀所有說明,條款和條件,如果您同意,<br>請確保選中“我已閱讀所有說明並同意付款操作的條款和條件”。<br>【點擊提交】"
		}, {
			"title": "為何我入金不成功？",
			"desc": "如果您的借記卡入金失敗,請嘗試再次入金,並檢查：<br>您是否正確輸入了您的卡片資訊。<br>您是否使用了有效（未過期）卡。<br>您的卡中是否有足够的資金。<br>如果不存在上述問題,但您仍然沒有成功入金,那麼可能是您的髮卡行並未授權您的卡進行入金。如果是這樣,請使用其他卡,或您的交易帳戶中的其他付款方式。"
		}, {
			"title": "我如何從我的帳戶中出金？",
			"desc": "您可以在任意時間提出出金申請。 我們的帳戶部門每天都會處理請求。 出金將轉回到您的指定帳戶中。 如果該選項不可用，資金將自動退回至客戶的後臺錢包中。 另請注意，<br>銀行電匯出金,請以各銀行實際處理的時間為准。各家銀行到賬時間不同,詳細請諮詢銀行。<br>若在持倉時出金,請確保交易帳戶中的預付比例在扣除出金金額後依然高於200%,否則平臺將無法處理您的取款申請。"
		}, {
			"title": "EBC收取手續費嗎？",
			"desc": "EBC對於採用任何支付方式的入金或出金,都不收取任何費用。但請注意,您向某些國際銀行機构的付款或從這些機构辦理的付款可能會產生費用。還請注意,您的銀行可能會將支付給EBC的款項視為預付現金,並根據您的預付現金規則向您開具帳單。"
		}, {
			"title": "我是否可以用他人的銀行卡入金/出金？",
			"desc": "否,EBC不接受來自協力廠商的付款。請確保所有進入您交易帳戶的入金均來自您名下的帳戶。如交易帳戶的持有者是聯合帳戶的其中一方,將會接受聯合帳戶的付款。如果我們懷疑是由協力廠商提供的資金,我們保留將資金退回給匯款人並凍結您帳戶餘額的權利,等待驗證身份證明和資金來源後再行處理。在未經驗證的情况下,我們保留凍結您帳戶餘額的權利,您將不得選取您的帳戶餘額。EBC不辦理對協力廠商的出金。如有特殊情况,請聯繫我們。"
		}]
	},
	"funding1":{
		"text1": "出入金",
		"text2": "線上交易的安全和防範欺騙，是我們的首要優先考慮事項",
		"btn": "即刻入金",
	},
	"funding2":{
		"text1": "全球多幣種資金解決方案",
		"text2": "提供全球主要貨幣的即時入金與出金服務，簡化國際與跨貨幣交易流程，實現全天候、便利的資金流動",
	},
	"funding3":{
		"text1": "資金隔離，獨立託管",
		"text2": "巴克萊銀行最高等級Corporate Banking Account",
		"text3": "EBC擁有巴克萊銀行最高級別企業帳戶，該帳戶要求企業營業額和存款達到650萬英鎊以上，且須要通過巴克萊一系列嚴格的財務審計和背景調查。 EBC英國嚴格執行CASS規定，透過特定信託函獨立託管投資人資金，保障資金安全與獨立。",
		"btn": "了解更多",
	},
	"funding4":{
		"text1": "專業金融責任保險",
		"text2": "多重國際專業金融責任險，全方位保障客戶資金安全，有效避免資金損失",
	},
	"funding5":{
		"text1": "出入金方式",
		"text2": "支援本地銀行轉帳，國際電匯，電子錢包 ，加密貨幣等多種出入金方式",
		"text3": "出入金方式",
		"text4": "支援貨幣",
		"text5": "入金單筆限額",
		"text6": "入金到帳時間",
		"text7": "出金處理時間",
		"text8": "本地銀行",
		"text9": "即時",
		"text10": "1個工作天",
		"text11": "國際電匯",
		"text12": "1000-100000",
		"text13": "2-5個工作天",
		"text14": "電子錢包",
		"text15": "數位貨幣",
	},
	入金到账说明:`*入金到帳說明：工作日處理時間約為1至15分鐘，超時未到帳請及時聯系客服或將您的訂單編號與付款截圖郵件至 cs@ebc.com 査詢。<br/>
出金處理說明：工作日16:00（GMT+8）前提交的申請，最快當天到帳； 16:00（GMT+8）後提交的申請，延遲至第二個工作日到帳。`
}

