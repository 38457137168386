<template>
	<div class="learning-center" :class="$t('CFooters.className')">
		<page-navigate></page-navigate>
		<div class="learning-center-new">

    <div class="list-head">
    <ul class="nav nav-tabs container">
        <li class="institute-search">
            <div class="search-box bs-example bs-example-form">
                    <div class="row">
                        <div class="col-lg-7"><p class="page-title">{{$t('CFooters.className') == 'zh-box' ? 'EBC研究院' : 'EBC Featured Insights'}}</p></div>
                        <div class="col-lg-5" v-if="$t('CFooters.className') == 'zh-box'">
                            <div class="input-group">
                                <input class="form-control keyword"
						:placeholder="$t('CFooters.className') == 'zh-box' ? '黄金 原油 股票指数' : 'Begin typing to see search results'"
						v-model="searchData">
                                <span class="input-group-btn">
                                    <button class="btn btn-primary" @click="searchLearnArticle">
                                        {{$t('CFooters.className') == 'zh-box' ? '搜索' : 'Search'}}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
        </li>
    </ul>
    </div>
    <div class="news-sub-nav" id="news-sub-nav" :style="{top:topNum + 'px'}" v-if="$t('CFooters.className') == 'zh-box'">
        <div class="cate-son container">
            <router-link v-for="(item, index) in newTab" :key="index" :to="`/LearningCenter?tabId=${index}`" :class="{'active':index == current}">{{item}}</router-link>
        </div>
    </div>
    <!-- Tab panes -->
    <div class="tab-content">
        <div class="tab-pane active container" v-if="state">
            <div class="row" v-if="current == 0">
					<div class="col-lg-4 col-md-6 news-li" v-for="(itx,idx) in displayedNews" :key="idx">
                        <div class="thumbnail">
                            <a href="javascript:void(0);" :title="itx.tit" @click="jumPage(itx)"><img :src="itx.logo"></a>
                            <div class="caption">
                                <h3><a href="javascript:void(0);" :title="itx.tit" @click="jumPage(itx)">{{itx.tit}}</a></h3>
                                <p>{{itx.tips}}</p>
                                <span>{{itx.time}}</span>
                            </div>
                        </div>
                    </div>
			</div>
            <div class="row"  v-for="(item,index) in newData" :key="index"
					v-if="current !== 0 && index === (current - 1)">
					<div class="col-lg-4 col-md-6 news-li" v-for="(itx,idx) in item.info" :key="idx"
								v-if="itx.tips !=='' && itx.tips !==null">
                        <div class="thumbnail">
                            <a href="javascript:void(0);" :title="itx.tit" @click="jumPage(itx)"><img :src="itx.logo"></a>
                            <div class="caption">
                                <h3><a href="javascript:void(0);" :title="itx.tit" @click="jumPage(itx)">{{itx.tit}}</a></h3>
                                <p>{{itx.tips}}</p>
                                <span>{{itx.time}}</span>
                            </div>
                        </div>
                    </div>
                    <template  v-if="current == 3">
                        <div class="col-lg-4 col-md-6 news-li">
                            <div class="thumbnail">
                                <router-link to="/cfa" title="创作者激励计划">
                                    <img src="../assets/image/LearningCenter/event1.jpg"></router-link>
                                    <div class="caption">
                                        <h3>
                                            <router-link to="/cfa" title="创作者激励计划">创作者激励计划</router-link>
                                        </h3>
                                        <p>EBC鼓励原创，支持金融观点分享，为推动专业内容创作惠及交易爱好者，特推出创作者激励计划，创作者专享额外奖励，让专业不负热爱</p>
                                    </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 news-li">
                            <div class="thumbnail">
                                <router-link to="/pay" title="交易领薪计划">
                                    <img src="../assets/image/LearningCenter/event2.png"></router-link>
                                    <div class="caption">
                                        <h3>
                                            <router-link to="/pay" title="交易领薪计划">交易领薪计划</router-link>
                                        </h3>
                                        <p>交易领薪计划，我们全程买单，只为寻找传奇，即刻发起交易信号，无限奖励月月拿，优享12个专属大礼</p>
                                    </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 news-li">
                            <div class="thumbnail">
                                <router-link to="/orderflow" title="领取订单流工具">
                                    <img src="../assets/image/LearningCenter/event3.png"></router-link>
                                    <div class="caption">
                                        <h3>
                                            <router-link to="/orderflow" title="领取订单流工具">领取订单流工具</router-link>
                                        </h3>
                                        <p>免费领取订单流工具，识别主力资金，跟庄交易，提高胜率，高盛、瑞银等华尔街欧美专业机构必备交易工具，2019年《期货》杂志评为「最受欢迎交易方法」</p>
                                    </div>
                            </div>
                        </div>
                    </template>
			</div>
        </div>
        <div class="tab-pane active container" v-else>
            <div class="row">
                 <div class="col-lg-4 col-md-6 news-li"  v-for="(itx,idx) in sData" :key="idx"
									v-if="itx.tips !=='' && itx.tips !==null">
                        <div class="thumbnail">
                            <a href="javascript:void(0);" :title="itx.tit" @click="jumPage(itx)"><img :src="itx.logo"></a>
                            <div class="caption">
                                <h3><a href="javascript:void(0);" :title="itx.tit" @click="jumPage(itx)">{{itx.tit}}</a></h3>
                                <p>{{itx.tips}}</p>
                                <span>{{itx.time}}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

    </div>
</div>
		<page-footer></page-footer>
	</div>
</template>

<script>
	export default {
		name: '',
		data() {
			return {
				current: 0,
				newData: [],
				newTab: [],
				searchData: '',
				state: true,
				sData: [],
				seoInfo: "",
                displayedNews: [],   // 当前显示在页面上的数据
                perPage: 18,          // 每页显示的数据数量
                currentPage: 1,       // 当前的页数
                isDataFetched: false,
                topNum: 245,
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
            this.getNewData()
			this.$getSeoInfo({
				lang: this.$t("common.requestLang"),//this.$store.state.Lan,
				path: "/LearningCenter"
			}).then(res => {
				this.seoInfo = res
			})
		},
        watch: {
            '$route' (to, from) {
                if (to.path === from.path) {
                    if(this.$route.query.tabId){
                        this.current = this.$route.query.tabId
                    }else{
                        this.current = 0
                    }
                    this.loadMore()
                }
            }
        },
        mounted() {
            this.throttledOnScroll = this.throttle(this.onScroll, 100);
            window.addEventListener('scroll', this.throttledOnScroll);
            let lang = this.$t("common.requestLang")//this.$store.state.Lan;
            if (lang === 'zh_CN') {
                window.addEventListener('scroll', this.scroll)
            }
        },
        destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
        beforeDestroy() {
            window.removeEventListener('scroll', this.throttledOnScroll);
        },
        computed:{
            allNewsSorted() {
                if (!this.isDataFetched) return [];

                // 收集所有新闻
                const allNews = this.newData.reduce((acc, category) => {
                    return acc.concat(category.info);
                }, []);

                const filteredNews = allNews.filter(news => news.tit && news.tit !== '');

                // 对新闻按日期排序
                return filteredNews.sort((a, b) => {
                    const dateA = this.parseDate(a.time);
                    const dateB = this.parseDate(b.time);
                    return dateB - dateA; // 倒序
                });
            }
        },
		methods: {
            async scroll() {
                const docScrollTop = document.documentElement.scrollTop

                if (docScrollTop <= 164) {
                    this.topNum = 245 - docScrollTop
                    this.pagIndex = 0
                } else {
                    this.topNum = 81
                }
			},
			getNewData() {
				let lang = this.$t("common.requestLang")//this.$store.state.Lan;
				let parms;
				if (lang === 'zh_CN') {
					parms = 'zh_CN'
					this.newTab = ['热门推荐']
				} else {
					this.newTab = ['HOT']
					parms = 'en_US'
				}
                if(this.$route.query.tabId){
                    this.current = this.$route.query.tabId
                }else{
                    this.current = 0
                }
				this.$http('/ebc/api/getLearnCategory/run', {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data
					res.data.data.forEach(o => {
						this.newTab = this.newTab.concat(o.jName)
					})
                    this.isDataFetched = true;
                    this.currentPage = 1;
                    if(this.current == 0){
                        this.loadMore();
                    }
				}) 
			},
			searchLearnArticle() {
				let lang = localStorage.getItem('language')
				let parms;
				if (lang === 'zh') {
					parms = 'zh_CN'
					this.newTab = []
				} else {
					this.newTab = []
					parms = 'en_US'
				}
				if (!this.searchData) {
					this.getNewData()
					this.state = true
					return
				} else {
					this.state = false
				}
				this.newTab = []
				this.$http('/ebc/api/searchLearnArticle/run', {
					lang: parms,
					keyword: this.searchData
				}).then(res => {
					this.sData = res.data.data
				})
			},
			changTab(idx) {
				if (this.current === idx) return
				this.current = idx
			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id
					}
				})

			},
            onScroll() {
                let nearBottom = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 850;
                if (nearBottom && this.current == 0) {
                    this.loadMore();
                }
            },
            loadMore() {
                const start = (this.currentPage - 1) * this.perPage;
                const end = this.currentPage * this.perPage;
                this.displayedNews = this.displayedNews.concat(this.allNewsSorted.slice(start, end));
                this.currentPage++;
            },
            throttle(func, delay) {
                let lastCall = 0;
                return function(...args) {
                    const now = new Date().getTime();
                    if (now - lastCall < delay) return;
                    lastCall = now;
                    return func.apply(this, args);
                };
            },
            parseDate(dateStr) {
                let yearMonthDayPattern = /(\d{4})年(\d{1,2})月(\d{1,2})日/;
                let match = dateStr.match(yearMonthDayPattern);
                if (match) {
                    return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
                }

                // 处理 "14 Sep 2023" 格式
                let dayMonthYearPattern = /(\d{1,2}) (\w{3}) (\d{4})/;
                match = dateStr.match(dayMonthYearPattern);
                if (match) {
                    let monthMap = {
                        Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                        Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
                    };
                    let month = monthMap[match[2]];
                    if (month !== undefined) {
                        return new Date(parseInt(match[3], 10), month, parseInt(match[1], 10));
                    }
                }

                return new Date(0);
            },
		}
	}
</script>

<style>
	@charset "UTF-8";
    .c-footer .fp-overflow{
		display: unset !important;
	}
.learning-center .page-screen-3__line3{
    font-size: 54px;
    font-family: 'SiYuanSong', serif;
    font-weight: 700;
    width: 28% !important;
}

.news-sub-nav{
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
}

.en-box .tab-content{
    padding-top: 40px;
}
.ct-box .tab-content{
    padding-top: 40px;
}
.ko-box .tab-content{
    padding-top: 40px;
}
.ja-box .tab-content{
    padding-top: 40px;
}
.th-box .tab-content{
    padding-top: 40px;
}
.vi-box .tab-content{
    padding-top: 40px;
}

.learning-center .advantage-box {
    width: 1500px;
    margin: 0 auto
}

.learning-center .advantage-box .advantage-title {
    display: flex;
    align-items: center;
    padding: 100px 0
}

.learning-center .advantage-box .advantage-title__line {
    flex: 1;
    border-top: 2px solid #d1d1d1
}

.learning-center .advantage-box .advantage-title .title {
    padding: 0 24px;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.44;
    color: #333
}

.learning-center .advantage-box .advantage-box {
    justify-content: space-between;
    align-items: center
}

.learning-center .nav-tabs a{
    padding: 26px 28px;
    float: left;
    font-size: 18px;
    color: #a1a1a1;
}

.learning-center .nav-tabs .active a{
    /* border-bottom: 2px #640005 solid; */
}

.learning-center .nav-tabs{
    margin: 20px auto;
    margin-top: 50px;
    display: block;
    width: 100%;
    border: none;
}



.learning-center .tab-content .news-li{
    padding: 0;
    padding-bottom: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    width: 32%;
    margin-right: 2%;
    background: #ffffff;
}
.learning-center .tab-content .news-li:hover{
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
}
.learning-center .tab-content .news-li:nth-child(3n){
     margin-right: 0%; 
}
.learning-center .tab-content .news-li img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: none;
    object-position: left;
    width: 100%;
    /* height: 280px; */
    max-height: 235px;
}
.learning-center .tab-content .news-li h3 a{
    margin: 14px 16px 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: #333;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    font-family: 'Noto Serif SC';
}
.learning-center .tab-content .news-li p{
    margin: 0 16px 10px;
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    font-family: auto;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
}
.learning-center .tab-content .news-li span{
    padding: 0 16px 10px;
    color: #666;
    font-size: 14px;
}
.learning-center .c-btn{
    margin: 30px auto 60px;
    font-size: 20px;
    max-width: 10px;
}

@media(max-width:767px) {
    
}



.pagination {
    display: block;
    padding-left: 0;
    margin: 20px auto;
    border-radius: 4px;
    margin-bottom: 60px;
    width: max-content;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 10px 16px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #404040;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    font-size: 16px;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eeeeee;
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3 !important;
    color: #fff !important;
    background-color: #3D0101 !important;
    border-color: #3D0101 !important;
    cursor: default !important;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  .c-footer .c-footer-hd[data-v-73eb7d57]{
    clear: both;
  }

.news-sub-nav{
    background: #ffffff;
}

.cate-son{
    margin: 20px auto 6px;
}
.cate-son a{
    font-size: 16px;
    padding: 9px 18px 10px;
    margin-right: 15px;
    border-radius: 30px;
    border: 1.5px solid #999;
    color: #999;
    display: inline-block;
    margin-bottom: 16px;
    cursor: pointer;
}
.cate-son a.active{
    background: #3D0101;
    color: #fff;
    border-color: #3D0101;
}
.cate-son a:hover{
    background: #3D0101;
    color: #fff;
    border-color: #3D0101;
}
.page{
    width: 100%;
    padding-bottom: 50px;
}
.page-title{
    color: #eeeeee;
    font-size: 34px;
    text-align: left;
    line-height: 34px;
    font-weight: 600;
}


@media(max-height:375px) {
    .justify-content-around {
        height: 200px;
        overflow-y: auto
    }
}


@media(width:768px) {
    .dropdown-menu .dropdown-menu {
        position: static !important
    }
}






.learning-center .nav-tabs li{
    position: static;
}
.learn-son{
    position: absolute;
    display: none;
    top: 153px;
    width: auto;
    border-radius: 5px;
    background: #fafafa;
    height: 53px;
}
.learn-son a{
    font-size: 16px !important;
    padding: 16px 40px !important;
}
.learn-son a:hover{
    color: rgba(117, 13, 13, 1);
}
.learning-center .nav-tabs a:hover{
    color: #fff;
}
.learning-center .nav-tabs li.active a{
    color: #ffffff;
    /* font-weight: 600; */
    font-size: 20px;
    padding-top: 23px;
}
.breadcrumb {
    padding: 16px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    font-size: 16px;
}
.breadcrumb>li {
    display: inline-block;
}
.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}
.breadcrumb>.active {
    color: #777;
}
.breadcrumb{
    display: none;
}
.breadcrumb a{
    color: #777;
}
.breadcrumb>.active{
    color: rgba(117, 13, 13, 1);
}
.institute-search{
    padding: 16px 0px 10px;
    /* width: 45%; */
}
.search-en{
    /* width: 29%; */
}
.form-control{
    color: #ffffff;
}
.form-control:focus{
    color: #ffffff;
}

.learning-center .list-head{
    background: url(../assets/image/LearningCenter/news-list-bg.png) 100%/auto 100% no-repeat;
    height: 180px;
    margin-top: 0px;
    text-align: center;
    padding-top: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.institute-search .keyword{
    border: 1.5px solid #AF8147;
    border-radius: 3px 0px 0px 3px;
    background: none;
}
.institute-search .keyword::placeholder {
    color: #a1a1a1;
}
.institute-search button{
    background: #AF8147;
    border-color: #AF8147;
    border-radius: 0px 3px 3px 0px;
    height: 40px;
    width: max-content;
    color: #3D0101;
}
.institute-search button:hover{
    background: #AF8147;
    border-color: #AF8147;
}
.tab-content{
    padding-top: 112px;
    background: #F5F5F5;
    padding-bottom: 50px;
}


@media(max-width:1400px) {
    .learning-center .tab-content .news-li img{
        /* height: 230px; */
        max-height: 204px;
    }
    .institute-search{
        /* width: 35%; */
    }
    .search-en{
        /* width: 18%; */
    }
    .cate-son a{
        padding: 8px 14px 9px;
        font-size: 14px;
    }
}
@media(max-width:1200px) {
    .learning-center .tab-content .news-li img{
        /* height: 200px; */
    }
    .institute-search{
        /* width: 24%; */
    }
    .search-en{
        width: 95%;
    }
}
@media(max-width:996px) {
    .en-box .page-title,.ct-box .page-title,.ko-box .page-title,.ja-box .page-title,.th-box .page-title,.vi-box .page-title{
        font-size: 28px;
    }
    .en-box .learning-center-new .list-head,.ct-box .learning-center-new .list-head,.ko-box .learning-center-new .list-head,.ja-box .learning-center-new .list-head,.th-box .learning-center-new .list-head,.vi-box .learning-center-new .list-head{
        height: 104px;
    }
    .cate-son a{
        padding: 10px 22px 11px;
    }
    .news-sub-nav{
        position: unset;
    }
    .search-box{
        padding: 0px 4%;
    }
    .tab-pane{
        padding: 0% 6%;
    }
    .input-group{
        margin-top: 26px;
    }
    .page-title{
        margin-top: 6px;
        font-size: 30px;
    }
    .learning-center .nav-tabs{
        margin-top: 0px;
        padding-left: 3%;
        padding-right: 3%;
    }
    .learning-center .nav-tabs a{
        padding: 0;
        font-size: 16px;
        width: 25%;
        margin-top: 36px;
    }
    .learning-center .nav-tabs li.active a{
        padding: 0;
        font-size: 16px;
    }

    .pagination > li > a,
    .pagination > li > span {
        padding: 6px 10px;
      }
    .learning-center .tab-content .news-li:nth-child(3n){
         margin: 6px auto 22px;
    }
    .institute-search form{
        position: absolute;
        top: 120px;
        width: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .learning-center .list-head{
        background: #2D0101;
        height: 170px;
    }
    .learning-center .tab-content .news-li{
        width: 100%;
    }
    .learning-center .tab-content .news-li img{
        height: auto;
        max-height: max-content;
    }
    
    .breadcrumb{
        display: block;
        margin-top: 92px;
    }
    .pagination{
        float: none;
        /* display: inline; */
    }
    .cate-son a{
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
    }
    .cate-son {
        margin: 0px 0px 5px;
        overflow-x: scroll;
        overflow-y: hidden;
        text-align: justify;
        width: 100%;
        white-space: nowrap;
        height: 64px;
        padding-top: 6px;
        padding-left: 20px;
    }
    .cate-son::-webkit-scrollbar {
        display: none;
    }
    .tab-content{
        padding-top: 20px;
    }
    .breadcrumb{
        margin-bottom: 10px;
    }
    .en-style .nav-tabs a{
        width: 50%;
        margin-top: 22px;
    }
    .en-style .list-head{
        height: 192px;
    }
}

@media(max-width:640px) {
    .learning-center{
        /* padding-left: 3%; */
        /* padding-right: 3%; */
    }
}

@media(max-width:400px) {
    .pagination > li > a, .pagination > li > span{
        padding: 6px 10px;
    }
}

@media(max-width:360px) {
    .pagination > li > a, .pagination > li > span{
        padding: 8px 14px;
    }
}

</style>