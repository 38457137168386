export default {
  type: "en",
  lang: "en",
  lang2: "en",
  "Trading Central": "Phân tích thị trường",
  市场分析: "Trading Central",
  EBC与TradingCentral携手合作:
    "EBC hợp tác với Trading Central để cung cấp công cụ phân tích thị trường chuyên nghiệp cho tất cả khách hàng, giúp khách hàng hiểu sâu hơn về thị trường, khám phá cơ hội giao dịch và tối ưu hóa chiến lược giao dịch.",
  分析师观点: "Quan điểm của nhà phân tích",
  财经日历解析: "Phân tích lịch kinh tế",
  "Alpha-MT4/MT5技术指标": "Chỉ báo Thế hệ Alpha MT4/MT5",
  "Martin Pring教学视频": "Video hướng dẫn của Martin Pring",
  解锁完整版: "Mở phiên bản đầy đủ",
  "*本页面的信息不构成EBC":
    "*Thông tin trên trang này không cấu thành lời khuyên hoặc khuyến nghị đầu tư vào các công cụ tài chính từ EBC, Trading Central, hoặc các nhà cung cấp nội dung của họ. EBC, Trading Central và các nhà cung cấp nội dung của họ không chịu trách nhiệm về bất kỳ tổn thất hoặc thiệt hại nào phát sinh từ việc sử dụng thông tin này.",
  收起: "Thu gọn",
  衍生品: "Forex",
  指数: "Indices ",
  商品: "Commodities ",
  星号数代表支撑位和阻力位的强度: "Star Ratings indicate the strength of the support and resistance level",
  分析师观点: "Quan điểm của nhà phân tích",
  通过清晰的趋势线展示简明易行的交易计划:
    "Hiển thị kế hoạch giao dịch đơn giản và dễ thực hiện thông qua các đường xu hướng rõ ràng, và liên tục cập nhật trong suốt phiên giao dịch để giúp bạn khám phá các cơ hội giao dịch mới.",
  财经日历解析: "Phân tích lịch kinh tế",
  提供38个国家:
    "Cung cấp dữ liệu kinh tế vĩ mô theo thời gian thực cho 38 quốc gia/khu vực, giúp các nhà giao dịch theo dõi, dự đoán và ứng phó với những biến động tiềm ẩn của thị trường.",
  "Alpha-MT4/MT5 技术指标": "Chỉ báo Thế hệ Alpha MT4/MT5",
  通过三个简明的创新指标展示市场情绪:
    "Hiển thị tâm lý thị trường thông qua ba chỉ báo đổi mới đơn giản, giúp nhà giao dịch đưa ra quyết định và tìm ra các điểm vào/ra tiềm năng.",
  技术观点: "Phân tích kỹ thuật：",
  通过技术分析结果: "Cung cấp hướng ưu tiên và mức mục tiêu cho mỗi biểu đồ dựa trên kết quả phân tích kỹ thuật.",
  自适应K线图: "Biểu đồ K-line tự thích ứng：",
  支持16种与决策相关的K线图形模式: "Hỗ trợ 16 mô hình nến ảnh hưởng đến quyết định giao dịch",
  "自适应趋异指标(ADC)": "Hội tụ phân kỳ thích ứng (ADC)：",
  "长短进/出信号": "Tín hiệu ra/vào dài ngắn, chỉ báo giá nhanh/chậm, tín hiệu ban đầu/trượt, và chỉ báo dao động nhanh/chậm.",
  下载指标: "Tải Chỉ báo",
  "Martin Pring 教学视频": "Video hướng dẫn của Martin Pring",
  "通过Martin Pring的视频课程":
    "Học các kiến thức cơ bản về phân tích kỹ thuật qua video của Martin Pring, nắm vững các kỹ năng như đường xu hướng và đường trung bình động, giúp nâng cao trình độ giao dịch.",
  灵活的学习模式: "Mô hình học tập linh hoạt：",
  可以自由安排学习进度:
    "Có thể tự do sắp xếp tiến độ học tập, thông qua nội dung phong phú và các công cụ giao dịch, dễ dàng nắm vững kỹ năng đầu tư.",
  广泛的内容覆盖: "Nội dung bao quát rộng rãi：",
  从初级到高级:
    "Từ cơ bản đến nâng cao, nội dung khóa học toàn diện đáp ứng nhu cầu của tất cả các cấp độ người dùng khác nhau, giúp nâng cao kỹ năng đầu tư một cách hệ thống.",
  互动学习体验: "Trải nghiệm học tập tương tác：",
  包含术语表和自我评估等互动工具:
    "Bao gồm bảng thuật ngữ và các công cụ tương tác như tự đánh giá, giúp kiểm tra tiến trình học tập, tăng cường sự hiểu biết và hiệu quả ghi nhớ.",
  了解更多: "Tìm hiểu thêm",
};
