<template>
  <div class="modal flex flex-ajcenter" :class="{'show':state}">
    <div class="modal-box">
      <div class="modal-box__tit flex flex-ajcenter">
        Notice
      </div>
      <div class="modal-box__desc" v-html="$t('CFooters.notice')"></div>
      <div class="modal-box__btn flex">
        <div class="btn" @click="change">
          OK
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex'
  export default {
    props: {
      footerInfo: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {}
    },
    computed: {
      ...mapState({
        state: state => state.showModal,
      }),
    },
    methods: {
      change() {
        let lang = this.$t("common.requestLang")//this.$store.state.Lan
        localStorage.setItem(lang, true)
        this.$store.commit('setShowModal', false)
      }
    }
  }
</script>

<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#000, 0.4);
    display: none;

    &.show {
      display: flex;
    }

    &-box {
      background-color: #fff;
      max-width: 50%;
      border-radius: 5px;

      &__tit {
        font-size: 32px;
        padding: 20px 0;
        color: #640606;
        font-weight: 700;
      }

      &__desc {
        padding: 20px;
        line-height: 1.75;
        font-size: 18px;
        display: flex;
        color: #949494;
        height: 400px;
        overflow-y: scroll;
      }

      &__btn {
        justify-content: flex-end;
        padding: 20px;

        .btn {
          background-color: #640606;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          color: #fff;
        }
      }
    }

    @media (max-width: 767px) {
      &-box {
        max-width: 90%;

        &__tit {
          font-size: 24px;
        }

        &__desc {
          line-height: 1.75;
          font-size: 14px;
          height: 300px;
        }

        &__btn {
          .btn {
            height: 36px;
            width: 70px;
            color: #fff;
          }
        }
      }
    }
  }
</style>
