<template>
<div :class="$t('security.security-class-name')">
	<page-navigate></page-navigate>

	<div class="security1">
        <div class="container">
            <p class="public-title">{{$t('security.security-page1.text1')}}</p>
            <img class="public-icon" src="../assets/image/security/security-icon1.svg" alt="">
            <div class="row">
                <div class="col-md-4">
                    <img src="../assets/image/index/index-new-01.svg" alt="">
                    <p class="p2">{{$t('WhyEBC.screen3.desc-l1')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-l2')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-l3')}}</p>
                    <a href="https://register.fca.org.uk/s/firm?id=0014G00002Vq7yNQAR" rel="nofollow" target="_blank">{{$t('security.security-page1.text2')}}</a>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/index/index-new-03.svg" alt="">
                    <p class="p2">{{$t('WhyEBC.screen3.desc-rr1')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-rr2')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-rr3')}}</p>
                    <a href="https://www.cima.ky/search-entities-cima/get_search_data" rel="nofollow" target="_blank">{{$t('security.security-page1.text2')}}</a>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/index/index-new-02.svg" alt="">
                    <p class="p2">{{$t('WhyEBC.screen3.desc-r1')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-r2')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-r3')}}</p>
                    <a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=yw3eguyzq_166" rel="nofollow" target="_blank">{{$t('security.security-page1.text2')}}</a>
                </div>
            </div>
        </div>
    </div>


    <div class="security2">
        <div class="container">
            <p class="public-title">{{$t('security.security-page2.text1')}}</p>
            <img class="public-icon" src="../assets/image/security/security-icon1.svg" alt="">
            <div class="row">
                <div class="col-md-12 list-box">
                    <img src="../assets/image/security/security4.png" alt="">
                    <div class="con-box">
                        <p class="p1">{{$t('security.security-page2.text2')}}</p>
                        <p class="p2">{{$t('security.security-page2.text3')}}</p>
                        <router-link :to="'/LearningCenterInfo?id=LEARN-196-367'">{{$t('security.security-page2.text10')}}</router-link>
                    </div>
                </div>
                <div class="col-md-12 list-box">
                    <img src="../assets/image/security/security5.png" alt="">
                    <div class="con-box">
                        <p class="p1">{{$t('security.security-page2.text4')}}</p>
                        <p class="p2">{{$t('security.security-page2.text5')}}</p>
                        <router-link :to="'/LearningCenterInfo?id=LEARN-770-2FF'">{{$t('security.security-page2.text10')}}</router-link>
                    </div>
                </div>
                <div class="col-md-12 list-box">
                    <img src="../assets/image/security/security6.png" alt="">
                    <div class="con-box">
                        <p class="p1">{{$t('security.security-page2.text6')}}</p>
                        <p class="p2">{{$t('security.security-page2.text7')}}</p>
                        <router-link :to="'/LearningCenterInfo?id=LEARN-2C9'">{{$t('security.security-page2.text10')}}</router-link>
                    </div>
                </div>
                <div class="col-md-12 list-box">
                    <img src="../assets/image/security/security7.png" alt="">
                    <div class="con-box">
                        <p class="p1">{{$t('security.security-page2.text8')}}</p>
                        <p class="p2">{{$t('security.security-page2.text9')}}</p>
                        <router-link :to="'/LearningCenterInfo?id=LEARN-369-368'">{{$t('security.security-page2.text10')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="security3">
        <div class="container">
            <p class="public-title">{{$t('WhyEBC.screen4.title')}}</p>
            <img class="public-icon" src="../assets/image/security/security-icon1.svg" alt="">
            <div class="row honor-list">
                <div class="swiper-wrapper slide-box">
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-647-43B')" :title="$t('WhyEBC.screen4.desc1')">
                        <img src="../assets/image/WhyEBC/about-11.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc1')">{{$t('WhyEBC.screen4.desc1')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-647-43B')" :title="$t('WhyEBC.screen4.desc2')">
                        <img src="../assets/image/WhyEBC/about-22.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc2')">{{$t('WhyEBC.screen4.desc2')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2DC')" :title="$t('WhyEBC.screen4.desc4')">
                        <img src="../assets/image/WhyEBC/about-33.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc4')">{{$t('WhyEBC.screen4.desc4')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2DC')" :title="$t('WhyEBC.screen4.desc5')">
                        <img src="../assets/image/WhyEBC/about-44.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc5')">{{$t('WhyEBC.screen4.desc5')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2E5')" :title="$t('WhyEBC.screen4.desc3')">
                        <img src="../assets/image/WhyEBC/about-55.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc3')">{{$t('WhyEBC.screen4.desc3')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-30-363')" :title="$t('WhyEBC.screen4.desc6')">
                        <img src="../assets/image/WhyEBC/about-66.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc6')">{{$t('WhyEBC.screen4.desc6')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-559-34F')" :title="$t('WhyEBC.screen4.desc7')">
                        <img src="../assets/image/WhyEBC/about-77.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc7')">{{$t('WhyEBC.screen4.desc7')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-486-3D7')" :title="$t('WhyEBC.screen4.desc8')">
                        <img src="../assets/image/WhyEBC/about-88.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc8')">{{$t('WhyEBC.screen4.desc8')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-518-4C3')" :title="$t('WhyEBC.screen4.desc9')">
                        <img src="../assets/image/WhyEBC/about-99.png" alt="">
                       <p v-if="$t('WhyEBC.screen4.desc9')">{{$t('WhyEBC.screen4.desc9')}}</p>
                    </div>
                </div>    
            </div>
        </div>
    </div>
      <page-footer></page-footer>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
    import Swiper from 'swiper'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				infoData: "",
				newData: "",
                pagIndex: 0,
                num: 3,
                newsdisplay: "none"
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
            $('html,body').animate({
				scrollTop: 0
			}, 0);
		},
        mounted(){
           window.addEventListener('scroll', this.scroll)
           if (!this.isMobile()) {
          this.swiper = new Swiper('.security3 .honor-list', {
          lazy: {
            loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
            loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
          },
          zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
            maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
            minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
            toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
          },
          navigation: { // 如果需要前进后退按钮
            nextEl: '.security3 .swiper-button-next',
            prevEl: '.security3 .swiper-button-prev'
          },
          scrollbar: { // 如果需要滚动条
            el: '.swiper-scrollbar',
            hide: true // 滚动条是否自动隐藏，默认false
          },
          slidesPerView: 4,
          spaceBetween: 60,
          centeredSlides: true,
          mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
          direction: 'horizontal', // 滑动方向
          speed: 6000, // 切换速度，自动滑动开始到结束的时间
          grabCursor: false, // 悬浮时鼠标样式切换
          centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
          effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
          cubeEffect: { // cube效果参数
            slideShadows: false, // 是否开启slide投影，默认true
            shadow: false, // 是否开启投影，默认true
            shadowOffset: 20, // 投影距离。默认 20，单位px。
            shadowScale: 1 // 投影缩放比例。默认0.94。
          },
          // autoplay: true, // 启动自动切换，等同于以下设置
          autoplay: {
            delay: 0,
            disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
            waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
          },
          loop: true // 循环模式选项
        })
        // 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
        this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
          this.swiper.autoplay.stop()
        }
        this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
          this.swiper.autoplay.start()
        }
      }else{
        this.swiper = new Swiper('.security3 .honor-list', {
          lazy: {
            loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
            loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
          },
          zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
            maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
            minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
            toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
          },
          navigation: { // 如果需要前进后退按钮
            nextEl: '.security3 .swiper-button-next',
            prevEl: '.security3 .swiper-button-prev'
          },
          scrollbar: { // 如果需要滚动条
            el: '.swiper-scrollbar',
            hide: true // 滚动条是否自动隐藏，默认false
          },
          slidesPerView: 1.6,
				  spaceBetween: 50,
				  centeredSlides: true,
          mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
          direction: 'horizontal', // 滑动方向
          speed: 6000, // 切换速度，自动滑动开始到结束的时间
          grabCursor: false, // 悬浮时鼠标样式切换
          centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
          effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
          cubeEffect: { // cube效果参数
            slideShadows: false, // 是否开启slide投影，默认true
            shadow: false, // 是否开启投影，默认true
            shadowOffset: 20, // 投影距离。默认 20，单位px。
            shadowScale: 1 // 投影缩放比例。默认0.94。
          },
          // autoplay: true, // 启动自动切换，等同于以下设置
          autoplay: {
            delay: 0,
            disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
            waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
          },
          loop: true // 循环模式选项
        })
        // 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
        this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
          this.swiper.autoplay.stop()
        }
        this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
          this.swiper.autoplay.start()
        }
      }

            
        },
		methods: {
			jumPage(id) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: id
					}
				})
			},
            isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			}
		}
	}

</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
@font-face{
    font-family: 'Baskerville Old Face';
    src : url('../assets/font/BaskervilleOldFace.ttf');
    font-style: normal;
    font-display: swap;
}


.public-title{
    color: #3D0101;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 2px;
}
.public-line{
    width: 70px;
    border: 1px solid #AF8147;
    margin: 36px auto 46px;
}
.public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.public-desc{
    width: 70%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    margin: 18px auto 0;
    letter-spacing: 1px;
}

.security1{
  background: url(../assets/image/security/security-bg.jpg) 100%/auto 100% no-repeat;
  height: 650px;
  margin-top: 0px;
  text-align: center;
  padding-top: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.security1 .public-title{
  color: #ffffff;
}
.security1 .public-line{
  margin: 36px auto 70px;
}

.public-icon{
  width: 60px;
  margin: 30px auto 44px;
  display: block;
}

.security1 .row img {
    width: auto;
    height: 90px;
    display: inline-block;
    margin-bottom: 14px;
}
.security1 .container{
  width: 1200px;
}
.security1 .p2 {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #ffffff;
    width: auto;
    line-height: 26px;
    opacity: 0.9;
    font-family: auto;
}
.security1 a{
  padding: 12px 24px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  border: 1px solid #FFF;
  font-family: auto;
  margin-top: 30px;
  display: inline-block;
}
.security1 a:hover{
  background: #4D0101;
  border: 1px solid #4D0101;
}



.security3{
  margin-top: 0px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 90px;
}
.security3 .container{
  width: 1200px;
}
.security3 .public-title{
  color: #333;
}
.security3 .row img{
  width: 260px;
}
.security3 .row p{
  color: #333333;
  font-size: 16px;
  line-height: 24px;
  font-family: auto;
}
.security3 .row .img11 {
    width: 200px;
    margin: 35px auto 20px;
}
.security3 .row .img33 {
    width: 90px;
    margin: 22px auto 20px;
}
.security3 .row .img55 {
    width: 140px;
    margin: 35px auto 20px;
}
.security3 .row span{
    color: #3D0101;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 10px;
    display: inline-block;
    line-height: 22px;
}


.en-style .public-title{
  letter-spacing: 0px;
}
.en-style .public-desc{
  letter-spacing: 0px;
  width: 76%;
}

.en-style .security1 .p2{
  letter-spacing: 0px;
}

.en-style .security3 .row p{
  letter-spacing: 0px;
}



.security3 .honor-list{
  overflow: hidden;
  padding-right: 22px;
  height: auto;
}

.security3 .row img{
  margin: 0px auto 30px;
}

.security3 .swiper-button-prev {
    left: -30px;
    background-image: url(../assets/image/security/about-arrow-left.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 62%;
}

.security3 .swiper-button-next {
    right: -30px;
    background-image: url(../assets/image/security/about-arrow-right.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 62%;
}
.security3 .container{
  position: relative;
}
.security3 .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  content: "";
}
.security3 .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  content: "";
}

.honor-list .swiper-wrapper{
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}


.security1 .row div{
  text-align: center;
}

.security2{
  margin-top: 0px;
  padding-top: 90px;
  padding-bottom: 60px;
  background: #F8F8F8;
}
.security2 .public-title{
  color: #333;
}
.security2 .container{
  width: 1200px;
}
.security2 .row .list-box{
  padding: 20px 30px 20px;
  border-radius: 5px;
  border: 1px solid #EBEBEC;
  background: #FFF;
  display: flex;
  margin-bottom: 30px;
}
.security2 .row .list-box:hover{
  border: 1px solid #AF8147;
}
.security2 .row .list-box:hover .p1{
  color: #AF8147;
}
.security2 .row img{
  width: 30%;
  float: left;
}
.security2 .con-box{
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3%;
}
.security2 .row .p1{
  color: #333;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
}
.security2 .row .p2{
  color: #666;
  font-size: 14px;
  line-height: 22px;
  font-family: auto;
  margin-top: 20px;
}
.security2 .row a{
  color: #4D0101;
  padding: 12px 24px;
  border: 1px solid #4D0101;
  font-size: 14px;
  font-family: auto;
  display: inline-block;
  margin-top: 20px;
  width: max-content;
}
.security2 .row a:hover{
  color: #ffffff;
  background: #4D0101;
}

.security-en-box .public-title{
  letter-spacing: 0px;
}

.security-en-box .security2 .row .list-box{
  padding: 32px 30px;
}



@media(max-width:1400px) {
  .security1 .p2{
    font-size: 14px;
  }
}

@media(max-width:1300px) {
    .security3 .swiper-button-prev {
      left: -5px;
    }
    .security3 .swiper-button-next {
      right: -5px;
    }
}    

@media(max-width:1200px) {
  

}

@media(max-width:995px) {
  .security1 .container{
    width: 100%;
  }
  .security3 .container{
    width: 100%;
  }

  .home-header .navbar-brand img{
    width: 120px;
    height: auto;
  }
    
   
    .public-title{
      letter-spacing: 1px;
      font-size: 30px;
    }

    .public-desc{
      width: 90%;
      color: #999;
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      margin-top: 10px;
    }
    .public-line{
      width: 50px;
      margin: 30px auto 30px;
    }
    .security1{
      background: url(../assets/image/security/security-bg-m.jpg) 100%/auto 100% no-repeat;
      height: auto;
      margin-top: 0px;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .security1{
      text-align: center;
    }
    .security1 .public-title{
      margin-top: 70px;
      font-size: 28px;
    }
    .security1 .row img{
      float: unset;
      width: 38%;
      height: auto;
      margin-bottom: 12px;
    }
    .security1 .p2{
      font-size: 14px;
      float: unset;
      text-align: center;
      line-height: 24px;
      margin-left: 0px;
    }
    .security1 .public-line{
      margin: 30px auto 40px;
    }
    .security1 .row div{
      margin-bottom: 60px;
    }
    .security1 a{
      margin-top: 16px;
    }
    .security3 {
        height: auto;
        margin-top: 0px;
        text-align: center;
        padding-top: 60px;
        padding-bottom: 70px;
    }
    .security3 .row img{
      width: 186px;
    }
    .security3 .row p{
      margin-bottom: 26px;
    }
    
    .en-style .public-desc{
      width: 90%;
    }
    .en-style .public-title{
      line-height: 36px;
      font-size: 24px;
      padding: 0 5%;
    }
    .en-style .security1{
      height: auto;
    }


    


    .security3 .swiper-button-prev{
      display: none;
    }
    .security3 .swiper-button-next{
      display: none;
    }
    .security3 .swiper-slide{
      /*flex-shrink: unset;*/
    }
    .security3 .swiper-wrapper{
      /*display: block;*/
    }
    .security3 .row img{
      width: 196px;
      margin-bottom: 16px;
    }
    .security3 .honor-list{
      height: auto;
      width: 100%;
    }
    .security3 .container{
      padding: 0;
    }
    .security3 .row img{
        width: 260px;
    }
    .security3 .row{
      margin: 0 auto;
    }
    .security3 .row p{
      margin-bottom: 0;
    }
    .security3 .row .img11,.security3 .row .img33,.security3 .row .img55{
        margin-top: 24px;
    }
    .security3 .row .img11{
        width: 185px;
    }
    .security3 .row .img55{
        width: 130px;
    }
    .security3{
      padding-bottom: 50px;
    }
    .security3 .row p{
      font-size: 16px;
    }
    .security2 .container{
      width: 100%;
    }
    .security2{
      padding-top: 60px;
      padding-bottom: 40px;
    }
    .security2 .public-title{
      font-size: 28px;
    }
    .security2 .row img{
      width: 100%;
      height: auto;
    }
    .security2 .con-box{
      width: 100%;
      margin-left: 0;
    }
    .security2 .row .list-box{
      display: block;
      text-align: center;
      width: 95%;
      margin: 0 auto 30px;
      padding: 0px 20px 10px;
    }
    .security2 .row a{
      margin: 30px auto;
      width: 65%;
      padding: 16px 24px;
      background: #4D0101;
      color: #fff;
    }
    .security2 .row .p1{
      font-size: 20px;
      line-height: 26px;
    }

    .security-en-box .security2 .row .list-box{
      padding: 0px 20px 10px;
    }

    .security-en-box .public-title{
        line-height: 32px;
        padding: 0 10px;
        font-size: 26px;
    }
}

@media(max-width:406px) {

    
}


</style>

