export default {
  fontFamily: "cn-font-family",
  classLang: "cn",
  headlist: [
    {
      text: "首页",
      url: "/",
    },
    {
      text: "交易",
      url: "",
      menu: [
        {
          tit: "账户&条件",
          list: [
            {
              text: "交易账户",
              url: "/TradingAccount",
            },
            {
              text: "交易产品",
              url: "/tradingproduct",
            },
            {
              text: "杠杆与保证金",
              url: "/leverage-margin",
            },
            {
              text: "出入金",
              url: "/funding",
            },
            {
              text: "股息",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "交易工具",
          list: [
            {
              text: "MT4下载",
              url: "/MT4",
            },
            {
              text: "MT5下载",
              url: "/MT5",
            },
            {
              text: "MT4交易工具",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "市场分析",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "EBC研究院",
      url: "",
      menu: [
        {
          tit: "",
          list: [
            {
              text: "学习中心",
              url: "/LearningCenter",
            },
            {
              text: "数据中心",
              url: "/lab",
            },
          ],
        },
      ],
    },
    {
      text: "最新活动",
      url: "/activity",
      // menu: [],
    },
    {
      text: "关于EBC",
      url: "",
      menu: [
        {
          tit: "关于EBC",
          list: [
            {
              text: "关于我们",
              url: "/about-us",
            },
            {
              text: "社会责任",
              url: "/ESG",
            },
            {
              text: "EBC动态",
              url: "/LearningCenter?tabId=1",
            },
          ],
        },
        {
          tit: "合作伙伴",
          list: [
            {
              text: "影响力联盟",
              url: "/affiliate-programmes",
            },
            {
              text: "机构服务",
              url: "/service",
            },
            {
              text: "底层科技",
              url: "/technology",
            },
          ],
        },
      ],
    },
    {
      text: "跟单社区",
      url: "/from0tohero",
    },
  ],
  FCA开户: "FCA开户",
  立即开户: "立即开户",
  登录: "登录",
  官方验证: "官方验证",
  帮助中心: "帮助中心",
  帮助中心Url: "https://support.ebchelp.com/hc/zh-cn",
};
