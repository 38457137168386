<template>
    <div>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap" rel="stylesheet">
        <page-navigate></page-navigate>
        <div class="cn-box home" :class="$t('TradingCentral.type')">
            <div class="section-1">
                <div class="section-1-box">
                    <div class="content">
                        <img class="bg-m" src="@/assets/image/trading_central/bg_block_m.png" alt="" />
                        <div class="title">{{ $t('TradingCentral.Trading Central') }} <br />{{ $t('TradingCentral.市场分析')
                            }}</div>
                        <div class="sub-title">
                            {{ $t('TradingCentral.EBC与TradingCentral携手合作') }}
                        </div>
                        <div class="list">
                            <div class="item">
                                <span class="iconfont icon-Vector"></span>
                                {{ $t('TradingCentral.分析师观点') }}
                            </div>
                            <div class="item">
                                <span class="iconfont icon-Vector"></span>
                                {{ $t('TradingCentral.财经日历解析') }}
                            </div>
                            <div class="item">
                                <span class="iconfont icon-Vector"></span>
                                {{ $t('TradingCentral.Alpha-MT4/MT5技术指标') }}
                            </div>
                            <div class="item">
                                <span class="iconfont icon-Vector"></span>
                                {{ $t('TradingCentral.Martin Pring教学视频') }}
                            </div>
                        </div>
                        <a :href="$store.state.khUrl" rel="nofollow" class="register">{{ $t('TradingCentral.解锁完整版') }}
                            <span class="iconfont icon-chevron-right"></span>
                        </a>
                        <img class="bg" src="@/assets/image/trading_central/bg_block.png" alt="" />
                    </div>
                    <div class="tips">
                        {{ $t('TradingCentral.*本页面的信息不构成EBC') }}
                    </div>
                </div>
                <!-- <h1 class="p1">市场分析</h1> -->
            </div>
            <div class="section-box">
                <div class="title">{{ $t('TradingCentral.分析师观点') }}</div>
                <div class="sub-title">
                    {{ $t('TradingCentral.通过清晰的趋势线展示简明易行的交易计划') }}
                </div>
                <div class="content">
                    <div class="app">
                        <div class="nav-list">
                            <div class="nav-item" :class="{ 'active': item.isActive }" @click="handleNavChange(item)"
                                v-for="(item, index) in navList" :key="index">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="data-content">
                            <div class="data-left scrollbar-container">
                                <div v-for="(item, index) in dataList" :key="index">
                                    <div class="data-item" :class="{ 'isSelected': item.isSelected }"
                                        @click="handlerSelect(item, index)">
                                        <div class="flex-between">
                                            <div class="data-name">
                                                {{ item.analysis.content.header.name['#text'] }}
                                            </div>
                                            <div class="data-time">
                                                {{ item.hover }}(UTC+3)
                                                <!-- |{{item.analysis.content.header.hour['#text'] }} -->
                                            </div>
                                        </div>
                                        <div class="data-title"
                                            :class="[{ 'up': item.opinion > 0 }, { 'down': item.opinion < 0 }]">
                                            <span class="iconfont icon-up"></span>
                                            {{ item.analysis.content.story.title['#text'] }}
                                        </div>
                                        <div class="data-summary">
                                            {{ item.analysis.content.story.summary['#text'] }}
                                        </div>
                                    </div>
                                    <transition name="fade">
                                        <div v-if="currentItem.analysis && item.isSelected && mobileShow"
                                            class="mobile-item">
                                            <img style="margin: 0 auto"
                                                :src="currentItem.analysis.content.header.media['#text']" alt="" />
                                            <div class="info-list">
                                                <div class="info-paragraph"
                                                    v-for="(item, index) in currentItem.analysis.content.story.paragraph">
                                                    <template v-if="item['@attributes']">
                                                        <div>{{ item['#text'] }}</div>
                                                    </template>
                                                    <template v-else>
                                                        <div v-for="(str, i) in getOptionsStr(item['#text'])"
                                                            :class="{ 'label': i == 0 }">
                                                            {{ str }}
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="info-paragraph" style="font-style: italic">
                                                    {{ $t('TradingCentral.星号数代表支撑位和阻力位的强度') }}
                                                </div>
                                            </div>
                                            <div class="flex-right">
                                                <div class="btn" @click="mobileShow = false">
                                                    <span class="iconfont icon-up2"></span>
                                                    {{ $t('TradingCentral.收起') }}
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                            <div class="data-right scrollbar-container">
                                <div v-if="currentItem.analysis">
                                    <img style="margin: 0 auto"
                                        :src="currentItem.analysis.content.header.media['#text']" alt="" />
                                    <div class="info-list">
                                        <div class="info-paragraph"
                                            v-for="(item, index) in currentItem.analysis.content.story.paragraph">
                                            <template v-if="item['@attributes']">
                                                <div>{{ item['#text'] }}</div>
                                            </template>
                                            <template v-else>
                                                <div v-for="(str, i) in getOptionsStr(item['#text'])"
                                                    :class="{ 'label': i == 0 }">
                                                    {{ str }}
                                                </div>
                                            </template>
                                        </div>
                                        <div class="info-paragraph" style="font-style: italic">
                                            {{ $t('TradingCentral.星号数代表支撑位和阻力位的强度') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="loading-box" v-show="loading">
                            <span class="iconfont icon-loading"></span>
                        </div>
                    </div>
                </div>
                <div class="tips">
                    {{ $t('TradingCentral.*本页面的信息不构成EBC') }}
                </div>
            </div>

            <div class="section-box">
                <div class="title">{{ $t('TradingCentral.财经日历解析') }}</div>
                <div class="sub-title">
                    {{ $t('TradingCentral.提供38个国家') }}

                </div>
                <div class="content">
                    <iframe id="TCIframe" class="scrollbar-container" :src="TCIframe"></iframe>
                </div>
            </div>

            <div class="section-box">
                <div class="title">{{ $t('TradingCentral.Alpha-MT4/MT5 技术指标') }}</div>
                <div class="sub-title">
                    {{ $t('TradingCentral.通过三个简明的创新指标展示市场情绪') }}
                </div>
                <div class="content">
                    <div class="left">
                        <img src="@/assets/image/trading_central/section_bg_3.png" alt="" />
                    </div>
                    <div class="right">
                        <div class="item">
                            <div class="icon">
                                <span class="iconfont icon-Vector"></span>
                            </div>
                            <div class="info">
                                <p class="label">{{ $t('TradingCentral.技术观点') }}</p>
                                <p class="value">
                                    {{ $t('TradingCentral.通过技术分析结果') }}
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="icon">
                                <span class="iconfont icon-Vector"></span>
                            </div>
                            <div class="info">
                                <p class="label">{{ $t('TradingCentral.自适应K线图') }}</p>
                                <p class="value">{{ $t('TradingCentral.支持16种与决策相关的K线图形模式') }}</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="icon">
                                <span class="iconfont icon-Vector"></span>
                            </div>
                            <div class="info">
                                <p class="label">{{ $t('TradingCentral.自适应趋异指标(ADC)') }}</p>
                                <p class="value">
                                    {{ $t('TradingCentral.长短进/出信号') }}

                                </p>
                            </div>
                        </div>
                        <a href="https://mt.tradingcentral.com/download" rel="nofollow" class="btn" type="blank">{{
                            $t('TradingCentral.下载指标') }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="section-box">
                <div class="title">{{ $t('TradingCentral.Martin Pring 教学视频') }}</div>
                <div class="sub-title">
                    {{ $t('TradingCentral.通过Martin Pring的视频课程') }}
                </div>
                <div class="content">
                    <div class="left">
                        <img src="@/assets/image/trading_central/section_bg_4.png" alt="" />
                    </div>
                    <div class="right">
                        <div class="item">
                            <div class="icon">
                                <span class="iconfont icon-Vector"></span>
                            </div>
                            <div class="info">
                                <p class="label">{{ $t('TradingCentral.灵活的学习模式') }}</p>
                                <p class="value">
                                    {{ $t('TradingCentral.可以自由安排学习进度') }}
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="icon">
                                <span class="iconfont icon-Vector"></span>
                            </div>
                            <div class="info">
                                <p class="label">{{ $t('TradingCentral.广泛的内容覆盖') }}</p>
                                <p class="value">
                                    {{ $t('TradingCentral.从初级到高级') }}

                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="icon">
                                <span class="iconfont icon-Vector"></span>
                            </div>
                            <div class="info">
                                <p class="label">{{ $t('TradingCentral.互动学习体验') }}</p>
                                <p class="value">
                                    {{ $t('TradingCentral.包含术语表和自我评估等互动工具') }}
                                </p>
                            </div>
                        </div>
                        <a :href="$store.state.khUrl" rel="nofollow" class="btn" type="blank">{{ $t('TradingCentral.了解更多') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <page-footer></page-footer>
    </div>
</template>
<script>
import moment from 'moment';

// XML解析函数
function parseElement(element) {
    let obj = {};
    if (element.nodeType === 1) {
        // Element
        if (element.attributes.length > 0) {
            obj["@attributes"] = {};
            for (let j = 0; j < element.attributes.length; j++) {
                let attribute = element.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (element.nodeType === 3) {
        // Text
        obj = element.nodeValue;
    }

    if (element.hasChildNodes()) {
        for (let i = 0; i < element.childNodes.length; i++) {
            let item = element.childNodes.item(i);
            let nodeName = item.nodeName;
            if (typeof obj[nodeName] === "undefined") {
                obj[nodeName] = parseElement(item);
            } else {
                if (typeof obj[nodeName].push === "undefined") {
                    let old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(parseElement(item));
            }
        }
    }
    return obj;
}

export default {
    data() {
        return {
            message: "Hello Vue!",
            navList: [
                { name: this.$i18n.t('TradingCentral.衍生品'), value: "forex", isActive: true },
                { name: this.$i18n.t('TradingCentral.指数'), value: "index", isActive: false },
                { name: this.$i18n.t('TradingCentral.商品'), value: "commodities", isActive: false },
            ],
            currentNav: "forex",
            currentItem: {},
            dataList: [],
            loading: false,
            mobileShow: true,
            TCIframe:""
        }
    },
    created() {
        this.getData();
        this.getUrl();
    },
    mounted() { },
    filters: {
        // 去除中文冒号
        removeColon(str) {
            if (str[str.length - 1] == "：") {
                return str.substring(0, str.length - 2);
            }
            return str;
        },
    },
    methods: {
        getUrl() {
            const lang = this.$i18n.t("TradingCentral.lang2");
            const formData = new FormData();
            formData.append('lang', lang);
            fetch('https://ebccfd.com/TradingCentral/EconomicCalendar/', {
                method: 'POST',
                body: formData
            }).then((res) => {
                return res.text()
            }).then((res)=>{
                console.log("res",res)
                this.TCIframe = res;
            }).catch(() => {
                
            })
            
        },
        getOptionsStr(str) {
            let reg1 = /:/;
            let reg2 = /：/;
            if (reg1.test(str)) {
                return str.split(":");
            } else if (reg2.test(str)) {
                return str.split("：");
            } else {
                return [str];
            }
        },
        handleNavChange(item) {
            // console.log("item", item, this.navList);
            if (item.value == this.currentNav) {
                return;
            }
            this.navList = this.navList.map((el) => {
                if (el.name == item.name) {
                    el.isActive = true;
                    this.currentNav = item.value;
                    this.getData();
                } else {
                    el.isActive = false;
                }
                return el;
            });
        },
        handlerSelect(item, index) {
            if (item.id === this.currentItem.id) {
                this.mobileShow = !this.mobileShow;
                return;
            }
            this.mobileShow = true;
            this.dataList = this.dataList.map((el, i) => {
                if (index == i) {
                    el.isSelected = true;
                    this.currentItem = this.createCurrentItem(el);
                } else {
                    el.isSelected = false;
                }
                return el;
            });
        },
        getData(pageSize = 10, pageNumber = 1) {
            const token = "%2bmWwS%2bbwWi1BYrke%2fZDrpA%3d%3d";
            const type_product = this.currentNav;
            this.loading = true;
            const lang = this.$i18n.t("TradingCentral.lang");
            fetch(
                `https://feed.tradingcentral.com/ws_ta.asmx/GetFeed?culture=${lang}
              &type_product=${type_product}&product=null&term=null&days=1&last_ta=true
              &pageSize=${pageSize}&pageNumber=${pageNumber}
              &partner=2010&token=${token}`,
                {
                    method: "get",
                }
            )
                .then((response) => response.text())
                .then((response) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(
                        response,
                        "application/xml"
                    );
                    const json = parseElement(xmlDoc.documentElement);
                    console.log(json);
                    this.dataList = json.article.map((el) => {
                        el.isSelected = false;

                        /*
                        Use <watch> element to know if the Technical Views is bullish or bearish.
                        Values can be 2, 1, -1, -2:
                        2 (rise), 1 (limited rise), 0 (consolidation), -1 (limited decline), -2 (decline)
      
                        **If <term> is “INTRADAY” then check <watch type=“opinionIntraday”>
                        If <term> is “ST” then check <watch type=“opinionST”>
                        If <term> is “MT” then check <watch type=“opinionMT”>
                        */
                        let opinion;
                        if (el.analysis.content.header.term["#text"] == "INTRADAY") {
                            console.log("INTRADAY");
                            el.analysis.content.header.option.watch.map((item) => {
                                if (item["@attributes"].type == "opinionIntraday") {
                                    opinion = item["#text"];
                                }
                            });
                        } else if (el.analysis.content.header.term["#text"] == "ST") {
                            console.log("ST");
                            el.analysis.content.header.option.watch.map((item) => {
                                if (item["@attributes"].type == "opinionST") {
                                    opinion = item["#text"];
                                }
                            });
                        } else if (el.analysis.content.header.term["#text"] == "MT") {
                            console.log("MT");
                            el.analysis.content.header.option.watch.map((item) => {
                                if (item["@attributes"].type == "opinionMT") {
                                    opinion = item["#text"];
                                }
                            });
                        }

                        let hover = moment(
                            el.analysis.content.header.date["#text"].substring(0, 4) +
                            "-" +
                            el.analysis.content.header.date["#text"].substring(4, 6) +
                            "-" +
                            el.analysis.content.header.date["#text"].substring(6) +
                            " " +
                            el.analysis.content.header.hour["#text"]
                        )
                            .add(1, "h")
                            .format("HH:mm");
                        el.id = el["@attributes"].id;
                        el.opinion = opinion;
                        el.hover = hover;
                        return el;
                    });

                    this.currentItem = this.createCurrentItem(this.dataList[0]);
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    console.log("err", err);
                });
        },
        createCurrentItem(item) {
            console.log("createCurrentItem", item);

            if (item) {
                item.isSelected = true;

                item.analysis.content.story.paragraph =
                    item.analysis.content.story.paragraph.filter((el) => {
                        return (
                            el["#text"] &&
                            (!el["@attributes"] ||
                                el["@attributes"].type != "disclaimer")
                        );
                    });
                return item;
            } else {
                return {};
            }
        },
    },

}
</script>
<style>
@charset "UTF-8";

.c-footer .fp-overflow {
    display: unset !important;
}

body {
    font-family: "Source Han Sans CN";
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.section-1 {
    position: relative;
    background: radial-gradient(129.79% 85.82% at 66.66% 87.6%,
            #0a0000 0%,
            #000 100%);
}

.section-1-box {
    position: relative;
    width: 100%;
    min-height: 661px;
    background: url(../assets/image/trading_central/bg_1.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
}

.section-1-box .content {
    position: relative;
    color: #fff;
    margin: 0 auto;
    padding: 100px 200px;
    max-width: 1440px;
    flex: 1;
}

.section-1-box .content .title {
    max-width: 616px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.en .section-1-box .content .title {
    font-size: 37px;
}

.section-1-box .content .title>br {
    display: none;
}

.section-1-box .content .sub-title {
    margin-top: 8px;
    max-width: 530px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
}

.section-1-box .content .list {
    margin-top: 28px;
}

.section-1-box .content .list .item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.section-1-box .content .list .item .icon-Vector {
    margin-right: 15px;
    font-size: 15px;
}

.section-1-box .content .bg {
    display: block;
    position: absolute;
    right: 67px;
    top: 85px;
    width: 722px;
}

.ar .section-1-box .content .bg {
    left: 67px;
    right: auto;
}

.section-1-box .content .bg-m {
    display: none;
}

.section-1 .tips {
    /* max-width: 1440px; */
    padding: 0 200px;
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
    position: absolute;
    bottom: 28px;
    text-align: center;
    /* left: 50%;
  transform: translate(-50%,0); */
}

.register {
    display: inline-flex;
    justify-content: center;
    min-width: 180px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #fff;
    color: #3d0101;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 28px;
    transition: all 0.2s;
}

.register .icon-chevron-right {
    font-size: 24px;
}

.register:hover {
    background-color: #3d0101;
    color: #fff;
}

.btn {
    display: flex;
    justify-content: center;
    /* width: 180px; */
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #fff;
    color: #3d0101;
    border-radius: 4px;
    border: 1px solid #3d0101;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 28px;
    transition: all 0.2s;
}

.btn[type="blank"] {
    display: inline-flex;
    justify-content: center;
    min-width: 180px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #5d0101;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 28px;
    transition: all 0.2s;
}

.btn[type="blank"] .icon-chevron-right {
    font-size: 24px;
}

.btn[type="blank"]:hover {
    background-color: #410101;
    color: #fff;
}

.section-box {
    margin: 0 auto;
    max-width: 1440px;
    padding: 44px 200px 74px;
}

.section-box .title {
    color: #333;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.en .section-box .title {
    font-size: 42px;
}

.section-box .sub-title {
    color: #666;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
    margin-top: 16px;
    padding: 0 80px;
}

.en .section-box .sub-title {
    font-size: 16px;
}

.jp .section-box .sub-title,
.kr .section-box .sub-title {
    font-weight: 400;
}

.section-box .content {
    margin-top: 40px;
    display: flex;
    column-gap: 80px;
    row-gap: 40px;
    padding: 40px 0;
}

.section-box .content .left {
    flex-shrink: 0;
    width: 524px;
    max-width: 50%;
}

.section-box .content .left img {
    width: 100%;
    height: auto;
}

.section-box .content .right .item {
    display: flex;
    justify-content: baseline;
}

.section-box .content .right .item .icon {
    font-size: 18px;
    color: #af8147;
    margin-right: 16px;
    margin-top: 3px;
    flex-shrink: 0;
}

.section-box .content .right .item .label {
    color: #666;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.section-box .content .right .item .value {
    margin-top: 8px;
    color: #666;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.section-box .content .right .item+.item {
    margin-top: 28px;
}

.section-box .tips {
    color: #949699;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
}

.app {
    position: relative;
    font-size: 12px;
    margin: 0 auto;
    width: 100%;
}

.mobile-item {
    display: none;
}

.app .nav-list {
    display: flex;
    margin-bottom: 20px;
}

.app .nav-list .nav-item {
    font-size: 18px;
    color: #999;
    cursor: pointer;
    padding: 13px 20px;
    border-bottom: 2px solid transparent;
    transition: background 0.2s;
    background: rgba(0, 0, 0, 0);
}

.app .nav-list .nav-item:hover {
    background: rgba(0, 0, 0, 0.03);
}

.app .nav-list .active {
    color: #3f62ec;
    border-bottom: 2px solid #3f62ec;
}

.app .data-content {
    display: flex;
    height: 500px;
    gap: 32px;
}

.app .data-content .data-left {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.app .data-content .data-left .data-item {
    padding: 10px;
    margin-bottom: 2px;
}

.app .data-content .data-left .data-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.app .data-content .data-left .isSelected {
    background-color: #ebebeb;
}

.app .data-content .data-left .data-name {
    font-size: 1rem;
    color: #333333;
}

.app .data-content .data-left .data-time {
    color: #5c5c5c;
    font-weight: 500;
    font-size: 0.75rem;
    white-space: nowrap;
    text-transform: uppercase;
    padding-top: 3px;
    padding-left: 15px;
}

.app .data-content .data-left .data-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 2px 0;
    color: #333333;
}

.app .data-content .data-left .data-title .icon-up {
    transform: rotate(90deg);
}

.app .data-content .data-left .up {
    color: #12823e;
}

.app .data-content .data-left .up .icon-up {
    transform: rotate(0);
}

.app .data-content .data-left .down {
    color: #d10000;
}

.app .data-content .data-left .down .icon-up {
    transform: rotate(180deg);
}

.app .data-content .data-left .icon-up {
    font-size: 20px;
    margin-right: 4px;
}

.app .data-content .data-left .data-summary {
    font-size: 14px;
    color: #333333;
    line-height: 120%;
}

.app .data-content .data-right {
    width: 35%;
    height: 100%;
    overflow-y: auto;
}

.app .data-content .info-list {
    margin-top: 18px;
}

.app .data-content .info-paragraph>div {
    margin-bottom: 12px;
    font-size: 14px;
    color: #333333;
}

.app .data-content .info-paragraph .label {
    font-weight: 600;
}

.app .loading-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.app .loading-box .icon-loading {
    font-size: 28px;
    color: #3d0101;
    animation: spin 2s linear infinite;
}

.fade-enter-active {
    transition: opacity 0.72s;
}

.fade-enter

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#TCIframe {
    width: 100%;
    /* padding-top: 40px; */
    /* height: 1500px; */
    height: 700px;
    border: none;
    margin: 0 -13px;
}

@media (min-width: 996px) {

    /* 滚动条轨道样式 */
    .scrollbar-container::-webkit-scrollbar {
        width: 8px;
        /* 设置滚动条宽度 */
    }

    /* 滚动条滑块样式 */
    .scrollbar-container::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        /* 设置滑块背景颜色 */
        /* border-radius: 2px; 设置滑块圆角 */
    }

    /* 滚动条轨道hover状态样式 */
    .scrollbar-container::-webkit-scrollbar-track:hover {
        background-color: #f1f1f1;
        /* 设置轨道hover状态时的背景颜色 */
    }

    /* 滚动条滑块hover状态样式 */
    .scrollbar-container::-webkit-scrollbar-thumb:hover {
        background-color: #d5d5d5;
        /* 设置滑块hover状态时的背景颜色 */
    }
}

@media (max-width: 1440px) {
    .section-1-box .content {
        padding: 100px 140px;
    }

    .section-1-box .content .bg {
        right: 17px;
    }

    .ar .section-1-box .content .bg {
        left: 17px;
        right: auto;
    }
}

@media (max-width: 1300px) {
    .section-1-box .content {
        padding: 100px 100px;
    }

    .section-1-box .content .bg {
        width: 650px;
        top: 100px;
        right: 0;
    }

    .ar .section-1-box .content .bg {
        right: auto;
        left: 0;
    }

    .en .section-1-box .content .bg {
        top: 120px;
    }

    .section-1-box .content .sub-title {
        max-width: 450px;
    }

    .section-1-box .content .list {
        max-width: 450px;
    }
}

@media (max-width: 1100px) {
    .section-1-box .content .bg {
        width: 600px;
        top: 100px;
    }

    .en .section-1-box .content .bg {
        top: 160px;
        width: 550px;
    }
}

@media (max-width: 996px) {
    .mobile-item {
        padding: 18px 10px;
        display: block;
    }

    .market-analysis-1 {
        height: 190px;
        padding-top: 106px;
    }

    .market-analysis-1 .p1 {
        color: #fff;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        margin-top: 0px;
    }

    #TCIframe {
        /* padding-top: 30px; */
        height: 120vh;
        margin: 0 auto;
    }

    .section-1-box {
        background: url(../assets/image/trading_central/bg_1_m.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
    }

    .section-1-box .content {
        padding: 105px 20px;
        width: 100%;
    }

    .en .section-1-box .content {
        padding: 105px 20px 130px;
    }

    .section-1-box .content .bg {
        display: none;
    }

    .section-1-box .content .bg-m {
        display: block;
        width: 100%;
        height: auto;
    }

    .section-1-box .content .title {
        text-align: center;
        margin: 5px auto 0;
        font-size: 36px;
        line-height: 140%;
    }

    .en .section-1-box .content .title {
        font-size: 36px;
    }

    .section-1-box .content .title>br {
        display: block;
    }

    .section-1-box .content .sub-title {
        padding: 0 8px;
        max-width: none;
    }

    .section-1-box .content .list .item {
        padding: 6px 8px;
        font-size: 17px;
    }

    .section-1-box .content .list .item .icon-Vector {
        margin-right: 7px;
        font-size: 13px;
    }

    .register {
        display: flex;
        width: auto;
        max-width: 274px;
        margin: 38px auto 0;

    }

    .en .register {
        font-size: 14px;
    }

    .btn[type="blank"] {
        display: flex;
        width: auto;
        max-width: 274px;
        margin: 38px auto 0;
    }

    .en .btn {
        font-size: 14px;
    }

    .section-1 .tips {
        font-size: 10px;
        padding: 0 28px;
    }

    .app {
        margin: 0;
        padding: 0 8px;
    }

    .app .data-content {
        height: auto;
        flex-direction: column;
    }

    .app .data-content .data-left {
        /* order: 2;
    max-height: 500px;
    overflow-y: auto; */
    }

    .app .data-content .data-right {
        display: none;
        /* order: 1;
    width: 100%;
    max-height: 500px;
    overflow-y: auto; */
    }

    .section-box .tips {
        margin-top: 40px;
    }

    .section-box {
        margin: 80px auto;
        padding: 0 20px;
    }

    .section-box .title {
        font-size: 40px;
        padding: 0 8px;
    }

    .section-box .sub-title {
        margin-top: 26px;
        padding: 0 8px;
    }

    .section-box .content {
        padding: 0;
        margin-top: 42px;
    }

    .section-box .content .left {
        margin: 0 auto;
        max-width: none;
        width: 100%;
    }

    .section-box .content .right {
        padding: 0 8px;
    }

    .section-box .content .right .item .value {
        margin-top: 14px;
    }

    .section-box .content {
        flex-direction: column;
    }
}
</style>