export default {
  fontFamily: "en-font-family",
  classLang: "en",
  headlist: [
    {
      text: "첫 장",
      url: "/",
    },
    {
      text: "거래",
      menu: [
        {
          tit: "계정 및 조건",
          list: [
            {
              text: "거래 계정",
              url: "/TradingAccount",
            },
            {
              text: "거래 제품",
              url: "/tradingproduct",
            },
            {
              text: "레버리지 마진",
              url: "/leverage-margin",
            },
            {
              text: "입출금",
              url: "/funding",
            },
            {
              text: "피제수",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "트레이딩 도구",
          list: [
            {
              text: "MT4 다운로드",
              url: "/MT4",
            },
            {
              text: "MT5 다운로드",
              url: "/MT5",
            },
            {
              text: "MT4 거래 도구",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "마케팅 분석",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "EBC 연구원",
      menu: [
        {
          tit: "",
          list: [
            {
              text: "교육 중심",
              url: "/LearningCenter",
            },
            {
              text: "데이터 중심",
              url: "/lab",
            },
          ],
        },
      ],
    },
    {
      text: "최신 이벤트",
      url: "/activity",
    },
    {
      text: "EBC 소개",
      menu: [
        {
          tit: "EBC 소개",
          list: [
            {
              text: "WHY EBC",
              url: "/about-us",
            },
            {
              text: "Sustainability & Impact",
              url: "/ESG",
            },
            {
              text: "FAQ",
              url: "https://support.ebchelp.com/hc/en-001",
            },
          ],
        },
        {
          tit: "협력 모드",
          list: [
            {
              text: "임팩트 얼라이언스",
              url: "/affiliate-programmes",
            },
            {
              text: "기관 서비스",
              url: "/service",
            },
            {
              text: "기반 기술",
              url: "/technology",
            },
          ],
        },
      ],
    },
  ],
  FCA开户: "FCA계좌개설",
  立即开户: "회원가입",
  登录: "로그인",
  官方验证: "공식 채널 인증",
  帮助中心: "FAQ",
  帮助中心Url: "https://support.ebchelp.com/hc/en-001",
};
