export default {
  screen0: {
    enTitle:
      "<span style='color: #800000;font-family: 'Source Serif Pro', Georgia, 'Times New Roman', serif;'>Your Trust, Our Protection<span>",
    title: "We regard online security as our top priority. ",
    text: "At EBC, we provide a range of safe, instant and easy-to-use deposit and withdrawal options.You can withdraw all your profits or initial investment at any time. To ensure full protection of our clients’ assets, we implement a high-level encryption technology based on industry best practices for online payment system, and take extra verification measures to ensure the security of all your funds at EBC. ",
    rightText: "Get your future started.",
  },
  screen1: {
    smallEnTitle: "Multiple Deposit Methods",
    smallTitle: "",
    title:
      "Multiple deposit methods available: <span style='color: #800000;'>We support UnionPay debit card, T/T, PayPal and other options for deposits.</span>",
    desc: "To help ensure that you have more funds remained for trading, <span style='color: #800000;'>EBC will not charge any fee</span> on deposit in or withdraw from the trading account.",
    list: [
      "deposit method ",
      " minimum deposit ",
      " deposit arrival time ",
      " minimum deposit ",
      "payment currency ",
      " service charge ",
      " deposit processing time ",
    ],
    btn: "Fund Now",
    summary: "Notes: ",
    imgUrl: "en",
    summaryDesc:
      "<p>1. Specific accounting time depends on the situation of banks and intermediary institutions.</p><p>2. Specific limit amount may vary from the stipulations of client’s issuing bank</p><p>3. Please be noted that if you hold a position in your account upon submission of withdrawal application, please pay attention to the amount of your withdrawal to avoid any impact on your position. </ p>",
  },
  screen2: {
    title: "Become one of millions around the world",
    desc: "<span style='color: #800000;'> Only 3 Steps away</span>",
    list: [
      {
        title: "ACCOUNT REGISTRATION",
        desc: "Submit basic information on the safe and straight-forward online application page.",
      },
      {
        title: "SUBMISSION OF PERSONAL INFORMATION",
        desc: "Just takes 1 minute to complete.",
      },
      {
        title: "DEPOSIT AND ACTIVATION",
        desc: "Place a minimum deposit of USD 200 to activate your account instantly.",
      },
    ],
    btn1: "Try a Free Demo",
    btn2: "Open a Live Account",
    yuyan: "en",
  },
  screen3: {
    title: "Deposit and Withdrawal Conditions",
    faqList: [
      {
        title: "How do I Deposit Money into my Trading Account?",
        desc: `After creating an account, you may access to your payment preference through our User Center.<br/><br/>
Specific procedures:<br/><br/>
Simply login to your User Center, and click on the green “Deposit” button on the left of the interface.<br/><br/>
Step 1: Select the account you’d like to deposit in as system prompts.<br/><br/>
Step 2: Then select the payment method by using the button on the right of the payment method table.<br/><br/>
Step 3: State how much you’d like to deposit into your account.<br/><br/>
The system may prompt you to enter the currency of the deposit you’d like to use or other options. EBC account provides you with various options for base currencies, including the most commonly used CNY, USD, GBP and EUR.<br/><br/>
Please carefully read all of the instructions, terms and conditions and if you agree, please make sure that you check the box that says “I have read all instructions and agree with terms and conditions of payments operations.”<br/><br/>`,
      },
      {
        title: "Why was my UnionPay Deposit Unsuccessful?",
        desc: `If you fail to deposit by using your debit card, please try to deposit again, while checking if:<br/><br/>
– Whether you have entered your card information correctly.<br/><br/>
– Whether you are using a vaild (not expired) card.<br/><br/>
– Whether you have sufficient funds on your card.<br/><br/>
– If there’s no above-mentioned problem, you still fail to deposit, it may mean that your card issuing bank does not authorize payment from your card.<br/><br/>
In that case, please use another card or any other payment method available in your trading account.`,
      },
      {
        title: "How do I Withdraw Money from my Trading Account?",
        desc: `Our account department processes funds withdrawal request everyday, so you may make your funds withdrawal request at anytime you wish. The withdrawn funds will be transferred to your specified bank account. Upon the failure of transfer, the withdrawn funds will be returned to your trading account’s wallet. Please note the actually processing time of wired transfer will be depended on the processing banks. Please consult the bank for details.<br/><br/>
Please make sure the ratio of prepaid deposit is still over 200% deducting the withdrawn funds while holding positions. Otherwise we won’t be able to proceed with your funds withdrawal request.`,
      },
      {
        title: "Does EBC Charge Any Transaction Fees?",
        desc: `EBC do not charge any transaction fees for using our deposit and withdrawal methods, but some costs may occur in payment you make to or from certain international banking institutions, which is beyond our control. Besides, pleased be noted that your bank may consider the payment to EBC as cash advance, and may bill you according to your cash prepayment rules.`,
      },
      {
        title: "Can I use Other People's Bank Card to Deposit/Withdraw?",
        desc: `No. EBC does not accept third-party payments. Please make sure that all deposits into your trading account are from your own account. If the holder of a trading account is a party to a joint account, payment from the joint account will be accepted.<br/><br/>
If we suspect that the funds are provided by any third party, we will reserve the right to refund the remitter’s money, freeze your account balance, and suspend your application after the identity and fund source are verified. We will reserve the right to freeze, and you will not be allowed to withdraw funds from your account balance before the verification.<br/><br/>
EBC do Not accept fund withdrawal to a third party bank account. If there’s any special situation, please contact us.`,
      },
    ],
  },
  funding1: {
    text1: "Deposit and Withdrawal",
    text2:
      "Ensuring safety and preventing fraud in online trading is our top priority.",
    btn: "Deposit Now",
  },
  funding2: {
    text1: "Global Multicurrency Funds Solution",
    text2:
      "Provide instant deposits and withdrawals for major global currencies, streamline international and cross-currency transactions, and enable convenient, 24/7 fund movement.",
  },
  funding3: {
    text1: "Fund Isolation and Independent Keeping",
    text2: "Corporate Banking Account",
    text3:
      "EBC has Barclays Corporate Banking Account which  requires a minimum turnover of £6.5m, strict financial audits and background check. Adhering to CASS, EBC group (UK) has customer funds held at a custody account through Trust Letter to ensure safety.",
    btn: "Learn More",
  },
  funding4: {
    text1: "Professional Financial Liability Coverage",
    text2:
      "Comprehensive international financial liability coverage, fully protecting client funds and minimizing risk.",
  },
  funding5: {
    text1: "Deposit and Withdrawal Methods",
    text2:
      "We support local bank transfer, international wire transfer, electronic wallet and crypto currencies etc.",
    text3: "Deposit Mothods",
    text4: "Supported Currencies",
    text5: "Single Deposit Limit",
    text6: "Deposit Processing Time",
    text7: "Withdrawal Processing Time",
    text8: "Local Bank",
    text9: "1 - 15 Min",
    text10: "1 Working Day",
    text11: "International Wire Transfer",
    text12: "1000-100000",
    text13: "2-5 Working Days	",
    text14: "Digital Wallet",
    text15: "Digital Currency	",
  },
  入金到账说明:`*Deposit Processing Instructions: On business days, the processing time is approximately 1 to 15 minutes. If the deposit is not received after this period, please promptly contact customer service or send your order number and payment screenshot via email to cs@ebc.com for inquiries.Withdrawal Processing Instructions: Withdrawal requests submitted before 11:00 (GMT+3) on business days will be processed as soon as the same day. Requests submitted after 11:00 (GMT+3) will be delayed to the next business day for processing.`
};
