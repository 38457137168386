export default {
    "box-class-name": "en-box",
    "lang": "th",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTader 5 (MT5) เป็นแพลตฟอร์มการเทรดมีหลายสินทรัพย์รุ่นใหม่ รวบรวมฟังก์ชันการเทรดขั้นสูงและเครื่องมือวิเคราะห์สำหรับตอบสนองความต้องการที่หลากหลายของนักเทรดทั่วโลก",
    "提供市场深度信息": "มอบข้อมูลเชิงลึกแนวโน้มตลาดทำให้นักเทรดสามารถเข้าใจสภาพคล่องของตลาดได้ดียิ่งขึ้น",
    "支持6种挂单交易类型": "รองรับคำสั่งซื้อที่รอดำเนินการ 6 ประเภทเพื่อตอบสนองความต้องการที่หลากหลายอย่างเพิ่มความยืดหยุ่น",
    "内置经济日历": "ปฏิทินเศรษฐกิจ ทันทุกเหตุการณ์สำคัญทางเศรษฐกิจทั่วโลกแบบเรียลไทม์",
    "立即注册": "ลงทะเบียนตอนนี้",
    "MetaTrader 5下载": "ดาวน์โหลด MetaTrader 5",
    "MT5移动版": "MT5 สำหรับ มือถือ",
    "随时随地进行交易": "เทรดได้ทุกที่ทุกเวลา ติดตามแนวโน้มของตลาดได้อย่างใกล้ชิด เตรียมความพร้อมจัดการบัญชีของคุณได้อย่างง่ายดาย",
    "下载Android版": "MT5 สำหรับ Android",
    "下载IOS版": "MT5 สำหรับ iOS",
    "下载iPad版": "MT5 สำหรับ iPad",
    "MT5桌面版": "MT5 Desktop",
    "全面的交易工具和高级图表分析": "เครื่องมือการเทรดที่คลอบคลุมและวิเคราะห์กราฟขั้นสูงตอบสนองทุกความต้องการของนักเทรดมืออาชีพ",
    "下载Windows版": "MT5 สำหรับ Windows",
    "下载MacOS版": "MT5 สำหรับ MacOS",
    "MT5网页版": "MT5 WebTrader",
    "无需下载": "ไม่ต้องดาวน์โหลดแอพก็เข้าถึงตลาดโลกได้ทำธุรกรรมออนไลน์ได้อย่างสะดวก รวดเร็ว และครอบคลุม",
    "打开网页版": "เปิดใช้งาน Webtrader",
    "MT4与MT5的区别": "ความแตกต่างของMT4 และ MT5",
    "MT4和MT5均由MetaQuotes开发": "MT4 และ MT5 พัฒนาโดย MetaQuotes อุปกรณ์ MT5 มีความแตกต่างกับ อุปกรณ์ MT4 ที่ชัดเจนในด้านฟังก์ชันและวิธีการใช้งานต่างๆที่มีการปรับปรุงให้คลอบคลุมมากขึ้น",
    "项目": "อุปกรณ์",
    "发布时间": "วันที่ประกาศ",
    "2005年": "ปี 2005",
    "2010年": "ปี 2010",
    "交易市场": "ตลาดเทรด",
    "主要是衍生品": "โดยเฉพาะ Forex",
    "包括股票": "หุ้น, ฟิวเจอร์ส, ฟอเร็กซ์และอื่นๆ",
    "挂单类型": "ประเภทคำสั่งซื้อที่รอดำเนินการ",
    "4种": "4 ประเภท",
    "6种": "6 ประเภท",
    "时间框架": "Time Frames",
    "9种": "9 Frames",
    "21种": "21 Frames",
    "技术指标": "ดัชนี",
    "30个": "30 ชนิด",
    "38个": "38 ชนิด",
    "市场深度":"ความลึกของตลาด",
    "不支持":"ไม่รองรับ",
    "支持":"รองรับ",
    "财经日历":"ปฏิทินเศรษฐกิจ",
    "内置":"ภายใน",
    "操作界面":"มุมมองผู้ใช้งาน",
    "更直观简单":"ใช้งานสะดวกยิ่งขึ้น",
    "更加现代化灵活":"ทันสมัยและรวดเร็วยิ่งขึ้น"
}