export default {
  lang: "",
  交易领薪计划: "交易領薪計劃",
  为了鼓励交易者提升交易水平:
    "為了鼓勵交易者提升交易水準，我們推出了交易領薪計畫。",
  加入交易社区: "加入交易社區，享受豐厚獎勵和專屬福利，助力交易者成就交易傳奇。",
  即刻发起交易信号: "即時發起交易訊號",
  无限奖励月月拿: "無限獎勵月月拿",
  优享12个专属大礼: "優享12個專屬大禮",
  即刻加入: "即時加入",
  交易员等级奖励机制: `交易員等級獎勵機制`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC基於跟隨人數和資金提供五級獎勵，不限制交易手數，僅以市場認可度為衡量標準",
  月: "月",
  新锐交易员: "新銳交易員",
  精英交易员: "菁英交易員",
  冠军交易员: "冠軍交易員",
  明星交易员: "明星交易員",
  传奇交易员: "傳奇交易員",
  管理资金: "+1%管理資金/年(月付）",
  管理资金量: "管理資金量",
  跟随用户数: "跟隨用戶數",
  多层次的增值服务: "多層次的加值服務",
  EBC提供多达11项增值服务:
    "EBC提供多達11項加值服務，整合流量和媒體資源，全面提升訊號影響力。",
  "7×24小时优先客服": "7x24小時優先客服",
  参与EBC品牌营销互动: "參與EBC品牌行銷互動",
  VPS赞助: "VPS贊助",
  金融投资类书籍赞助: "金融投資類書籍贊助",
  订单流软件一年使用权: "訂單流軟體一年使用權",
  EBC定制大礼包: "EBC訂製大禮包",
  信号置顶展示: "訊號置頂展示",
  受邀参观海外办公室: "受邀參觀海外辦公室",
  媒体资源推送: "媒體資源推送",
  品牌战略合作: "品牌策略合作",
  海外基金产品发行顾问服务: "海外基金產品發行顧問服務",
  六维指标评价系统: "六維指標評估系統",
  为交易员信号提供公开透明的评估:
    "為交易員訊號提供公開透明的評估，展現交易風格，提升曝光率，從而吸引更多跟隨者。",
  "最大回撤6.39%": "最大回撤6.39%",
  衡量风控能力: "衡量風控能力，越低風險越小。",
  "标准差1.59": "標準差1.59",
  收益波动率: "收益波動率，越低越穩定。",
  "盈利交易(胜率)75.82%": "獲利交易(勝率)75.82%",
  盈利订单占比: "獲利訂單佔比，越高獲利機率越高。",
  "收益率406.06%": "收益率406.06%",
  历史收益率: "歷史收益率，值越高越好。",
  "获利系数6.19": "獲利係數6.19",
  利润因子: "利潤因子，越高獲利能力越強",
  "夏普比率44.46": "夏普比率44.46",
  每份风险的收益: "每份風險的收益，越高性價比越高。",
  收益率: "收益率",
  最大回撤: "最大回撤",
  标准差: "标准差",
  获利系数: "获利系数",
  夏普比率: "夏普比率",
  "盈利交易(胜率)": "盈利交易(胜率)",
  以上数据仅供参考: "*以上數據僅供參考。",
  活动须知: "活動須知",
  交易员等级计算: "1.交易員等級計算",
  管理资金量跟随资金量:
    "管理資金量=跟隨資金量+交易員(交易訊號)帳號自有資金量，資金量皆以淨值計算。",
  等级计算以自然月为周期:
    "等級計算以自然月為週期，跟隨管理資金量和跟隨客戶數連續一個月達標即達到相應等級。",
  有效跟随用户: "2.有效跟隨用戶",
  起始跟随金额: "起始跟隨金額多於500USD，則計為1個有效跟隨用戶。",
  每个MT4账号最多跟随10个交易员: "每個MT4帳號最多跟隨10個交易員(交易訊號)。",
  每个MT4账号跟随金额: "每個MT4帳號跟隨金額=跟隨帳戶資金/訊號數。",
  奖励结算: "3.獎勵結算",
  次月第一周计算并发放上月奖励:
    "隔月第一週計算並發放上月獎勵，以上月達標的最低等級計算獎勵。",
  所有奖励发到EBC后台钱包:
    "所有獎勵發放至交易帳號，得獎者自行提領，請參賽者提前報名。",
  活动最终解释权归主办方EBC所有: "活動最終解釋權歸主辦單位EBC所有。",
};
