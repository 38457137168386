import Vue from "vue";
import VueI18n from "vue-i18n";
import i18nMessages from "./modules";

Vue.use(VueI18n); // 全局挂载

export const i18n = new VueI18n({
  locale: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "zh", // 从localStorage中获取 默认中文
  messages: i18nMessages,
});

export default i18n;
