<template>  
    <div :class="`trading-view-container ${$t('TradingView.tradingView.tvBodyClass')}-TradingView-body`"> 
     <page-navigate></page-navigate> 
      <!-- Section 1 -->  
      <div :class="`TradingView-section-1 ${$t('TradingView.tradingView.tvlang')}-font-family`">  
        <div class="container">  
          <div class="row">  
            <div class="col-6">  
              <p class="p1">{{ $t('TradingView.tradingView.disclaimer') }}</p>  
              <p :class="`p2 ${$t('TradingView.tradingView.tvlang')}-font-family-title`">  
                {{ $t('TradingView.tradingView.registerNow') }}  
                <img :src="$t('TradingView.tradingView.icon15')" alt="">  
              </p>  
              <div class="d1">  
                <p>  
                  <img src="../assets/image/TradingView/TradingView-icon14.svg" alt="">  
                  {{ $t('TradingView.tradingView.paidBenefits') }}  
                </p>  
              </div>  
              <div class="d2">  
                <p>  
                  <span>{{ $t('TradingView.tradingView.benefits.analysis') }}</span>  
                  <span>{{ $t('TradingView.tradingView.benefits.charts') }}</span>  
                  <span>{{ $t('TradingView.tradingView.benefits.orderFlow') }}</span>  
                </p>  
                <a :href="$store.state.khUrl" target="_blank" rel="nofollow">  
                  <p>  
                    {{ $t('TradingView.tradingView.getItNow') }}  
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">  
                      <path d="M1.58854 12L8 6L1.58854 0L0 1.48659L4.82292 6L0 10.5134L1.58854 12Z" fill="white"/>  
                    </svg>  
                  </p>  
                </a>  
              </div>  
            </div>  
            <div class="col-6" style="padding: 0;">  
              <img class="TradingView-img1 TradingView-img1-pc" src="../assets/image/TradingView/TradingView-img1.png" alt="">  
              <img class="TradingView-img1 TradingView-img1-mobile" src="../assets/image/TradingView/TradingView-img1-mobile.png" alt="">  
            </div>  
          </div>  
        </div>  
      </div>  
  
      <!-- Section 2 -->  
      <div :class="`TradingView-section-2 ${$t('TradingView.tradingView.tvlang')}-font-family`">  
        <div class="container">  
          <p :class="`tv-section-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{ $t('TradingView.tradingView.steps.title') }}</p>  
          <div class="row">  
            <div v-for="(step, index) in $t('TradingView.tradingView.steps.list')"   
                 :key="index"   
                 class="col-4">  
              <img :src="step.icon" :alt="step.title">  
              <p class="p1">{{ step.title }}</p>  
              <p class="p2" v-html="step.desc"></p>  
            </div>  
          </div>  
        </div>  
      </div>  
  
      <!-- Section 3 -->  
      <div :class="`TradingView-section-3 ${$t('TradingView.tradingView.tvlang')}-font-family`">  
        <div class="container">  
          <p :class="`tv-section-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">  
            {{ $t('TradingView.tradingView.section3.title') }}  
          </p>  
          <p class="tv-section-desc">  
            {{ $t('TradingView.tradingView.section3.desc') }}  
          </p>  
          
          <swiper ref="marketSwiper" class="TradingView-section-3-slide" :options="swiperOptions3">  
            <swiper-slide v-for="(slide, index) in $t('TradingView.tradingView.section3.slides')"   
                          :key="index">  
              <div class="row">  
                <div class="col-8">  
                  <img :src="slide.image" :alt="slide.title">  
                </div>  
                <div class="col-4">  
                  <img :src="slide.icon" :alt="slide.title">  
                  <p class="p1">{{ slide.title }}</p>  
                  <p class="p2">{{ slide.desc }}</p>  
                  <a :href="$store.state.khUrl" target="_blank" rel="nofollow">  
                    {{ $t('TradingView.tradingView.getItFree') }}  
                  </a>  
                </div>  
              </div>  
            </swiper-slide>  
            
            <div class="TradingView-section-3-pagination swiper-pagination" slot="pagination"></div>  
            <div class="TradingView-section-3-swiper-button-prev swiper-button-prev" slot="button-prev">  
              <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">  
                <circle cx="28" cy="28" r="28" transform="rotate(180 28 28)" fill="#DDDDDD"/>  
                <path d="M31 35L24 28L31 21" stroke="white" stroke-width="2"/>  
              </svg>  
            </div>  
            <div class="TradingView-section-3-swiper-button-next swiper-button-next" slot="button-next">  
              <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">  
                <circle cx="28" cy="28" r="28" fill="#DDDDDD"/>  
                <path d="M25 21L32 28L25 35" stroke="white" stroke-width="2"/>  
              </svg>  
            </div>  
          </swiper>  
        </div>  
      </div>  
  
      <!-- Section 4 -->  
      <div :class="`TradingView-section-4 ${$t('TradingView.tradingView.tvlang')}-font-family`">  
        <div class="container">  
          <p :class="`tv-section-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">  
            {{ $t('TradingView.tradingView.section4.title') }}  
          </p>  
          <p class="tv-section-desc">  
            {{ $t('TradingView.tradingView.section4.desc') }}  
          </p>  
          <div class="row">  
            <div v-for="(plan, index) in $t('TradingView.tradingView.section4.plans')"   
                 :key="index"   
                 class="col-6">  
              <div :class="['vip', plan.isPremium ? 'premium' : '']">  
                <div class="vip-head">  
                  <p class="p1">{{ plan.title }}</p>  
                  <p class="p2">{{ plan.requirement }}</p>  
                  <a :href="$store.state.khUrl" target="_blank" rel="nofollow">  
                    {{ $t('TradingView.tradingView.depositToGet') }}  
                  </a>  
                </div>  
                <div class="vip-list">  
                  <ul>  
                    <li v-for="(feature, fIndex) in plan.features"   
                        :key="fIndex"  
                        :class="{ disabled: !feature.enabled }">  
                      {{ feature.text }}  
                    </li>  
                  </ul>  
                </div>  
              </div>  
            </div>  
          </div>  
        </div>  
      </div>  
  
      <div :class="`TradingView-section-5 ${$t('TradingView.tradingView.tvlang')}-font-family`">  
    <div class="container">  
      <div class="row">  
        <div class="col-4">  
          <p :class="`p1 ${$t('TradingView.tradingView.tvlang')}-font-family-title`"  
             v-html="$t('TradingView.tradingView.section5.title')"></p>  
          <!-- 修改这里：使用 currentSlide.text 替代 currentText -->  
          <p class="p2">{{ $t(currentSlide.textKey) }}</p>  
          <a :href="$store.state.khUrl"  
             target="_blank"  
             rel="nofollow">  
            {{ $t('TradingView.tradingView.getItNow') }}  
          </a>  
          <div class="custom-fraction">  
            <span class="prev-btn" @click="prevSlide">&lt;</span>  
            <span class="current">{{ currentIndex }}</span>  
            <span>/</span>  
            <span class="total">{{ totalSlides }}</span>  
            <span class="next-btn" @click="nextSlide">&gt;</span>  
          </div>  
        </div>  
        <div class="col-8">  
          <!-- 修改这里：将 @slideChange 改为使用 options 中的配置 -->  
          <swiper ref="reportSwiper"  
                  class="TradingView-section-5-slide"  
                  :options="swiperOptions">  
            <swiper-slide v-for="(slide, index) in slides" :key="index">  
              <img :src="slide.image" :alt="slide.text">  
            </swiper-slide>  
          </swiper>  
        </div>  
      </div>  
    </div>  
  </div>
  
      <!-- Section 6 -->  
      <div :class="`TradingView-section-6 ${$t('TradingView.tradingView.tvlang')}-font-family`">  
        <div class="container">  
          <p :class="`tv-section-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">  
            {{ $t('TradingView.tradingView.section6.title') }}  
          </p>  
          <p class="tv-section-desc">  
            {{ $t('TradingView.tradingView.section6.desc') }}  
          </p>  
          <div class="row honor-list">  
    <swiper :options="swiperOptions6" class="honor-swiper">  
      <swiper-slide :title="$t('WhyEBC.screen4.desc1')">  
        <div class="col-md-4" @click="jumPage('LEARN-647-43B')">  
          <img src="../assets/image/WhyEBC/about-11.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc1')">{{$t('WhyEBC.screen4.desc1')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc2')">  
        <div class="col-md-4" @click="jumPage('LEARN-647-43B')">  
          <img src="../assets/image/WhyEBC/about-22.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc2')">{{$t('WhyEBC.screen4.desc2')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc4')">  
        <div class="col-md-4" @click="jumPage('LEARN-2DC')">  
          <img src="../assets/image/WhyEBC/about-33.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc4')">{{$t('WhyEBC.screen4.desc4')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc5')">  
        <div class="col-md-4" @click="jumPage('LEARN-2DC')">  
          <img src="../assets/image/WhyEBC/about-44.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc5')">{{$t('WhyEBC.screen4.desc5')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc3')">  
        <div class="col-md-4" @click="jumPage('LEARN-2E5')">  
          <img src="../assets/image/WhyEBC/about-55.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc3')">{{$t('WhyEBC.screen4.desc3')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc6')">  
        <div class="col-md-4" @click="jumPage('LEARN-30-363')">  
          <img src="../assets/image/WhyEBC/about-66.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc6')">{{$t('WhyEBC.screen4.desc6')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc7')">  
        <div class="col-md-4" @click="jumPage('LEARN-559-34F')">  
          <img src="../assets/image/WhyEBC/about-77.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc7')">{{$t('WhyEBC.screen4.desc7')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc8')">  
        <div class="col-md-4" @click="jumPage('LEARN-486-3D7')">  
          <img src="../assets/image/WhyEBC/about-88.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc8')">{{$t('WhyEBC.screen4.desc8')}}</p>  
        </div>  
      </swiper-slide>  

      <swiper-slide :title="$t('WhyEBC.screen4.desc9')">  
        <div class="col-md-4" @click="jumPage('LEARN-518-4C3')">  
          <img src="../assets/image/WhyEBC/about-99.png" alt="">  
          <p v-if="$t('WhyEBC.screen4.desc9')">{{$t('WhyEBC.screen4.desc9')}}</p>  
        </div>  
      </swiper-slide>  
    </swiper>  
  </div>   
          <p class="TradingView-section-6-p">  
            {{ $t('TradingView.tradingView.disclaimer') }}  
          </p>  
        </div>  
      </div> 
      <page-footer></page-footer> 
    </div>  
  </template>  
  
  <script>  
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'  
import 'swiper/css/swiper.css'  

export default {  
  name: 'TradingView',  
  components: {  
    Swiper,  
    SwiperSlide  
  },  
  data() {  
    return {  
      // Section 5数据  
      currentIndex: '01',  
      totalSlides: '03',  
      currentSlide: {  
        text: '',
        image: ''  
      },  
      
      slides: [  
        {  
          image: require('../assets/image/TradingView/TradingView-img7.png'),  
          textKey: 'TradingView.tradingView.section5.desc'  
        },  
        {  
          image: require('../assets/image/TradingView/TradingView-img8.png'),  
          textKey: 'TradingView.tradingView.section5.desc2'  
        },  
        {  
          image: require('../assets/image/TradingView/TradingView-img9.png'),  
          textKey: 'TradingView.tradingView.section5.desc3'   
        }  
      ],  

      // Section 5配置  
      swiperOptions: {  
        loop: true,  
        autoplay: {  
          delay: 3000,  
          disableOnInteraction: false  
        },  
        on: {  
          slideChange: function() {  
            this.updateSlideInfo();  
          }.bind(this)  
        }  
      },  

      // Section 3配置  
      swiperOptions3: {  
        loop: true,  
        autoplay: true,  
        pagination: {  
          el: '.TradingView-section-3-pagination',  
          clickable: true  
        },  
        navigation: {  
          nextEl: '.TradingView-section-3-swiper-button-next',  
          prevEl: '.TradingView-section-3-swiper-button-prev'  
        }  
      },  

      // Section 6配置  
      swiperOptions6: {  
        speed: 6000,  
        slidesPerView: window.screen.width > 994 ? 5 : 2,  
        spaceBetween: window.screen.width > 994 ? 60 : 50,  
        centeredSlides: true,  
        loop: true,  
        autoplay: {  
          delay: 0,  
          disableOnInteraction: false  
        }  
      }  
    }  
  },  
  
  methods: {  
    padNumber(number) {  
      return String(number).padStart(2, '0')  
    },  
    
    updateSlideInfo() {  
      if (this.$refs.reportSwiper && this.$refs.reportSwiper.$swiper) {  
        const swiper = this.$refs.reportSwiper.$swiper;  
        const realIndex = swiper.realIndex || 0;  
        this.currentIndex = this.padNumber(realIndex + 1);  
        this.currentSlide = this.slides[realIndex] || this.slides[0];  
      }  
    },  

    prevSlide() {  
      if (this.$refs.reportSwiper && this.$refs.reportSwiper.$swiper) {  
        this.$refs.reportSwiper.$swiper.slidePrev();  
      }  
    },  

    nextSlide() {  
      if (this.$refs.reportSwiper && this.$refs.reportSwiper.$swiper) {  
        this.$refs.reportSwiper.$swiper.slideNext();  
      }  
    },  

    // 新增 Section 6 的响应式处理方法  
    updateSection6Options() {  
      const winWide = window.screen.width;  
      if (this.$refs.honorSwiper && this.$refs.honorSwiper.$swiper) {  
        const swiper = this.$refs.honorSwiper.$swiper;  
        if (winWide > 994) {  
          swiper.params.slidesPerView = 5;  
          swiper.params.spaceBetween = 60;  
        } else {  
          swiper.params.slidesPerView = 2;  
          swiper.params.spaceBetween = 50;  
        }  
        swiper.update();  
      }  
    },

    jumPage(id) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: id
					}
				})
			},
  },  

  mounted() {  
    this.$nextTick(() => {  
      // Section 5 初始化  
      if (this.$refs.reportSwiper && this.$refs.reportSwiper.$swiper) {  
        this.totalSlides = this.padNumber(this.slides.length);  
        this.updateSlideInfo();  
      }  

      // 监听窗口大小变化，用于 Section 6 的响应式  
      window.addEventListener('resize', this.updateSection6Options);  
    });  
  },  

  beforeDestroy() {  
    // 清理 Section 5 的 Swiper 实例  
    if (this.$refs.reportSwiper && this.$refs.reportSwiper.$swiper) {  
      this.$refs.reportSwiper.$swiper.destroy(true, true);  
    }  

    // 清理 Section 6 的 Swiper 实例  
    if (this.$refs.honorSwiper && this.$refs.honorSwiper.$swiper) {  
      this.$refs.honorSwiper.$swiper.destroy(true, true);  
    }  

    // 移除窗口大小变化监听器  
    window.removeEventListener('resize', this.updateSection6Options);  
  }  
}  
</script>  
  
  <style>  
  @charset "UTF-8";
.TradingView-section-1{
  background: url(../assets/image/TradingView/TradingView-img2.png) 100%/auto 100% no-repeat;
  margin-top: 0px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.TradingView-section-1 .p1{
  color: #FFF;
  font-family: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.4;
  margin-top: 50px;
}
.TradingView-section-1 .p1::before,  
.TradingView-section-1 .p1::after {  
  display: inline-block;
  content: '';  
  width: 8px;  
  height: 8px;  
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'%3E%3Cpath opacity='0.2' d='M3.93496 2.05962C4.76965 2.89431 5.90244 2.90515 5.90244 2.90515C5.9544 2.90515 6 2.95 6 3.00271C6 3.05149 5.96206 3.09485 5.90786 3.09485C5.89702 3.09485 4.76965 3.10569 3.94038 3.93496C3.11111 4.76423 3.09485 5.8916 3.09485 5.90244C3.09485 5.95664 3.05691 6 3.00271 6C2.94851 6 2.90515 5.95664 2.90515 5.90244C2.90515 5.8916 2.88889 4.76423 2.05962 3.93496C1.22493 3.10569 0.102981 3.09485 0.0921409 3.09485C0.0433604 3.09485 0 3.05691 0 3.00271C0 2.94851 0.0379404 2.90515 0.0921409 2.90515C0.108401 2.90515 1.23035 2.88889 2.05962 2.06504C2.89431 1.23035 2.90515 0.108401 2.90515 0.097561C2.90515 0.0433604 2.95393 0 3.00271 0C3.05149 0 3.09485 0.0433604 3.09485 0.097561C3.09485 0.108401 3.10569 1.23035 3.93496 2.05962Z' fill='white'/%3E%3C/svg%3E");  
    background-size: contain;  
    background-repeat: no-repeat;  
    background-position: center;  
}

.TradingView-section-1 .p2{
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-size: 36.768px;
  font-style: normal;
  font-weight: 900;
  line-height: 55.153px;
  margin-top: 20px;
}
.TradingView-section-1 .p2 img{
  vertical-align: bottom;
  margin-left: 10px;
}
.TradingView-section-1 .d1{
  margin-top: 10px;
}
.TradingView-section-1 .d1 p{
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-size: 40.824px;
  font-style: normal;
  font-weight: 900;
  line-height: 61.236px;
}
.TradingView-section-1 .d1 img{
  vertical-align: bottom;
  margin-right: 10px;
}
.TradingView-section-1 .d2{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}
.TradingView-section-1 .d2 span:nth-child(-n+2) {  
    position: relative;
    padding-right: 30px;
}  

.TradingView-section-1 .d2 span:nth-child(-n+2)::after {  
    content: '';  
    position: absolute;  
    top: 57%;  
    right: 8px;  
    transform: translateY(-50%);
    width: 9px;  
    height: 9px;  
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'%3E%3Cpath d='M5.59844 2.88087C6.74092 4.02334 8.29142 4.03818 8.29142 4.03818C8.36255 4.03818 8.42496 4.09957 8.42496 4.17172C8.42496 4.23848 8.37302 4.29783 8.29884 4.29783C8.284 4.29783 6.74092 4.31267 5.60586 5.44773C4.4708 6.58279 4.44854 8.12587 4.44854 8.14071C4.44854 8.2149 4.39661 8.27424 4.32243 8.27424C4.24824 8.27424 4.18889 8.2149 4.18889 8.14071C4.18889 8.12587 4.16663 6.58279 3.03158 5.44773C1.8891 4.31267 0.353434 4.29783 0.338596 4.29783C0.271828 4.29783 0.212479 4.2459 0.212479 4.17172C0.212479 4.09753 0.264409 4.03818 0.338596 4.03818C0.360852 4.03818 1.89652 4.01592 3.03158 2.88828C4.17405 1.74581 4.18889 0.210141 4.18889 0.195304C4.18889 0.121117 4.25566 0.0617676 4.32243 0.0617676C4.38919 0.0617676 4.44854 0.121117 4.44854 0.195304C4.44854 0.210141 4.46338 1.74581 5.59844 2.88087Z' fill='%239F734C'/%3E%3C/svg%3E");  
    background-size: contain;  
    background-repeat: no-repeat;  
    background-position: center; 
} 
.TradingView-section-1 .d2 a{
  border-radius: 8px;
  border: 1px solid #FFF;
  display: inline-flex;
  padding: 14px 72px;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
  color: #FFF;
  font-size: 20px;
  margin-top: 70px;
  font-weight: 400;
}
.TradingView-section-1 .d2 a:hover{
  background: #A52933;
  border: 1px #A52933 solid;
}
.TradingView-section-1 .d2 a p{
  padding-right: 20px;
  position: relative;
}
.TradingView-section-1 .d2 a svg{
  position: absolute;
  transform: translateY(-50%);
  top: 55%;
  right: 0px;
}

.tv-section-title{
  font-size: 32px;
  font-weight: 900;
  text-align: center;
}
.tv-section-desc{
  font-size: 16px;
  text-align: center;
  margin-top: 28px;
}

.TradingView-section-2{
  padding-top: 100px;
  padding-bottom: 100px;
}
.TradingView-section-2 .col-4{
  text-align: center;
  margin-top: 60px;
  position: relative;
}
.TradingView-section-2 .p1{
  color: #232222;
  font-size: 16px;
  margin-top: 22px;
  font-weight: 700;
}
.TradingView-section-2 .p2{
  color: #232222;
  font-size: 14px;
  margin-top: 16px;
  line-height: 1.5;
}
.TradingView-section-2 .col-4:nth-child(-n+2)::after {  
    content: '';  
    position: absolute;  
    top: 72%;  
    right: 0px;  
    transform: translateY(-50%);
    width: 7px;
    height: 14px; 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='16' viewBox='0 0 9 16' fill='none'%3E%3Cpath d='M1 1L8 8L1 15' stroke='%23B2AFAD'/%3E%3C/svg%3E");
    background-size: contain;  
    background-repeat: no-repeat;  
    background-position: center; 
} 

.TradingView-section-3{
  padding-top: 100px;
  padding-bottom: 100px;
  background: #F8F8F8;
}


.TradingView-section-3-slide{
  background: #fff;
  border-radius: 16px;
  width: 83%;
  margin: 60px auto 40px;
}
.TradingView-section-3-slide .swiper-slide{
  padding: 40px 40px 30px;

}
.TradingView-section-3 .col-4{
  padding-left: 30px;
}
.TradingView-section-3 .col-4{
  margin-top: 20px;
}
.TradingView-section-3 .p1{
  color: #232222;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 24px;
  line-height: 1.5;
}
.TradingView-section-3 .p2{
  color: #232222;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 16px;
}
.TradingView-section-3 a{
  border-radius: 8px;
  border: 1px solid #3D0101;
  background: #5D2333;
  padding: 16px 24px;
  font-size: 16px;
  color: #fff;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: 50px;
}
.TradingView-section-3 a:hover{
  background: #49212B;
}

.swiper-pagination-bullet-active{
  background: #5D2333;
}
.TradingView-section-3-pagination {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  position: relative;  
  bottom: 22px !important;
}


.swiper-button-prev::after,  
.swiper-button-next::after {  
    display: none; /* 隐藏默认箭头 */  
}  

.swiper-button-prev,  
.swiper-button-next {  
    width: 56px; /* 设置箭头容器大小 */  
    height: 56px;  
}  

/* 可选：添加鼠标悬停效果 */  
.swiper-button-prev:hover svg circle,  
.swiper-button-next:hover svg circle {  
    fill: #cccccc; /* 悬停时改变颜色 */  
} 

.TradingView-section-3-swiper-button-prev{
  left: 30px;
}
.TradingView-section-3-swiper-button-next{
  right: 30px;
}
.TradingView-section-3 .container{
  position: relative;
}


.TradingView-section-4{
  padding-top: 100px;
  padding-bottom: 100px;
}
.TradingView-section-4 .vip{
  border-radius: 16px;
  background: linear-gradient(270deg, #FFF0D9 0%, #FFF7ED 100%);
  width: 99%;
  margin: 0 auto;
  margin-top: 70px;
}
.TradingView-section-4 .vip .vip-head{
  text-align: center;
  padding: 40px;
  background: url(../assets/image/TradingView/TradingView-img12.png) 100%/auto 100% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  border-radius: 16px;
}
.TradingView-section-4 .vip .p1{
  font-size: 32px;
  font-weight: 700;
  color: #232222;
}
.TradingView-section-4 .vip .p2{
  color: #B2AFAD;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}
.TradingView-section-4 .vip a{
  border-radius: 8px;
  background: #5D2333;
  width: 100%;
  padding: 20px;
  display: inline-block;
  margin-top: 36px;
  color: #fff;
  font-size: 18px;
}
.TradingView-section-4 .vip a:hover{
  background: #49212B;
}
.TradingView-section-4 .vip .vip-list{
  background: #fff;
  border-radius: 16px;
  border: 1px solid #DDD;
  padding: 30px 40px;
}
.TradingView-section-4 .vip .vip-list ul li{
  color: #232222;
    font-size: 16px;
    line-height: 26px;
    width: 100%;
    position: relative;
    padding-right: 10%;
    margin-bottom: 16px;
}
.TradingView-section-4 .vip .vip-list ul li::after {  
    content: '';  
    position: absolute;  
    right: 0;  
    top: 50%;  
    transform: translateY(-50%);  
    width: 20px;  
    height: 20px;  
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='10' cy='10' r='10' fill='%239F734C'/%3E%3Cpath d='M5 9L9 13L15 7' stroke='white'/%3E%3C/svg%3E");  
    background-size: contain;  
    background-repeat: no-repeat;
}
.TradingView-section-4 .container{
  width:76%;
}
.TradingView-section-4 .premium{
  background: linear-gradient(270deg, #232222 0%, #4D4841 100%);
}
.TradingView-section-4 .premium .vip-head{
  background: url(../assets/image/TradingView/TradingView-img11.png) 100%/auto 100% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  border-radius: 16px;
}
.TradingView-section-4 .premium .p1{
  color: #fff;
}
.TradingView-section-4 .premium a{
  background: linear-gradient(270deg, #FFF0D9 0%, #FFF7ED 100%);
  color: #232222;
}
.TradingView-section-4 .premium a:hover{
 background: linear-gradient(270deg, #E3DAC9 0%, #E5DFD8 100%);
}
.TradingView-section-4 .vip .vip-list ul li.disabled::after{
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><circle cx="10" cy="10" r="10" fill="%23B2AFAD"/><path d="M6 14L14 6" stroke="white"/><path d="M6 6L14 14" stroke="white"/></svg>');  
}


.TradingView-section-5{
  background: url(../assets/image/TradingView/TradingView-img13.png) 100%/auto 100% no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.TradingView-section-5 .p1{
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5;
  margin-top: 40px;
}
.TradingView-section-5 .p2{
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 80px;
  width: 80%;
  height: 80px;
}
.TradingView-section-5 a{
  display: block;
  padding: 16px 70px;
  border-radius: 8px;
  border: 1px solid  #FFF;
  color: #FFF;
  font-size: 16px;
  margin-top: 60px;
  width: auto;
  text-align: center;
  width: max-content;
}
.TradingView-section-5 a:hover{
  background: #A52933;
  border: 1px #A52933 solid;
}
.TradingView-section-5 .container{
  width:76%;
}
.TradingView-section-5 .swiper-slide{
  width: 100%;
  display: inline-block;
}
.TradingView-section-5 .col-4{
  padding-right: 40px;
  position: relative;
}
 
.TradingView-section-5 .custom-fraction {  
    width: 100%;   
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;  
    margin-top: 70px;
}  
.TradingView-section-5 .prev-btn, .TradingView-section-5 .next-btn {  
    cursor: pointer;  
    margin: 0 10px;  
}  
.TradingView-section-5 .current {  
    color: #FF4B4E;
} 


.TradingView-section-6{
  margin-top: 0px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 90px;
  position: relative;

  background: url(../assets/image/TradingView/TradingView-img17.png) 100%/auto 100% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.TradingView-section-6 .container{
  width: 1200px;
}
.TradingView-section-6 .public-title{
  color: #3D0101;
}
.TradingView-section-6 .row img{
  width: 260px;
}
.TradingView-section-6 .row p{
  color: #5D2333;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}
.TradingView-section-6 .row .img11 {
    width: 200px;
    margin: 35px auto 20px;
}
.TradingView-section-6 .row .img33 {
    width: 90px;
    margin: 22px auto 20px;
}
.TradingView-section-6 .row .img55 {
    width: 140px;
    margin: 35px auto 20px;
}
.TradingView-section-6 .row span{
    color: #3D0101;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 10px;
    display: inline-block;
    line-height: 22px;
}
.TradingView-section-6 .honor-list{
  overflow: hidden;
  height: auto;
  margin-top: 80px;
}
.TradingView-section-6 .TradingView-section-6-img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}


.TradingView-section-6 .TradingView-section-6-p{
  color: #6A6967;
  font-family: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.4;
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;  
  align-items: center;  
  justify-content: center;  
  gap: 10px;
  line-height: 1;
}
.TradingView-section-6 .TradingView-section-6-p::before,  
.TradingView-section-6 .TradingView-section-6-p::after {  
  display: inline-block;
  content: '';  
  width: 8px;  
  height: 8px;  
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'%3E%3Cpath opacity='0.2' d='M3.93496 2.05962C4.76965 2.89431 5.90244 2.90515 5.90244 2.90515C5.9544 2.90515 6 2.95 6 3.00271C6 3.05149 5.96206 3.09485 5.90786 3.09485C5.89702 3.09485 4.76965 3.10569 3.94038 3.93496C3.11111 4.76423 3.09485 5.8916 3.09485 5.90244C3.09485 5.95664 3.05691 6 3.00271 6C2.94851 6 2.90515 5.95664 2.90515 5.90244C2.90515 5.8916 2.88889 4.76423 2.05962 3.93496C1.22493 3.10569 0.102981 3.09485 0.0921409 3.09485C0.0433604 3.09485 0 3.05691 0 3.00271C0 2.94851 0.0379404 2.90515 0.0921409 2.90515C0.108401 2.90515 1.23035 2.88889 2.05962 2.06504C2.89431 1.23035 2.90515 0.108401 2.90515 0.097561C2.90515 0.0433604 2.95393 0 3.00271 0C3.05149 0 3.09485 0.0433604 3.09485 0.097561C3.09485 0.108401 3.10569 1.23035 3.93496 2.05962Z' fill='%236A6967'/%3E%3C/svg%3E");  
  background-size: contain;  
  background-repeat: no-repeat;  
  background-position: center;  
}

.en-TradingView-body .TradingView-section-1 .p2{
  font-weight: 500;
}
.en-TradingView-body .TradingView-section-1 .d1 p{
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
}
.en-TradingView-body .TradingView-section-1 .d1 img{
  height: 45px;
}
.en-TradingView-body .TradingView-img1{
  margin-top: 40px;
}
.en-TradingView-body .TradingView-section-1 .p2 img{
  margin-left: 5px;
  height: 42px;
}
.en-TradingView-body .TradingView-section-1 .d2 span{
      padding-left: 30px;
      padding-right: 0;
      position: relative;
      white-space: nowrap; 
      margin-right: 10px;
      line-height: 2;
}
.en-TradingView-body .TradingView-section-1 .d2 span::after{
    content: '';
    position: absolute;
    top: 52%;
    left: 8px;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'%3E%3Cpath d='M5.59844 2.88087C6.74092 4.02334 8.29142 4.03818 8.29142 4.03818C8.36255 4.03818 8.42496 4.09957 8.42496 4.17172C8.42496 4.23848 8.37302 4.29783 8.29884 4.29783C8.284 4.29783 6.74092 4.31267 5.60586 5.44773C4.4708 6.58279 4.44854 8.12587 4.44854 8.14071C4.44854 8.2149 4.39661 8.27424 4.32243 8.27424C4.24824 8.27424 4.18889 8.2149 4.18889 8.14071C4.18889 8.12587 4.16663 6.58279 3.03158 5.44773C1.8891 4.31267 0.353434 4.29783 0.338596 4.29783C0.271828 4.29783 0.212479 4.2459 0.212479 4.17172C0.212479 4.09753 0.264409 4.03818 0.338596 4.03818C0.360852 4.03818 1.89652 4.01592 3.03158 2.88828C4.17405 1.74581 4.18889 0.210141 4.18889 0.195304C4.18889 0.121117 4.25566 0.0617676 4.32243 0.0617676C4.38919 0.0617676 4.44854 0.121117 4.44854 0.195304C4.44854 0.210141 4.46338 1.74581 5.59844 2.88087Z' fill='%239F734C'/%3E%3C/svg%3E");  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.en-TradingView-body .TradingView-section-1 .d2 a{
  margin-top: 60px;
}
.en-TradingView-body .tv-section-title{
  font-weight:500;
  padding: 0 8%;
  line-height: 1.3;
}
.en-TradingView-body .tv-section-desc{
  font-weight:500;
  padding: 0 6%;
  line-height: 1.5;
}
.en-TradingView-body .TradingView-section-2 .p2{
  padding: 0 12%;
}
.en-TradingView-body .TradingView-section-5 .p1{
  font-weight: 500;
  margin-top: 10px;
}
.en-TradingView-body .TradingView-section-5 .p2{
  font-size: 16px;
  width: 88%;
  margin-top: 60px;
}
.en-TradingView-body .TradingView-section-5 .col-4{
  padding-right: 40px;
}

.vi-TradingView-body .TradingView-section-1 .p2{
  font-weight: 900;
}
.vi-TradingView-body .tv-section-title{
  font-weight: 900;
}

.TradingView-img1-mobile{
  display: none;
}

.TradingView-section-6 .col-md-4{
    width: 100%;
    cursor: pointer;
}
.TradingView-section-3 .swiper-container,.TradingView-section-3 .swiper-wrapper{
    position: unset;
}




@media(max-width:1600px) {
  .en-TradingView-body .TradingView-section-5 .p2{
    width: 90%;
    font-size: 14px;
    margin-top: 40px;
  }
  .en-TradingView-body .TradingView-section-5 a{
    margin-top: 40px;
  }
  .en-TradingView-body .TradingView-section-5 .p1{
    font-size: 28px;
  }
}

@media(max-width:1400px) {
  .TradingView-section-5 .p1{
    margin-top: 20px;
  }
  .TradingView-section-5 .p2{
    font-size: 16px;
    margin-top: 40px;
  }
  .TradingView-section-5 a{
    margin-top: 50px;
  }
  .TradingView-section-5 .custom-fraction{
    margin-top: 50px;
  }

  .TradingView-section-1 .p2{
    font-size: 32px;
  }
  .TradingView-section-1 .d1 img{
    height: 45px;
  }
  .TradingView-section-1 .d1 p{
    font-size: 36px;
    line-height: 52px;
  }
  .TradingView-section-1 .d2 a{
    font-size: 18px;
    padding: 12px 60px;
  }
  .TradingView-section-1 .p1{
    margin-top: 36px;
  }
  .TradingView-section-6 .container{
    width: 1000px;
  }

  .en-TradingView-body .TradingView-section-1 .p2{
    font-size: 28px;
  }
  .en-TradingView-body .TradingView-section-1 .d1 img{
    height: 38px;
  }
  .en-TradingView-body .TradingView-section-1 .d1 p{
    font-size: 30px;
    line-height: 40px;
  }
  .en-TradingView-body .TradingView-section-1 .p1{
    margin-top: 10px;
  }
  .en-TradingView-body .TradingView-img1{
    margin-top: 30px;
  }
  .en-TradingView-body .TradingView-section-3-slide{
    width: 90%;
  }
  .en-TradingView-body .swiper-button-next, .en-TradingView-body .swiper-button-prev{
    top: 58%;
  }
  .en-TradingView-body .TradingView-section-3-swiper-button-prev{
    left: -12px;
  }
  .en-TradingView-body .TradingView-section-3-swiper-button-next{
    right: -12px;
  }
  .en-TradingView-body .TradingView-section-3 .p2{
    font-size: 14px;
  }
  .en-TradingView-body .tv-section-title{
    font-size: 28px;
  }
  .en-TradingView-body .TradingView-section-4 .vip .p1{
    font-size: 26px;
  }
  .en-TradingView-body .TradingView-section-4 .vip a{
    font-size: 16px;
  }
  .en-TradingView-body .TradingView-section-5 .p2{
    width: 94%;
    margin-top: 20px;
    height: 90px;
  }
  .en-TradingView-body .TradingView-section-5 .p1{
    font-size: 26px;
  }
}

@media(max-width:995px) {
  .TradingView-img1-mobile{
    display: inline-block;
  }
  .TradingView-img1-pc{
    display: none;
  }
  .TradingView-section-6 .container{
    width: 100%;
  }
  .TradingView-section-1 .col-6{
    width: 100%;
  }
  .TradingView-section-1{
    background: url(../assets/image/TradingView/TradingView-img15.png?t=1736411672) 100% / auto 100% no-repeat;
    padding: 0 5%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .TradingView-section-1 .p1{
    margin-top: 60px;
  }
  .TradingView-section-1 .p2 {
      font-size: 30px;
  }
  .TradingView-section-1 .p2 img{
    margin-left: 0;
  }
  .TradingView-section-1 .d1 img{
    width: 100%;
    height: auto;
  }
  .TradingView-section-1 .d2 span:nth-child(-n+2){
    padding-right: 0px;
  }
  .TradingView-section-1 .d2 span{
    width: 100%;
    display: inline-block;
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
  }
  .TradingView-section-1 .d2 span::after{
    content: '';
    position: absolute;
    top: 57%;
    left: 0px;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'%3E%3Cpath d='M5.59844 2.88087C6.74092 4.02334 8.29142 4.03818 8.29142 4.03818C8.36255 4.03818 8.42496 4.09957 8.42496 4.17172C8.42496 4.23848 8.37302 4.29783 8.29884 4.29783C8.284 4.29783 6.74092 4.31267 5.60586 5.44773C4.4708 6.58279 4.44854 8.12587 4.44854 8.14071C4.44854 8.2149 4.39661 8.27424 4.32243 8.27424C4.24824 8.27424 4.18889 8.2149 4.18889 8.14071C4.18889 8.12587 4.16663 6.58279 3.03158 5.44773C1.8891 4.31267 0.353434 4.29783 0.338596 4.29783C0.271828 4.29783 0.212479 4.2459 0.212479 4.17172C0.212479 4.09753 0.264409 4.03818 0.338596 4.03818C0.360852 4.03818 1.89652 4.01592 3.03158 2.88828C4.17405 1.74581 4.18889 0.210141 4.18889 0.195304C4.18889 0.121117 4.25566 0.0617676 4.32243 0.0617676C4.38919 0.0617676 4.44854 0.121117 4.44854 0.195304C4.44854 0.210141 4.46338 1.74581 5.59844 2.88087Z' fill='%239F734C'/%3E%3C/svg%3E");  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .TradingView-section-1 .d2 a{
    width: 100%;
    margin-top: 40px;
    padding: 14px 20px;
    margin-bottom: 40px;
  }
  .TradingView-section-1 .TradingView-img1{
    margin-bottom: 60px;
  }
  .TradingView-section-2{
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .TradingView-section-2 .col-4{
    width: 100%;
    margin-bottom: 30px;
  }
  .TradingView-section-2 .col-4:nth-child(-n+2)::after {
    content: '';
    position: absolute;
    right: 0px;
    bottom: -50px;
    top: unset;
    left: 49.2%;
    transform: rotate(90deg);
    width: 7px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='16' viewBox='0 0 9 16' fill='none'%3E%3Cpath d='M1 1L8 8L1 15' stroke='%23B2AFAD'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .tv-section-title{
    font-size: 30px;
    line-height: 1.4;
    padding: 0 5%;
  }
  .tv-section-desc{
    line-height: 1.4;
    padding: 0 5%;
    margin-top: 18px;
  }
  .TradingView-section-3{
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .TradingView-section-3 .col-8,.TradingView-section-3 .col-4{
    width: 100%;
  }
  .TradingView-section-3-slide .swiper-slide{
    padding: 0;
    position: relative;
    padding-bottom: 80px;
    height: auto;
  }
  .TradingView-section-3-slide{
    background: none;
    width: 90%;
    border-radius: unset;
  }
  .TradingView-section-3 .col-4{
    padding-left: calc(var(--bs-gutter-x)* 0.5);
    text-align: center;
    margin-top: 40px;
  }
  .swiper-button-prev, .swiper-button-next{
    display: none;
  }
  .TradingView-section-3 a{
    width: 99%;
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .TradingView-section-3 .p2{
    padding: 0 12%;
  }
  .TradingView-section-4{
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .TradingView-section-4 .container{
    width: 100%;
  }
  .TradingView-section-4 .col-6{
    width: 100%;
    padding: 0 4%;
  }
  .TradingView-section-4 .vip{
    margin-top: 40px;
  }
  .TradingView-section-4 .vip .p1{
    font-size: 26px;
  }
  .TradingView-section-4 .vip .p2{
    font-size: 14px;
  }
  .TradingView-section-4 .vip a{
    font-size: 16px;
    padding: 18px;
  }
  .TradingView-section-4 .vip .vip-head{
    padding: 40px 7% 32px;
  }
  .TradingView-section-4 .vip .vip-list{
    padding: 30px 7%;
  }
  .TradingView-section-5 .col-4{
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
  .TradingView-section-5 .col-4,.TradingView-section-5 .col-8{
    width: 100%;
  }
  .TradingView-section-5 .container{
    width: 100%;
    padding: 0 7%;
  }
  .TradingView-section-5 .p2{
    width: 100%;
    padding: 0 7%;
  }
  .TradingView-section-5{
    background: url(../assets/image/TradingView/TradingView-img16.png) 100% / auto 100% no-repeat;
    padding: 0 5%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 70px;
    padding-bottom: 120px;
    position: relative;
  }
  .TradingView-section-5 a{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .TradingView-section-5 .col-8{
    padding: 0;
  }
  .TradingView-section-5 .custom-fraction{
    position: absolute;
    bottom: 80px;
    margin-top: 0;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .TradingView-section-5 .col-4{
    position: unset;
  }
  .TradingView-section-6{
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .TradingView-section-6 .tv-section-title{
    padding: 0 18%;
  }
  .TradingView-section-6 .honor-list{
    margin-top: 60px;
  }


  .en-TradingView-body .TradingView-section-1 .p1 {
    display: none;
  }
  .en-TradingView-body .TradingView-section-1 .p2{
    margin-top: 70px;
    line-height: 1.5;
  }
  .en-TradingView-body .TradingView-section-1 .p2 img{
    margin-top: 12px;
  }
  .en-TradingView-body .TradingView-section-1 .d1 img{
    width: 100%;
    height: auto;
    margin-top: 8px;
  }
  .en-TradingView-body .TradingView-section-1 .d1 p{
    font-size: 28px;
  }
  .en-TradingView-body .TradingView-section-1 .d2 span{
    margin-bottom: 5px;
    padding-left: 20px;
    white-space: normal;
    line-height: 1.7;
  }
  .en-TradingView-body .TradingView-section-1 .d2 span::after{
    left: 0px;
  }
  .en-TradingView-body .TradingView-section-1 .d2 a{
    margin-top: 40px;
  }
  .en-TradingView-body .TradingView-img1{
    margin-top: 10px;
  }
  .en-TradingView-body .tv-section-title{
    font-size: 26px;
    padding: 0 3%;
  }
  .en-TradingView-body .tv-section-desc{
    font-size: 14px;
  }
  .en-TradingView-body .TradingView-section-4 .vip .p1{
    font-size: 24px;
  }
  .en-TradingView-body .TradingView-section-5 .col-4{
    padding-right: 0;
  }
  .en-TradingView-body .TradingView-section-5 .p2{
    padding: 0 2%;
  }
  .en-TradingView-body .TradingView-section-5 a{
    margin-top: 30px;
  }
  .en-TradingView-body .TradingView-section-5 .p1{
    font-size: 24px;
  }
  .en-TradingView-body .TradingView-section-6 .TradingView-section-6-p{
    width: 100%;
    line-height: 1.3;
    padding: 0 6%;
  }
  .en-TradingView-body .TradingView-section-6 .TradingView-section-6-p::before, .en-TradingView-body .TradingView-section-6 .TradingView-section-6-p::after{
    display: none;
  }
  .TradingView-section-3 .swiper-container, .TradingView-section-3 .swiper-wrapper{
    padding-bottom: 60px;
  }
  .TradingView-section-3-pagination{
    bottom: 30px !important;
    overflow: auto;
  }
  .TradingView-section-3{
    padding-bottom: 0;
  }
}
  </style>