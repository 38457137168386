export default {
  leftNav: [
    { id: "#Trading", name: "거래" },
    { id: "#Regulation", name: "이중 감독" },
    { id: "#Security", name: "보안" },
    { id: "#Fintech", name: "금융 기술" },
    { id: "#Black-Box", name: "블랙 박스" },
    { id: "#Private-Room", name: "거래 공간" },
    { id: "#Liquidity", name: "유동성" },
    { id: "#Honor", name: "명예" },
    //{ "id": "#News", "name": "소식" }
  ],
  pages1: {
    h1: "Trust & Respect",
    title: "거래를 열심히 하는 사람은 모두 열심을 다한 대우를 받을 가치가 있다",
    btn: " 동영상 보기 ",
  },
  pages2: {
    title: "고객 맞춤형 글로벌 인기 거래 상품",
    title1:
      "기회와 경쟁우위의 세계를 탐색하며 가장 진실되고 안정적인 시가를 파악해 보세요",
    btn: " 더 알아보기 ",
    text: "외환 거래",
    text1: " 원자재 상품 ",
    text2: " 지수 CFDs ",
    text3: " 셰어 CFDs ",
  },
  pages3: {
    title: "전세계 최고 수준의 감독 기관",
    title1:
      "EBC 금융 그룹의 자회사는 해당 지역 관할 구역에서 규제 및 라이센스를 받습니다.",
    btn: "더 알아보기",
    text: "영국 금융감독청 FCA",
    text1:
      "EBC Financial Group (UK) Ltd 는 영국FCA 금융감독청의 승인 및 규제를 받고 있습니다. 레퍼런스 번호: 927552",
    text2: "호주 증권 투자 위원회 ASIC",
    text3:
      "EBC Financial Group (Australia) Pty Ltd는 호주 증권 투자 위원회의 승인 및 규제를 받고 있습니다. 레퍼런스 번호: 500991",
    text4: "CIMA (CAYMAN)",
    text5:
      "EBC Financial Group (Cayman) Ltd 는 케이맨 제도 통화당국(CIMA)의 승인 및 규제를 받고 있습니다. 레퍼런스 번호: 2038223",
  },
  pages4: {
    title: "Driving You To Succeed",
    title1:
      "런던 LD5, 뉴욕 NY4, 싱가포르 SG1, 도쿄 TY3, 홍콩 HK2의 초고속 전용회선이 연결된 독립 서버에 배치되어 있으며, 스마트 오더 라우팅 및 시세 최적화 엔진을 제공하여 글로벌 초고속 금융 생태계를 구축합니다.",
    btn: " 더 알아보기",
    text: " 초 당 최고 주문 체결량 ",
    text1: " 초고속 추문 체결 ",
    text2: " 업타임 ",
    text3: "인터뱅크 레벨의 RAW ECN 스프레드",
  },
  pages5: {
    title: "85% 이상의 주문을 보다 우수한 단가로 체결",
    title1: "EBC트레이딩 블랙박스，더 정확한 최적의 매도 주문 알고리즘",
    btn: " 더 알아보기",
  },
  pages6: {
    title: "EBC 거래공간",
    title1:
      "EBC의 Private Room은 유동적인 매칭을 기반으로 귀하의 독점적인 거래 채널을 구축하고, 심지어 귀하의 거래 정보의 일부를 숨김으로써 '히든카드'를 보호할 수 있습니다. 이는 귀하의 주문이 더 나은 시세를 취합할 수 있을 뿐만 아니라 거래 과정에서 사이버 공격으로부터 피할 수 있음을 의미합니다.",
    btn: " 더 알아보기",
  },
  pages7: {
    title: "유동성",
    title1:
      "EBC는 다수의 국제 최고 은행과 장기적이고 안정적인 제휴관계를 구축하고, 여러 고품질 유동성 시세를 통합하며 매우 경쟁력 있는 시장 스프레드를 제공하여 초저 거래 비용을 쉽게 누릴 수 있도록 합니다.",
    btn: "더 알아보기",
  },
  pages8: {
    btn: "더 알아보기",
  },
  pages9: {
    title: "안심하고 이용할 수 있는 전면적 보장 체계",
    title1:
      "EBC는 자금의 안전을 최우선으로 생각합니다. 고객 자금은 Barclays Bank의 분리된 관리 계좌에 보관됩니다. EBC는 FCA, Financial Commission, Lloyds of London 및 AON과 협력하여 포괄적이고 다층적인 보안 메커니즘을 구축했습니다.",
    btn: "보안",
  },
  lang: "en",

  连接全球热门交易产品: "글로벌 인기 거래 상품",
  探索一个充满机遇和竞争优势的世界:
    "기회와 경쟁 우위가 가득한 세상을 탐험하고, 가장 정확하고 안정적인 시장 가격을 파악합니다",
  衍生品交易: "외환 거래",
  期货CFDs: "상품",
  全球股票指数CFDs: "현물 지수 차액거래",
  美国股票CFDs: "주식 차액거래 ",
  "欧元/美元": "EURUSD",
  "黄金/美元": "XAUUSD",
  道琼斯工业指数: "U30USD",
  了解交易产品: "거래 상품 알아보기",
  全球顶级监管: "글로벌 최고 규제",
  EBC金融集团旗下各公司:
    "EBC 금융 그룹은 각 자회사의 현지 관할 지역에서 승인 및 규제를 받고 운영되고 있습니다. ",
  授权及监管于: "규제 및 감독",
  监管号: "라이선스 번호",
  英国FCA监管: "영국 금융행위감독청(FCA)",
  "英国金融行为监管局 (FCA)": "영국 금융행위감독청(FCA)",
  开曼CIMA监管: "케이맨 제도 통화청(CIMA)",
  "开曼群岛金融管理局(CIMA)": "케이맨 제도 통화청(CIMA)",
  澳洲ASIC监管: "호주 증권 투자위원회(ASIC)",
  澳大利亚证券和投资委员会: "호주 증권 투자위원회(ASIC)",
  了解EBC官方: "자세히 알아보기",
  "全面保障 安心以致": "안심할 수 있는 종합적인 보호 정책",
  EBC将资金安全视为首要原则:
    "EBC 금융 그룹은 고객의 자산 안전을 최우선으로 생각합니다. 고객의 자산은 바클레이즈 은행의 수탁 계좌에 별도로 보관되며, 당사는 FCA, 금융위원회, 런던 로이드, AON와 긴밀한 협력으로 견고하고 다층적인 보안 시스템을 구축하고 있습니다.",
  了解安全保障: "자세히 알아보기",
  "连接全球 信赖首选": "많은 사람들의 신뢰를 받는 세상과 연결",
  EBC构建全方位服务生态:
    "EBC는 30년 이상의 경험을 가진 전문가 팀의 지원을 받는 포괄적인 서비스 에코시스템을 구축했습니다. 전 세계에 거쳐 운영되고 활발하고 광범위한 사용자 기반을 갖춘 EBC는 국제 무대에서 높은 평가를 받고 있습니다.",
  全球注册用户数: "글로벌 등록자 인원수 ",
  日均交易订单量: "일일 거래 주문수",
  "2024年第三季度为交易订单优化增益": "2024-Q3, 거래 주문에 대한 이익 최적화",
  多个: "+",
  "业务覆盖国家/地区": "업무 국가/지역",
  了解全球视野: "글로벌 인사이트에 대해 자세히 알아보기 ",
  "85%以上": "85% 이상 주문에 대해 더 나은 가격을 확보",
  的订单成交于更优价格: "",
  EBC交易黑盒:
    "EBC의 트레이딩 블랙박스는 주문 알고리즘을 정밀하게 최적화하여 가격 최적화, 주문 실행률 및 알고리즘 안정성 면에서 기존 시스템을 능가합니다.",
	每秒聚合订单: "초당 최대 주문 수",
  极速订单执行: "빠른 체결 속도",
  数据传输稳定高达: "데이터 전송 안정성",
  "行级RAW ECN点差": "인터뱅크 수준의 RAW ECN 스프레드",
  了解金融科技: "금융 기술에 대해 자세히 알아보기",
  EBC全球合作伙伴: "EBC 글로벌 파트너",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "EBC는 국제적으로 유명한 25개 이상의 은행 및 비은행 기관과 장기적이고 안정적인 협력 관계를 구축하여 진정한 기관 등급의 유동성 깊이와 0.0 PIPS 원시 스프레드를 제공합니다. EBC는 금융 서비스 외에도 옥스퍼드 대학교 경제학과와의 이니셔티브, WAGGGS와의 파트너십, 평등하고 지속 가능한 사회를 구축하기 위한 유엔 재단과의 공동 노력을 통해 기업의 사회적 책임을 적극적으로 이행하고 있습니다.",
  了解更多: "더 알아보기 ",
  十大最受欢迎券商: "인기 브로커 TOP10",
  最透明交易券商: "가장 투명한 브로커",
  最受好评券商: "최고 평점 브로커",
  卓越执行券商: "최우수 실행 브로커",
  全球最佳交易体验奖: "글로벌 거래 경험 최우수상",
  最受信赖券商: "가장 신뢰받는 FX 브로커",
  最佳CFD券商: "베스트 CFD 브로커",
  全球最佳ECN券商: "글로벌 베스트 ECN 브로커",
  全球新锐券商: "글로벌 브로커 루키",
  tabList: [
    `Forex`,
    `Commodities`,
    `Index CFDs`,
    `Stock CFDs`,
  ],
  出售: "Ask",
  买入: "Bid",
  点差: "Spread",
  立即交易: "Trade",
  致力于让交易者成为盈家: "고객의 성공에 무엇보다 열정적인 브로커",
  EBC每个引以为傲的数据背后:
    "EBC는 100개국 이상의 글로벌 무대에서 높은 평가를 받고 있습니다. 당사는 고객의 거래를 지원하며 단 한 번의 클릭으로도 글로벌 시장에서 거래 기회에 닿을 수 있도록 최선을 다하고 있습니다.",
  月均交易量: "월 평균 거래량",
  全球注册用户数: "글로벌 등록자 인원수",
  "2024年第四季度为交易订单优化增益": "2024-Q4, 거래 주문에 대한 이익 최적화",
  "业务覆盖国家/地区": "업무 국가/지역",
  number1: "177.5",
  number1Text: "177.5",
  number2: "4",
  number2Text: "4",
  number3: "273",
  number3Text: "2.73",
  number4: "100",
  number4Text: "100",
  langFlag: "en",
  亿: "B+",
  万: "M+",
  "+": "+",
};
