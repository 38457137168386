export default {  
  tradingView: {  
    tvlang: 'kr', 
    tvBodyClass: 'kr', 

    // 通用文本  
    disclaimer: '이벤트의 최종 결정권은 EBC에게 있습니다',  
    registerNow: '지금 바로 EBC 회원가입하기',  
    paidBenefits: '프리미엄 특전',  
    getItNow: '지금 받기',  
    getItFree: '무료로 받기',  
    depositToGet: '입금 시 무료로 특전 제공',  

    icon15: require('@/assets/image/TradingView/TradingView-icon15_kr.svg'),  

    // 顶部权益  
    benefits: {  
      analysis: '전문 시장 분석',  
      charts: '고효율 차트 기능',  
      orderFlow: '주문 흐름 보고서 별도 증정'  
    },  

    // 第二部分 - 领取步骤  
    steps: {  
      title: '특전 받는 방법',  
      list: [  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
          title: '회원가입',  
          desc: 'EBC 계정 회원가입하고<br>트레이딩 시작하기'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),  
          title: '입금 후 즉시 이용 가능',  
          desc: '한 달 순예치금 $1,000 or $10,000의 경우<br>무료로 기간 한정 특전을 받으실 수 있습니다'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
          title: '지금 사용하기',  
          desc: 'EBC 고객센터에 문의 후 트레이딩뷰<br>프리미엄 특전을 받아보세요'  
        }  
      ]  
    },  

    // 第3部分 - 市场动态  
    section3: {  
      title: 'EBC에서 트레이딩뷰로 글로벌 시장 동향을 한 눈에',  
      desc: '수백 개의 데이터 소스에 연결하고 전 세계의 2,019,615개 도구에 엑세스해 기관급 데이터 파트너로부터 전문 투자자들이 사용하는 최신 정보를 받아보세요.',  
      slides: [  
        {  
          image: require('@/assets/image/TradingView/TradingView-img3.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
          title: '실시간 시장 데이터와 고급 차트',  
          desc: '글로벌 시장 동향을 파악하고 모든 수익 기회를 붙잡으세요'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img4.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
          title: '심층 기술 지표와 전략',  
          desc: '다양한 기술 지표를 활용해 나만의 트레이딩 전략을 만들어 보세요'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img5.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
          title: '여러 기기에서 언제 어디서든',  
          desc: 'PC, 스마트폰 동시 거래 가능, 언제 어디서든 시장을 선도하세요'  
        }  
      ]  
    },  

    // 第4部分 - 会员计划  
    section4: {  
      title: '입금 후 즉시Trading View VIP로 등록',  
      desc: '아직도 망설이고 계신가요? EBC에서 원클릭으로 계좌를 개설하고 트레이딩뷰 VIP 혜택을 무료로 받아 보세요. 트레이딩은 지금 여기서 시작입니다!',  
      plans: [  
        {  
          isPremium: false,  
          title: '월간 일반 회원',  
          requirement: '한 달 순예치금 $1,000 무료로 특전 제공',  
          features: [  
            { text: '탭당 2개 차트', enabled: true },  
            { text: '차트당 5 인디케이터', enabled: true },  
            { text: '10K 히스토리 바', enabled: true },  
            { text: '20개 가격 알림', enabled: true },  
            { text: '20개 기술적 알림', enabled: true },  
            { text: '10 병렬 차트 연결', enabled: true },  
            { text: '무광고', enabled: true },  
            { text: '거래량 분포차트(VP)', enabled: true },  
            { text: '사용자 지정 기간', enabled: true },  
            { text: '사용자 지정 범위 K라인', enabled: true },  
            { text: '다중 감시 목록', enabled: true },  
            { text: 'K-라인 재생', enabled: true },  
            { text: '인디케이터 온 인디케이터', enabled: true },  
            { text: '차트 데이터 내보내기', enabled: false },  
            { text: '렌코, 카기, 라인브레이크, 포인트앤피겨 등 스페셜 차트', enabled: false },  
            { text: '커스텀 수식을 기반으로 한 차트', enabled: false },  
            { text: '시간 가격 기회', enabled: false },  
            { text: '볼륨 풋프린트', enabled: false },  
            { text: '자동 차트 패턴', enabled: false },  
            { text: '두번쨰 기반 알림', enabled: false },  
            { text: '만료되지 않는 알림', enabled: false },  
            { text: '초대 전용 스크립트 게시', enabled: false },  
            { text: '두 번째 기반 간격', enabled: false }  
          ]  
        },  
        {  
          isPremium: true,  
          title: '월간 프리미엄 회원',  
          requirement: '한 달 순예치금  $10,000 무료로 특전 제공',  
          features: [  
            { text: '탭당 8개 차트', enabled: true },  
            { text: '차트당 25 인디케이터', enabled: true },  
            { text: '20K 히스토리 바', enabled: true },  
            { text: '400개 가격 알림', enabled: true },  
            { text: '400개 기술적 알림', enabled: true },  
            { text: '50 병렬 차트 연결', enabled: true },  
            { text: '무광고', enabled: true },  
            { text: '거래량 분포차트(VP)', enabled: true },  
            { text: '사용자 지정 기간', enabled: true },  
            { text: '사용자 지정 범위 K라인', enabled: true },  
            { text: '다중 감시 목록', enabled: true },  
            { text: 'K-라인 재생', enabled: true },  
            { text: '인디케이터 온 인디케이터', enabled: true },  
            { text: '차트 데이터 내보내기', enabled: true },  
            { text: '렌코, 카기, 라인브레이크, 포인트앤피겨 등 스페셜 차트', enabled: true },  
            { text: '커스텀 수식을 기반으로 한 차트', enabled: true },  
            { text: '시간 가격 기회', enabled: true },  
            { text: '볼륨 풋프린트', enabled: true },  
            { text: '자동 차트 패턴', enabled: true },  
            { text: '두번쨰 기반 알림', enabled: true },  
            { text: '만료되지 않는 알림', enabled: true },  
            { text: '초대 전용 스크립트 게시', enabled: true },  
            { text: '두 번째 기반 간격', enabled: true }  
          ]  
        }  
      ]  
    },  

    // 第5部分 - 新客福利  
    section5: {  
      title: '신규 고객 전용 혜택<br>트레이딩 성공률 UP',  
      desc: 'CME 거래소 심층 주문흐름 일일 보고서 : 주요 흐름 추적하고 거래 성공률 높이기',  
      desc2: 'Trading Central  분석가의 시장 인사이트 : 시장 기회를 정확히 포착하기',  
      desc3: '38개 국가/지역의 실시간 거시경제 데이터를 제공해 잠재적 시장 변동을 모니터링하고 예측해 대응할 수 있도록 지원합니다',  
      images: [  
        require('@/assets/image/TradingView/TradingView-img7.png'),  
        require('@/assets/image/TradingView/TradingView-img8.png'),  
        require('@/assets/image/TradingView/TradingView-img9.png')  
      ]  
    },  

    // 第6部分 - 荣誉展示  
    section6: {  
      title: '지금 입금하기, 거래 성공은 EBC',  
      desc: 'EBC 금융그룹 가장 신뢰할 수 있는 글로벌 파트너'  
    }  
  }  
}