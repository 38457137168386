export default {  
  tradingView: {  
    tvlang: 'en',  
    tvBodyClass: 'en',

    // General text  
    disclaimer: ' EBC reserves the right to the final interpretation of this promotion ',  
    registerNow: 'Register to Trade at EBC Now',  
    paidBenefits: 'Paid Privileges',  
    getItNow: 'Claim Now',  
    getItFree: 'Claim for Free',  
    depositToGet: 'Deposit to Claim for Free',  

    icon15: require('@/assets/image/TradingView/TradingView-icon15_en.svg'),  

    // Top benefits  
    benefits: {  
      analysis: 'Professional Market Analysis',  
      charts: 'Powerful Charting Features',  
      orderFlow: 'Bonus Order Flow Report'  
    },  

    // Section 2 - Claim steps  
    steps: {  
      title: 'Steps to Claim',  
      list: [  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
          title: 'Register Now',  
          desc: 'Complete your registration on EBC\'s official website and start your trading journey'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),  
          title: 'Deposit to Enjoy',  
          desc: 'Net Deposit of 1,000 USD or 10,000 USD in a Single Month to Receive Free Limited-time Membership Benefits'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
          title: 'Start Using',  
          desc: 'Contact EBC customer service to access TradingView premium membership privileges'  
        }  
      ]  
    },  

    // Section 3 - Market Dynamics  
    section3: {  
      title: 'Trade with EBC and Master Global Market Trends Using TradingView',  
      desc: 'Connect to hundreds of data sources and access 2,019,615 tools worldwide. Obtain forward-looking information used by professional investors from institutional-grade data partners.',  
      slides: [  
        {  
          image: require('@/assets/image/TradingView/TradingView-img3.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
          title: 'Real-time Market Data and Advanced Charts',  
          desc: 'Stay on top of global market trends and seize every profit opportunity'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img4.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
          title: 'Comprehensive Technical Indicators and Strategies',  
          desc: 'Unlock a variety of technical indicators and build personalised trading strategies'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img5.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
          title: 'Trade Anytime, Anywhere on Multiple Devices',  
          desc: 'Sync trading across your computer and mobile devices to remain connected to the market'  
        }  
      ]  
    },  

    // Section 4 - Membership Plans  
    section4: {  
      title: 'Deposit to enjoy free TradingView Premium Membership',  
      desc: 'Still missing market opportunities and struggling with unclear trends? Register an account with EBC in one click and get TradingView Premium Membership for free, starting your trading journey on a winning note.',  
      plans: [  
        {  
          isPremium: false,  
          title: 'Essential Membership',  
          requirement: 'Net Deposit of 1,000 USD to Claim for Free',  
          features: [  
            { text: '2 charts per tab', enabled: true },  
            { text: '5 indicators per chart', enabled: true },  
            { text: '10K historical bars', enabled: true },  
            { text: '20 price alerts', enabled: true },  
            { text: '20 technical alerts', enabled: true },  
            { text: '10 parallel chart connections', enabled: true },  
            { text: 'No ads', enabled: true },  
            { text: 'Volume profile', enabled: true },  
            { text: 'Custom timeframes', enabled: true },  
            { text: 'Custom Range Bars', enabled: true },  
            { text: 'Multiple watchlists', enabled: true },  
            { text: 'Bar Replay', enabled: true },  
            { text: 'Indicators on indicators', enabled: true },  
            { text: 'Chart data export', enabled: false },  
            { text: 'Intraday Renko, Kagi, Line Break, Point & Figure charts', enabled: false },  
            { text: 'Charts based on custom formulas', enabled: false },  
            { text: 'Time Price Opportunity', enabled: false },  
            { text: 'Volume footprint', enabled: false },  
            { text: 'Auto Chart Patterns', enabled: false },  
            { text: 'Second-based alerts', enabled: false },  
            { text: 'Alerts that don\'t expire', enabled: false },  
            { text: 'Publishing invite-only scripts', enabled: false },  
            { text: 'Second-based intervals', enabled: false }  
          ]  
        },  
        {  
          isPremium: true,  
          title: 'Premium Membership',  
          requirement: 'Net Deposit of 10,000 USD to Claim for Free',  
          features: [  
            { text: '8 charts per tab', enabled: true },  
            { text: '25 indicators per chart', enabled: true },  
            { text: '20K historical bars', enabled: true },  
            { text: '400 price alerts', enabled: true },  
            { text: '400 technical alerts', enabled: true },  
            { text: '50 parallel chart connections', enabled: true },  
            { text: 'No ads', enabled: true },  
            { text: 'Volume profile', enabled: true },  
            { text: 'Custom timeframes', enabled: true },  
            { text: 'Custom Range Bars', enabled: true },  
            { text: 'Multiple watchlists', enabled: true },  
            { text: 'Bar Replay', enabled: true },  
            { text: 'Indicators on indicators', enabled: true },  
            { text: 'Chart data export', enabled: true },  
            { text: 'Intraday Renko, Kagi, Line Break, Point & Figure charts', enabled: true },  
            { text: 'Charts based on custom formulas', enabled: true },  
            { text: 'Time Price Opportunity', enabled: true },  
            { text: 'Volume footprint', enabled: true },  
            { text: 'Auto Chart Patterns', enabled: true },  
            { text: 'Second-based alerts', enabled: true },  
            { text: 'Alerts that don\'t expire', enabled: true },  
            { text: 'Publishing invite-only scripts', enabled: true },  
            { text: 'Second-based intervals', enabled: true }  
          ]  
        }  
      ]  
    },  

    // Section 5 - New Client Benefits  
    section5: {  
      title: 'Exclusive Benefits for New Clients<br>Boost Your Win Rate',  
      desc: 'Daily Depth Order Flow Report from CME Exchange: Track major players and improve your win rate',  
      desc2: 'Market Insights from Trading Central Analysts: Accurately capture market opportunities',  
      desc3: 'Access real-time macroeconomic data from 38 countries/regions to help traders monitor, predict, and respond to potential market fluctuations.',  
      images: [  
        require('@/assets/image/TradingView/TradingView-img7.png'),  
        require('@/assets/image/TradingView/TradingView-img8.png'),  
        require('@/assets/image/TradingView/TradingView-img9.png')  
      ]  
    },  

    // Section 6 - Honor Display  
    section6: {  
      title: 'Deposit Now and Gain an Advantage with EBC from the Start',  
      desc: 'EBC Financial Group, Your Most Trusted Global Trading Partner'  
    }  
  }  
}