export default {
	"official-class-name": "official-cn-box",
	"lang": "cn",
	"official-page1": {
		"text1": "官方渠道验证",
		"text2": "为防止不法分子盗用EBC名义谋利，您可通过此页面进行信息查询，验证渠道真实性。",
		"text3": "请输入完整网站链接",
		"text4": "查询验证",
	},
	"official-page2": {
		"text1": "EBC官方声明：",
		"text2": "1. EBC目前仅上线官方MT4、MT5交易软件，请从官方网站下载。",
		"text3": "2. EBC目前未涉足任何加密货币及与其相关的CFD交易。",
		"text4": "3. EBC不提供任何形式的投资建议，也不会对交易收益进行任何形式的承诺或保证。",
		"text5": "4. 对任何盗用EBC名义的行为，我们将立即采取法律行动，对盗用者进行严厉打击。",
		"text6": "5. EBC致力于维护客户权益，若您发现任何可疑活动，请将证据提交给我们的客服团队，或通过电子邮件至<a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a>与我们联系。我们将迅速响应，并为受骗者提供必要的法律支持和援助。",
	},
	"official-page3": {
		"text1": "官方网站，您查询的信息已通过官方验证",
		"text2": "返回重新查询",
		"text3": "您查询的信息未经官方验证，请不要与未经验证的渠道互动",
		"text4": "举报该信息",
		"text5": "如果您认为该信息涉嫌虚假盗用，请向我们提交举报",
		"text6": "举报信息",
		"text7": "网站地址",
		"text8": "*如果您需要获得更多法律援助与反馈，请",
		"text8-1": "点击此处留言",
		"text8-2": "",
		"text9": "收起留言板",
		"text10": "贵姓/昵称",
		"text11": "请输入贵姓/昵称",
		"text12": "邮箱",
		"text13": "请输入邮箱",
		"text14": "手机号",
		"text15": "请输入手机号",
		"text16": "信息反馈",
		"text17": "请填写信息反馈",
		"text18": "验证码",
		"text19": "请输入验证码",
		"text20": "取消",
		"text21": "提交举报",
		"text22": "已提交，感谢您的反馈。",
	},
}