<template>
	<div class="cfa">
		<page-navigate></page-navigate>
		<div class="cfa1">
			<div class="cfa1-box">
				<div class="cfa1-head flex">
					<div class="cfa1-head__logo">
					</div>
					<div class="cfa1-head__title flex-1">
					</div>
				</div>
				<div class="container">
					<div class="cfa1-title flex">
						<div class="cfa1-title__left flex-1"></div>
						<img src="../assets/image/cfa/xx-icon.svg" alt="">
						<div class="cfa1-title__text">
							{{ $t("cfa.cfa1.h1") }}
						</div>
						<div class="cfa1-title__text wap">
							{{ $t("cfa.cfa1.h1wap") }}
						</div>
						<img src="../assets/image/cfa/xx-icon.svg" alt="">
						<div class="cfa1-title__right flex-1"></div>
					</div>

					<img v-if="$t('cfa.cfa3.tips') == -1" :src="require(`../assets/image/cfa/tit-ja.svg`)" alt=""
						class="img-wap">
					<img v-else :src="require(`../assets/image/cfa/tit-${$t('cfa.lang')}.svg`)" alt="" class="img-wap">
					<div class="cfa1-info">
						<div class="cfa1-info__item" :class="{ 'inlineBlock': $t('cfa.cfa3.tips') == -1 }">
							{{ $t("cfa.cfa1.text") }}
						</div>
						<div class="cfa1-info__item" :class="{ 'inlineBlock': $t('cfa.cfa3.tips') == -1 }">
							{{ $t("cfa.cfa1.text1") }}
						</div>
						<div class="cfa1-info__item" :class="{ 'inlineBlock': $t('cfa.cfa3.tips') == -1 }">
							{{ $t("cfa.cfa1.text2") }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="cfa1-desc flex">
				<div class="cfa1-desc__item" :class="$t('cfa.lang')">
					<div class="pag flex" v-for="(itx, idx) in $t('cfa.cfa1.list')" :key="idx">
						{{ itx }}
					</div>
				</div>
				<div class="cfa1-desc__item" :class="[$t('cfa.lang'), { 'ja': $t('cfa.cfa3.tips') == -1 }]">
					<div class="pag flex" v-for="(itx, idx) in $t('cfa.cfa1.list1')" :key="idx">
						{{ itx }}
					</div>
				</div>
			</div>
		</div>
		<div class="cfa2">
			<div class="cfa2-title">
				<div class="cfa2-title__box">
					<div class="cfa2-title__box__text" :class="$t('cfa.lang')">
						{{ $t('cfa.cfa2') }}
					</div>
					<div class="cfa2-title__box__line" :class="$t('cfa.lang')">

					</div>
				</div>
			</div>
			<div class="cfa2-list flex">
				<div class="cfa2-list__item flex" v-for="(itx, idx) in $t('cfa.cfa3.reward_list_m')" :key="idx">
					<div class="item-left flex flex-1">
						<div class="num" v-if="idx == 0">
							LV.7
						</div>
						<div class="num" v-if="idx == 1">
							LV.6
						</div>
						<div class="num" v-if="idx == 2">
							LV.5
						</div>
						<div class="num" v-if="idx == 3">
							LV.4
						</div>
						<div class="num" v-if="idx == 4">
							LV.3
						</div>
						<div class="num" v-if="idx == 5">
							LV.2
						</div>
						<div class="num" v-if="idx == 6">
							LV.1
						</div>
						<div class="xx flex" v-if="idx == 0">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
						</div>
						<div class="xx flex" v-if="idx == 1">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
						</div>
						<div class="xx flex" v-if="idx == 2">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
						</div>
						<div class="xx flex" v-if="idx == 3">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
						</div>
						<div class="xx flex" v-if="idx == 4">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
						</div>
						<div class="xx flex" v-if="idx == 5">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
						</div>
						<div class="xx flex" v-if="idx == 6">
							<img src="../assets/image/cfa/xx-icon.svg" alt="">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
							<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
						</div>
					</div>
					<div class="item-right flex">
						<div class="tips" v-if="$t('cfa.cfa3.tips') != -1">
							{{ $t('cfa.cfa3.tips') }}
						</div>
						<div class="sum">
							{{ itx }}<span style="font-size: 16px;font-weight: 400;"
								v-if="$t('cfa.cfa3.tips') == -1">USD</span>
						</div>
						<div class="tips">
							{{ $t('cfa.cfa3.tips1') }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="cfa3">
			<div class="container">
				<div class="cfa3-box">
					<div class="cfa3-box__title">
						<img src="../assets/image/cfa/xx-icon-shuang.svg" alt="" class="shuang">{{ $t("cfa.cfa3.title")
						}}
						<img src="../assets/image/cfa/xx-icon-kong.svg" alt="" class="kong">
					</div>
					<div class="cfa3-box__list flex">
						<div class="cfa3-box__list__item flex flex-1" v-for="(itx, idx) in $t('cfa.cfa3.reward_list')"
							:key="idx">
							<div class="num">
								LV.{{ idx + 1 }}
							</div>
							<div class="img">
								<img :src="require(`../assets/image/cfa/icon-${idx}.svg`)" alt="">
							</div>
							<div class="tips" v-if="$t('cfa.cfa3.tips') != -1">
								{{ $t("cfa.cfa3.tips") }}
							</div>
							<div class="sum">
								{{ itx }}<span style="font-size: 16px;font-weight: 400;"
									v-if="$t('cfa.cfa3.tips') == -1">USD</span>
							</div>
							<div class="tips">
								{{ $t("cfa.cfa3.tips1") }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="cfa4">
			<div class="container">
				<div class="cfa4-box">
					<div class="cfa4-title flex">
						<img src="../assets/image/cfa/xx-icon.svg" alt="">
						<div class="tit">
							{{ $t('cfa.cfa4') }}
						</div>
						<div class="flex-1 line">

						</div>
					</div>
					<ss-banner-new :newData="newData" v-if="newData.length != 0"></ss-banner-new>
				</div>
			</div>
		</div>
		<div class="cfa5">
			<div class="container">
				<div class="cfa5-desc" v-html="$t('cfa.cfa5')" v-if="$t('cfa.cfa3.tips') != -1">

				</div>
				<div class="row" v-else>
					<div class="title">【参加資格】</div>
					<div>
						金融関連の情報発信チャネルを所有し、定期的にブログ記事、レポートや教育コースなどの形で以下の内容を発信されているインフルエンサー様が対象です。
					</div>
					<div>
						・テクニカル分析　・マーケットコメンテーター　・経済指標分析　・啓蒙教育コンテンツ　・その他金融関連情報
					</div>
					<div class="title">【参加方法】</div>
					<div>
						ご参加希望の際に、弊社カスタマーサポートまでお問い合わせください。
					</div>
					<div>
						・LiveChat（HP）：
						<a href="https://www.ebc.com/jp/">https://www.ebc.com/jp/</a>
					</div>
					<div>
						・LINE：<a href="https://line.me/ti/p/%40171aosek">https://line.me/ti/p/%40171aosek</a>
						(検索ID: @ebc_japan)
					</div>
					<div>
						・E-mail：mkt.jp@ebc.com
					</div>
					<div class="title">【報酬について】</div>
					<div>前月の実績に基づいて報酬が計算されます。報酬は月初5営業日以内に開設されたEBC口座に入金されます。</div>
					<div class="title">【注意事項】</div>
					<div>・作成されたコンテンツはオリジナルである必要があります。転載や剽窃行為が発覚した場合、当プログラムへの参加はできません。</div>
					<div>・その他不正と判断された行為や当社の規約に違反した場合、当プログラムへの参加はできません。</div>
					<div>・本キャンペーンは、予告なく延長、変更または終了する場合がございます。</div>
				</div>
			</div>
		</div>
		<div class="cfa6">
			<!-- <div class="container">
				<div class="cfa6-box">
					<div class="cfa6-title">
						{{$t('cfa.cfa6.title')}}
					</div>
					<div class="cfa6-title wap">
						{{$t('cfa.cfa6.titlewap')}}
					</div>
					<div class="cfa6-list flex">
						<div class="cfa6-list__item flex flex-1">
							<div class="img">
								<img src="../assets/image/cfa/gq-1.svg" alt="">
							</div>
							<div class="desc" v-html="$t('cfa.cfa6.desc')">

							</div>
						</div>
						<div class="cfa6-list__item flex">
							<div class="img">
								<img src="../assets/image/cfa/gq-2.svg" alt="">
							</div>
							<div class="desc" v-html="$t('cfa.cfa6.desc1')">

							</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>

	</div>
</template>

<script>
import {
	mapState
} from "vuex";
export default {
	metaInfo() {
		return {
			title: this.seoInfo.title || '',
			meta: [ // set meta
				{
					name: 'keyWords',
					content: this.seoInfo.keyWords || ''
				},
				{
					name: 'description',
					content: this.seoInfo.description || ''
				}
			]
		}
	},
	data() {
		return {
			newData: [],
			list: [2550, 6300, 13000, 22000, 38000, 64000, '78000+'],
			list1: ['78000+', 64000, 38000, 22000, 13000, 6300, 2550],
			seoInfo: {}
		}
	},
	computed: {
		...mapState({
			Lan: (state) => state.Lan,
		}),
	},
	created() {
		setTimeout(() => {
			this.getNewData()
			this.$getSeoInfo({
				lang: this.Lan,
				path: "/cfa"
			}).then(res => {
				this.seoInfo = res
			})
		}, 200)
	},
	methods: {
		getNewData() {
			let parms;
			if (this.Lan === 'zh_CN') {
				parms = 'zh_CN'
			} else {
				parms = 'en_US'
			}
			this.$http('/ebc/api/getLearnCategory/run', {
				lang: parms
			}).then(res => {
				if (parms == 'zh_CN') {
					this.newData = res.data.data[1].info
				} else {
					this.newData = res.data.data[0].info
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.inlineBlock {
	display: inline-block;
}

.cfa {
	background: linear-gradient(90deg, #400504 0%, #230000 100%);

	.cfa1 {
		background: url('../assets/image/cfa/head-bg.png') no-repeat top center;
		background-size: 100% 100%;
		height: 400px;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		.img-wap {
			display: none;
		}

		&-head {
			height: 110px;
			align-items: center;
			margin-bottom: 50px;
			padding: 0 26px;

			&__logo {
				img {
					width: 151px;
					height: 62px;
				}
			}

			&__title {
				color: #A57C52;
				font-size: 14px;
				letter-spacing: 1.044px;
				text-align: right;
			}
		}

		&-title {
			align-items: center;
			justify-content: center;
			padding-bottom: 36px;

			&__text {
				color: #AF8147;
				font-weight: bold;
				letter-spacing: 3.6px;
				font-size: 36px;
				padding: 0 16px;

				&.wap {
					display: none;
				}
			}

			img {
				width: 20px;
				height: 20px;
			}

			&__left {
				height: 2px;
				background: linear-gradient(to left, #AF8147, rgba(175, 129, 71, 0.00));
				margin-right: 5px;
			}

			&__right {
				height: 2px;
				background: linear-gradient(to right, #AF8147, rgba(175, 129, 71, 0.00));
				margin-left: 5px;
			}
		}

		&-info {
			text-align: center;
			padding-bottom: 50px;

			&__item {
				padding-bottom: 20px;
				letter-spacing: 3px;
				font-size: 14px;
				color: #D7CDCC;
			}
		}

		&-desc {
			margin-top: 40px;

			&__item {
				padding: 40px 20px 30px;
				display: flex;
				border: 1px solid #704124;
				flex-wrap: wrap;

				&.en {
					flex-direction: column;
					flex-wrap: nowrap;
				}

				&:nth-child(1) {
					width: 50%;
					background: url('../assets/image/cfa/EBC.svg') no-repeat 20px 20px;
					min-height: 230px;
				}

				&:nth-child(2) {
					width: 52%;
					background: url('../assets/image/cfa/name.svg') no-repeat center 30px;
					margin-left: -2%;
					margin-top: 50px;
					height: 200px;
					padding: 76px 90px 62px 190px;

					&.en {
						background: url('../assets/image/cfa/name-en.svg') no-repeat center 30px;
					}

					&.ja {
						background: url('../assets/image/cfa/name-ja.svg') no-repeat center 30px;
					}
					// &.th {
					// 	background: url('../assets/image/cfa/name-th.svg') no-repeat center 30px;
					// }
				}

				.pag {
					color: #fff;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 1.4px;
					padding-left: 20px;
					background: url('../assets/image/cfa/xx-icon-bai.svg') no-repeat center left;
					margin-top: 10px;
					width: 50%;
					height: 20px;
					align-items: center;
					white-space: nowrap;
				}
			}
		}
	}

	.cfa2 {
		display: none;
	}

	.cfa3 {
		&-box {
			position: relative;
			border: 1px solid #704124;
			padding: 20px 9px;
			margin-top: 50px;

			&__title {
				color: #AF8147;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 1px;
				position: absolute;
				top: -20px;
				left: 90px;
				background-color: #380303;
				text-transform: capitalize;

				.kong {
					width: 20px;
					height: 20px;
					margin-right: 2px;
				}

				.shuang {
					width: 30px;
					height: 30px;
					margin-right: 10px;
				}
			}

			&__list {
				align-items: flex-end;

				&__item {
					flex-direction: column;
					margin-left: 6px;
					background: linear-gradient(180deg, rgba(109, 1, 1, 0.00) 0%, #6D0101 51.04%, rgba(109, 1, 1, 0.00) 100%);
					display: flex;
					align-items: center;
					justify-content: center;

					&:nth-child(1) {
						margin-left: 0;
						height: 230px;
					}

					&:nth-child(2) {
						height: 280px;
					}

					&:nth-child(3) {
						height: 330px;
					}

					&:nth-child(4) {
						height: 390px;
					}

					&:nth-child(5) {
						height: 440px;
					}

					&:nth-child(6) {
						height: 490px;
					}

					&:nth-child(7) {
						height: 530px;
					}

					.num {
						font-size: 36px;
						color: #AF8147;
					}

					.img {
						padding: 18px 0;

						img {
							width: 60px;
							height: 60px;
						}
					}

					.tips {
						font-size: 12px;
						color: #D7CDCC;
						text-transform: capitalize;
					}

					.sum {
						color: #fff;
						font-weight: 900;
						font-size: 24px;
						padding: 8px 0 15px;
					}
				}
			}
		}
	}

	.cfa4 {
		&-box {
			padding: 0 50px;
		}

		&-title {
			padding: 60px 0 30px;
			align-items: center;

			img {
				width: 20px;
				height: 20px;
			}

			.tit {
				color: #AF8147;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 1px;
				padding-left: 8px;
			}

			.line {
				margin-left: 8px;
				height: 2px;
				background: linear-gradient(90deg, #AF8147 0%, rgba(175, 129, 71, 0.00) 100%);
			}
		}
	}

	.cfa5 {
		margin-top: 40px;

		&-desc {
			color: #D7CDCC;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 2px;
			padding: 0 46px;
		}
	}

	.cfa6 {
		padding: 50px 0;

		&-box {
			padding: 0 50px;
		}

		&-title {
			padding: 15px 0 30px;
			text-align: center;
			color: #D39C6A;
			font-size: 18px;
			letter-spacing: 2px;

			&.wap {
				display: none;
			}
		}

		&-list {
			&__item {
				.img {
					img {
						width: 93px;
						height: 63px;
					}
				}

				.desc {
					padding-left: 15px;
					color: #A57C52;
					font-size: 16px;
					letter-spacing: 1px;
					line-height: 1.3;
					text-transform: capitalize;
				}
			}
		}
	}

	@media (max-width: 500px) {
		.inlineBlock {
			display: block;
		}

		.cfa1 {
			background: url('../assets/image/cfa/head-bg-wap.png') no-repeat top center;
			background-size: 100%;
			height: auto;
			position: unset;

			&-box {
				background: linear-gradient(to bottom, rgba(64, 5, 4, 0) 0%, #230000 100%);
			}

			.img-wap {
				display: block;
				margin: 0 auto 36px;
			}

			&-info {
				padding-bottom: 100px;

				&__item {
					color: #FFFFFF;
					font-size: 11px;
					letter-spacing: 0;
					padding-bottom: 15px;
					line-height: 1.5;
				}
			}

			&-title {
				display: none;
				border: 1px solid #704124;
				padding: 10px 0;
				position: relative;
				margin: 0 15px 40px;

				img {
					position: absolute;
					top: 30%;
					left: -10px;

					&:nth-child(2) {
						right: -10px;
						left: initial;
					}
				}

				&__text {
					display: none;
					font-size: 28px;
					letter-spacing: 2px;

					&.wap {
						display: block;
					}
				}

				&__right {
					display: none;
				}

				&__left {
					display: none;
				}
			}

			&-head {
				padding: 0 10px;
				height: 70px;

				&__logo {
					img {
						width: 97px;
						height: 40px;
					}
				}

				&__title {
					font-size: 12px;
					letter-spacing: 0;
				}
			}

			&-desc {
				display: none;
			}
		}

		.cfa2 {
			display: block;
			margin-top: -100px;

			&-title {
				background: url('../assets/image/cfa/TrustRespect.svg') no-repeat top center;
				height: 87px;

				&__box {
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-top: 20px;
					justify-content: center;

					&__text {
						color: #D09C5B;
						font-size: 23px;
						letter-spacing: 2px;
						white-space: nowrap;

						&.en {
							opacity: 0;
						}
					}

					&__line {
						&.en {
							opacity: 0;
						}

						margin-top: 10px;
						width: 200px;
						height: 1px;
						background: linear-gradient(90deg, rgba(175, 129, 71, 0.00) 0%, #AF8147 51.04%, rgba(175, 129, 71, 0.00) 100%);
					}
				}
			}

			&-list {
				flex-direction: column;

				&__item {
					margin-top: 12px;
					height: 114px;
					align-items: center;
					background: linear-gradient(90deg, rgba(109, 1, 1, 0.00) 0%, #5D0101 51.04%, rgba(109, 1, 1, 0.00) 100%);
					width: 100%;
					padding: 0 20px;

					.item {
						&-left {
							flex-direction: column;

							.num {
								color: #AF8147;
								font-size: 30px;
							}

							.xx {
								align-items: center;
								padding-top: 10px;

								img {
									width: 20px;
									height: 20px;
									margin-right: 7px;

									&.kong {
										width: 27px;
										height: 27px;
										margin-right: 0;
									}
								}
							}
						}

						&-right {
							flex-direction: column;
							align-items: center;

							.tips {
								font-size: 13px;
								color: #D7CDCC;
								text-transform: capitalize;
							}

							.sum {
								color: #FFF;
								font-size: 24px;
								font-weight: 900;
								padding: 9px 0;
							}
						}
					}
				}
			}
		}

		.cfa3 {
			display: none;
		}

		.cfa4 {
			display: none;
		}

		.cfa5 {
			display: none;
		}

		.cfa6 {
			padding: 30px 0;
			background: #1D0101;

			&-box {
				padding: 0;
			}

			&-title {
				display: none;
				line-height: 1.5;
				font-size: 14px;

				&.wap {
					display: block;
					letter-spacing: 1px;
				}
			}

			&-list {
				flex-direction: column;

				&__item {
					margin-bottom: 10px;
					width: 100%;

					.img {
						img {
							width: 62px;
							height: 42px;
						}
					}

					.desc {
						padding-left: 5px;
						font-size: 12px;
						line-height: 1.2;
					}
				}
			}
		}
	}
}

.cfa5 .row {
	padding: 0 46px;
}

.cfa5 .row>div {
	color: #fff;
	font-size: 16px;
	line-height: 38px;
	letter-spacing: 0.5px;
	opacity: 0.7;
}

.cfa5 .row>div>a {
	color: #fff;
}

.cfa5 .row>.title {
	font-weight: 500;
	font-size: 20px;
	margin-left: 0;
	padding-left: 0;
}
</style>