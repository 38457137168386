export default {
  type: "en",
  lang: "en",
  lang2: "en",
  "Trading Central": "Trading Central",
  市场分析: "Market Analysis",
  EBC与TradingCentral携手合作:
    "EBC has partnered with Trading Central to offer all active clients professional market analysis tools. These tools help clients gain deeper insights into the market, identify trading opportunities, and optimize their trading strategies.",
  分析师观点: "Analyst Insights",
  财经日历解析: "Economic Calendar Analysis ",
  "Alpha-MT4/MT5技术指标": "Alpha-MT4/MT5 Technical Indicators",
  "Martin Pring教学视频": "Martin Pring's Educational Video ",
  解锁完整版: "Unlock Full Version",
  "*本页面的信息不构成EBC":
    "*The information on this page does not constitute advice or recommendations for investing in financial instruments from EBC, Trading Central, or their content providers. EBC, Trading Central, and their content providers are not liable for any losses or damages resulting from the use of this information.",
  收起: "Collapse ",
  衍生品: "Forex",
  指数: "Indices ",
  商品: "Commodities ",
  星号数代表支撑位和阻力位的强度: "Star Ratings indicate the strength of the support and resistance level",
  分析师观点: "Analyst Insights",
  通过清晰的趋势线展示简明易行的交易计划:
    "Display a clear and straightforward trading plan with trend lines, continuously updated throughout the trading session to help you identify new trading opportunities.",
  财经日历解析: "Economic Calendar Analysis ",
  提供38个国家:
    "Access real-time macroeconomic data from 38 countries/regions, enabling traders to monitor, forecast, and respond to potential market fluctuations.",
  "Alpha-MT4/MT5 技术指标": "Alpha-MT4/MT5 Technical Indicators",
  通过三个简明的创新指标展示市场情绪:
    "Leverage three concise and innovative indicators that reveal market sentiment, helping traders make informed decisions and identify potential entry and exit points.",
  技术观点: "Technical Insights：",
  通过技术分析结果: "Provide preferred directions and target levels for each charts based on technical analysis result ",
  自适应K线图: "Adaptive K-Line Charts：",
  支持16种与决策相关的K线图形模式: "Support 16 decision-related K-line patterns.",
  "自适应趋异指标(ADC)": "Adaptive Divergence Convergence (ADC) Indicator ：",
  "长短进/出信号": "Includes long/short entry and exit signals, fast/slow price indicators, initial/smoothed signal lines, and fast/slow oscillators.",
  下载指标: "Download Indicators ",
  "Martin Pring 教学视频": "Martin Pring Educational Video ",
  "通过Martin Pring的视频课程":
    "Through out Martin Pring's video courses, learn the fundementals of technical analysis, including techniques like trend lines and moving averages to enchance your trading skills ",
  灵活的学习模式: "Flexible Learning Mode：",
  可以自由安排学习进度:
    "Schedule your learning at your own pace with a wealth of content and tools to easily master investment techniques.",
  广泛的内容覆盖: "Broad Content Coverage：",
  从初级到高级:
    "From beginner to advanced levels, comprehensive course content caters to users of various skill levels, systematically improving investment skills",
  互动学习体验: "Interative Learning Experience：",
  包含术语表和自我评估等互动工具:
    "Features tools like a glossary and self-assessments to help you track your progress, deepen your understanding, and improve retention.",
  了解更多: "Learn More",
};
