export default {  
  tradingView: {  
    tvlang: 'jp',  
    tvBodyClass: 'jp',

    // 通用文本  
    disclaimer: '本キャンペーンの最終解釈権はEBCに帰属します',  
    registerNow: 'EBC新規登録＆入金で',  
    paidBenefits: '会員特典',  
    getItNow: '今すぐ登録',  
    getItFree: '今すぐ無料で獲得',  
    depositToGet: '今すぐ無料で獲得',  

    icon15: require('@/assets/image/TradingView/TradingView-icon15_jp.svg'),  

    // 顶部权益  
    benefits: {  
      analysis: '専門の市場分析',  
      charts: '高機能チャート',  
      orderFlow: 'オーダーフローレポート'  
    },  

    // 第二部分 - 领取步骤  
    steps: {  
      title: '特典の受取る方法',  
      list: [  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon1.svg'),  
          title: '今すぐ登録',  
          desc: 'EBC公式サイトで会員登録し、<br>口座開設を完了してください'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon2.svg'),  
          title: '今すぐ入金',  
          desc: '月間1,000ドル/10,000ドルのご入金で、<br>TradingView会員特典を無料で獲得'  
        },  
        {  
          icon: require('@/assets/image/TradingView/TradingView-icon3.svg'),  
          title: '利用開始',  
          desc: 'TradingView会員特典を受け取るには、<br>EBCカスタマーサポートにご連絡ください'  
        }  
      ]  
    },  

    // 第3部分 - 市场动态  
    section3: {  
      title: 'EBCでTrading Viewを利用して、グローバルな相場動向を把握',  
      desc: '何百ものデータソースや世界中の2,019,615の銘柄に直接アクセスし、プロの投資家が利用している最新の情報を得ることができます。',  
      slides: [  
        {  
          image: require('@/assets/image/TradingView/TradingView-img3.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon5.svg'),  
          title: 'リアルタイムの相場情報と高機能チャート',  
          desc: 'グローバルな相場動向を把握し、あらゆる取引機会を見逃しません。'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img4.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon8.svg'),  
          title: '詳細なテクニカル分析と取引戦略の立案',  
          desc: '幅広いテクニカル分析を利用し、ニーズに合わせた取引戦略を立てられます。'  
        },  
        {  
          image: require('@/assets/image/TradingView/TradingView-img5.png'),  
          icon: require('@/assets/image/TradingView/TradingView-icon9.svg'),  
          title: 'マルチデバイスでの取引',  
          desc: 'パソコンやスマホで取引を同期させることで、時間と場所を問わず相場動向を把握できます。'  
        }  
      ]  
    },  

    // 第4部分 - 会员计划  
    section4: {  
      title: '入金してTrading Viewプレミアム会員特典をゲット',  
      desc: '取引チャンスを見逃さず！　EBCで口座を開設し、Trading Viewプレミアム会員を無料で獲得しましょう。',  
      plans: [  
        {  
          isPremium: false,  
          title: 'Essentialマンスリー会員',  
          requirement: '月間1,000ドルのご入金で会員特典にアクセス',  
          features: [  
            { text: '1つのタブに2個のチャート', enabled: true },  
            { text: 'チャート毎に5個のインジケーター', enabled: true },  
            { text: '10,000本の過去バー', enabled: true },  
            { text: '20個の価格アラート', enabled: true },  
            { text: '20個のテクニカルアラート', enabled: true },  
            { text: '10個のチャート同時接続', enabled: true },  
            { text: '広告なし', enabled: true },  
            { text: '出来高プロファイル', enabled: true },  
            { text: '時間足のカスタマイズ', enabled: true },  
            { text: 'カスタムレンジバー', enabled: true },  
            { text: '複数のウォッチリスト', enabled: true },  
            { text: 'バーリプレイ', enabled: true },  
            { text: 'インジケーターに他のインジケーターを適用', enabled: true },  
            { text: 'チャートデータのエクスポート', enabled: false },  
            { text: '日中の練行足、カギ足、新値足、ポイント＆フィギュアチャート', enabled: false },  
            { text: 'カスタム計算式に基づくチャート', enabled: false },  
            { text: 'タイム・プライス・オポチュニティ', enabled: false },  
            { text: '出来高フットプリント', enabled: false },  
            { text: '自動チャートパターン', enabled: false },  
            { text: '秒単位の警報', enabled: false },  
            { text: '期限切れのないアラート', enabled: false },  
            { text: '招待専用スクリプトの公開', enabled: false },  
            { text: '秒足', enabled: false }  
          ]  
        },  
        {  
          isPremium: true,  
          title: 'Premiumマンスリー会員',  
          requirement: '月間10,000ドルのご入金で会員特典にアクセス',  
          features: [  
            { text: '1つのタブに8個のチャート', enabled: true },  
            { text: 'チャート毎に25個のインジケーター', enabled: true },  
            { text: '20,000本の過去バー', enabled: true },  
            { text: '400個の価格アラート', enabled: true },  
            { text: '400個のテクニカルアラート', enabled: true },  
            { text: '50個のチャート同時接続', enabled: true },  
            { text: '広告なし', enabled: true },  
            { text: '出来高プロファイル', enabled: true },  
            { text: '時間足のカスタマイズ', enabled: true },  
            { text: 'カスタムレンジバー', enabled: true },  
            { text: '複数のウォッチリスト', enabled: true },  
            { text: 'バーリプレイ', enabled: true },  
            { text: 'インジケーターに他のインジケーターを適用', enabled: true },  
            { text: 'チャートデータのエクスポート', enabled: true },  
            { text: '日中の練行足、カギ足、新値足、ポイント＆フィギュアチャート', enabled: true },  
            { text: 'カスタム計算式に基づくチャート', enabled: true },  
            { text: 'タイム・プライス・オポチュニティ', enabled: true },  
            { text: '出来高フットプリント', enabled: true },  
            { text: '自動チャートパターン', enabled: true },  
            { text: '秒単位の警報', enabled: true },  
            { text: '期限切れのないアラート', enabled: true },  
            { text: '招待専用スクリプトの公開', enabled: true },  
            { text: '秒足', enabled: true }  
          ]  
        }  
      ]  
    },  

    // 第5部分 - 新客福利  
    section5: {  
      title: '新規登録者限定の特典で<br>取引の勝率をアップしよう',  
      desc: 'CME取引所のオーダーフローレポート：売買動向を追跡し、勝率向上に役立てられます。',  
      desc2: 'Trading Centralのアナリストによる市場分析：市場機会を的確に把握するシグナルが得られます。',  
      desc3: '38の国・地域のマクロ経済データをリアルタイムで提供。潜在的な市場変動を把握・予測・対応する参考になります。',  
      images: [  
        require('@/assets/image/TradingView/TradingView-img7.png'),  
        require('@/assets/image/TradingView/TradingView-img8.png'),  
        require('@/assets/image/TradingView/TradingView-img9.png')  
      ]  
    },  

    // 第6部分 - 荣誉展示  
    section6: {  
      title: '今すぐ入金して特典をゲットしよう',  
      desc: 'EBC Financial Groupは世界で最も信頼できるブローカーと評価されています。'  
    }  
  }  
}