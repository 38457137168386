<template>
  <div>
    <page-navigate></page-navigate>
    <div class="cn cn-font-family">
      <div class="session1">
        <div class="info">
          <div class="title cn-font-family-title">
            领取订单流<br class="mobile" />分析工具
          </div>
          <div class="sub-title">助您夺回交易世界的主动权</div>
          <img class="bg-block mobile" src="../assets/image/MiFi/bg_block.png" alt="" />
          <div class="cards">
            <div class="card">
              <img class="icon" src="../assets/image/MiFi/icon_1.svg" alt="" />
              <div>追踪主力资金</div>
            </div>
            <div class="card">
              <img class="icon" src="../assets/image/MiFi/icon_2.svg" alt="" />
              <div>构建高胜率分析系统</div>
            </div>
            <div class="card">
              <img class="icon" src="../assets/image/MiFi/icon_3.svg" alt="" />
              <div>识别震荡和趋势</div>
            </div>
          </div>
          <div class="btns">
            <a class="btn" :href="$store.state.khUrl" rel="nofollow">申请开通订单流 <span class="iconfont icon-right"></span></a>
            <a class="btn" href="https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/FFTraderLauncher%203.msi">下载订单流工具
              <span class="iconfont icon-right"></span></a>
          </div>
          <div class="tips">
            <span class="tip-text">账户余额大于$10000, 即可免费申请</span>
            <a class="tip-link pc" target="_blank"
              href="https://support.ebchelp.com/hc/zh-cn/articles/38220463065881-%E8%AE%A2%E5%8D%95%E6%B5%81%E5%88%86%E6%9E%90%E5%B7%A5%E5%85%B7%E5%AE%89%E8%A3%85%E6%8C%87%E5%8D%97">安装指南</a>
          </div>
        </div>
        <img class="bg-block pc" src="../assets/image/MiFi/bg_block.png" alt="" />
      </div>
      <div class="session2">
        <div class="title cn-font-family-title">
          全球市场交易<br class="mobile" />深度解码
        </div>
        <div class="content">
          <div class="item">
            <img class="item-img" src="../assets/image/MiFi/section2_1.png" />
            <div class="item-title">Order Flow订单流工具组</div>
            <div class="item-info">
              实时显示市场逐笔成交订单情况，图形化展示不同价格水平的成交分布，帮助交易者了解市场流动性和买卖力量。
            </div>
          </div>
          <div class="item">
            <img class="item-img" src="../assets/image/MiFi/section2_2.png" />
            <div class="item-title">Volume筹码分析系统</div>
            <div class="item-info">
              通过逐tick的分析，精准锁定价值区域，定位主力需求轮廊，深度数据展现成交明细，揭示庄家资金动向。
            </div>
          </div>
          <div class="item">
            <img class="item-img" src="../assets/image/MiFi/section2_3.png" />
            <div class="item-title">Delta逐差资金流监控</div>
            <div class="item-info">
              逐笔分析量价数据，快速识别资金强度，基于AI资金因子和量能关系分析价格，直观呈现机构吸筹派发行为。
            </div>
          </div>
        </div>
      </div>
      <div class="session3">
        <div class="content">
          <div class="title cn-font-family-title">
            全球最受欢迎<br class="mobile" />交易工具
          </div>
          <div class="sub-title">
            通过追踪市场上的实时买卖订单量，分析大机构交易行为，帮助投资者识别主力资金、提高交易胜率，已被高盛、瑞银等华尔街顶级机构采用，成为专业交易者的必备工具。
          </div>
          <div class="info">
            <div class="info-item">
              <div class="info-item-value">40,000+</div>
              <div class="info-item-label">全球专业用户</div>
            </div>
            <div class="info-item">
              <div class="info-item-value">0ms</div>
              <div class="info-item-label">CME数据实时响应</div>
            </div>
            <div class="info-item">
              <div class="info-item-value">NO.1</div>
              <div class="info-item-label">对冲基金最热门交易工具</div>
            </div>
            <div class="info-item">
              <div class="info-item-value">NO.1</div>
              <div class="info-item-label">《期货》2019最受欢迎交易技术</div>
            </div>
          </div>
        </div>
        <div style="position: relative; flex-shrink: 0">
          <img class="bg-block" src="../assets/image/MiFi/section3_bg.png" />
          <img class="bg-block-gif" src="../assets/image/MiFi/section3_bg.gif" alt="" />
        </div>
      </div>
      <div class="session4">
        <div class="title cn-font-family-title">
          权威机构<br class="mobile" />技术支持
        </div>
        <div class="content">
          <div class="list-item">
            <img class="list-item-img" src="../assets/image/MiFi/section4_1.png"></img>
            <div class="list-item-info">
              <div class="list-item-info-title">数据提供方</div>
              <div class="list-item-info-info">
                芝加哥商品交易所（CME）是全球最多元化的衍生工具市场，每年处理超过30亿份合约，价值约1千兆美元。CME、CBOT、NYMEX和COMEX交易所涵盖全球基准产品，包括利率、股票指数、衍生品、能源、农产品、金属等主要资产类别。芝商所还致力于创新，不断引入新的清算场外产品和服务以管理市场风险。
              </div>
            </div>
          </div>
          <div class="list-item">
            <img class="list-item-img" src="../assets/image/MiFi/section4_2.png"></img>
            <div class="list-item-info">
              <div class="list-item-info-title">软件提供商</div>
              <div class="list-item-info-info">
                NinjaTrader是华尔街欧美地区顶尖的金融软件平台之一，专注于股票、期货和衍生品交易。其第八代新版本在保持传统软件功能的基础上，提供更深度、更可拓展的系统，以开放、专业、丰富和自由的特点著称。
              </div>
            </div>
          </div>
          <div class="list-item">
            <img class="list-item-img" src="../assets/image/MiFi/section4_3.png"></img>
            <div class="list-item-info">
              <div class="list-item-info-title">投教合作商</div>
              <div class="list-item-info-info">
                微观市场数据结构研究所（MIFI）拥有近十年的研究经验，其团队在大数据、高频交易和量化交易领域拥有超过二十年的技术沉淀和研发经验。MIFI提供订单流工具的教学支持，致力于推动金融教育和市场数据结构的深入研究。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="session5">
        <div class="title cn-font-family-title">用户评价</div>
        <div class="content pc">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “长时间以来，我在交易时苦苦挣扎。学习这套订单流工具后，我的获胜率显著提升到75%。运用订单流确认进出场点位时，我信心满满并坚定执行。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_1.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Jason Li</div>
                      <div class="evaluate-job">资深衍生品交易员</div>
                    </div>
                  </div>
                </div>
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流工具成了我交易的关键利器。它让我直观地观察并分析大机构的吸筹与派发行为，这对于准确把握进出市场时机至关重要。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_2.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Emily Turner</div>
                      <div class="evaluate-job">专业交易策略分析师</div>
                    </div>
                  </div>
                </div>
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流、主力足迹和成交量分布等对日内交易者至关重要，它们能揭示市场动态并提供数据支持。我喜欢衍生品订单流数据，它使我的交易策略更加精准。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_3.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">David Clark</div>
                      <div class="evaluate-job">金融市场分析师</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “我觉得订单流工具比我之前用过的其他指标更实用，让我直观地看到市场买单和卖单的交易量，针对数据分析调整交易策略，提高准确率。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_4.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Richard Wu</div>
                      <div class="evaluate-job">融资顾问</div>
                    </div>
                  </div>
                </div>
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流工具可以说是很强大的，深入学习，你会发现能了解到更多的市场报价的层级，提前预判到大资金的布局挂单，避免被当韭菜割掉。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_5.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Nathan Young</div>
                      <div class="evaluate-job">投资组合经理</div>
                    </div>
                  </div>
                </div>
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流工具的实用性和准确性极高，显著提升了交易决策效率。通过精细的数据分析和实时市场动态，帮助我们精准把握市场趋势和流动性，做出更好的交易决策。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_6.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Robert Evans</div>
                      <div class="evaluate-job">风险管理师</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination my-pagination"></div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev my-prev iconfont icon-right"></div>
            <div class="swiper-button-next my-next iconfont icon-right"></div>
          </div>
        </div>
        <div class="content mobile">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “长时间以来，我在交易时苦苦挣扎。学习这套订单流工具后，我的获胜率显著提升到75%。运用订单流确认进出场点位时，我信心满满并坚定执行。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_1.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Jason Li</div>
                      <div class="evaluate-job">资深衍生品交易员</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流工具成了我交易的关键利器。它让我直观地观察并分析大机构的吸筹与派发行为，这对于准确把握进出市场时机至关重要。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_2.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Emily Turner</div>
                      <div class="evaluate-job">专业交易策略分析师</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流、主力足迹和成交量分布等对日内交易者至关重要，它们能揭示市场动态并提供数据支持。我喜欢衍生品订单流数据，它使我的交易策略更加精准。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_3.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">David Clark</div>
                      <div class="evaluate-job">金融市场分析师</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “我觉得订单流工具比我之前用过的其他指标更实用，让我直观地看到市场买单和卖单的交易量，针对数据分析调整交易策略，提高准确率。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_4.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Richard Wu</div>
                      <div class="evaluate-job">融资顾问</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流工具可以说是很强大的，深入学习，你会发现能了解到更多的市场报价的层级，提前预判到大资金的布局挂单，避免被当韭菜割掉。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_5.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Nathan Young</div>
                      <div class="evaluate-job">投资组合经理</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide evaluate-box">
                <div class="evaluate-item">
                  <div class="evaluate-score">
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                    <img src="../assets/image/MiFi/star.png" alt="" />
                  </div>
                  <div class="evaluate-text">
                    “订单流工具的实用性和准确性极高，显著提升了交易决策效率。通过精细的数据分析和实时市场动态，帮助我们精准把握市场趋势和流动性，做出更好的交易决策。”
                  </div>
                  <div class="evaluate-user">
                    <img src="../assets/image/MiFi/section5_6.png" class="evaluate-avatar" />
                    <div>
                      <div class="evaluate-name">Robert Evans</div>
                      <div class="evaluate-job">风险管理师</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination my-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper'
export default {
  head() {
    return {
      script: [{
        src: '/fullpage.min.js'
      }]
    }
  },
  data() {
    return {
      state: false
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.state = false
    } else {
      this.state = true
    }
    $(document).on('click', '#menu', function () {
      fullpage_api.moveSectionDown();
    });

    this.mySwiper = new Swiper(".swiper", {
      autoplay: true, // 启动自动切换，等同于以下设置
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        bulletActiveClass: "my-bullet-active",
        clickable: true,
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
  created() {

  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  }
}
</script>

<style>
@charset "UTF-8";
.swiper{
  overflow: hidden;
}

.c-footer .fp-overflow {
  display: unset !important;
}

@font-face {
  font-family: "Financier Display";
  src: url("../assets/font/fontFamilies/FinancierDisplay/FinancierDisplay-Medium.ttf") format("opentype");
}

.session1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 70px;
  min-height: 450px;
  background-image: url(../assets/image/MiFi/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 120px;
}

.session1 .title {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  text-transform: uppercase;
  max-width: 700px;
  margin: 0 auto;
}

.session1 .sub-title {
  margin: 10px auto 0;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 170%;
  text-transform: uppercase;
  max-width: 700px;
}


.session1 .cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.session1 .cards .card {
  display: flex;
  padding: 16px;
  border-radius: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(17px);
  color: var(--Color, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 21px */
}

.session1 .card .icon {
  width: 32px;
  height: 32px;
}

.session1 .btns {
  margin-top: 26px;
  display: flex;
  justify-content: center;
  gap: 24px;
}

.session1 .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  min-height: 48px;
  gap: 4px;
  border-radius: 4px;
  background: #fff;
  color: #af8147;
  font-size: 16px;
  font-weight: 350;
  line-height: 150%;
  transition: color 0.3s, background-color 0.3s;
}

.session1 .btn:hover {
  color: #fff;
  background: #af8147;
}

.session1 .tips {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
}

.session1 .tip-text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 350;
  line-height: 150%;
  opacity: 0.6;
}

.session1 .tip-link {
  cursor: pointer;
  color: #239cff;
  text-align: center;
  font-size: 14px;
  font-weight: 350;
  line-height: 150%;
  /* 21px */
  text-decoration-line: underline;
}

.session1 .tip-link:hover {
  color: #239cff;
}

.session1 .bg-block {
  width: 480px;
}


.session2,
.session3,
.session4,
.session5 {
  position: relative;
  padding: 80px 64px;
  max-width: 1440px;
  margin: 0 auto;
}

.session2 .title,
.session3 .title,
.session4 .title,
.session5 .title {
  color: #333;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  /* 57.6px */
}

.en .session2 .title,
.en .session3 .title,
.en .session4 .title,
.en .session5 .title {
  font-size: 56px;
  font-weight: 700;
}

.en .session1 .title {
  font-weight: 700;
}

.en .session1 .sub-title {
  font-size: 20px;
  font-weight: 400;
}

.en .session2 .content .item .item-info,
.en .list-item-info-info {
  font-weight: 400;
}

.en .session5 .content {
  min-height: 520px;
}

.session2 .content {
  margin-top: 80px;
  position: relative;
  display: flex;
  gap: 32px;
  justify-content: space-between;
}

.session2 .content .item {
  width: calc(33% - 21px);
  flex-grow: 1;
}

.session2 .content .item .item-img {
  /* height: 300px; */
  width: 100%;
  background-position: center;
}

.section2_1 {
  background: url("../assets/image/MiFi/section2_1.png");
  background-repeat: no-repeat;
  background-size: contain;

}

.section2_2 {
  background: url("../assets/image/MiFi/section2_2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.section2_3 {
  background: url("../assets/image/MiFi/section2_3.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.session2 .content .item .item-title {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 24px;
}

.session2 .content .item .item-info {
  color: #666;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  margin-top: 8px;
}

.session3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.session3 .title {
  text-align: left;
}

.session3 .content {
  flex-grow: 1;
}

.session3 .sub-title {
  color: #666;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  /* 27px */
  margin-top: 24px;
}

.session3 .bg-block {
  flex-shrink: 0;
  width: 600px;
  /* min-height: 450px; */
  /* background: url(../assets/image/MiFi/section3_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
}


.session3 .bg-block-gif {
  height: 84%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 1% 1%;
  width: 100%;
  z-index: -1;
}

.session3 .content .info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
  margin-top: 40px;
}

.info-item {
  width: calc(50% - 12px);
}

.info-item-value {
  color: #3d0101;
  font-family: "Financier Display";
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
  /* 67.2px */
}

.info-item-label {
  margin-top: 8px;
  color: #666;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  /* 27px */
}

.session4 .content {
  margin-top: 80px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  gap: 70px;
}

.list-item+.list-item {
  margin-top: 64px;
}

.list-item-img {
  width: 340px;
}

.list-item-info {
  flex-grow: 1;
}

.list-item-info-title {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 33.6px */
}

.list-item-info-info {
  margin-top: 8px;
  color: #666;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  /* 27px */
}

.session5 .content {
  position: relative;
  margin: 80px -28px 0;
  height: 460px;
  /* background-color: #239cff; */
}

.swiper {
  width: 100%;
  height: 100%;
}

.my-prev {
  width: 56px !important;
  height: 56px !important;
  border: 1px solid #666;
  border-radius: 50%;
  background-color: #fff;
  left: -0 !important;
  top: calc(50% - 38px) !important;
  color: #666 !important;
  transform: rotate(180deg);
  font-size: 24px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px !important;
}

.my-next {
  width: 56px !important;
  height: 56px !important;
  border: 1px solid #666;
  border-radius: 50%;
  background-color: #fff;
  right: -0 !important;
  top: calc(50% - 38px) !important;
  color: #666 !important;
  font-size: 24px !important;
}

.my-bullet-active {
  background: #666 !important;
  opacity: 1 !important;
}

.evaluate-box {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 32px;
  padding: 0 28px;
  padding-bottom: 40px;
}

.evaluate-item {
  width: calc(33% - 21px);
  height: 400px;
  border: 1px solid #000;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-grow: 1;
}

.evaluate-score {
  display: flex;
  gap: 4px;
}

.evaluate-score>img {
  width: 20px;
}

.evaluate-text {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  flex-grow: 1;
}

.evaluate-user {
  display: flex;
  gap: 16px;
  align-items: center;
}

.evaluate-avatar {
  width: 56px;
  height: 56px;
}

.evaluate-name {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.evaluate-job {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}


@media (max-width: 1300px) {
  .session1 .bg-block {
    width: 380px;
  }

  .session3 {
    gap: 60px;
  }

  .session3 .bg-block {
    width: 480px;
    min-height: auto;
  }

}

@media(max-width:1100px) {
  .session3 {
    gap: 40px;
  }

  .session3 .bg-block {
    width: 400px;
    min-height: auto;
  }
}

@media (max-width: 995px) {
  .en .session1 .title {
    font-size: 36px;
  }

  .en .session1 .sub-title {
    font-size: 16px;
  }

  .en .session2 .title,
  .en .session3 .title,
  .en .session4 .title,
  .en .session5 .title {
    font-size: 38px;
  }

  .en .session3 .sub-title {
    font-size: 16px;
  }

  .en .evaluate-text {
    font-size: 16px;
  }

  .session2 .title,
  .session3 .title,
  .session4 .title,
  .session5 .title {
    font-size: 42px;
  }

  .session1 {
    padding: 100px 30px 74px;
    background-image: url(../assets/image/MiFi/bg_m.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .session1 .info {
    flex-grow: 1;
  }

  .session1 .cards {
    margin-top: 64px;
    flex-direction: column;
  }

  .session1 .btns {
    margin-top: 40px;
    flex-direction: column;
  }

  .session1 .bg-block {
    margin: 20px auto 0;
  }

  .session2,
  .session3,
  .session4,
  .session5 {
    padding: 100px 30px 0;
  }

  .session2 .content {
    flex-direction: column;
    margin-top: 70px;
    gap: 80px;
  }

  .session2 .content .item {
    width: 100%;
  }

  /* .session2 .content .item .item-img{
    width: 100%;
    height: 240px;
  } */
  .session2 .content .item .item-title {
    margin-top: 30px;
  }

  .session3 {
    flex-direction: column;
    gap: 45px;
  }

  .session3 .title {
    text-align: center;
  }

  .session3 .sub-title {
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
  }

  .session3 .bg-block {
    width: 100%;
  }

  .session3 .content .info {

    row-gap: 42px;
  }

  .info-item-value {
    font-size: 36px;
  }

  .info-item-label {
    font-size: 14px;
  }

  .list-item {
    flex-direction: column;
    gap: 30px;
  }

  .list-item-img {
    width: 100%;
  }

  .list-item-info-info {
    font-size: 16px;
  }

  .list-item+.list-item {
    margin-top: 80px;
  }

  .evaluate-item {
    width: 100%;
    padding: 32px 34px;
  }
}
</style>
