export default {
  lang: "",
  交易领薪计划: "Trading Rewards Plan",
  为了鼓励交易者提升交易水平:
    "To encourage traders to improve their trading skills, we have launched the trading rewards plan.",
  加入交易社区: "Join the trading community to enjoy generous rewards and exclusive benefits, helping traders build their trading legacy.",
  即刻发起交易信号: "Trade Now",
  无限奖励月月拿: "Unlimited Monthly Rewards",
  优享12个专属大礼: "Enjoy 12 Exclusive Gifts",
  即刻加入: "Join Now",
  交易员等级奖励机制: `Trader Level Rewards`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC offers five reward levels based on follower numbers and funds, without limiting the number of trades, and solely measures market recognition.",
  月: "month",
  新锐交易员: "Beginner Traders",
  精英交易员: "Elite Traders",
  冠军交易员: "Champion Traders",
  明星交易员: "Star Traders",
  传奇交易员: "Lengendary Traders",
  管理资金: "+1% Annual Fee（Monthly Payment)",
  管理资金量: "Managing Capital",
  跟随用户数: "Number of Followers",
  多层次的增值服务: "Multi-tiered Value-added Service",
  EBC提供多达11项增值服务:
    "EBC offers up to 11 value-added services, integrating traffic and media resources to comprehensively enhance signal influence.",
  "7×24小时优先客服": "7x24 Priority Customer Service",
  参与EBC品牌营销互动: "Participation in EBC Brand Marketing",
  VPS赞助: "VPS Sponsorship",
  金融投资类书籍赞助: "Sponsorship of Financial Investment Books",
  订单流软件一年使用权: "One-year Access to Order Flow Software",
  EBC定制大礼包: "EBC Gift Package",
  信号置顶展示: "Top Signal Display",
  受邀参观海外办公室: "Visit Overseas Offices Invitation",
  媒体资源推送: "Media Resources Promotion",
  品牌战略合作: "Brand Strategic Cooperation",
  海外基金产品发行顾问服务: "Overseas Fund Product Issuance Advisory Service",
  六维指标评价系统: "Six-Dimensional Evaluation System",
  为交易员信号提供公开透明的评估:
    "Provides an open and transparent evaluation for trader signals, showcasing trading style, increasing exposure, and attracting more followers.",
  "最大回撤6.39%": "Max Drawdown 6.39%",
  衡量风控能力: "Measures risk control capability; the lower the better.",
  "标准差1.59": "Standard Deviation 1.59",
  收益波动率: "Measures volatility; the lower, the more stable.",
  "盈利交易(胜率)75.82%": "Winning Trades (Success Rate) 75.82%",
  盈利订单占比: "Proportion of profitable orders; the higher, the greater the profit probability.",
  "收益率406.06%": "Return Rate of 406.06%",
  历史收益率: "Historical return rate; the higher, the better.",
  "获利系数6.19": "Profit Factor 6.19",
  利润因子: "Profit factor; the higher, the stronger the profitability.",
  "夏普比率44.46": "Sharpe Ratio 44.46",
  每份风险的收益: "Returns per unit of risk; the higher, the better.",
  收益率: "Return Rate of",
  最大回撤: "Max Drawdown",
  标准差: "Standard Deviation",
  获利系数: "Return Rate of",
  夏普比率: "Sharpe Ratio",
  "盈利交易(胜率)": "Winning Trades (Success Rate)",
  以上数据仅供参考: "**The above data is for reference only.",
  活动须知: "Activity Guidelines",
  交易员等级计算: "1. Trader Level Calculation",
  管理资金量跟随资金量:
    "Managed capital = follower fund volume + trader (trading signal) account's own fund volume, all calculated by net value.",
  等级计算以自然月为周期:
    "Level calculation is based on the calendar month, with the managed fund volume and number of followers meeting the standards for one consecutive month to reach the corresponding level.",
  有效跟随用户: "2. Valid Users",
  起始跟随金额: "A starting following amount of more than 500 USD counts as one valid follower.",
  每个MT4账号最多跟随10个交易员: "Each MT4 account can follow a maximum of 10 traders (trading signals).",
  每个MT4账号跟随金额: "Each MT4 account's following amount = follower account funds/signals.",
  奖励结算: "3. Reward Settlement",
  次月第一周计算并发放上月奖励:
    "Rewards are calculated and distributed in the first week of the following month, based on the lowest level reached in the previous month.",
  所有奖励发到EBC后台钱包:
    "All rewards are credited directly to the trading account, where the winners are responsible for withdrawing the funds themselves. Participants are encouraged to register in advance.",
  活动最终解释权归主办方EBC所有: "The final interpretation of the activity belongs to the organizer, EBC.",
};
