// export default {
//   title: `<span class="text">EBC 글로벌 실거래 대회 Ⅱ</span>`,
//   title2: ``,
//   巴萨传奇再启: "새로운 전설의 시작, 다음 제왕을 찾아라",
//   成就交易之王: "",
//   大赛时间: "대회 기간: 2025년 3월 1일 ~ 2025년 5월 30일",
//   距开赛: ``,
//   天: "d",
//   时: "h",
//   分: "m",
//   秒: "s",
//   一键跟单: "원-클릭 카피트레이딩",
//   免费复制冠军收益: "챔피언의 트레이딩 무료로 카피하기",
//   公开透明: "공정하고 투명하게",
//   观摩冠军交易技巧: "챔피언의 거래 기술 배우기",
//   推荐有礼: "초대 이벤트",
//   推荐有奖上不封顶: "제한 없는 이벤트 보상받기",
//   立即参赛: "참가신청",
//   现金奖池: "현금 상금액",
//   巴萨梦之队: "엘리트 팀",
//   拉玛西亚组: "루키 팀",
//   冠军: "1위",
//   亚军: "2위",
//   季军: "3위",
//   第四至五名: "4위 ~ 5위",
//   第六至十名: "6위 ~ 10위",
//   "$1,000,000交易账号或$200,000现金": "100만 달러 거래 계좌 or 20만 달러 현금",
//   "1,000,000交易账号或200,000现金": "100만 달러 거래 계좌 or 20만 달러 현금",
//   大赛推荐官: "초대 이벤트",
//   推荐奖励: "초대도 보상도 무제한!",
//   巴萨梦之队HTML: `엘리트 팀`,
//   拉玛西亚组HTML: `루키 팀`,
//   "/人": "인당",
//   活动时间: "2025년 3월 1일 06:00:00 ~ 2025년 5월 1일 05:59:59 (GMT+9)",
//   推荐流程: "초대 방법",
//   推荐好友: "친구 초대",
//   好友参赛: "회원가입",
//   赢得奖金: "보상 받기",
//   实时赛况: "대회 실황",
//   排名: "랭킹",
//   昵称: "닉네임",
//   服务器: "서버",
//   账号: "계좌",
//   收益额: "순수익",
//   收益率: "수익률",
//   奖金: "상금",
//   跟单: "카피 트레이딩",
//   该页面仅展示收益表现排名前10的交易账号:
//     "*해당 페이지는 10위 내 계좌만 표시합니다. 금액 단위는 USD입니다. 랭킹은 매 1시간마다 갱신됩니다. ",
//   赛事进程: "대회 절차",
//   比赛开始: "대회 시작",
//   推荐结束: "초대 이벤트 종료",
//   比赛结束: "대회 종료",
//   颁奖典礼: "결과 발표",
//   赛组规则: "대회 규정",
//   类别: "",
//   账号要求: "계좌 요건",
//   "新交易账号，且账号余额大于$500":
//     "새로 개설한 계좌이며 잔액이 500달러 이상이어야 합니다. ",
//   "新交易账号，且$10,000≤账号余额≤$200,000":
//     "새로 개설한 계좌이며 잔액이 1만 달러 이상, 20만 달러 이하여야 합니다. ",
//   排名规则: "랭킹 규정",
//   以收益率排名为评判标准: "순위는 수이률을 기준으로 판정됩니다. ",
//   "收益率=期末净值/账号累积入金": "수익률 =  최종 순자산 / 총 계좌 예탁금",
//   以净盈利额排名为评判标准: "순위는 순이익을 기준으로 판정됩니다. ",
//   持仓限制: "포지션 제한",
//   每个参赛账号最多持有100手仓位:
//     "각 참여 계좌가 보유할 수 있는 최대 포지션은 100랏입니다.",
//   无持仓限制: "포지션 제한 없음",
//   参赛条款: "대회 규정",
//   参赛须知: "주의 사항",
//   section6List: [
//     {
//       t: "1. 기간",
//       c: [
//         "대회 기간: 2025년 3월 1일 06:00:00 ~ 2025년 5월 1일 05:59:59 (GMT+9)",
//       ],
//     },
//     {
//       t: "2. 계좌 요건",
//       c: [
//         "참가 계좌는 거래 커뮤니티에 시그널 등록을 해야합니다. 단, 타인의 시그널을 팔로우 할 수 없으며, 본인의 팔로우 허용은 선택 사항입니다.",
//         "참가 계좌 개수에 제한은 없으나, 순위에 반영되는 계좌는 성과가 가장 우수한 계좌에 한합니다. ",
//         "STD 및 PRO 계좌만 참가가 가능하며, PAMM 계좌와 이슬람 계좌는 참가할 수 없습니다.",
//       ],
//     },
//     {
//       t: "3. 보상 규정",
//       c: [
//         "1위 보상: 100만 달러의 거래 계좌를 선택한 경우, 해당 계좌에 최대 20만 달러 손실액이 있을 수 있습니다.",
//         "그 외의 상금은 순위에 따라 차등 지급됩니다.",
//       ],
//     },
//     {
//       t: "4. 금지 사항",
//       c: [
//         "여러 계좌를 이용한 악의적인 헤징으로 부당한 성과를 얻는 행위는 금지입니다. ",
//         "서버 하드웨어 오류나 가격 오류 등 불가피한 상황으로 발생한 주문은 무효로 처리됩니다.",
//       ],
//     },
//     {
//       t: "5. 최종 해석권",
//       c: ["본 대회의 최종 해석권은 EBC에 있습니다."],
//     },
//   ],
//   参赛流程: "참가 방법",
//   注册开户: "계좌 개설",
//   注册并开设新交易账号: "회원가입 후 새로운 거래 계좌를 개설하기",
//   报名参赛: "참가 신청",
//   在用户中心报名参赛: "사용자 센터에서 대회 참가 신청하기",
//   参赛成功: "신청 완료",
//   参赛成功查看实时赛况: "대회에 참가해 실황 확인하기",
//   往届回顾: "이전 대회",
//   第一届交易大赛颁奖典礼: "제1회 실거래 대회 시상식",
//   第一届交易大赛高光时刻: "제1회 실거래 대회 하이라이트",
//   第一届交易大赛巅峰对决: "제1회 실거래 대회 결승전",
//   查看更多: "더 보기",
//   常见问题: "FAQ",
//   qaList: [
//     {
//       t: `1. "계정"과 "계좌"의 차이가 무엇인가요?`,
//       c: [
//         "계정: EBC 사용자 센터에서 계정(UID)을 의미하며, 각 사용자는 실명 인증을 거쳐야만 계정을 만들 수 있습니다. ",
//         "계좌: MT 거래 계좌를 의미하며, EBC 계정 하나당 여러 거래 계좌를 개설할 수 있습니다. ",
//       ],
//     },
//     {
//       t: "2. 대회에 신청됐는지 어떻게 확인하나요?",
//       c: [
//         "대회 신청이 완료되면 사용자 센터 이벤트 페이지에 참가 계좌 및 현 랭킹이 표시됩니다.",
//       ],
//     },
//     {
//       t: "3. 여러 계좌로 참가할 수 있나요?",
//       c: [
//         "여러 계좌로 두 개의 팀에 참가할 수 있으나, 각 계좌 당 참가할 수 있는 팀은 하나입니다.",
//       ],
//     },
//     {
//       t: "4. 동시에 두 팀에서 수상할 수 있나요?",
//       c: [
//         "가능합니다. 단, 두 팀에서 모두 정식으로 대회를 완료하고 순위권에 들어야 합니다.",
//       ],
//     },
//     {
//       t: "5. 이미 있는 MT5 계좌로 참가할 수 있나요?",
//       c: [
//         "대회에 참가할 계좌는 반드시 2025년 2월 1일 전에 개설했으며 거래 이력이 없는 계좌여야 합니다. ",
//       ],
//     },
//     {
//       t: "6. 루키 팀에 참가한 뒤, 1만 달러 이상을 입금하면 팀이 바뀌나요?",
//       c: [
//         "아니오. 참가 팀은 신청 시점에 결정되며 이후 자금 변동은 팀에 영향을 주지 않습니다. ",
//       ],
//     },
//     {
//       t: "7. 순위는 어떻게 보나요?",
//       c: [
//         "랭킹은 1시간(정시가 아닌 매시간)마다 갱신되며, 사용자 센터 대회 페이지에서 확인할 수 있습니다. ",
//       ],
//     },
//     {
//       t: "8. 대회 진행 중에 자금 입출금 할 수 있나요?",
//       c: [
//         "가능합니다. 다만 입금 시 수익률 계산에 영향이 있을 수 있습니다. (수익률 = 최종 순자산 / 총 계좌 예탁금)",
//       ],
//     },
//     {
//       t: "9. 초대 이벤트 보상은 어떻게 받나요?",
//       c: [
//         "대회 종류 후 7영업일 이내에 초대된 사용자의 대회 상황을 확인 후 보상을 지급합니다. 확인 절차를 위해 고객 서비스 팀에서 연락이 갑니다. ",
//       ],
//     },
//     {
//       t: "10. 왜 대회 기간에 초대된 참가자 에이전트를 이전할 수 없나요?",
//       c: [
//         "형평성을 보장하고 참가자가 수수료와 초대 보너스를 동시에 얻는 행위를 방지하고자 초대된 참가자는 추천인 링크를 통해서만 대회에 참가할 수 있으며, 에이전트를 지정할 수 없습니다. 대회 종료 후 특정 에이전트를 지정하고자 한다면 고객 서비스 팀에 연락해 주세요. ",
//       ],
//     },
//   ],
// };
export default {
  title: `<span class="text">EBC's Million Dollar Trading Challenge II</span>`,
  title2: ``,
  巴萨传奇再启: "Trade Like a Champion: Inspired by Barça, Built for Winners",
  成就交易之王: "",
  大赛时间:"Event Duration: 1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replicate Top Trades in Just One-Click",
  公开透明: "Transparent",
  观摩冠军交易技巧: "Master the Trading Techniques of Champions",
  推荐有礼: "Refer for Rewards",
  推荐有奖上不封顶: "Unlimited Rewards for Every Referral",
  立即参赛: "Join Now",
  现金奖池: "Cash Prize Pool",
  巴萨梦之队: "Barça Dream Squad",
  拉玛西亚组: "La Masia Prodigies",
  冠军: "Champion",
  亚军: "Runner-up",
  季军: "Third place",
  第四至五名: "4th to 5th Place",
  第六至十名: "6th to 10th Place",
  "$1,000,000交易账号或$200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  "1,000,000交易账号或200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  大赛推荐官: "Referral Programme",
  推荐奖励: "Unlimited Referral Rewards",
  巴萨梦之队HTML: `Barça Dream Squad`,
  拉玛西亚组HTML: `La Masia Prodigies`,
  "/人": "/person",
  活动时间:
    "Event Duration:  22 February 2025, 01:00:00 (MT4/MT5 time) - 23 May 2025, 23:59:59 (MT4/MT5 time)",
  推荐流程: "Referral Process",
  推荐好友: "Refer Friends",
  好友参赛: "Friend Trades",
  赢得奖金: "Win Rewards",
  实时赛况: "Real-time Leaderboard",
  排名: "Rank",
  昵称: "Nickname",
  服务器: "Server",
  账号: "Account Number",
  收益额: "Profit",
  收益率: "ROI",
  奖金: "Prizes",
  跟单: "Copy Trading",
  该页面仅展示收益表现排名前10的交易账号:
    "*This page only displays the top ten trading accounts ranked by profit performance, with the leaderboard in USD and updated every hour",
  赛事进程: "Event Timeline",
  比赛开始: "Challenge Begins",
  推荐结束: "Referral Period Ends",
  比赛结束: "Challenge Ends",
  颁奖典礼: "Awards Ceremony",
  赛组规则: "Event Rules",
  类别: "Categories",
  账号要求: "Account Requirements",
  "新交易账号，且账号余额大于$500":
    "A new trading account with a starting balance ≥ $500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "A new trading account with a starting balance of $10,000 ≤ $200,000 ",

  排名规则: "Leaderboard Rules",
  以收益率排名为评判标准:
    "Rankings are determined based on the rate of return",
  "收益率=期末净值/账号累积入金":
    "Rate of Return = Net Account Value / Cumulative Deposit",
  以净盈利额排名为评判标准: "Rankings are determined based on total account value",
  持仓限制: "Position Limits",
  每个参赛账号最多持有100手仓位: "Each participating account may hold a maximum position of 100 lots",
  无持仓限制: "No position limits",
  参赛条款: "Event Terms",
  参赛须知: "Event Guidelines",
  section6List: [
    {
      t: "1. Event Duration",
      c: ["1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)"],
    },
    {
      t: "2. Account Requirements",
      c: [
        "The account must be listed as a signal account in the trading community and allow others to copy trades for free.",
        "Participants may use multiple accounts to compete, but only the account with the best performance will be considered for ranking.",
        "Only STP and PRO accounts are eligible to participate. PAMM and Islamic accounts are not eligible.",
      ],
    },
    {
      t: "3. Reward Rules",
      c: [
        "Prizes: If the champion chooses the 1,000,000 USD trading account reward, the maximum loss limit is 200,000 USD.",
        "Other prizes will be awarded based on rankings.",
      ],
    },
    {
      t: "4. Prohibited Actions",
      c: [
        "Arbitrage trading between accounts to boost profits is strictly prohibited. Any violations, including cross-account positions, hedging trades, or abuse of quotes, will lead to disqualification.",
        "Orders caused by uncontrollable factors, such as server hardware failure or pricing errors, will be considered invalid.",
      ],
    },
    {
      t: "5. Disclaimer",
      c: ["EBC Financial Group reserves the right to make the final decision of this event."],
    },
    
  ],
  参赛流程: "Event Process",
  注册开户: "Account Registration",
  注册并开设新交易账号: "Register and Open A New Trading Account",
  报名参赛: "Event Registration",
  在用户中心报名参赛: "Register for the Challenge in the User Portal",
  参赛成功: "Successful Registration",
  参赛成功查看实时赛况: "Once Registered, View Your Real-time Performance",
  往届回顾: "Previous Edition",
  第一届交易大赛颁奖典礼: "The First Trading Competition Awards Ceremony",
  第一届交易大赛高光时刻: "Highlights of the First Trading Competition",
  第一届交易大赛巅峰对决: "The Ultimate Showdown of the First Trading Competition",
  查看更多: "View More",
  常见问题: "Frequently Asked Questions",
  qaList: [
    {
      t: '1. "Account" vs "Account Number"',
      c: [
        "Account: Refers to the EBC User Portal Account (UID). Each user can only have one account after completing their real-name authentication.",
        "Account Number: Refers to the MT trading account. Multiple trading accounts can be created under the same EBC account for trading purposes.",
      ],
    },
    {
      t: "2. How Can I Confirm My Registration Status for the Event?",
      c: ["After successful registration, your competition account and current ranking will appear on the event page in the user portal."],
    },
    {
      t: "3. Can I Join the Event with Multiple Accounts?",
      c: [
        "You can join the event with multiple accounts, but each of these accounts can only be registered for one category.",
      ],
    },
    {
      t: "4. Can I Claim Prizes from Both Categories?",
      c: ["Yes, you can claim rewards from both categories as long as you comply with the rules and complete both challenges successfully."],
    },
    {
      t: "5. Can I Join the Challenge If I Already Have an MT4 Account?",
      c: ["Yes, provided the MT4 account was created on or after 22 February 2025, at 01:00:00 (MT4/MT5 time) and has no previous trading history."],
    },
    {
      t: "6. If I deposit more than 10,000 USD after joining La Masia Prodigies, will my category be changed?",
      c: ["No, your category is determined at the time of registration. Subsequent fund changes will not affect the category."],
    },
    {
      t: "7. How Do I Check My Ranking?",
      c: ["Rankings are updated hourly (not on the hour) and can be viewed on the event activity page in the user portal."],
    },
    {
      t: "8. Can I Deposit or Withdraw Funds During the Event?",
      c: [
        "Yes, you can freely deposit or withdraw funds. However, deposits will affect the rate of return calculation: Rate of Return = Net Account Value / Cumulative Deposits.",
      ],
    },
    {
      t: "9. How Do I Claim My Referral Reward?",
      c: [
        'The referral reward will be credited to your bonus balance 30 days after the invited user completes their first trade. You may then request a withdrawal, and the reward will be processed upon approval.',
      ],
    },
    {
      t: `10. Why Can't Invited Users Transfer Their Agents During the Event?`,
      c: [
        `To ensure fairness, invited users can only join through the referral link and are not assigned a specific agent. This avoids duplicate commissions or rewards. After the event ends, users can contact customer service to connect with an agent.`,
      ],
    },
  ],
};
