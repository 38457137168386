export default {
  title: `<span class="text">EBC's Million Dollar Trading Challenge II</span>`,
  title2: ``,
  巴萨传奇再启: "Trade Like a Champion: Inspired by Barça, Built for Winners",
  成就交易之王: "",
  大赛时间:"Event Duration: 1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replicate Top Trades in Just One-Click",
  公开透明: "Transparent",
  观摩冠军交易技巧: "Master the Trading Techniques of Champions",
  推荐有礼: "Refer for Rewards",
  推荐有奖上不封顶: "Unlimited Rewards for Every Referral",
  立即参赛: "Join Now",
  现金奖池: "Cash Prize Pool",
  巴萨梦之队: "Barça Dream Squad",
  拉玛西亚组: "La Masia Prodigies",
  冠军: "Champion",
  亚军: "Runner-up",
  季军: "Third place",
  第四至五名: "4th to 5th Place",
  第六至十名: "6th to 10th Place",
  "$1,000,000交易账号或$200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  "1,000,000交易账号或200,000现金":
    "$1 Million Trading Account ; $200,000 in Cash",
  大赛推荐官: "Referral Programme",
  推荐奖励: "Unlimited Referral Rewards",
  巴萨梦之队HTML: `Barça Dream Squad`,
  拉玛西亚组HTML: `La Masia Prodigies`,
  "/人": "/person",
  活动时间:
    "Event Duration:  22 February 2025, 01:00:00 (MT4/MT5 time) - 23 May 2025, 23:59:59 (MT4/MT5 time)",
  推荐流程: "Referral Process",
  推荐好友: "Refer Friends",
  好友参赛: "Friend Trades",
  赢得奖金: "Win Rewards",
  实时赛况: "Real-time Leaderboard",
  排名: "Rank",
  昵称: "Nickname",
  服务器: "Server",
  账号: "Account Number",
  收益额: "Profit",
  收益率: "ROI",
  奖金: "Prizes",
  跟单: "Copy Trading",
  该页面仅展示收益表现排名前10的交易账号:
    "*This page only displays the top ten trading accounts ranked by profit performance, with the leaderboard in USD and updated every hour",
  赛事进程: "Event Timeline",
  比赛开始: "Challenge Begins",
  推荐结束: "Referral Period Ends",
  比赛结束: "Challenge Ends",
  颁奖典礼: "Awards Ceremony",
  赛组规则: "Event Rules",
  类别: "Categories",
  账号要求: "Account Requirements",
  "新交易账号，且账号余额大于$500":
    "A new trading account with a starting balance ≥ $500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "A new trading account with a starting balance of $10,000 ≤ $200,000 ",

  排名规则: "Leaderboard Rules",
  以收益率排名为评判标准:
    "Rankings are determined based on the rate of return",
  "收益率=期末净值/账号累积入金":
    "Rate of Return = Net Account Value / Cumulative Deposit",
  以净盈利额排名为评判标准: "Rankings are determined based on total account value",
  持仓限制: "Position Limits",
  每个参赛账号最多持有100手仓位: "Each participating account may hold a maximum position of 100 lots",
  无持仓限制: "No position limits",
  参赛条款: "Event Terms",
  参赛须知: "Event Guidelines",
  section6List: [
    {
      t: "1. Event Duration",
      c: ["1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)"],
    },
    {
      t: "2. Account Requirements",
      c: [
        "The account must be listed as a signal account in the trading community and allow others to copy trades for free.",
        "Participants may use multiple accounts to compete, but only the account with the best performance will be considered for ranking.",
        "Only STP and PRO accounts are eligible to participate. PAMM and Islamic accounts are not eligible.",
      ],
    },
    {
      t: "3. Reward Rules",
      c: [
        "Prizes: If the champion chooses the 1,000,000 USD trading account reward, the maximum loss limit is 200,000 USD.",
        "Other prizes will be awarded based on rankings.",
      ],
    },
    {
      t: "4. Prohibited Actions",
      c: [
        "Arbitrage trading between accounts to boost profits is strictly prohibited. Any violations, including cross-account positions, hedging trades, or abuse of quotes, will lead to disqualification.",
        "Orders caused by uncontrollable factors, such as server hardware failure or pricing errors, will be considered invalid.",
      ],
    },
    {
      t: "5. Disclaimer",
      c: ["EBC Financial Group reserves the right to make the final decision of this event."],
    },
    
  ],
  参赛流程: "Event Process",
  注册开户: "Account Registration",
  注册并开设新交易账号: "Register and Open A New Trading Account",
  报名参赛: "Event Registration",
  在用户中心报名参赛: "Register for the Challenge in the User Portal",
  参赛成功: "Successful Registration",
  参赛成功查看实时赛况: "Once Registered, View Your Real-time Performance",
  往届回顾: "Previous Edition",
  第一届交易大赛颁奖典礼: "The First Trading Competition Awards Ceremony",
  第一届交易大赛高光时刻: "Highlights of the First Trading Competition",
  第一届交易大赛巅峰对决: "The Ultimate Showdown of the First Trading Competition",
  查看更多: "View More",
  常见问题: "Frequently Asked Questions",
  qaList: [
    {
      t: '1. "Account" vs "Account Number"',
      c: [
        "Account: Refers to the EBC User Portal Account (UID). Each user can only have one account after completing their real-name authentication.",
        "Account Number: Refers to the MT trading account. Multiple trading accounts can be created under the same EBC account for trading purposes.",
      ],
    },
    {
      t: "2. How Can I Confirm My Registration Status for the Event?",
      c: ["After successful registration, your competition account and current ranking will appear on the event page in the user portal."],
    },
    {
      t: "3. Can I Join the Event with Multiple Accounts?",
      c: [
        "You can join the event with multiple accounts, but each of these accounts can only be registered for one category.",
      ],
    },
    {
      t: "4. Can I Claim Prizes from Both Categories?",
      c: ["Yes, you can claim rewards from both categories as long as you comply with the rules and complete both challenges successfully."],
    },
    {
      t: "5. Can I Join the Challenge If I Already Have an MT4 Account?",
      c: ["Yes, provided the MT4 account was created on or after 22 February 2025, at 01:00:00 (MT4/MT5 time) and has no previous trading history."],
    },
    {
      t: "6. If I deposit more than 10,000 USD after joining La Masia Prodigies, will my category be changed?",
      c: ["No, your category is determined at the time of registration. Subsequent fund changes will not affect the category."],
    },
    {
      t: "7. How Do I Check My Ranking?",
      c: ["Rankings are updated hourly (not on the hour) and can be viewed on the event activity page in the user portal."],
    },
    {
      t: "8. Can I Deposit or Withdraw Funds During the Event?",
      c: [
        "Yes, you can freely deposit or withdraw funds. However, deposits will affect the rate of return calculation: Rate of Return = Net Account Value / Cumulative Deposits.",
      ],
    },
    {
      t: "9. How Do I Claim My Referral Reward?",
      c: [
        'The referral reward will be credited to your bonus balance 30 days after the invited user completes their first trade. You may then request a withdrawal, and the reward will be processed upon approval.',
      ],
    },
    {
      t: `10. Why Can't Invited Users Transfer Their Agents During the Event?`,
      c: [
        `To ensure fairness, invited users can only join through the referral link and are not assigned a specific agent. This avoids duplicate commissions or rewards. After the event ends, users can contact customer service to connect with an agent.`,
      ],
    },
  ],
};
