export default {
  lang: "",
  交易领薪计划: "거래 급여 프로그램",
  为了鼓励交易者提升交易水平:
    "트레이더의 실력 향상을 돕기 위해 거래 급여 프로그램을 도입합니다. ",
  加入交易社区: "거래 커뮤니티에 가입해 다양한 보너스와 혜택을 받아 트레이더계 전설이 되어보세요!",
  即刻发起交易信号: "거래 시그널 공유",
  无限奖励月月拿: "월간 무제한 보너스",
  优享12个专属大礼: "12가지 혜택",
  即刻加入: "바로 신청하기",
  交易员等级奖励机制: `거래자 등급별 보상 제도`,
  EBC基于跟随人数和资金提供五档奖励:
    "팔로워 수와 자금에 따라 5가지 등급의 보상을 제공합니다. 거래 랏 수는 제한하지 않으며, 등급 측정은 시장 수용도를 기준으로 합니다. ",
  月: "월",
  新锐交易员: "초보 트레이더",
  精英交易员: "엘리트 트레이더",
  冠军交易员: "챔피언 트레이더",
  明星交易员: "스타 트레이더",
  传奇交易员: "레전드 트레이더",
  管理资金: "+1%연회비 (월 결제)",
  管理资金量: "관리 자산 규모",
  跟随用户数: "팔로워 수",
  多层次的增值服务: "다양한 서비스",
  EBC提供多达11项增值服务:
    "본 프로그램은 보상 외 최대 11가지 부가가치 서비스를 제공합니다. 트래픽 및 미디어 자원을  통합하여 시그널의 영향력 증대를 지원합니다.",
  "7×24小时优先客服": "7x24시간 온라인 고객 상담 서비스",
  参与EBC品牌营销互动: "EBC 브랜드 마케팅 인터랙티브",
  VPS赞助: "VPS 스폰서십",
  金融投资类书籍赞助: "금융 투자 관련 서적 후원",
  订单流软件一年使用权: "주문 흐름 도구 1년 사용권",
  EBC定制大礼包: "EBC 기프트 패키지",
  信号置顶展示: "시그널 디스플레이 상단 표기",
  受邀参观海外办公室: "해외 지사 초대방문",
  媒体资源推送: "미디어 리로스 푸시",
  品牌战略合作: "브랜드 제휴",
  海外基金产品发行顾问服务: "해외 펀드 상품 발행 자문 서비스",
  六维指标评价系统: "6가지 평가 시스템",
  为交易员信号提供公开透明的评估:
    "공정하고 투명한 평가를 위해 트레이더의 시그널과 거래 스타일을 공개합니다. 시그널에 대한 인지도를 높이고 더 많은 팔로워를 확보할 수 있습니다.",
  "最大回撤6.39%": "최대 손실 6.39%",
  衡量风控能力: "리스크 관리 능력 측정, 수치가 낮을수록 리스크가 낮습니다.",
  "标准差1.59": "표준 편차 1.59",
  收益波动率: "변동성 측정, 수치가 낮을수록 안정적입니다.",
  "盈利交易(胜率)75.82%": "거래 성공 75.82%",
  盈利订单占比: "수익이 나는 거래 비율 측정, 수치가 높을수록 수익 가능성이 큽니다.",
  "收益率406.06%": "수익률 406.06%",
  历史收益率: "과거 거래의 수익률 측정, 수치가 높을수록 좋습니다.",
  "获利系数6.19": "수익 요인 6.19",
  利润因子: "이익계수가 높을수록 수익성이 더 강해집니다. ",
  "夏普比率44.46": "샤프 비율 44.46",
  每份风险的收益: "위험 단위당 수익을 측정, 수치가 높을 수록 좋습니다.",
  收益率: "수익률",
  最大回撤: "최대 손실",
  标准差: "표준 편차",
  获利系数: "수익 요인",
  夏普比率: "샤프 비율",
  "盈利交易(胜率)": "거래 성공",
  以上数据仅供参考: "*위 수치는 참고용으로, 실제와 다를 수 있습니다.",
  活动须知: "안내 사항",
  交易员等级计算: "1. 트레이더 등급 산정",
  管理资金量跟随资金量:
    "관리 자산 규묘 = 팔로워 자산 규모 + 트레이더(거래 시그널) 계좌 자산 규모 자산 규모는 순자산을 기준으로 계산됩니다.",
  等级计算以自然月为周期:
    "등급 산정은 월 주기로 진행되며, 팔로워 자산 규모와 팔로워 고객 수가 한 달 연속 유지되어야 해당 등급으로 산정됩니다.",
  有效跟随用户: "2. 유효 팔로워 고객수",
  起始跟随金额: "팔로워 자산 규모 $500 이상을 유효 팔로워 1명으로 산정합니다.",
  每个MT4账号最多跟随10个交易员: "MT4 계좌 1개당 최대 10개의 트레이더(거래 시그널)을 팔로우할 수 있습니다.",
  每个MT4账号跟随金额: "MT4 계좌 1개 자산 규모 = 팔로워 계좌 보유 금액/시그널 수",
  奖励结算: "3. 보상 정산",
  次月第一周计算并发放上月奖励:
    "매달 첫째 주에 전월 보상을 지급합니다. 보상금은 전월 최소 등급을 기준으로 계산합니다.",
  所有奖励发到EBC后台钱包:
    "모든 보상은 거래 계좌에 직접 적립되며, 당첨자는 자금을 직접 인출할 책임이 있습니다. 참가자는 사전에 등록하는 것이 좋습니다.",
  活动最终解释权归主办方EBC所有: "본 프로그램의 관련 권한은 EBC에 있습니다. ",
};
