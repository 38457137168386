<template>
    <div class="insitution-service" :class="$t('InsitutionService.box-name')">
        <page-navigate></page-navigate>
        <div class="service-t-sub1 technology0">
            <h1 class="p1" v-html="$t('InsitutionService.screen0.title')"></h1>
            <p class="line"></p>
            <div class="m-box">
                <ul>
                    <li>
                        <img src="../assets/image/InsitutionService/technology-star.svg" alt="">
                        <p>{{ $t('InsitutionService.screen0.list[1].desc') }}</p>
                    </li>
                    <li>
                        <img src="../assets/image/InsitutionService/technology-star.svg" alt="">
                        <p>{{ $t('InsitutionService.screen0.list[0].desc') }}</p>
                    </li>
                    <li>
                        <img src="../assets/image/InsitutionService/technology-star.svg" alt="">
                        <p>{{ $t('InsitutionService.screen0.list[2].desc') }}</p>
                    </li>
                </ul>
            </div>
            <a href="https://client.ebccrm.com/signup" class="btn">{{ $t('InsitutionService.public-btn-text') }}</a>
            <img class="partner" src="../assets/image/InsitutionService/service-partner.png" alt="">
        </div>


        <div class="technology-ash service-page-screen-1  technology5" data-v-2bf02733="">
            <div class="container" data-v-2bf02733="">
                <p class="service-public-title">{{ $t('InsitutionService.screen1.title') }}</p>
                <p class="service-public-line"></p>
                <c-products></c-products>
            </div>
            <router-link class="public-link2" to="/AllProducts">{{ $t('Dividend.public-btn-text2') }}<img class="arrow1"
                    src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img class="arrow2"
                    src="../assets/image/TradingAccount/fca12.png" alt=""></router-link>
        </div>


        <div class="technology-white technology3 service2">
            <div class="container">
                <p class="service-public-title">{{ $t('InsitutionService.screen2.pageTitle') }}</p>
                <p class="service-public-line"></p>
                <p class="public-desc">{{ $t('InsitutionService.screen2.title') }}</p>
                <div class="row">
                    <img :src="require('../assets/image/InsitutionService/' + $t('CFooters.className') + '.png')" alt="">
                </div>
                <a class="public-link2"
                    href="https://client.ebccrm.com/signup">{{ $t('InsitutionService.public-btn-text') }}<img
                        class="arrow1" src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img
                        class="arrow2" src="../assets/image/TradingAccount/fca12.png" alt=""></a>
            </div>
        </div>


        <div class="technology-ash technology6">
            <div class="container">
                <p class="service-public-title">{{ $t('InsitutionService.screen3.pageTitle') }}</p>
                <p class="service-public-line"></p>
                <p class="service-public-title2">{{ $t('InsitutionService.screen3.title') }}</p>
                <p class="public-desc">{{ $t('InsitutionService.screen3.desc') }}</p>
                <div class="row">
                    <img src="../assets/image/InsitutionService/group6@2x.png" alt="">
                </div>
                <a class="public-link2"
                    href="https://client.ebccrm.com/signup">{{ $t('InsitutionService.public-btn-text') }}<img
                        class="arrow1" src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img
                        class="arrow2" src="../assets/image/TradingAccount/fca12.png" alt=""></a>
            </div>
        </div>


        <div class="technology-white technology3">
            <div class="container">
                <p class="service-public-title">{{ $t('InsitutionService.screen5.title') }}</p>
                <p class="service-public-line"></p>
                <p class="public-desc">{{ $t('InsitutionService.screen5.desc') }}</p>
                <div class="row">
                    <p>{{ $t('InsitutionService.screen5.it') }}</p>
                    <img class="img-text" src="../assets/image/InsitutionService/service-01.png" alt="">
                </div>
                <router-link class="public-link2" to="/about-us">{{ $t('TradingAccount.screen1.btn') }}<img class="arrow1"
                        src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img class="arrow2"
                        src="../assets/image/TradingAccount/fca12.png" alt=""></router-link>
            </div>
        </div>
        <page-slogan></page-slogan>
        <ss-contact-us mode="all"></ss-contact-us>
        <page-footer></page-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            seoInfo: ""
        }
    },
    computed: {
        allData_() {
            console.log(this[this.tabData[this.tabIndex]]);
            return this[this.tabData[this.tabIndex]]
        }
    },
    metaInfo() {
        return {
            title: this.seoInfo.title || '',
            meta: [ // set meta
                {
                    name: 'keyWords',
                    content: this.seoInfo.keyWords || ''
                },
                {
                    name: 'description',
                    content: this.seoInfo.description || ''
                }
            ]
        }
    },
    created() {
        this.$getSeoInfo({
            lang: this.$t("common.requestLang"),//this.$store.state.Lan,
            path: "/service"
        }).then(res => {
            this.seoInfo = res
        })
    },
    methods: {
        changTab(idx) {
            this.tabIndex = idx
        },
    }
}
</script>

<style>
@charset "UTF-8";

.c-footer .fp-overflow {
    display: unset !important;
}

.service-t-sub1 {
    background: url(../assets/image/InsitutionService/service-bg.png) 100%/auto 100% no-repeat;
    height: 800px;
    margin-top: 0px;
    text-align: center;
    padding-top: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.service-t-sub1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}

.service-t-sub1 .p1 {
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
    letter-spacing: 2px;
}

.line {
    width: 80px;
    border: 1px solid #D9D9D9;
    margin: 50px auto 50px;
}

.service-t-sub1 .p2 {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 710px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}

.service-t-sub1 .btn {
    display: block;
    width: max-content;
    padding: 10px 56px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 40px auto;
}

.service-t-sub1 .btn:hover {
    color: #3D0101;
    background: #AF8147;
}

.service-public-title {
    color: #000000;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 2px;
}

.service-public-line {
    width: 70px;
    border: 1px solid #AF8147;
    margin: 46px auto 36px;
}

.service-public-title2 {
    color: #000000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.public-desc {
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.service-t-sub2 {
    background: #F8F8F8;
    padding: 80px 0;
}

.service-t-sub2 .row {
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}

.service-t-sub2 .row div {
    margin-top: 10px;
}

.service-t-sub2 .row img {
    width: 30px;
    position: relative;
    top: 7px;
}

.service-t-sub2 .row p {
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.public-btn {
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
}

.public-btn:hover {
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}

.btn-box {
    width: max-content;
    margin: auto;
}


.service-t-sub3 {
    background: #ffffff;
    padding: 90px 0 160px;
}

.service-t-sub3 .row {
    margin: 54px auto;
    width: 1200px;
}

.service-t-sub3 .row div {
    text-align: center;
}

.service-t-sub3 .row img {
    width: 65px;
    position: relative;
    top: 26px;
}

.service-t-sub3 .row img {
    width: 90px;
}

.service-t-sub3 .row .title {
    color: #080E1C;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 22px;
}

.service-t-sub3 .row .desc {
    color: #080E1C;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 10%;
}


.service-t-sub4 {
    background: #F8F8F8;
    padding: 80px 0;
}

.service-t-sub4 img {
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}

.service-t-sub4 .public-desc {
    width: 60%;
    text-align: left;
}


.service-t-sub5 {
    background: #FFFFFF;
    padding: 80px 0;
}

.service-t-sub5 .service-t-sub5-img {
    display: block;
    margin: 40px auto;
    width: 1000px;
}

.service-t-sub5 .public-desc {
    width: 500px;
}

.service-t-sub5-content {
    position: relative;
    width: 1000px;
    margin: 0 auto;
}

.service-t-sub5-bottom {
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}

.service-t-sub5-bottom p {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}

.service-t-sub5-bottom img {
    width: 7px;
    margin-left: 10px;
}

.service-t-sub5-bottom span {
    font-size: 26px;
    font-weight: bold;
}

.public-slogan {
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.service-t-subtner-box-en .service-t-sub2 .row {
    padding: 50px 0% 26px 22%;
}

.service-t-subtner-box-en .service-t-sub2 .row div {
    width: 100%;
}

.service-t-subtner-box-en .service-t-sub1 .p1 {
    line-height: 52px;
    margin-top: 60px;
}

.service-t-subtner-box-en .public-desc {
    width: 750px;
}

.service-t-subtner-box-en .service-t-sub3 .row p {
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}

.service-t-subtner-box-en .service-t-sub1 .p2 {
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}

.service-t-subtner-box-en .service-t-sub2 .row p {
    letter-spacing: 0px;
}

.service-t-sub1 .btn-ug {
    width: 190px;
}


.service-t-sub5 .container {
    max-width: 1100px;
}

.service-t-sub5 .row div {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}

.service-t-sub5 .row img {
    width: 80%;
}

.service-t-sub5 .row p {
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.service-t-sub6 {
    background: #F8F8F8;
    padding: 80px 0;
}

.service-t-sub6 .container {
    max-width: 1100px;
}

.service-t-sub6 .row div {
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}

.service-t-sub6 .row div img {
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}

.service-t-sub6 .row div p {
    float: left;
    font-size: 16px;
}

.service-t-sub1 .p1 br {
    display: none;
}

.en-style .service-t-sub1 .p2 {
    letter-spacing: 0px;
}

.en-style .service-t-sub1 .p1 {
    margin-top: 80px;
}

.vi-box .service-t-sub1 .p1 {
    margin-top: 40px;
}




.public-link {
    background: #3d0101;
    /* padding: 11px 28px; */
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    padding: 15px 29px;
    float: left;
    margin-top: 20px;
}

.public-link img {
    width: 7px;
    margin-left: 12px;
}

.public-link:hover {
    color: #ffffff;
    background: #AF8147;
}

.public-link2 {
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    margin-right: 12px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    float: left;
    margin-top: 20px;
}

.public-link2 img {
    width: 7px;
    margin-left: 12px;
}

.public-link2:hover {
    color: #ffffff;
    background: #3d0101;
    border: 1px solid #3d0101;
}

.arrow2 {
    display: none !important;
}

.public-link2:hover .arrow1 {
    display: none !important;
}

.public-link2:hover .arrow2 {
    display: inline-block !important;
}


.product {
    width: 1000px;
    margin: 50px auto;
}

.product .accordion-header {
    cursor: pointer
}

.product .table-list {
    width: 100%;
    border: none;
    position: relative;
    flex-direction: inherit;
    background: #949699;
}

.product .table-list .flex-1 {
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 12px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    cursor: pointer;
    font-family: auto;
    text-align: center;
}

.product .table-list .flex-1.active {
    background-color: #3D0101;
    color: #ffffff;
}

.product-box__nav {
    /* margin-bottom: 4px; */
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    color: #666666;
    padding: 0 12px;
    background-color: #F8F8F8;
    padding: 4px 0;
}

.product-box__nav div {
    line-height: 40px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: auto;
    text-align: center;
}

.product-box__accordion-item {
    background: #f7f7f7;
    border: none
}

.product-box__accordion-item .accordion-button {
    padding: 10px;
    font-size: 14px;
    background: #ffffff;
    color: #333
}

.product-box__accordion-item .accordion-body-head {
    width: 100%;
    padding: 12px 0;
    background-color: #949699;
    color: #f7f7f7
}

.product-box__accordion-item .accordion-body-head div {
    flex: 1
}

.product-box__accordion-header {
    margin-bottom: 0
}

.product-box .accordion-body-tr {
    width: 100%;
    padding: 12px 0;
    font-family: auto;
}

.product-box .accordion-body-tr div {
    flex: 1;
    text-align: center;
}

.product-box .accordion-body-tr div:nth-of-type(2) {
    color: #48ba13
}

.product-box .accordion-body-tr .red {
    color: #cc3221 !important
}

.product-box .accordion-body-tr div:nth-of-type(5) {
    color: #48ba13
}

.product .look-all {
    display: flex;
    justify-content: center;
    color: #8b8b8b;
    padding: 20px 0;
    font-size: 16px;
    margin-top: 30px;
}

.product .look-all span {
    padding-top: 5px;
    border-top: 1px solid #8b8b8b
}

.product .c-underline__top:after {
    /* background: #7d1614;
    height: 4px */
}

.accordion-body {
    font-size: 14px;
    font-family: auto;
    background: #f8f8f8;
}



.en-style2 .service-t-sub1 .p2 {
    letter-spacing: 0px;
}

.en-style2 .service-t-sub3 .row .title {
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}

.en-style2 .service-t-sub3 .row .desc {
    letter-spacing: 0px;
}

.en-style2 .public-link2 {
    letter-spacing: 0px;
}

.en-style2 .public-link {
    letter-spacing: 0px;
}

.account1 {
    padding: 80px 0 170px;
    background: #f8f8f8;
}

.account1 .container {
    width: 950px;
    text-align: center;
}

.account1 .row .title {
    font-size: 28px;
    color: #080E1C;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.account1 .row .title2 {
    color: #3D0101;
}

.account1 .row .desc1,
.account1 .row .desc2,
.account1 .row .desc3 {
    font-size: 17px;
    line-height: 50px;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.account1 .row .div1 {
    margin-top: 60px;
}

.account1 .row .desc1 {
    color: #949699;
}

.account1 .row .desc2 {
    color: #080E1C;
}

.account1 .row .desc3 {
    color: #3D0101;
}

.account1 .public-link2 {
    margin-right: 0px;
    margin-top: 40px;
}

.technology-ash {
    padding: 100px 0 80px;
    background: #f8f8f8;
}

.technology-ash .row img {
    width: 80%;
    margin: 40px auto 40px;
}

.technology-ash .public-desc {
    color: #949699;
    font-size: 18px;
}

.technology-ash .public-link2 {
    float: unset;
    margin: 0 auto;
}

.technology-white {
    padding: 100px 0 80px;
}

.technology-white .row img {
    width: 80%;
    margin: 40px auto 40px;
}

.technology-white .public-desc {
    color: #949699;
    font-size: 18px;
}

.technology-white .public-link2 {
    float: unset;
    margin: 0 auto;
}

.en-style2 .service-t-sub1 .p2 {
    letter-spacing: 0px;
}

.en-style2 .account1 .row .desc1,
.en-style2 .account1 .row .desc2,
.en-style2 .account1 .row .desc3 {
    letter-spacing: 0px;
}

.en-style2 .account1 .row .title {
    letter-spacing: 0px;
    font-size: 26px;
}

.en-style2 .public-desc {
    width: 80%;
}

.en-style2 .service-public-title {
    padding: 0 10%;
    line-height: 38px;
}

.technology2 .row img {
    width: 60%;
}

.technology3 .row img {
    width: 96%;
}

.technology3 .public-link2 {
    margin-top: 30px;
}



.technology1 {
    background: #ffffff;
    padding: 32px 0 38px;
    text-align: center;
}

.technology1 p {
    font-size: 18px;
    display: inline-block;
    line-height: 28px;
}

.technology1 span {
    color: #7D0101;
    font-size: 30px;
    font-weight: bold;
}

.technology4 .public-desc {
    display: none;
}

.technology4 .service-public-line {
    display: none;
}

.technology4 {
    position: relative;
    padding-top: 120px;
    padding-bottom: 52px;
}

.technology4 .base-text {
    color: #F1F1F1;
    font-size: 120px;
    letter-spacing: 1px;
    font-weight: bold;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}

.technology4 .service-public-title {
    z-index: 999;
    position: relative;
}

.technology4 .public-link2 img {
    width: 7px;
    margin: 0;
    margin-left: 12px;
}

.technology4 .desc {
    width: 90%;
    color: #999999;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 160px 0 0;
}

.technology4 .row {
    width: 900px;
    margin: 0 auto;
}

.technology4 .public-link2 {
    margin: 30px 0;
}

.technology4 .div-img {
    text-align: right;
}

.technology4 .div-img img {
    margin-top: 100px;
}

.technology5 .public-desc {
    width: 60%;
}

.technology2 .public-desc {
    width: 46%;
}

.technology5 .container {
    width: 1000px;
}

.technology5 .bg-red {
    background: #2D0101;
}

.technology5 .row {
    margin-top: 30px;
    margin-bottom: 50px;
}

.technology5 .row div {
    padding: 0;
    height: 405.5px;
}

.technology5 .row .icon {
    width: 50px;
    float: left;
    margin-left: 54px;
    margin-top: 68px;
}

.technology5 .row .right-img {
    margin: 0;
    width: 100%;
}

.technology5 .row p {
    color: #FFFFFF;
    font-size: 18px;
    display: inline-block;
    float: left;
    width: 200px;
    line-height: 72px;
    margin-left: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.technology5 .row .text {
    margin-top: 54px;
}

.technology6 .row img {
    width: 80%;
}

.technology6 .public-desc {
    width: 60%;
}

.technology5 {
    /*padding-bottom: 10px;*/
}

.technology6 .a-box {
    width: 960px;
    margin: 30px auto 0;
    padding: 18px 0;
    background: #3D0101;
    text-align: center;
}

.technology6 .a-box a {
    font-size: 16px;
    color: #ffffff;
    margin: 0 50px;
}

.technology6 .a-box .link-icon {
    width: 7px;
    margin: 0;
    margin-left: 12px;
}

.technology6 .public-link2 {
    margin-top: 10px;
}

.service-page-screen-1 {
    padding-bottom: 80px;
}

.technology4 .m-link {
    display: none;
}

.technology4 .pc-link {
    display: block;
}

.en-style2 .technology2 .public-desc {
    width: 60%;
}

.en-style2 .technology1 p {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.en-style2 .technology5 .public-desc {
    width: 80%;
}

.en-style2 .technology5 .row .icon {
    margin-left: 40px;
}

.en-style2 .technology6 .public-desc {
    width: 80%;
}

.en-style2 .technology4 .service-public-title {
    line-height: 44px;
}

.en-style2 .technology4 {
    padding-top: 92px;
}

.en-style2 .technology4 .desc {
    margin: 120px 0 0;
}

.en-style2 .technology4 .div-img img {
    margin-top: 72px;
}



.technology0 .m-box li {
    display: flex;
    align-items: baseline;
    width: max-content;
    margin: 10px auto;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.59);
    padding: 14px 30px;
}

.technology0 .m-box li p {
    font-size: 16px;
    color: #ffffff;
    margin: 0 7px;
    display: inline-block;
    opacity: 0.73;
}

.technology0 .m-box li span {
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
}

.technology0 .m-box {
    display: block;
}

.technology0 .m-box img {
    width: 17px;
    margin: 0;
    position: relative;
    top: 2px;
}

.technology0 .partner {
    width: 70%;
    margin-top: 80px;
}

.en-style2 .service-public-title {
    letter-spacing: 0px;
}

.en-style2 .service-t-sub1 .p1 {
    letter-spacing: 0px;
    line-height: 52px;
}

.technology3 .row img {
    width: 76%;
}

.technology3 .row p {
    position: relative;
    top: 241px;
    text-align: center;
    font-size: 18px;
    color: #cacaca;
    letter-spacing: 4px;
}

.service2 .row img {
    width: 94%;
}

.en-style2 .service-t-sub1 .p1 br {
    display: block !important;
}

.en-style2 .technology0 .m-box li {
    width: 58%;
}

.en-style2 .technology0 .m-box li p {
    line-height: 26px;
    margin-top: 4px;
}

.en-style2 .service-t-sub1 {
    height: 1000px;
    padding-top: 140px;
}

.en-style2 .technology3 .row p {
    letter-spacing: 1px;
}

.en-style2 .technology0 .partner {
    margin-top: 60px;
}





@media(max-width:1400px) {
    .service-t-sub1 {
        height: 750px;
        padding-top: 140px;
    }

    .service-t-sub1 .p1 {
        font-size: 34px;
    }

    .service-t-sub1 .p2 {
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }

    .service-t-sub4 img {
        width: 700px;
    }

    .service-t-sub5 .service-t-sub5-img {
        width: 700px;
    }

    .service-t-sub5-bottom {
        width: 700px;
        height: 68px;
    }

    .service-t-sub5-content {
        width: 700px;
    }

    .service-t-sub5-bottom p {
        line-height: 60px;
    }

    .service-t-subtner-box-en .service-t-sub1 {
        height: 650px;
    }

    .en-style2 .technology1 p {
        font-size: 15px;
    }

    .technology3 .row p {
        top: 214px;
    }

    .en-style2 .technology0 {
        height: 980px;
    }

    .en-style2 .technology0 .partner {
        margin-top: 40px;
    }
}


@media(max-width:995px) {
    .service-t-sub1 .p1 br {
        display: block !important;
    }

    .service-t-sub2 .row {
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }

    .service-t-sub2 .row p {
        font-size: 14px;
    }

    .service-t-sub2 .row div {
        margin-top: 0px;
    }

    .service-t-sub3 .row {
        width: 100%;
        padding: 19px 0% 0px 0%;
        margin: 10px auto 30px;
        background: #ffffff;
    }

    .service-t-sub3 .row div {
        width: 50%;
        margin-bottom: 30px;
    }

    .service-t-sub3 .row img {
        width: 90px;
    }

    .public-link2 {
        padding: 14px 18px;
        margin-top: 0px;
        text-align: center;
        width: 100%;
    }

    .public-link {
        padding: 15px 19px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }

    .service-t-sub3 .row .title {}

    .service-t-sub3 .row .desc {
        font-size: 15px;
        padding: 0 0%;
        line-height: 26px;
    }

    .service-t-sub5-content {
        width: 100%;
    }

    .service-t-sub5 .service-t-sub5-img {
        width: 100%;
    }

    .service-t-sub5-bottom {
        width: 100%;
        height: 46px;
    }

    .service-t-sub5-bottom p {
        line-height: 42px;
        font-size: 14px;
        padding: 0px 20px;
    }

    .service-t-sub5-bottom span {
        font-size: 18px;
    }

    .service-t-sub5-bottom img {
        width: 6px;
        margin-left: 6px;
    }

    .service-t-sub1 .p2 {
        width: 100%;
        padding: 24px 8%;
        font-size: 16px;
        line-height: 30px;
    }

    .line {
        width: 62px;
        border: 1px solid #D9D9D9;
        margin: 26px auto 0px;
    }

    .service-t-sub1 .p1 {
        margin-top: 20px;
        line-height: 58px;
        font-size: 34px;
    }

    .service-t-sub1 .btn {
        margin: 14px auto;
    }

    .public-desc {
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }

    .service-t-sub4 .public-desc,
    .service-t-sub5 .public-desc {
        width: 90%;
    }

    .service-t-sub2,
    .service-t-sub3,
    .service-t-sub4,
    .service-t-sub5 {
        padding: 56px 0;
    }

    .service-public-line {
        width: 50px;
        margin: 40px auto 0px;
    }

    .service-public-title {
        font-size: 26px;
    }

    .service-t-sub2 .row img {
        width: 28px;
    }

    .service-t-sub4 img {
        width: 90%;
    }

    .public-slogan {
        padding: 20px 20px;
        line-height: 28px;
    }

    .service-t-subtner-box-en .service-t-sub2 .row {
        padding: 20px 0% 0px 3%;
    }

    .service-t-subtner-box-en .service-t-sub2 .row div {
        width: 100%;
        margin-top: 14px;
    }

    .service-t-subtner-box-en .service-t-sub2 .row div p {
        line-height: 30px;
    }

    .service-t-subtner-box-en .service-t-sub1 .p1 {
        line-height: 44px;
        font-size: 28px;
        width: 90%;
        margin: 20px auto 0;
    }

    .service-public-title2 {
        line-height: 26px;
        color: #3D0101;
    }

    .service-t-subtner-box-en .service-t-sub5-bottom p {
        font-size: 12px;
        letter-spacing: 0px;
        padding: 0px 8px;
    }

    .service-t-subtner-box-en .service-t-sub5-bottom span {
        font-size: 16px;
    }

    .service-t-subtner-box-en .public-desc {
        width: 90%;
    }

    .service-t-subtner-box-en .service-t-sub1 .p2 {
        width: 100%;
        font-size: 14px;
        line-height: 26px;
    }

    .service-t-subtner-box-en .service-t-sub2 .row {
        width: 100%;
    }

    .service-t-subtner-box-en .service-t-sub2 .row img {
        width: 24px;
    }

    .service-t-subtner-box-en .service-t-sub1 .p1 {
        font-size: 26px;
        line-height: 34px;
    }

    .service-t-subtner-box-en .service-public-title {
        font-size: 22px;
        line-height: 32px;
    }

    .service-t-subtner-box-en .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }

    .service-t-subtner-box-en .service-t-sub2 .row div p {
        line-height: 30px;
        margin-left: 6px;
    }

    .service-t-subtner-box-en .service-t-sub2 .row div {
        text-align: center;
    }

    .service-t-subtner-box-en .service-t-sub3 .row p {
        margin-left: 8px;
        display: block;
        line-height: 18px;
        margin-top: 8px;
        font-size: 12px;
    }

    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }

    .service-t-sub1 .p1 br {
        display: block;
    }

    .service-t-sub4 img {
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 96%;
    }

    .public-btn {
        margin: 40px auto 0px;
    }

    .service-t-sub4 .public-desc {
        margin-top: 30px;
    }

    .service-t-sub5 .row div {
        width: 50%;
        margin-bottom: 0px;
    }

    .service-t-sub5 .row p {
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }

    .service-t-sub5 .row img {
        width: 88%;
    }

    .service-t-sub6 .row div p {
        font-size: 14px;
    }

    .service-t-sub6 .row div {
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }

    .service-t-sub6 .row div img {
        top: 13px;
        left: 12px;
    }

    .service-t-sub6 {
        padding-bottom: 50px;
    }

    .en-style .service-t-sub6 .row div {
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }

    .en-style .service-t-sub6 .row div p {
        font-size: 14px;
    }

    .en-style .service-t-sub6 .row div img {
        top: 18px;
        left: 12px;
        width: 13px;
    }

    .en-style .service-public-title {
        font-size: 23px;
    }

    .en-style .service-t-sub1 .p1 {
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }

    .product {
        width: 100%;
    }

    .product .table-list .flex-1:nth-child(4),
    .product .table-list .flex-1:nth-child(5) {
        display: none;
    }

    .accordion-body {
        padding: 0;
    }

    .product-box__nav {
        font-size: 12px;
    }

    .product-box__accordion-item .accordion-button {
        font-size: 12px;
    }

    .accordion-body {
        font-size: 12px;
    }

    .product .table-list .flex-1 {
        font-size: 13px;
    }

    .product .accordion-button:after {
        display: none;
    }

    .product-box__accordion-item .accordion-button {
        padding: 8px 0;
    }

    .en-style2 .public-link2 {
        float: unset;
        margin-bottom: 16px;
    }

    .en-style2 .public-link {
        float: unset;
    }

    .en-style2 .service-t-sub3 .row .title {
        line-height: 24px;
    }

    .en-style2 .service-t-sub3 .row .desc {
        margin-top: 14px;
    }

    .en-style2 .product {
        margin-top: 30px;
    }

    .en-style2 .service-public-title {
        padding: 0 8%;
        line-height: 32px;
    }

    .en-style2 .product {
        margin-bottom: 30px;
    }

    .service-t-sub1 .btn {
        display: block;
        margin-top: 36px;
    }

    .service-t-sub1 {
        background: url(../assets/image/InsitutionService/service-bg-m.png) 100%/auto 100% no-repeat;
        height: 830px;
        margin-top: 0px;
        text-align: center;
        padding-top: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .service-t-sub1 .p1 {
        font-size: 30px;
        line-height: 46px;
    }

    .account1 {
        padding: 50px 2% 50px;
        background: #ffffff;
    }

    .account1 .container {
        width: 100%;
    }

    .account1 .row div {
        width: 33.3%;
    }

    .account1 .row .title {
        font-size: 17px;
        letter-spacing: 0px;
        white-space: nowrap;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
    }

    .account1 .row .desc1,
    .account1 .row .desc2,
    .account1 .row .desc3 {
        font-size: 14px;
        line-height: 20px;
        margin-top: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0px;
    }

    .account1 .row .div1 {
        margin-top: 22px;
        text-align: right;
    }

    .account1 .btn-box {
        display: none;
    }

    .service-t-sub3 {
        background: #f8f8f8;
    }

    .service-t-sub3 .row {
        background: #f8f8f8;
    }

    .service-t-sub3 .row div {
        width: 100%;
    }

    .public-link,
    .public-link2 {
        width: 70%;
        text-align: center;
        margin: 20px auto 10px;
        float: unset;
    }

    .btn-box {
        width: 100%;
    }

    .technology-ash {
        /* background: #ffffff; */
        padding: 60px 3%;
    }

    .technology-ash .public-desc {
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }

    .technology-ash .row img {
        width: 100%;
        margin: 20px auto 30px;
    }

    .technology-white {
        padding: 60px 3%;
        /* background: #f8f8f8; */
    }

    .technology-white .public-desc {
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }

    .technology-white .row img {
        width: 100%;
    }

    .service-public-line {
        margin: 30px auto 30px;
    }

    .service-t-sub3 .service-public-line {
        margin-bottom: 0px;
    }

    .en-style2 .service-t-sub1 .p2 {
        padding-top: 14px;
    }

    .en-style2 .account1 .row .title {
        font-size: 16px;
    }

    .en-style2 .service-public-title {
        font-size: 24px;
    }

    .en-style2 .service-t-sub3 .row .desc {
        padding: 0 10%;
    }

    .service-t-sub1 img {
        width: 16px;
        margin: 0;
    }

    .technology0 .m-box li {
        width: 75%;
        margin: 8px auto;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.59);
        padding: 16px 5%;
    }

    .technology0 .m-box li p {
        font-size: 16px;
        color: #ffffff;
        margin: 8px 0 0;
        line-height: 26px;
    }

    .technology0 .m-box li span {
        font-size: 24px;
        color: #ffffff;
        font-weight: bold;
    }

    .technology0 .m-box {
        display: block;
        margin-top: 30px;
    }

    .technology1 {
        display: none;
    }

    .service-t-sub1 .p2 br {
        display: none;
    }

    .technology4 .row {
        width: 100%;
    }

    .technology4 .service-public-title {
        padding: 0 10%;
        line-height: 38px;
    }

    .technology4 .base-text {
        display: none;
    }

    .technology4 .service-public-line {
        display: block;
    }

    .technology4 .public-link2 img {
        width: 7px;
        margin: 0;
        margin-left: 12px;
    }

    .technology4 .desc {
        width: 100%;
        color: #949699;
        font-weight: 400;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }

    .technology4 .public-link2 {
        margin: 30px auto;
    }

    .technology4 .pc-link {
        display: none;
    }

    .technology4 .m-link {
        display: block;
    }

    .technology4 .div-img {
        text-align: center;
    }

    .technology4 .div-img img {
        width: 60%;
        margin-top: 30px;
        margin-bottom: 0px;
    }

    .technology5 .container {
        width: 100%;
    }

    .technology5 {
        padding-left: 0;
        padding-right: 0;
    }

    .technology5 .row div {
        height: auto;
    }

    .technology5 .row .icon {
        width: 50px;
        float: left;
        margin-left: 22%;
        margin-top: 40px;
    }

    .technology5 .row .text {
        margin-top: 27px;
    }

    .technology5 .row {
        margin-bottom: 30px;
    }

    .service-page-screen-1 {
        padding-bottom: 60px;
    }

    .technology6 .a-box {
        padding: 15px 0;
    }

    .technology6 .a-box a {
        font-size: 15px;
        margin: 0 4%;
    }

    .en-style2 .service-t-sub1 .p1 {
        line-height: 34px;
        padding: 0 9%;
    }

    .en-style2 .technology0 .m-box li {
        width: 78%;
    }

    .en-style2 .technology4 .service-public-title {
        padding: 0 6%;
    }

    .en-style2 .technology5 .row .icon {
        margin-left: 20%;
    }

    .en-style2 .technology6 .a-box a {
        display: block;
        margin-bottom: 14px;
    }

    .en-style2 .technology6 .a-box {
        padding: 18px 0 3px;
    }

    .technology6 {
        padding-bottom: 60px;
    }

    .en-style2 .service-t-sub1 .p1 {
        font-size: 24px;
        padding: 0 4%;
    }

    .en-style2 .technology0 .m-box li p {
        /* font-size: 14px; */
        /* line-height: 20px; */
    }

    .en-style2 .technology0 .m-box li {
        /* width: 80%; */
        /* padding: 20px 3px; */
    }

    .en-style2 .service-t-sub1 .p2 {
        padding: 24px 4%;
    }

    .en-style2 .service-t-sub1 {
        height: 880px;
    }

    .en-style2 .service-public-title {
        padding: 0;
    }

    .en-style2 .technology4 .service-public-title {
        line-height: 32px;
        padding: 0;
    }

    .en-style2 .technology4 {
        padding-top: 60px;
        padding-bottom: 40px;
    }

    .en-style2 .technology4 .desc {
        margin-top: 0px;
    }

    .en-style2 .technology5 .row .icon {
        margin-left: 19%;
    }

    .en-style2 .public-link2 {
        padding: 14px 9%;
        width: max-content;
    }

    .technology0 .partner {
        display: none;
    }

    .product .look-all {
        margin-top: 10px;
    }

    .service-page-screen-1 {
        padding-bottom: 60px;
        padding-top: 60px;
    }

    .technology3 .row .img-text {
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0;
    }

    .technology3 .row p {
        font-size: 16px;
        position: absolute;
        top: 48%;
    }

    .technology3 .row {
        position: relative;
    }

    .en-style2 .service-t-sub1 {
        height: 1040px;
    }
}

@media(max-width:400px) {
    .service-t-sub6 .row div p {
        font-size: 13px;
    }

    .service-t-sub6 .row div {
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }

    .service-t-sub6 .row div img {
        top: 13px;
        left: 12px;
        width: 14px;
    }

    .en-style .service-t-sub6 .row div {
        width: 92%;
        line-height: 22px;
        height: auto;
    }

    .en-style .service-t-sub6 .row div p {
        font-size: 14px;
    }

    .account1 .row .title {
        font-size: 16px;
    }
}

@media(max-width:390px) {

    .account1 .row .desc1,
    .account1 .row .desc2,
    .account1 .row .desc3 {
        font-size: 13px;
    }
}
</style>
