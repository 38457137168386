export default {
  leftNav: [
    { id: "#Trading", name: "Trading" },
    { id: "#Regulation", name: "Regulation" },
    { id: "#Security", name: "Security" },
    { id: "#Fintech", name: "Fintech" },
    { id: "#Black-Box", name: "Black Box" },
    { id: "#Private-Room", name: "Private Room" },
    { id: "#Liquidity", name: "Liquidity" },
    { id: "#Honor", name: "Honour" },
    //{ "id": "#News", "name": "News" }
  ],
  pages1: {
    h1: "Trust & Respect",
    title: "Exceptional Brilliant Care for Every Committed Trader",
    btn: " WATCH FULL VIDEO ",
  },
  pages2: {
    title: "Professional Liquidity Tailored To Your World",
    title1: "Exploring a World of Opportunities and Competitive Advantages.",
    btn: " Trading Product ",
    text: " Forex ",
    text1: " Commodities ",
    text2: " Index CFDs ",
    text3: " Share CFDs ",
  },
  pages3: {
    title: "Top-Tier Global Regulations",
    title1:
      "EBC Financial Group's subsidiaries are regulated and licensed in their local jurisdictions.",
    btn: "Regulation of EBC",
    text: "FCA (UK)",
    text1:
      "EBC Financial Group (UK) Ltd is authorised and regulated by the Financial Conduct Authority.Reference Number: 927552",
    text2: "ASIC (AU)",
    text3:
      "EBC Financial Group (Australia) Pty Ltd is authorised and regulated by the Australian Securities and Investments Commission. Reference Number: 500991",
    text4: "CIMA (CAYMAN)",
    text5:
      "EBC Financial Group (Cayman) Ltd is authorised and regulated by the Cayman Islands Monetary Authority (CIMA). Reference Number: 2038223",
  },
  pages4: {
    title: "Driving You To Succeed",
    title1:
      "Professional And Leading Financial Technology Makes Trading With Infinite Possibilities.",
    btn: " Explore EBC Tech",
    text: "ORDERS PROCESSED PER SECOND ",
    text1: "EXTREME ORDER EXECUTION ",
    text2: "UPTIME ",
    text3: "INTERBANK LEVEL OF RAW ECN SPREAD",
  },
  pages5: {
    title: "Enabling More Than 85% Of Orders Filled",
    title1: "EBC Trading Black-Box With Optimum, AccurateSell-Order Algorithm.",
    btn: " Trading Black-Box",
  },
  pages6: {
    title: "EBC Private Room",
    title1:
      "Private Room Can Hide Part Of Your Transaction Information, Which Means That Your 'Large Order' May Be Filled At A Better Price Without Serious Slippage Or Rejection Due To Last Look.",
    btn: " Private Room",
  },
  pages7: {
    title: "LIQUIDITY",
    title1:
      "EBC Has Established Long-Term And Stable Cooperative Relationships With World's Leading International Banks, Allowing You To Make Transactions Easily At Ultra-Low Costs.",
    btn: " EBC Liquidity",
  },
  pages8: {
    btn: "More News",
  },
  pages9: {
    title: "Comprehensive Protection for Peace of Mind",
    title1:
      "EBC considers the safety of funds as its top priority. Customer funds are held in segregated custodial accounts at Barclays Bank. By collaborating with the FCA, Financial Commission, Lloyds of London, and AON, EBC has established a comprehensive and multi-layered security mechanism.",
    btn: "Security",
  },
  lang: "en",

  连接全球热门交易产品: "Access Top Global Trading Products",
  探索一个充满机遇和竞争优势的世界:
    "Explore a world of opportunities and competitive advantages in the trading market.",
  衍生品交易: "Forex",
  期货CFDs: "Commodities",
  全球股票指数CFDs: "Index CFDs",
  美国股票CFDs: "Stock CFDs",
  "欧元/美元": "EURUSD",
  "黄金/美元": "XAUUSD",
  道琼斯工业指数: "U30USD",
  了解交易产品: "Trading Product",
  全球顶级监管: "Top-tier Global Regulations",
  EBC金融集团旗下各公司:
    "EBC Financial Group's subsidiaries are regulated and licenced in their local jurisdictions.",
  授权及监管于: "Authorised and regulated by",
  监管号: "Reference number",

  英国FCA监管: "FCA (UK)",
  "英国金融行为监管局 (FCA)": "The Financial Conduct Authority (FCA)",
  开曼CIMA监管: "CIMA (CAYMAN)",
  "开曼群岛金融管理局(CIMA)": "The Cayman Islands Monetary Authority (CIMA)",
  澳洲ASIC监管: "ASIC (AU)",
  澳大利亚证券和投资委员会:
    "The Australian Securities and Investments Commission (ASIC)",
  了解EBC官方: "Our Regulations",
  "全面保障 安心以致": "Comprehensive Protection for Peace of Mind",
  EBC将资金安全视为首要原则:
    "EBC Financial Group prioritises the safety of your funds above all else. We securely hold customer funds in segregated custodial accounts at Barclays Bank. By collaborating with the FCA, Financial Commission, Lloyds of London, and AON, EBC has created a robust, multi-layered security system to ensure peace of mind.",
  了解安全保障: "Our Security ",
  "连接全球 信赖首选": "Connecting the World, Trusted by Many",
  EBC构建全方位服务生态:
    "EBC has built a comprehensive service ecosystem backed by a team of experts with over 30 years of experience. With operations spanning the globe and an active, expansive user base, EBC is a recognised leader on the international stage.",
  
  日均交易订单量: "Average Daily Transaction Volume",
  "2024年第三季度为交易订单优化增益":
    "Q3 2024 Transaction Order Optimisation Gains",
  多个: "+",
  
  了解全球视野: "Learn More About Global Insights",
  "85%以上": "Secure Better Prices for Over 85% of Orders",
  的订单成交于更优价格: "",
  EBC交易黑盒:
    "EBC's Trading Black Box precisely optimises order algorithms, surpassing traditional systems in price optimisation, order execution rates, and algorithm stability.",
  每秒聚合订单: "Orders Processed Per Second",
  极速订单执行: "Extreme Order Execution",
  数据传输稳定高达: "Data Transmission Stability",
  "行级RAW ECN点差": "Interbank-level RAW ECN Spreads",
  了解金融科技: "Learn More About Financial Technologies",
  EBC全球合作伙伴: "EBC's Global Partners",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "EBC has established long-term, stable collaborations with over 25 internationally renowned banks and non-bank institutions, providing true institutional-grade liquidity depth and 0.0PIPS raw spreads. Beyond financial services, EBC actively fulfils corporate social responsibilities through initiatives with the University of Oxford's Department of Economics, partnerships with WAGGGS, and joint efforts with the United Nations Foundation to build an equal and sustainable society.",
  了解更多: "Learn More",
  十大最受欢迎券商: "Top 10 Most Popular Brokers",
  最透明交易券商: "Most Transparent Broker",
  最受好评券商: "Most Highly Rated Broker",
  卓越执行券商: "Excellence in Execution Broker",
  全球最佳交易体验奖: "Global Best Trading Experience Award",
  最受信赖券商: "Most Trusted Broker",
  最佳CFD券商: "Best CFD Broker",
  全球最佳ECN券商: "Global Best ECN Broker",
  全球新锐券商: "Global Rising Star Broker",
  tabList: [`Forex`, `Commodities`, `Index CFDs`, `Stock CFDs`],
  出售: "Ask",
  买入: "Bid",
  点差: "Spread",
  立即交易: "Trade",
  致力于让交易者成为盈家: "Committed to empowering traders to become global winners.",
  EBC每个引以为傲的数据背后:
    "At EBC, every achievement reflects the trust of traders from over 100 countries. We’re dedicated to supporting your trading journey, helping passionate traders connect to global opportunities with just one click.",
  月均交易量: "Average monthly trading<br/> volume",
  全球注册用户数: "Number of registered<br/>users worldwide",
  "2024年第四季度为交易订单优化增益": "Optimizing Gains for Trading<br/> Orders in Q4 2024",
  "业务覆盖国家/地区": "Business coverage<br/> countries/regions",
  number1: "177.5",
  number1Text: "177.5",
  number2: "4",
  number2Text: "4",
  number3: "273",
  number3Text: "2.73",
  number4: "100",
  number4Text: "100",
  langFlag: "en",
  亿: "B+",
  万: "M+",
  "+": "+",
};
