export default {
  linkList: [
    {
      title: "账户&条件",
      list: [
        {
          label: "交易账户",
          link: "/TradingAccount",
        },
        {
          label: "交易产品",
          link: "/tradingproduct",
        },
        {
          label: "杠杆与保证金",
          link: "/leverage-margin",
        },
        {
          label: "出入金",
          link: "/funding",
        },
        {
          label: "股息",
          link: "/dividend",
        },
      ],
    },
    {
      title: "交易工具",
      list: [
        {
          label: "MT4下载",
          link: "/MT4",
        },
        {
          label: "MT5下载",
          link: "/MT5",
        },
        {
          label: "MT4交易工具",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "市场分析",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "EBC研究院",
      list: [
        {
          label: "学习中心",
          link: "/LearningCenter",
        },
        {
          label: "数据中心",
          link: "/lab",
        },
      ],
    },
    {
      title: "最新活动",
      list: [
        {
          label: "积分商城",
          link: "PointsMall",
        },
        {
          label: "百万美金交易大赛",
          link: "/win",
        },
        {
          label: "创作者激励计划",
          link: "/cfa",
        },
        {
          label: "领取订单流工具",
          link: "/orderflow",
        },
        {
          label: "交易领薪计划",
          link: "/pay",
        },
      ],
    },
    {
      title: "关于EBC",
      list: [
        {
          label: "EBC介绍",
          link: "/about-us",
        },
        {
          label: "法律文件",
          link: "/LegalDocumen",
        },
        {
          label: "社会责任",
          link: "/ESG",
        },
        {
          label: "帮助中心",
          link: "https://support.ebchelp.com/hc/zh-cn",
        },
        {
          label: "官方渠道验证",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "合作伙伴",
      list: [
        {
          label: "影响力联盟",
          link: "/affiliate-programmes",
        },
        {
          label: "机构服务",
          link: "/service",
        },
        {
          label: "底层科技",
          link: "/technology",
        },
      ],
    },
  ],
  context:`
                <p>
                    <span class="page-footer-intro-blod">
                        EBC金融集团是由以下公司集团共享的联合品牌<br />
                    </span>

                    EBC Financial Group (SVG) LLC 在圣文森特与格林纳丁斯金融服务管理局注册并授权运营，注册号为353 LLC 2020。
                </p>
                <p>
                    <span class="page-footer-intro-blod">其他相关实体：<br /></span>
                    EBC Financial Group (UK) Limited 由英国金融行为监管局(FCA)授权和监管，监管编号：927552，网址：<a target="_blank"
                        href="https://www.ebcfin.co.uk" rel="nofollow">www.ebcfin.co.uk</a>
                <br/>
                    EBC Financial Group (Cayman) Limited由开曼群岛金融管理局(CIMA)授权和监管，监管编号：2038223，网址：
                    <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow">www.ebcgroup.ky</a>
                    
                <br/>
                EBC Financial Group (Australia) Pty Ltd（公司编号 ：619 073 237）由澳大利亚证券和投资委员会(ASIC)授权和监管（监管编号：500991），是EBC
                    Financial Group (SVG) LLC 的关联实体，两个实体独立运营管理。本网站提供的金融产品和服务并非由澳大利亚公司实体提供，不涉及该实体的责任。
                </p>
                <p>
                    <span class="page-footer-intro-blod"> 公司地址：</span>
                    The Leadenhall Building, 122 Leadenhall Street, London, United Kingdom EC3V
                    4AB；邮件地址：cs@ebc.com；电话：+44 20 3376 9662
                </p>
                <p>
                    <span
                        class="page-footer-intro-blod">免责声明：</span>EBC金融集团明确不涉及任何虚拟货币业务，也不提供与虚拟货币相关的CFD服务，我们提供的金融服务仅限于传统金融产品。请客户注意，任何以EBC名义推荐虚拟货币相关服务的行为均为误导，且未经授权。任何过往表现都不代表未来表现，网站内容仅供参考，不构成任何投资建议。
                </p>
                <p>
                    <span
                        class="page-footer-intro-blod">地区限制：</span>EBC不向以下地区的居民提供服务，阿富汗、白俄罗斯、缅甸、加拿大、中非共和国、刚果、古巴、刚果民主共和国、厄立特里亚、海地、伊朗、伊拉克、黎巴嫩、利比亚、马来西亚、马里、朝鲜（朝鲜民主主义人民共和国）、俄罗斯、索马里、苏丹、南苏丹、叙利亚、乌克兰（包括克里米亚、顿涅茨克和卢甘斯克地区）、美国、委内瑞拉和也门。想了解更多，请查阅常见问题解答。
                </p>
                <p>
                    <span
                        class="page-footer-intro-blod">合规披露：</span>公司网站在全球范围内可见，不针对任何特定实体。您的权利和义务将依据您所在地区的监管及适用的实体决定。当地法律和法规可能限制或禁止您访问、下载、传播、分享或使用网站上发布的任何文档及信息。
                </p>
                <p>
                    <span
                        class="page-footer-intro-blod">高风险交易产品披露：</span>CFD是复杂的金融产品，使用杠杆伴随高风险，可能导致迅速亏损。保证金交易风险较大，可能不适合所有投资者。您应结合自身目标、财务状况和风险承受能力，认真评估相关信息。您可能面临部分或全部本金损失的风险，我们建议您寻求专业建议，以确保在作出投资决策前充分理解相关风险。在开始交易前，请务必详细阅读相关的风险披露声明。
                </p>
  `
};
