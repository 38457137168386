export default {
    "box-class-name": "en-box",
    "lang": "en",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTrader 5 (MT5) is a next-gen multi-asset trading platform with advanced features and tools to meet global traders' diverse needs.",
    "提供市场深度信息": "Offers market depth information for deeper insights into market liquidity.",
    "支持6种挂单交易类型": "Supports 6 order types, providing flexibility for various trading needs.",
    "内置经济日历": "Built-in economic calendar for real-time updates on key global events.",
    "立即注册": "Register Now",
    "MetaTrader 5下载": "MetaTrader 5 Download",
    "MT5移动版": "MT5 Mobile Version",
    "随时随地进行交易": "Trade anywhere, anytime, stay on top of the market trends and manage your account with ease.",
    "下载Android版": "Download Android Version",
    "下载IOS版": "Download iOS Version",
    "下载iPad版": "Download iPad Version",
    "MT5桌面版": "MT5 Desktop Version",
    "全面的交易工具和高级图表分析": "Advanced chart analysis and comprehensive trading tools designed for professional traders' high-level needs.",
    "下载Windows版": "Download Windows Version",
    "下载MacOS版": "Download MacOS Version",
    "MT5网页版": "MT5 Webtrader",
    "无需下载": "No need for downloads to access the global marketplace at breakneck speeds, easily enabling full-rounded online transaction management.",
    "打开网页版": "Open Webtrader",
    "MT4与MT5的区别": "Comparison of MT4 and MT5",
    "MT4和MT5均由MetaQuotes开发": "While both MT4 and MT5 are developed by MetaQuotes, MT5 goes beyond being a mere upgrade of MT4. It offers significant improvements in functionality and a broader range of features.",
    "项目": "Items",
    "发布时间": "Release time",
    "2005年": "2005",
    "2010年": "2010",
    "交易市场": "Trading Markets",
    "主要是衍生品": "Mainly Forex",
    "包括股票": "Stocks, Futures, Forex, etc.",
    "挂单类型": "Pending Order Type",
    "4种": "4",
    "6种": "6",
    "时间框架": "Time Frames",
    "9种": "9",
    "21种": "21",
    "技术指标": "Indexes",
    "30个": "30",
    "38个": "38",
    "市场深度":"Market Depth",
    "不支持":"Not Applicable",
    "支持":"Applicable",
    "财经日历":"Financial Calendar",
    "内置":"Built-in",
    "操作界面":"Operation Interface",
    "更直观简单":"Simple and Straightforward ",
    "更加现代化灵活":"Advanced and Flexible"
}