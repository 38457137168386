<template>
	<div class="pamm">
		<page-navigate></page-navigate>
		<div :class="$t('PAMM.styleName')">
			<div class="pamm1">
                    <p class="p1" v-html="$t('PAMM.screen0.title')"></p>
                    <p class="line"></p>
                    <p class="p2">{{$t('PAMM.screen0.content')}}</p>
                    <a :href="$store.state.khUrl" class="btn">{{$t('PAMM.btn-text')}}</a>
                </div>


                <div class="pamm3">
                    <div class="container">
                        <p class="public-title">{{$t('PAMM.screen1.pageTitle')}}</p>
                        <p class="public-line"></p>
                        <img :src="require(`../assets/image/PAMM/pamm-2.f0d0d64-${$t('PAMM.imgUrl')}.png`)" alt="">
                        <div class="row">
                            <div class="col-md-6">
                                <p class="p3-title">{{$t('PAMM.screen1.c1Title')}}</p>
                                <p class="p3-desc">{{$t('PAMM.screen1.c1Desc')}}</p>
                            </div>
                            <div class="col-md-6">
                                <p class="p3-title">{{$t('PAMM.screen1.c2Title')}}</p>
                                <p class="p3-desc">{{$t('PAMM.screen1.c2Desc')}}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="pamm4">
                    <div class="container">
                        <p class="public-title">{{$t('PAMM.screen2.pageTitle')}}</p>
                        <p class="public-line"></p>
                        <img :src="require(`../assets/image/PAMM/pamm-c1-${$t('PAMM.imgUrl')}.png`)" alt="">
                        <p class="public-desc">{{$t('PAMM.screen2.desc')}}</p>
                    </div>
                </div>


                <div class="pamm5">
                    <div class="container">
                        <p class="public-title">{{$t('PAMM.screen3.pageTitle')}}</p>
                        <p class="public-line"></p>
                        <div class="row">
                            <div class="col-md-4"><img src="../assets/image/PAMM/icon@2x(2).ae02756.png" alt=""><p>{{$t('PAMM.screen3.text1')}}</p></div>
                            <div class="col-md-4"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALUAAABOBAMAAACUKEpAAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAkUExURZmZmUdwTJsiI5shIpshIp0jI5shIpsiIrgvL58mJpiYmJeXl6MaqrEAAAALdFJOU4AAgKfQQ/BiBiD+EDzu4wAAARZJREFUWMPt2b0OgjAQB3BcZeH8Akcx0VlsjKuSGNdOuqq8gBoewKjEVQef17YSXR3ub6K5S4DtR3PXXgn1Ag8Vgeej6Kq5CBSB2GJ/bvOvn7fNvn6qlZfNnpdAbLHFFltssT+y27v+FWQ3Z0k30xD7onREYXZA2MXA3o9zgB2Nns8hwG5OiBY5UWPLbzc0nTZq7N7Bbd9dOhTROrbBau/diFuabpbudTjtmEIzUepltms/Y5ucrFxFAbapZerKOeTPtxnxMR3NKZzyz5NzuXYKDegn5WJXiH5SrFwREb3K9liiMIX0WDrPEpUtMftO1N7lqD1NviHEFltssX/JZv+H51e+8u8REmKL/ec27MzLtydqsLO6BzL4sSyvQ7g8AAAAAElFTkSuQmCC" alt=""><p>{{$t('PAMM.screen3.text2')}}</p></div>
                            <div class="col-md-4"><img src="../assets/image/PAMM/icon@2x(3).0561a35.png" alt=""><p>{{$t('PAMM.screen3.text3')}}</p></div>
                            <div class="col-md-4"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZQAAADCAgMAAAATC9xtAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTP///5eXl40cHK7NEY4AAAABdFJOUwBA5thmAAAAwElEQVR42u3ZwQ3CIBiA0UZnrK7jEi7BFC7hPHjz90CQpIYAvu9Kyks4EKDbJkmSJK3aKaV0W0W57xcKhbK2cs45P1ZRnvuVQqFQKBQKZVXl2C1wMOXQKlIolHGV2ENalfZdp7vynqdZaV5FCoVCoVAolF8occapK/E+P7AS89SVwiiFQqFQKBRKX6X0NjWt0jjP/yilU+3n2+JUype7CIVCoVAoFAqFQqFQllXiVSH+8sRdtHG0/kUvRZIkSZqnF3deOSu1onUYAAAAAElFTkSuQmCC" alt=""><p>{{$t('PAMM.screen3.text4')}}</p></div>
                            <div class="col-md-4"><img src="../assets/image/PAMM/icon@2x(5).f212434.png" alt=""><p>{{$t('PAMM.screen3.text5')}}</p></div>
                            <div class="col-md-4"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAABWBAMAAAB4GsSeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAhUExURUdwTJiYmJmZmZoiJJsiIpohIpiYmJwiI5pHR54mJpshIvyXXYgAAAALdFJOUwCBf4F/+t9Svh3MTqLJAQAAAU1JREFUaN7t2T1LxEAQBuDJEQhrlaQItrE4LAOHoJ3gCZYLmxCy3d0vsPAKS7/gytRWghz+TUUt5iAOZG8mIMzbTRGeZLJhNglEGVOASHHCkrJICaQEnkRTIDNFFFFEEUX+BxI+GIs1rlICidbBozHDh2YrCkmZJmNGILOU6Z6UiiiiiCKKHIosxuT8CVdbXFQEEtfLEbl5xtUWF45CKqZ2XSmiiCKKcCMF2vDn8xWqjh9GvTpQSJSjLf8c7/9PH0e9OgCBAFO7QBFFFAlD+ikQOwFy18ojr03jxJHdfXdWCSPGgT2ywkhSg00upNvl+wlW16YeRsLH736+P6olO387gEAePH73/3D9dMR43w8gwNSu39hNJ4x8rSybtNJIC9bUwojxYGPpK4Hrj/blTRqJm+ZdfnWZbuhhZF/CUyCgiCJ/IG7BE0edwZIpl5/f4L+sQzMVYgAAAABJRU5ErkJggg==" alt=""><p>{{$t('PAMM.screen3.text6')}}</p></div>
                        </div>
                        <a :href="$store.state.khUrl" class="public-btn">{{$t('PAMM.btn-text')}}</a>
                    </div>
                </div>
		</div>
		<page-slogan></page-slogan>
		<page-footer></page-footer>
	</div>
</template>

<script>
	if (process.browser) {
		var {
			WOW
		} = require('wowjs')
	}
	export default {
		data() {
			return {

				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$t("common.requestLang"),//this.$store.state.Lan,
				path: "/PAMM"
			}).then(res => {
				this.seoInfo = res
			})
		},
		mounted() {
			this.$nextTick(() => {
				if (process.browser) { // 在页面mounted生命周期里面 根据环境实例化WOW
					new WOW({
						animateClass: 'animate__animated'
					}).init()
				}
			});
		},
		methods: {
			refresh() {
				this.$nuxt.refresh()
			},
		}
	}
</script>

<style>
.c-footer .fp-overflow{
		display: unset !important;
	}
	.pamm1{
  background: url(../assets/image/PAMM/pamm-bg.png) 100%/auto 100% no-repeat;
  height: 750px;
  margin-top: 0px;
  text-align: center;
  padding-top: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.pamm1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    margin-top: 86px;
}
.line{
    width: 80px;
    border: 1px solid #D9D9D9;
    margin: 60px auto 50px;
}
.pamm1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 666px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}
.pamm1 .btn{
    display: block;
    width: max-content;
    padding: 10px 40px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 50px auto;
}
.pamm1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.public-title{
    color: #000;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}
.public-line{
    width: 80px;
    border: 1px solid #AF8147;
    margin: 38px auto 36px;
}
.public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.public-desc{
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.pamm2{
    background: #F8F8F8;
    padding: 80px 0;
}
.pamm2 .row{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}
.pamm2 .row div{
    margin-top: 10px;
}
.pamm2 .row img{
    width: 30px;
    position: relative;
    top: 7px;
}
.pamm2 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.public-btn{
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
	line-height: 16px;
}
.public-btn:hover{
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}


.pamm3{
    background: #ffffff;
    padding: 80px 0;
}
.pamm3 .row{
    
}
.pamm3 .row img{
    width: 65px;
    position: relative;
    top: 26px;
}
.pamm3 .container{
    max-width: 1000px;
}
.pamm3 .row div{
    padding: 20px 5%;
}
.pamm3 img{
    background: #ffffff;
    padding: 30px;
}
.pamm3 .row .p3-title{
    color: #3D0101;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 46px;
    font-weight: bold;
}
.pamm3 .row .p3-desc{
    color: #999;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    margin-top: 6px;
}


.pamm4{
    background: #F8F8F8;
    padding: 80px 0;
}
.pamm4 img{
    display: block;
    margin: 40px auto;
    width: 70%;
    background: #fff;
    padding: 6px 26px;
}
.pamm4 .public-desc{
    width: 70%;
    text-align: left;
}


.pamm5{
    background: #ffffff;
    padding: 80px 0;
}
.pamm5 .pamm5-img{
    display: block;
    margin: 40px auto;
    width: 1000px;
}
.pamm5 .public-desc{
    width: 500px;
}
.pamm5-content{
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.pamm5-bottom{
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}
.pamm5-bottom p{
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}
.pamm5-bottom img{
    width: 7px;
    margin-left: 10px;
}
.pamm5-bottom span{
    font-size: 26px;
    font-weight: bold;
}
.public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.pammtner-box-en .pamm2 .row{
    padding: 50px 0% 26px 22%;
}
.pammtner-box-en .pamm2 .row div{
    width: 100%;
}
.pammtner-box-en .pamm1 .p1{
    line-height: 52px;
    margin-top: 60px;
}
.pammtner-box-en .public-desc{
    width: 750px;
}

.pammtner-box-en .pamm3 .row p{
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}
.pammtner-box-en .pamm1 .p2{
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}
.pammtner-box-en .pamm2 .row p{
    letter-spacing: 0px;
}
.pamm1 .btn-ug{
    width: 190px;
}
.pamm3 img{
    width: 92%;
    margin: 0 auto 16px;
    display: block;
}


.pamm5 .container{
    max-width: 1100px;
}
.pamm5 .row div{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.pamm5 .row img{
    width: auto;
    height: 110px;
}
.pamm5 .row p{
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 73%;
    margin: 30px auto 0;
}

.pamm6{
    background: #F8F8F8;
    padding: 70px 0;
}
.pamm6 .container{
    max-width: 1100px;
}
.pamm6 .row div{
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}
.pamm6 .row div img{
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}
.pamm6 .row div p{
    float: left;
    font-size: 16px;
}
.pamm1 .p1 br{
    display: none;
}

.en-style .pamm1 .p2{
    letter-spacing: 0px;
}
.en-style .pamm1 .p1{
    margin-top: 80px;
}
.vi-box .pamm1 .p1{
    margin-top: 40px;
}
.en-style2 .pamm1 .p2{
    line-height: 34px;
    width: 56%;
}
.en-style2 .pamm1 .p1{
    margin-top: 68px;
}






@media(max-width:1400px) {
    .pamm1 .p1{
        font-size: 34px;
    }
    .pamm1 .p2{
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }
    .pamm1{
        height: 600px;
        padding-top: 80px;
    }
    .pamm4 img{
        width: 70%;
    }
    .pamm5 .pamm5-img{
        width: 700px;
    }
    .pamm5-bottom{
        width: 700px;
        height: 68px;
    }
    .pamm5-content{
        width: 700px;
    }
    .pamm5-bottom p{
        line-height: 60px;
    }
    .pammtner-box-en .pamm1{
        height: 650px;
    }
    .en-style2 .pamm1 .p2{
        line-height: 30px;
        width: 58%;
    }
}


@media(max-width:995px) {
    .pamm1{
        background: url(../assets/image/PAMM/pamm-bg-m.png) 100%/auto 100% no-repeat;
        height: 750px;
        margin-top: 0px;
        text-align: center;
        padding-top: 126px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .pamm1 .p1 br{
        display: block !important;
    }
    .pamm2 .row{
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }
    .pamm2 .row p{
        font-size: 14px;
    }
    .pamm2 .row div{
        margin-top: 0px;
    }
    .pamm3 .row{
        
    }
    .pamm3 .row div{
        text-align: center;
    }
    .pamm3 .row .p3-title,.pamm3 .row .p3-desc{
        margin: 0;
    }
    .pamm3 .row img{
        width: 54px;
        position: relative;
        top: 0px;
        left: 5%;
    }
    .pamm3 .row p{
        display: block;
        font-size: 14px;
        margin-left: 9%;
        line-height: 20px;
    }
    .pamm5-content{
        width: 100%;
    }
    .pamm5 .pamm5-img{
        width: 100%;
    }
    .pamm5-bottom{
        width: 100%;
        height: 46px;
    }
    .pamm5-bottom p{
        line-height: 42px;
        font-size: 14px;
        padding: 0px 20px;
    }
    .pamm5-bottom span{
        font-size: 18px;
    }
    .pamm5-bottom img{
        width: 6px;
        margin-left: 6px;
    }
    .pamm1 .p2{
        width: 100%;
        padding: 8%;
        font-size: 14px;
        line-height: 26px;
    }
    .line{
        width: 62px;
        border: 1px solid #D9D9D9;
        margin: 26px auto 0px;
    }
    .pamm1 .p1{
        margin-top: 58px;
        line-height: 58px;
        font-size: 34px;
    }
    .pamm1 .btn{
        margin: 14px auto;
    }
    .public-desc{
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }
    .pamm4 .public-desc,.pamm5 .public-desc{
        width: 90%;
    }
    .pamm2,.pamm3,.pamm4,.pamm5{
        padding: 56px 0;
    }
    .public-line{
        width: 50px;
        margin: 26px auto 26px;
    }
    .public-title{
        font-size: 26px;
    }
    .pamm2 .row img{
        width: 28px;
    }
    .pamm4 img{
        width: 98%;
    }
    .public-slogan{
        padding: 20px 20px;
        line-height: 28px;
    }
    .pammtner-box-en .pamm2 .row{
        padding: 20px 0% 0px 3%;
    }
    .pammtner-box-en .pamm2 .row div{
        width: 100%;
        margin-top: 14px;
    }
    .pammtner-box-en .pamm2 .row div p{
        line-height: 30px;
    }
    .pammtner-box-en .pamm1 .p1{
        line-height: 44px;
        font-size: 28px;
        width: 90%;
        margin: 20px auto 0;
    }
    .public-title2{
        line-height: 26px;
    }
    .pammtner-box-en .pamm5-bottom p{
        font-size: 12px;
        letter-spacing: 0px;
        padding: 0px 8px;
    }
    .pammtner-box-en .pamm5-bottom span{
        font-size: 16px;
    }
    .pammtner-box-en .public-desc{
        width: 90%;
    }
    .pammtner-box-en .pamm1 .p2{
        width: 100%;
        font-size: 14px;
        line-height: 26px;
    }
    .pammtner-box-en .pamm2 .row{
        width: 100%;
    }
    .pammtner-box-en .pamm2 .row img{
        width: 24px;
    }
    .pammtner-box-en .pamm1 .p1{
        font-size: 26px;
        line-height: 34px;
    }
    .pammtner-box-en .public-title{
        font-size: 22px;
        line-height: 32px;
    }
    .pammtner-box-en .public-slogan{
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .pammtner-box-en .pamm2 .row div p{
        line-height: 30px;
        margin-left: 6px;
    }
    .pammtner-box-en .pamm2 .row div{
        text-align: center;
    }
    .pammtner-box-en .pamm3 .row p {
        margin-left: 8px;
        display: block;
        line-height: 18px;
        margin-top: 8px;
        font-size: 12px;
    }
    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .pamm1 .p1 br{
        display: block;
    }
    .pamm3 img{
        width: 100%;
        padding: 10px 4px;
        margin-bottom: 16px;
    }
    .pamm3 .row div{
        padding: 10px 5%;
    }
    .pamm4 img{
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 99%;
    }
    .public-btn{
        margin: 40px auto 0px;
    }
    .pamm4 .public-desc{
        margin-top: 30px;
    }
    .pamm5 .row div{
        width: 50%;
        margin-bottom: 0px;
    }
    .pamm5 .row p{
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }
    .pamm5 .row img{
        width: auto;
        height: 70px;
    }
    .pamm6 .row div p{
        font-size: 14px;
    }
    .pamm6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }
    .pamm6 .row div img{
        top: 13px;
        left: 12px;
    }
    .pamm6{
        padding-bottom: 50px;
    }
    .en-style .pamm6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }
    .en-style .pamm6 .row div p{
        font-size: 14px;
    }
    .en-style .pamm6 .row div img{
        top: 18px;
        left: 12px;
        width: 13px;
    }
    .en-style .public-title{
        font-size: 23px;
    }
    .en-style .pamm1 .p1{
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }
    .en-style2 .pamm1 .p2{
        width: 100%;
        line-height: 26px;
    }
    .en-style2 .pamm1 .p1{
        margin-top: 16px;
    }
}
@media(max-width:400px) {
    .pamm6 .row div p{
        font-size: 13px;
    }
    .pamm6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }
    .pamm6 .row div img{
        top: 13px;
        left: 12px;
        width: 14px;
    }
    .en-style .pamm6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
    }
    .en-style .pamm6 .row div p{
        font-size: 14px;
    }
}
</style>