<template>
<div>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap" rel="stylesheet">
	<page-navigate></page-navigate>
    <div :class="$t('AffiliateProgrammes.box-name')">
        <div class="partner2-section1">
            <h1 class="p1">{{$t('AffiliateProgrammes.section1.text1')}}</h1>
            <p class="p2">{{$t('AffiliateProgrammes.section1.text2')}}</p>
            <ul>
                <li>{{$t('AffiliateProgrammes.section1.text3')}}</li>
                <li>{{$t('AffiliateProgrammes.section1.text4')}}</li>
                <li>{{$t('AffiliateProgrammes.section1.text5')}}</li>
                <li>{{$t('AffiliateProgrammes.section1.text6')}}</li>
            </ul>
        </div>

        <div class="partner2-section2">
            <div class="container">
                <p class="partner-public-title">{{$t('AffiliateProgrammes.section2.text1')}}</p>
                <div class="row">
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon1.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section2.text2')}}</p>
                        <p class="p2" v-html="$t('AffiliateProgrammes.section2.text3')"></p>
                        <ul v-html="$t('AffiliateProgrammes.section2.text4')">
                        </ul>
                        <a rel="nofollow" :href="$store.state.khUrl" target="_blank">{{$t('AffiliateProgrammes.section2.text5')}}</a>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon2.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section2.text6')}}</p>
                        <p class="p2" v-html="$t('AffiliateProgrammes.section2.text7')"></p>
                        <ul v-html="$t('AffiliateProgrammes.section2.text8')">
                        </ul>
                        <a rel="nofollow" :href="$store.state.khUrl" target="_blank">{{$t('AffiliateProgrammes.section2.text9')}}</a>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon3.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section2.text10')}}</p>
                        <p class="p2" v-html="$t('AffiliateProgrammes.section2.text11')"></p>
                        <ul v-html="$t('AffiliateProgrammes.section2.text12')">
                        </ul>
                        <a rel="nofollow" :href="$store.state.khUrl" target="_blank">{{$t('AffiliateProgrammes.section2.text13')}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="partner2-section3">
            <div class="container">
                <p class="partner-public-title">{{$t('AffiliateProgrammes.section3.text1')}}</p>
                <div class="row">
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon5.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section3.text2')}}</p>
                        <p class="p2">{{$t('AffiliateProgrammes.section3.text3')}}</p>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon6.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section3.text4')}}</p>
                        <p class="p2">{{$t('AffiliateProgrammes.section3.text5')}}</p>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon7.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section3.text6')}}</p>
                        <p class="p2">{{$t('AffiliateProgrammes.section3.text7')}}</p>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon8.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section3.text8')}}</p>
                        <p class="p2">{{$t('AffiliateProgrammes.section3.text9')}}</p>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon9.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section3.text10')}}</p>
                        <p class="p2">{{$t('AffiliateProgrammes.section3.text11')}}</p>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/image/AffiliateProgrammes/partner-new-icon10.svg" alt="">
                        <p class="p1">{{$t('AffiliateProgrammes.section3.text12')}}</p>
                        <p class="p2">{{$t('AffiliateProgrammes.section3.text13')}}</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="partner2-section4">
            <div class="container">
                <p class="partner-public-title">{{$t('AffiliateProgrammes.section4.text1')}}</p>
                <div class="row">
                    <div class="col-md-6">
                        <img src="../assets/image/AffiliateProgrammes/partner-new1.png" alt="">
                    </div>
                    <div class="col-md-6 info">
                        <p class="p1">{{$t('AffiliateProgrammes.section4.text2')}}</p>
                        <ul v-html="$t('AffiliateProgrammes.section4.text3')">
                        </ul>
                        <p class="p2">{{$t('AffiliateProgrammes.section4.text4')}}</p>
                        <a href="/DiNapoli">{{$t('AffiliateProgrammes.section4.text5')}}</a>
                    </div>
                </div>
                <div class="row list">
                    <div class="col-md-3">
                        <img src="../assets/image/AffiliateProgrammes/partner-new2.png" alt="">
                        <div class="d1">
                            <p class="s1">Lawrence Smart</p>
                        </div>
                        <div class="introduce">
                            <div class="introduce-content">
                                <p class="i1">Lawrence Smart</p>
                                <ul v-html="$t('AffiliateProgrammes.section4.text6')">
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <img src="../assets/image/AffiliateProgrammes/partner-new3.png" alt="">
                        <div class="d1">
                            <p class="s1">Pieter van Wyk</p>
                        </div>
                        <div class="introduce">
                            <div class="introduce-content">
                                <p class="i1">Pieter van Wyk</p>
                                <ul v-html="$t('AffiliateProgrammes.section4.text7')">
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <img src="../assets/image/AffiliateProgrammes/partner-new4.png" alt="">
                        <div class="d1">
                            <p class="s1">Monchai Kongthanapakdi</p>
                        </div>
                        <div class="introduce">
                            <div class="introduce-content">
                                <p class="i1">Monchai Kongthanapakdi</p>
                                <ul v-html="$t('AffiliateProgrammes.section4.text8')">
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <img src="../assets/image/AffiliateProgrammes/partner-new5.png" alt="">
                        <div class="d1">
                            <p class="s1">Jason Zeng</p>
                        </div>
                        <div class="introduce">
                            <div class="introduce-content">
                                <p class="i1">Jason Zeng</p>
                                <ul v-html="$t('AffiliateProgrammes.section4.text9')">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="partner2-section5">
            <div class="container">
                <p class="partner-public-title">{{$t('AffiliateProgrammes.section5.text1')}}</p>
                <div class="row">
                    <div class="col-md-6">
                        <img src="../assets/image/AffiliateProgrammes/partner-new6.png" alt="">
                    </div>
                    <div class="col-md-6 info">
                        <p class="p1">{{$t('AffiliateProgrammes.section5.text2')}}</p>
                        <ul>
                            <li>
                                <p class="pp1">{{$t('AffiliateProgrammes.section5.text3')}}</p>
                                <p class="pp2">{{$t('AffiliateProgrammes.section5.text4')}}</p>
                            </li>
                            <li>
                                <p class="pp1">{{$t('AffiliateProgrammes.section5.text5')}}</p>
                                <p class="pp2">{{$t('AffiliateProgrammes.section5.text6')}}</p>
                            </li>
                            <li>
                                <p class="pp1">{{$t('AffiliateProgrammes.section5.text7')}}</p>
                                <p class="pp2">{{$t('AffiliateProgrammes.section5.text8')}}</p>
                            </li>
                        </ul>
                        <a href="/LearningCenterInfo?id=LEARN-93-460">{{$t('AffiliateProgrammes.section4.text5')}}</a>
                    </div>
                </div>
            </div>
        </div>
      </div> 
      <page-footer></page-footer>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
    import Swiper from 'swiper'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
		},
		created() {
		},
		methods: {
		}
	}

</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
  .partner2-section1{
    background: url(../assets/image/AffiliateProgrammes/partner-new-bg.png) 100%/auto 100% no-repeat;
    height: auto;
    margin-top: 0px;
    padding-top: 140px;
    padding-bottom: 200px;
    padding-left: 8%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.partner2-section1 .p1{
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 70px;
}
.partner2-section1 .p2{
    width: 52%;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 22px;
}
.partner2-section1 ul{
    width: 55%;
    margin-top: 30px;
}
.partner2-section1 ul li{
    width: 50%;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
    float: left;
    margin-bottom: 12px;
    padding-left: 22px;
    position: relative;
}
.partner2-section1 ul li::before {  
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon12.svg');  
  background-size: contain;
  background-repeat: no-repeat;
}

.partner-public-title{
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.partner2-section2{
    margin-top: 100px;
    margin-bottom: 100px;
}

.partner2-section2 .row div{
    border: 1px solid #D4D4D4;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    padding: 36px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    margin-top: 60px;
}
.partner2-section2 .row div .p1{
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-top: 24px;
}
.partner2-section2 .row div .p2{
    color: #666;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    margin-top: 14px;
}
.partner2-section2 .row div ul{
    margin-top: 30px;
    width: 90%;
}
.partner2-section2 .row div ul li{
    width: 100%;
    color: #666666;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
    float: left;
    margin-bottom: 12px;
    padding-left: 24px;
    position: relative;
}
.partner2-section2 .row div ul li::before {  
    content: "";
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon4.svg');  
    background-size: contain;
    background-repeat: no-repeat;
}
.partner2-section2 .row div a{
    display: block;
    width: 100%;
    padding: 16px 0px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #3D0101;
    background: #3D0101;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 26px;
}

.partner2-section3{
    margin-top: 150px;
    margin-bottom: 100px;
}
.partner2-section3 .row div{
    text-align: center;
    margin-top: 100px;
}
.partner2-section3 .row div .p1{
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    width: 70%;
    margin: 20px auto;
}
.partner2-section3 .row div .p2{
    color: #666;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 80%;
    margin: 0 auto;
}

.partner2-section4 .p1{
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 30px;
}

.partner2-section4{
    margin-top: 160px;
    margin-bottom: 160px;
}
.partner2-section4 .row{
    margin-top: 80px;
}

.partner2-section4 .info{
    padding-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.partner2-section4 img{
    width: 100%;
}

.partner2-section4 .info ul li{
    width: 70%;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: block;
    float: left;
    margin-bottom: 22px;
    padding-left: 22px;
    position: relative;
}
.partner2-section4 .info ul li::before {  
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon11.svg');  
  background-size: contain;
  background-repeat: no-repeat;
}

.partner2-section4 .p2{
    width: 100%;
    color: #666;
    text-align: justify;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    margin-top: 12px;
}

.partner2-section4 .info a{
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #3D0101;
    background: #3D0101;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 30px; 
    display: block;
    width: max-content;
}   

.partner2-section4 .list .col-md-3 {  
    position: relative;  
    overflow: hidden;  
}  

.partner2-section4 .list div .d1 {  
    background: linear-gradient(0deg, #3D0101 0%, rgba(61, 1, 1, 0.00) 32.22%);  
    width: 93%;  
    height: 480px;  
    bottom: 0px;  
    position: absolute;  
    transition: opacity 0.5s ease;  
}  

.partner2-section4 .list div .s1 {  
    color: #FFF;  
    text-align: center;  
    font-family: "Roboto", sans-serif;  
    font-size: 18px;  
    font-style: normal;  
    font-weight: 700;  
    line-height: 140%;   
    position: absolute;  
    bottom: 30px;  
    left: 50%;  
    transform: translateX(-50%);  
    transition: opacity 0.5s ease;  
}  

.partner2-section4 .list div .introduce {  
    position: absolute;  
    bottom: 0;  
    background: linear-gradient(0deg, rgba(61, 1, 1, 0.80) 0%, rgba(61, 1, 1, 0.80) 100%);  
    width: 93%;  
    height: 100%;  
    opacity: 0;  
    transition: opacity 0.5s ease;  
    display: flex;  
    flex-direction: column;  
    justify-content: center;  
    align-items: center;  
}  

.partner2-section4 .list div .introduce .i1 {  
    color: #FFF;  
    text-align: center;  
    font-family: "Roboto", sans-serif;  
    font-size: 18px;  
    font-style: normal;  
    font-weight: 700;  
    line-height: 140%;  
}  

.partner2-section4 .list div .introduce-content {  
    position: absolute;  
    bottom: 6%;  
}  

.partner2-section4 .list div ul {  
    margin-top: 24px;  
}  

.partner2-section4 .list div ul li {  
    width: 100%;  
    color: #fff;  
    font-family: "Roboto", sans-serif;  
    font-size: 11px;  
    font-style: normal;  
    line-height: 150%;  
    display: block;  
    float: left;  
    margin-bottom: 6px;  
    padding-left: 22px; 
    padding-right: 16px;  
    position: relative;  
    opacity: 0.9;  
}  

.partner2-section4 .list div ul li i {  
    font-weight: 600;  
}  

.partner2-section4 .list div:hover .d1,  
.partner2-section4 .list div:hover .s1 {  
    opacity: 0;  
}  

.partner2-section4 .list div:hover .introduce {  
    opacity: 1;  
}  




.partner2-section5 .p1{
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 30px;
}

.partner2-section5{
    margin-top: 160px;
    margin-bottom: 160px;
}
.partner2-section5 .row{
    margin-top: 80px;
}

.partner2-section5 .info{
    padding-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.partner2-section5 img{
    width: 100%;
}

.partner2-section5 .info ul li .pp1{
    width: 100%;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
    margin-bottom: 10px;
    padding-left: 22px;
    position: relative;
}
.partner2-section5 .info ul li .pp2{
    width: 100%;
    color: #666;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-bottom: 22px;
    padding-left: 22px;
}
.partner2-section5 .info ul li .pp1::before {  
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url('../assets/image/AffiliateProgrammes/partner-new-icon11.svg');  
  background-size: contain;
  background-repeat: no-repeat;
}

.partner2-section5 .p2{
    width: 100%;
    color: #666;
    text-align: justify;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    margin-top: 12px;
}

.partner2-section5 .info a{
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #3D0101;
    background: #3D0101;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 20px; 
    display: block;
    width: max-content;
}

.partner2-page-ar .partner2-section1{
    padding-left: 0%;
    padding-right: 7%;
}
.partner2-page-ar .partner2-section1 ul li{
    padding-right: 22px;
    padding-left: 0;
}
.partner2-page-ar .partner2-section1 ul li::before{
    right: 0;
    left: unset;
}
.partner2-page-ar .partner2-section2 .row div ul li{
    padding-left: 0px;
    padding-right: 24px;
}
.partner2-page-ar .partner2-section2 .row div ul li::before{
    right: 0;
    left: unset;
}
.partner2-page-ar .partner2-section4 .info ul li{
    padding-right: 22px;
    padding-left: 0;
    float: right;
}
.partner2-page-ar .partner2-section4 .info ul li::before{
    right: 0;
    left: unset;
}
.partner2-page-ar .partner2-section5 .info ul li .pp1{
    padding-right: 22px;
    padding-left: 0;
}
.partner2-page-ar .partner2-section5 .info ul li .pp1::before{
    right: 0;
    left: unset;
}
.partner2-page-ar .partner2-section5 .info,.partner2-page-ar .partner2-section4 .info{
    padding-left: 0%;
    padding-right: 4%;
}
.partner2-page-cn .partner2-section4 .list div ul li{
    font-size: 13px;
    float: unset;
}
.partner2-page-cn .partner2-section1 ul{
    width: 32%;
}


@media(max-width:1500px) {
    .partner2-section1 .p2{
        width: 66%;
    }
    .partner2-section1 .p2{
        width: 66%;
    }
    .partner2-section1 ul{
        width: 66%;
    }
    .partner2-page-cn .partner2-section1 ul{
        width: 38%;
    }
    .partner2-section1 ul{
        width: 60%;
        margin-top: 30px;
    }
}

@media(max-width:1300px) {
    .partner2-section1{
        padding-top: 100px;
        padding-bottom: 150px;
    }
    .partner2-section1 .p1{
        font-size: 42px;
    }
    .partner2-section1 .p2{
        font-size: 16px;
    }
    .partner2-section1 ul li{
        font-size: 14px;
    }
    .partner2-section2 .row div .p1{
        font-size: 22px;
    }
    .partner2-section2 .row div ul li{
        font-size: 13px;
    }
    .partner2-section2 .row div ul{
        width: 94%;
    }
    .partner2-section3 .row div .p1{
        font-size: 24px;
    }
    .partner-public-title{
        font-size: 36px;
    }
    .partner2-section3,.partner2-section4,.partner2-section5{
        margin-top: 110px;
    }
    .partner2-section4 .p1{
        font-size: 28px;
    }
    .partner2-section4 .info ul li{
        margin-bottom: 14px;
        width: 100%;
    }
    .partner2-section5 .p1{
        font-size: 16px;
    }
}


@media(max-width:996px) {
    .partner2-section1{
        background: url(../assets/image/AffiliateProgrammes/partner-new-bg-m.png) 100% / auto 100% no-repeat;
        height: auto;
        padding-top: 70px;
        padding-bottom: 220px;
        padding-left: 4%;
        padding-right: 4%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .partner2-section1 .p2{
        width: 100%;
        font-size: 15px;
        line-height: 140%;
    }
    .partner2-section1 .p1{
        font-size: 30px;
    }
    .partner2-section1 ul{
        width: 100%;
    }
    .partner2-section1 ul li{
        width: 100%;
    }
    .partner-public-title {
        font-size: 28px;
    }
    .partner2-section2{
        margin-top: 60px;
        padding: 0 3%;
    }
    .partner2-section2 .row div{
        width: 100%;
        flex: unset;
        margin-top: 40px;
    }
    .partner2-section2 .row div ul li{
        font-size: 14px;
    }
    .partner2-section2{
        margin-bottom: 60px;
    }
    .partner2-section3, .partner2-section4, .partner2-section5{
        margin-top: 80px;
    }
    .partner2-section3 .row div{
        margin-top: 80px;
    }
    .partner2-section3 .row div .p2{
        width: 90%;
    }
    .partner2-section4 .row{
        margin-top: 40px;
    }
    .partner2-section4 .p1{
        font-size: 24px;
        margin-top: 30px;
    }
    .partner2-section4 .info a{
        width: 100%;
        text-align: center;
    }
    .partner2-section4 .list .col-md-3{
        width: 47%;
        padding: 0;
        margin-right: 1.5%;
        margin-left: 1.5%;
        margin-bottom: 16px;
    }
    .partner2-section4 .list{
        padding: 0 2%;
    }
    .partner2-section4 .list div .introduce{
        width: 100%;
    }
    .partner2-section4 .list div .d1{
        width: 100%;
    }
    .partner2-section4 .list div .s1{
        font-size: 16px;
    }
    .partner2-section4 .list div ul li{
        font-size: 10px;
        padding-left: 10px;
        padding-right: 3%;
    }
    .partner2-section4 .list div .introduce .i1{
        font-size: 16px;
    }
    .partner2-section4 .list div ul{
        margin-top: 12px;
    }
    .partner2-section4{
        margin-bottom: 0px;
    }
    .partner2-section5 .row{
        margin-top: 40px;
    }
    .partner2-section5 .p1{
        margin-top: 28px;
    }
    .partner2-section5 .info a{
        width: 100%;
        text-align: center;
    }
    .partner2-section5{
        margin-bottom: 70px;
    }
    .partner2-section5 .info,.partner2-section4 .info{
        padding-left: 4%;
        padding-right: 4%;
    }
    .partner2-page-ar .partner2-section1{
        padding-left: 3%;
        padding-right: 3%;
    }
    .partner2-page-ar .partner2-section5 .info, .partner2-page-ar .partner2-section4 .info{
        padding-left: 3%;
        padding-right: 3%;
    }
    .partner2-page-cn .partner2-section1 ul{
        width: 100%;
    }
}
</style>

