export default {
  多元活动连接全球市场: "Kết nối đa dạng hoạt động với thị trường toàn cầu",
  共享全球顶级资源与学术支持:
    "Tiếp cận nguồn lực hàng đầu thế giới và hỗ trợ học thuật để bắt đầu hành trình giao dịch đầy tự tin.",
  了解更多: "Tìm hiểu thêm",
  敬请期待: "Hãy đón chờ những điều thú vị sắp tới!",
  每个认真交易的人:
    "Mỗi một nhà giao dịch nghiêm túc đều xứng đáng được coi trọng",
  activityListMetaData: [
    {
      label: "Hoạt động thị trường",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_en.png"),
          title: "Cuộc Thi Giao Dịch Triệu Đô EBC II",
          subtitle: "Sao chép miễn phí lợi nhuận của nhà vô địch",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7_vi.png"),
          title: "EBC hợp tác cùng TradingView mở rộng mạng lưới tài nguyên toàn cầu",
          subtitle: "Ưu đãi có thời hạn: Nhận gói hội viên TradingView và theo dõi biến động thị trường theo thời gian thực",
          url: "/TradingView",
          time: "",
        },
        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_en.png"),
          title: "Khuyến khích người sáng tạo",
          subtitle:
            "Sáng tạo góc nhìn tài chính độc đáo, nhận ngay phần thưởng độc quyền",
          url: "/cfa",
          time: "",
        },
      ],
    },
    {
      label: "Tin tức EBC",
      list: [
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_vi.png"),
          title: "EBC sắp có mặt tại Money Expo Mexico 2025",
          subtitle:
            "Với vai trò là Nhà tài trợ Bạch Kim, EBC hân hạnh góp mặt tại Money Expo Mexico 2025 – sự kiện tài chính hàng đầu tại Mexico! Hãy đến với chúng tôi tại gian hàng số 48 để cùng khám phá xu hướng fintech mới nhất và tìm kiếm những cơ hội giao dịch đột phá!",
          url: "",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_vi.png"),
          title: "Cách tận dụng kinh tế học khí hậu để khám phá cơ hội đầu tư",
          subtitle: `Kết hợp với chủ đề nóng hiện nay, hội thảo "Các nhà kinh tế thực sự làm gì?" sẽ thảo luận cách ứng dụng kinh tế học để thúc đẩy cải tiến từ các chính trị gia, góp phần vào sự thịnh vượng kinh tế.`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_vi.png"),
          title: "Hội thảo EBC hợp tác cùng Đại học Oxford",
          subtitle: `Hội thảo lần này sẽ diễn ra vào lúc 14:30 (GMT) ngày 14/11 tại trường Đại học Oxford, Christ Church College. Sự kiện được phát trực tiếp và mở cửa cho các nhà đầu tư toàn cầu.`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
