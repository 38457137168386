<template>
	<div :class="$t('FAQ.faq-class-name')" class="FAQ">
		<page-navigate></page-navigate>
		<div class="faq-t-sub1">
                    <h1 class="p1">{{$t('FAQ.faq-title')}}</h1>
                    <p class="FAQ-line"></p>
                    <p class="p2" v-html="$t('FAQ.faq-desc')"></p>
                    <div class="search-container">
                        <input id="searchInput" class="search-input" type="search" v-model="searchQuery" @input="performSearch" :placeholder="$t('FAQ.faq-search')">
                        <i class="search-icon" @click="performSearch"><img src="../assets/image/FAQ/FAQ-search.svg" alt=""></i>
                    </div>
                </div>


                <div class="faq-tabs" v-if="!searchQuery">
                    <div class="faq-tab" v-for="(item, index) in $t('FAQ.faq-tabs')" :key="index"
                        :class="{ active: activeTabIndex === index }"
                        @click="setActiveTab(index)">
                        {{ item }}<i></i>
                    </div>
                </div>

                <div class="faq-tab-content" v-for="(item, index) in filteredFaqData" :key="index"
     :class="{ active: activeTabIndex == index }">
    <div class="FAQ-page-screen-3">
        <div class="problem-box">
            <div class="vision-item__line d-block d-md-none"></div>
            <div id="accordionExample" class="accordion">
                <div id="accordionFlushExample" class="accordion accordion-flush">
                    <div class="accordion-item" v-for="(item2, index2) in item" :key="index2">
                        <h2 class="accordion-header" :id="'flush-heading' + index2">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#flush-collapse' + index2" aria-expanded="false"
                                    :aria-controls="'flush-collapse' + index2" v-html="(index2 + 1 + '. ') + highlightMatch(item2.Q)">
                                
                            </button>
                        </h2>
                        <div :id="'flush-collapse' + index2" class="accordion-collapse collapse"
                             :aria-labelledby="'flush-heading' + index2" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body" v-html="highlightMatch(item2.A)" :style="{ 'white-space': 'pre-wrap' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



				<page-slogan></page-slogan>
				<page-footer></page-footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				faqData: [],
                filteredFaqData: [],
                searchQuery: '',
                seoInfo: "",
                activeTabIndex: 0
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$t("common.requestLang"),//this.$store.state.Lan,
				path: "/FAQ"
			}).then(res => {
				this.seoInfo = res
			})
            this.faqData = this.$t('FAQ.faq-data');
            this.filteredFaqData = this.faqData;
		},
		methods: {
            setActiveTab(index) {
                this.activeTabIndex = index;
            },
            setActiveTab(index) {
                this.activeTabIndex = index;
                this.performSearch(); // 重新执行搜索，当切换标签页时
            },
            highlightMatch(text) {
                if (!this.searchQuery) return text;
                const regex = new RegExp(`(${this.searchQuery})`, 'gi');
                return text.replace(regex, `<span class="highlight">$1</span>`);
            },
            performSearch() {
                if (!this.searchQuery) {
                    this.filteredFaqData = this.faqData;
                } else {
                    const lowerCaseQuery = this.searchQuery.toLowerCase();
                    // 创建一个新的对象来存储过滤后的数据
                    const filteredData = {};

                    // 遍历每个分类
                    Object.keys(this.faqData).forEach(category => {
                        // 过滤每个分类中的问题和答案
                        const filteredQuestions = this.faqData[category].filter(faq =>
                            faq.Q.toLowerCase().includes(lowerCaseQuery) ||
                            faq.A.toLowerCase().includes(lowerCaseQuery)
                        );

                        // 只有当分类中有匹配的问题时才添加到结果中
                        if (filteredQuestions.length > 0) {
                            filteredData[category] = filteredQuestions;
                        }
                    });

                    this.filteredFaqData = filteredData;
                }
            }
		}
	}
</script>

<style>
.c-footer .fp-overflow{
		display: unset !important;
	}
	.faq-t-sub1{
  background: url(../assets/image/FAQ/faq-new2-bg.jpg?t=1715168068) 100%/auto 100% no-repeat;
  height: 450px;
  margin-top: 0px;
  text-align: center;
  padding-top: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.faq-t-sub1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}
.faq-t-sub1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
}
.FAQ-line{
    width: 80px;
    border: 1px solid #D9D9D9;
    margin: 40px auto 30px;
}
.faq-t-sub1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 940px;
    margin: 0 auto;
    line-height: 38px;
    opacity: 0.9;
    font-family: auto;
}
.faq-t-sub1 .btn{
    display: block;
    width: max-content;
    padding: 10px 56px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 28px auto;
}
.faq-t-sub1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.FAQ-public-title{
    color: #3D0101;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}
.FAQ-public-line{
    width: 70px;
    border: 1px solid #AF8147;
    margin: 46px auto 36px;
}
.FAQ-public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.FAQ-public-desc{
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.t-sub2{
    background: #F8F8F8;
    padding: 80px 0;
}
.t-sub2 .row{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}
.t-sub2 .row div{
    margin-top: 10px;
}
.t-sub2 .row img{
    width: 30px;
    position: relative;
    top: 7px;
}
.t-sub2 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.FAQ-public-btn{
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
}
.FAQ-public-btn:hover{
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}

.btn-box{
    width: max-content;
    margin: auto;
}


.t-sub3{
    background: #f8f8f8;
    padding: 90px 0 160px;
}
.t-sub3 .row{
    margin: 54px auto;
    width: 1200px;
}
.t-sub3 .row div{
    text-align: center;
}
.t-sub3 .row img{
    width: 65px;
    position: relative;
    top: 26px;
}
.t-sub3 .row img{
    width: 90px;
}
.t-sub3 .row .title{
    color: #080E1C;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 22px;
}
.t-sub3 .row .desc{
    color: #080E1C;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 10%;
}


.t-sub4{
    background: #F8F8F8;
    padding: 80px 0;
}
.t-sub4 img{
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}
.t-sub4 .FAQ-public-desc{
    width: 60%;
    text-align: left;
}


.t-sub5{
    background: #FFFFFF;
    padding: 80px 0;
}
.t-sub5 .t-sub5-img{
    display: block;
    margin: 40px auto;
    width: 1000px;
}
.t-sub5 .FAQ-public-desc{
    width: 500px;
}
.t-sub5-content{
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.t-sub5-bottom{
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}
.t-sub5-bottom p{
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}
.t-sub5-bottom img{
    width: 7px;
    margin-left: 10px;
}
.t-sub5-bottom span{
    font-size: 26px;
    font-weight: bold;
}
.FAQ-public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.t-subtner-box-en .t-sub2 .row{
    padding: 50px 0% 26px 22%;
}
.t-subtner-box-en .t-sub2 .row div{
    width: 100%;
}
.t-subtner-box-en .faq-t-sub1 .p1{
    line-height: 52px;
    margin-top: 60px;
}
.t-subtner-box-en .FAQ-public-desc{
    width: 750px;
}

.t-subtner-box-en .t-sub3 .row p{
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}
.t-subtner-box-en .faq-t-sub1 .p2{
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}
.t-subtner-box-en .t-sub2 .row p{
    letter-spacing: 0px;
}
.faq-t-sub1 .btn-ug{
    width: 190px;
}


.t-sub5 .container{
    max-width: 1100px;
}
.t-sub5 .row div{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.t-sub5 .row img{
    width: 80%;
}
.t-sub5 .row p{
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.t-sub6{
    background: #F8F8F8;
    padding: 80px 0;
}
.t-sub6 .container{
    max-width: 1100px;
}
.t-sub6 .row div{
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}
.t-sub6 .row div img{
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}
.t-sub6 .row div p{
    float: left;
    font-size: 16px;
}
.faq-t-sub1 .p1 br{
    display: none;
}

.en-style .faq-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style .faq-t-sub1 .p1{
    margin-top: 80px;
}
.vi-box .faq-t-sub1 .p1{
    margin-top: 40px;
}





.FAQ .product {
    width: 1000px;
    margin: 80px auto;
}

.FAQ .product .accordion-header {
    cursor: pointer
}

.FAQ .product .table-list {
    width: 100%;
    border: none;
    position: relative;
    flex-direction: inherit;
    background: #949699;
}

.FAQ .product .table-list .flex-1 {
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 12px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    cursor: pointer;
    font-family: auto;
    text-align: center;
}

.FAQ .product .table-list .flex-1.active {
    background-color: #3D0101;
    color: #ffffff;
}

.FAQ .product-box__nav {
    /* margin-bottom: 4px; */
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    color: #666666;
    padding: 0 12px;
    background-color: #F8F8F8;
    padding: 4px 0;
}

.FAQ .product-box__nav div {
    line-height: 40px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: auto;
    text-align: center;
}

.FAQ .product-box__accordion-item {
    background: #f7f7f7;
    border: none
}

.FAQ .product-box__accordion-item .accordion-button {
    padding: 10px;
    font-size: 14px;
    background: #ffffff;
    color: #333
}

.FAQ .product-box__accordion-item .accordion-body-head {
    width: 100%;
    padding: 12px 0;
    background-color: #949699;
    color: #f7f7f7
}

.FAQ .product-box__accordion-item .accordion-body-head div {
    flex: 1
}

.FAQ .product-box__accordion-header {
    margin-bottom: 0
}

.FAQ .product-box .accordion-body-tr {
    width: 100%;
    padding: 12px 0;
    font-family: auto;
}

.FAQ .product-box .accordion-body-tr div {
    flex: 1;
    text-align: center;
}

.FAQ .product-box .accordion-body-tr div:nth-of-type(2) {
    color: #48ba13
}

.FAQ .product-box .accordion-body-tr .red {
    color: #cc3221 !important
}

.FAQ .product-box .accordion-body-tr div:nth-of-type(5) {
    color: #48ba13
}

.FAQ .product .look-all {
    display: flex;
    justify-content: center;
    color: #8b8b8b;
    padding: 20px 0;
    font-size: 16px;
}

.FAQ .product .look-all span {
    padding-top: 5px;
    border-top: 1px solid #8b8b8b
}

.FAQ .product .c-underline__top:after {
    background: #7d1614;
    height: 4px
}

.FAQ .accordion-body{
    font-size: 14px;
    font-family: auto;
    background: #f8f8f8;
}


.FAQ .FAQ-page-screen-3 {
    background-color: #ffffff;
    position: relative;
    padding-top: 0px;
    padding-bottom: 50px;
}

.FAQ .FAQ-page-screen-3 .problem-box {
    width: 100%;
    max-width: 882px;
    margin: 22px auto 0
}

.FAQ .FAQ-page-screen-3 .problem-box .accordion {
    padding: 0px 0 0px;
}

.FAQ .FAQ-page-screen-3 .problem-box .accordion-item {
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    border: 0 solid #d9d9d9;
    margin-bottom: 25px;
}
.FAQ .FAQ-page-screen-3 .problem-box .accordion-item:hover{
    box-shadow: 0 12px 18px -6px rgba(34, 56, 101, .12);
}

.FAQ .FAQ-page-screen-3 .problem-box .accordion-button {
    font-size: 18px;
    line-height: 1.5;
    color: #333 !important;
    background: #ffffff;
    font-family: auto;
}

.FAQ-page-screen-3 .problem-box .accordion-button:focus,
.FAQ-page-screen-3 .problem-box .accordion-button:not(.collapsed) {
    box-shadow: none;
    /* background-color: #fff; */
}

.FAQ-page-screen-3 .problem-box .accordion .cont-info {
    display: none
}

.FAQ-page-screen-3 .problem-box .accordion .cont-info.active {
    display: block
}

.FAQ-page-screen-3 .problem-box .accordion .accordion-body {
    line-height: 34px;
    font-size: 16px;
    color: #585858;
    background: #ffffff;
    font-family: auto;
}

.FAQ-page-screen-3 .problem-box .accordion .accordion-body ::v-deep p {
    padding-bottom: 13px
}

.FAQ-page-screen-3 .problem-box .accordion .accordion-body ::v-deep a {
    color: #2ea3f2
}

.FAQ-page-screen-3 .problem-box .accordion-item {
    border: none
}

.rotate .accordion-button:after{
    transform: rotate(180deg);
}

.FAQ-page-screen-3 .page-title {
    text-align: center
}

.FAQ-page-screen-3 .leaf-left,
.FAQ-page-screen-3 .leaf-right {
    position: absolute;
    top: 0;
    right: 0
}

.FAQ-page-screen-3 .leaf-left img,
.FAQ-page-screen-3 .leaf-right img {
    width: 650px;
    display: block
}

.FAQ-page-screen-3 .leaf-left {
    left: -70px;
    right: auto
}

.en-style2 .faq-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .t-sub3 .row .title{
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}
.en-style2 .t-sub3 .row .desc{
    letter-spacing: 0px;
}
.en-style2 .FAQ-public-link2{
    letter-spacing: 0px;
}
.en-style2 .FAQ-public-link{
    letter-spacing: 0px;
}






.faq-tabs {
  display: flex;
  width: max-content;
  margin: 40px auto 0;
}

.faq-tab {
  color: #949699;
  cursor: pointer;
  font-size: 18px;
  font-family: auto;
  padding: 0px 40px;
  text-align: center;
}

.faq-tab i{
    width: 35px;
    height: 2px;
    background: #3D0101;
    display: block;
    margin: 14px auto;
    display: none;
}

.faq-tab.active{
    color: #3D0101;
    font-weight: bold;
}
.faq-tab.active i{
    display: block;
}



.faq-tab.active {
}

.faq-tab-content {
  display: none;
  padding: 20px 20px 0;
  font-size: 20px;
}

.FAQ .highlight {
    background-color: yellow;
    white-space: nowrap;
}

.faq-tab-content.active {
  display: block;
}


.FAQ .search-container {
    position: relative;
    max-width: 500px;
    margin: auto;
    margin-top: 36px;
}


.FAQ .search-input {
    width: 100%;
    padding: 13px 26px;
    padding-right: 50px;
    font-size: 16px;
    border: none;
    border-radius: 30px;
    outline: none;
    font-family: auto;
}


.FAQ .search-icon {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    font-size: 24px;
    color: grey;
    cursor: pointer;
}
.FAQ .search-icon img{
    width: 22px;
    margin-top: 14px;
}


.en-faq-new .faq-tab{
    font-size: 17px;
    padding: 0px 18px;
}
.en-faq-new .FAQ-page-screen-3 .problem-box{
    max-width: 1100px;
}
.en-faq-new .faq-t-sub1 .p2{
    letter-spacing: 0px;
    line-height: 30px;
}






@media(max-width:1400px) {
    .faq-t-sub1 .p1{
        /* font-size: 34px; */
    }
    .faq-t-sub1 .p2{
        /* line-height: 32px; */
        /* font-size: 16px; */
        width: 75%;
    }
    
}


@media(max-width:995px) {
    
    .faq-t-sub1 .p1 br{
        display: block !important;
    }
    
    .faq-t-sub1 .p2{
        width: 100%;
        padding: 20px 8%;
        font-size: 16px;
        line-height: 30px;
    }
    
    .faq-t-sub1 .p1{
        margin-top: 0px;
        line-height: 58px;
        font-size: 34px;
    }
    .faq-t-sub1 .btn{
        margin: 14px auto;
    }
    
    .FAQ-public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
   
    .FAQ .product{
        width: 100%;
    }
    .FAQ .product .table-list .flex-1:nth-child(4),.product .table-list .flex-1:nth-child(5){
        display: none;
    }
    .FAQ .accordion-body{
        padding: 0;
    }
    .FAQ .product-box__nav{
        font-size: 12px;
    }
    .FAQ .product-box__accordion-item .accordion-button{
        font-size: 12px;
    }
    .FAQ .accordion-body{
        font-size: 12px;
    }
    .FAQ-page-screen-3 .problem-box .accordion .accordion-body{
        padding: 1rem 1.25rem;
    }
    .FAQ-page-screen-3 .problem-box .accordion{
        padding-top: 0px;
    }
    .FAQ .product .table-list .flex-1{
        font-size: 13px;
    }
    .FAQ-page-screen-3 .problem-box .accordion{
        margin-bottom: 50px;
    }
    .FAQ .product .accordion-button:after{
        display: none;
    }
    .FAQ .product-box__accordion-item .accordion-button{
        padding: 8px 0;
    }
    .FAQ-page-screen-3{
        padding-bottom: 100px;
    }
    
    .faq-t-sub1 .btn{
        display: block;
    }
    .faq-t-sub1 {
        background: url(../assets/image/FAQ/faq-new2-bg.jpg?t=1715168068) 100%/auto 100% no-repeat;
        height: 420px;
        margin-top: 0px;
        text-align: center;
        padding-top: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .faq-t-sub1 .p1{
        font-size: 25px;
        letter-spacing: 0px;
    }
    
    .FAQ-page-screen-3{
        background: #ffffff;
    }
    .FAQ-page-screen-3 .problem-box .accordion-button{
        background: #ffffff;
        font-size: 16px;
    }
    .FAQ-page-screen-3 .problem-box .accordion .accordion-body{
        background: #ffffff;
    }
    .FAQ-page-screen-3{
        padding-top: 60px;
        padding-bottom: 0;
    }
    .FAQ .FAQ-public-line{
        margin: 30px auto 30px;
    }
    .en-style2 .faq-t-sub1 .p2{
        padding-top: 14px;
        width: 100%;
    }

    .faq-t-sub1 .p2 br{
        display: none;
    }
    .FAQ .search-input {
        padding: 10px 15px;
        padding-right: 40px;
    }

    .FAQ .search-icon {
        right: 15px;
    }

    .faq-tabs{
        margin: 22px 0px 12px;
        overflow-x: scroll;
        overflow-y: hidden;
        text-align: justify;
        width: 100%;
        white-space: nowrap;
        height: 45px;
        padding-top: 6px;
        padding-left: 6px;
    }
    .faq-tab{
        padding: 0px 16px;
        font-size: 16px;
    }
    .FAQ-page-screen-3{
        padding-top: 0px;
    }
    .FAQ-page-screen-3 .problem-box{
        margin-top: 0px;
    }
    .faq-tab-content.active{
        padding: 0;
        padding-top: 10px;
    }
    .FAQ .search-input{
        width: 80%;
        font-size: 14px;
    }
    .FAQ .search-icon{
        right: 60px;
    }
    .FAQ .search-icon img{
        width: 19px;
    }
    .FAQ .search-container{
        margin-top: 20px;
    }
    .en-faq-new .faq-t-sub1{
        height: 500px;
    }
    .FAQ-line{
        margin: 20px auto 10px;
    }
    .FAQ .FAQ-page-screen-3 .problem-box .accordion-item{
        margin-bottom: 10px;
    }
    .FAQ .FAQ-page-screen-3 .problem-box{
        margin-top: 10px;
    }
    .faq-tab-content.active{
        padding-top:10px;
    }
}
</style>